import React from 'react'
import { IHr } from './props'

const Hr = ({color, borderWidth}: IHr) => {
  return (
    <hr
      style={{
        borderBottom: borderWidth,
        borderColor: color,
        borderStyle: 'none none solid none',
      }}
    />
  )
}

Hr.defaultProps = {
  color: '#F5F5F5',
  borderWidth: 2,
}

export default Hr