import React, { useEffect } from "react";
import Section from "components/molecules/section";
import { BannerSlider } from "components/organisms";
import { Button } from "components/atoms";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const Comprobantes = () => {
  const { t } = useTranslation();

  const datas = {
    banners: [
      {
        id: 1,
        titulo: t("comprobantes.howToAccessInvoices.accessInvoices"),
        subtitulo: "",
        color_fondo_logo: "rgba(62, 145, 41, 1)",
        img_desktop: {
          name: "banner-home",
          path: "https://s3.us-east-1.amazonaws.com/multimediaqa.casa-andina.com/system/images/960X540_55555.jpg",
          width: 1440,
          height: 400,
          size: 794666,
          title: "Banner Home",
          alt: "Banner Home",
          description: null,
          caption: "Banner Home",
        },
        img_mobile: [],
        descuento: "25",
        descripcion_boton: "",
        redireccion_boton: "",
        img_logos: [],
        tipo_banner: {
          id: 1,
          titulo: "landings",
        },
      },
    ],
  };

  const handleClick = (opcion: number) => {
    let nuevaURL;

    switch (opcion) {
      case 1:
        nuevaURL =
          "https://ww1.sunat.gob.pe/ol-ti-itconsultaunificadalibre/consultaUnificadaLibre/consulta?gdp=cj0kcqiapoyqbhdlarisagfnympk7gx0mgkcplc0na9bz97ilo8fnklwmokie1j7d9sifoyplmpn_lwaamefealw_wcb:2520252";
        break;
      case 2:
        nuevaURL =
          "https://asp403r.paperless.com.pe/BoletaCasaAndina/?gdp=cj0kcqiapoyqbhdlarisagfnympk7gx0mgkcplc0na9bz97ilo8fnklwmokie1j7d9sifoyplmpn_lwaamefealw_wcb:2520218";
        break;
      case 3:
        nuevaURL =
          "https://asp403r.paperless.com.pe/BoletaCasaAndinaSelva/?gdp=cj0kcqiapoyqbhdlarisagfnympk7gx0mgkcplc0na9bz97ilo8fnklwmokie1j7d9sifoyplmpn_lwaamefealw_wcb:2520209";
        break;
    }

    window.open(nuevaURL, "_blank");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BannerSlider data={datas.banners} gradiente />
      <Section>
        <div className="comprobantes">
          <div className="comprobantes__container">
            <div className="comprobantes__container--form">
              <h2>{t("comprobantes.howToAccessInvoices.question")}</h2>
              <p>{t("comprobantes.howToAccessInvoices.intro")}</p>
              <ul>
                <li>
                  {t(
                    "comprobantes.howToAccessInvoices.electronicBillingPlatform"
                  )}
                </li>
                <li>
                  {t("comprobantes.howToAccessInvoices.historicalDocuments")}
                </li>
              </ul>
              <div className="content-button">
                <Button type="gold" onClick={() => handleClick(1)}>
                  {t(
                    "comprobantes.howToAccessInvoices.downloadPrintHistorical"
                  )}
                </Button>
                <Button type="gold" onClick={() => handleClick(3)}>
                  {t(
                    "comprobantes.howToAccessInvoices.downloadPrintRegionSelva"
                  )}
                </Button>
                <Button type="gold" onClick={() => handleClick(2)}>
                  {t("comprobantes.howToAccessInvoices.downloadPrint")}
                </Button>
              </div>
              <ul>
                <li>{t("comprobantes.howToAccessInvoices.emailProvided")}</li>
                <li>
                  {t("comprobantes.howToAccessInvoices.printedRepresentation")}
                </li>
              </ul>
              <h2>{t("comprobantes.receivingInvoices.question")}</h2>
              <p>{t("comprobantes.receivingInvoices.intro")}</p>
              <p>{t("comprobantes.receivingInvoices.note")}</p>
              <ul>
                <li>
                  {t("comprobantes.receivingInvoices.noCommunication")}{" "}
                  <a
                    href="tel:+012139700"
                    target="_blank"
                    rel="noreferrer"
                    className="cursor"
                  >
                    213-9700
                  </a>{" "}
                  {t("comprobantes.receivingInvoices.anexo")}{" "}
                  <a
                    href="mailto:facturacion-corporativo@casa-andina.com"
                    target="_blank"
                    rel="noreferrer"
                    className="cursor"
                  >
                    facturacion-corporativo@casa-andina.com
                  </a>
                </li>
                <li>{t("comprobantes.receivingInvoices.appliesTo")}</li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Comprobantes;
