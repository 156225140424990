import React, { useState } from "react";
import "./styles.scss";
import { Icon, IconText, Paragraph, Text } from "components/atoms";
import { Modal } from "components/molecules";
import { useTranslation } from "react-i18next";

const PromotionsCopy = ({ data, isList, bank }: any) => {
  const [modal, setModal] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");
  const { t } = useTranslation();

  const toogleModal = () => setModal(!modal);

  const handleCopyClick = () => {
    const textToCopy = data.codigo;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopyMessage("¡Copiado en el portapapeles!");
        setTimeout(() => {
          setCopyMessage("");
        }, 8000);
      })
      .catch((error) => {
        console.error("Error al copiar el texto:", error);
        setCopyMessage("Error al copiar el texto");
      });
  };

  return (
    <div className="promotionscopy">
      <div className="promotionscopy__container">
        <div className="promotionscopy__container--description">
          <Text html={{ __html: data.descripcion }} className={"description"} />
          {data.terminos_condiciones === null ||
          data.terminos_condiciones === "" ||
          !data.terminos_condiciones ? (
            ""
          ) : (
            <div className="terms">
              <p>
                *{t("promotions.tyc")}
                <span onClick={toogleModal} className="txtlink">
                  {t("promotions.here")}
                </span>
              </p>
            </div>
          )}
        </div>
        <div className="promotionscopy__container--icon">
          <Icon name="rightcopy" list="arrows" width={26} height={45} />
        </div>
        <div className="promotionscopy__container--codigo">
          <Text
            html={{ __html: data.prm_descripcion_2 || data.descripcion_2 }}
            family={"txpromotion"}
          />
          {data.codigo === null || data.codigo === undefined || !data.codigo ? (
            ""
          ) : (
            <div className="btnclip">
              <div className="btnclip__container">
                <div className="btnclip__container--text">
                  <Paragraph
                    text={data.codigo}
                    family={"txpromotion"}
                    style={{ color: data.color_promocion }}
                  />
                </div>
                <div
                  className="btnclip__container--copy"
                  onClick={handleCopyClick}
                >
                  <IconText
                    nameIcon="copy"
                    listIcon={"items"}
                    text={"Copiar"}
                    family={"txpromotion"}
                  />
                </div>
              </div>
              {copyMessage && (
                <p style={{ color: data.color_promocion, paddingTop: "10px" }}>
                  {copyMessage}
                </p>
              )}
            </div>
          )}
          <div className="container-list">
            {data.servicios &&
              data.servicios.map((item: any, index: number) => (
                <div className="list" key={index}>
                  <img
                    src={item.icono?.path}
                    alt={item.icono?.alt}
                    width={16}
                    height={16}
                  />
                  <p>{item.titulo}</p>
                </div>
              ))}
          </div>
        </div>
      </div>

      <Modal
        isTitle={true}
        showModal={modal}
        close={toogleModal}
        title={t("promotions.tycPromotion")}
      >
        <div className="modal__terminos">
          <Text
            html={{ __html: data.terminos_condiciones }}
            type="paragraph"
            className={"paragraph"}
          />
        </div>
      </Modal>
    </div>
  );
};

PromotionsCopy.defaultProps = {
  isList: false,
  tyC: true,
};

export default PromotionsCopy;
