import { Icon, Text } from "components/atoms";
import Section from "../section";
import "./styles.scss";

const ListIcons = ({ title, isBgImg, data, colorIcon }: any) => {
  return (
    <Section title={title} pB={0} isBg={isBgImg}>
      <div className="list">
        <div className="description">
          <div className="services">
            {data.map((item: any, index: number) => (
              <div className="column" key={index}>
                {/* <img src={item.icono?.path || Check} alt={item.icono?.alt} width={18} height={18}/> */}
                <Icon
                  name={item.titulo.toLowerCase()}
                  color={colorIcon}
                  list="premium"
                  width={20}
                  height={20}
                />
                <div>
                  <Text
                    className={"paragraph"}
                    text={item.titulo}
                    type="paragraph"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ListIcons;
