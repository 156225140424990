
export const banner_data_centrado = [
    {
      id: 1,
      titulo: '',
      subtitulo: '',
      color_fondo_logo: 'rgba(62, 145, 41, 1)',
      img_desktop: {
        name: 'banner-friends-family',
        path: 'https://s3.us-east-1.amazonaws.com/ca-webprod/media/55379af1-8b2a-4f06-9868-c23ce89d56d2.jpg',
        width: 1440,
        height: 400,
        size: 794666,
        title: '',
        alt: 'Banner Friends and Family',
        description: null,
        caption: 'Banner riends and Family'
      },
      img_mobile: [],
      descuento: '',
      descripcion_boton: '',
      redireccion_boton: '',
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: 'Título/subtitulo collage logo'
      }
    }
  ]