import bbvaWhite from "./bbva-white.png";
import bbvaColor from "./bbva-color.png";
import bcpWhite from "./bcp-white.png";
import bcpColor from "./bcp-color.png";
import dinnersWhite from "./dinners-white.png";
import dinnersColor from "./diiners-black.png";
import ibkWhite from "./ibk-white.png";
import ibkColor from "./ibk-color.png";
import background from "./background.webp";
import Card1 from "./card1.png";
import Card2 from "./card2.png";
import Card3 from "./card3.png";
import Card4 from "./card4.png";

export const bankEntities = {
  Card1,
  Card2,
  Card3,
  Card4,
  bbvaWhite,
  bbvaColor,
  bcpWhite,
  bcpColor,
  dinnersWhite,
  dinnersColor,
  ibkWhite,
  ibkColor,
  background
};