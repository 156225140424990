import { useLayoutEffect, useState } from "react";
import { Text } from "components/atoms";
import Section from "components/molecules/section";
import { BannerSlider } from "components/organisms";
import { Accordion } from "components/molecules";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import Texture from 'assets/images/bkg-texture.webp';

const PreguntasFrecuentes = () => {
  const { t } = useTranslation();

  const banner = [
    {
      id: 1,
      titulo: t("faq.title"),
      subtitulo: "",
      color_fondo_logo: "rgba(62, 145, 41, 1)",
      img_desktop: {
        name: "banner-home",
        path: "https://s3.us-east-1.amazonaws.com/multimediaqa.casa-andina.com/images/1440X700_preguntasfrecuentescasaandinahoteles2.JPG",
        width: 1440,
        height: 400,
        size: 794666,
        title: "Banner Home",
        alt: "Banner Home",
        description: null,
        caption: "Banner Home",
      },
      img_mobile: [],
      descuento: "25",
      descripcion_boton: "",
      redireccion_boton: "",
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: "landings",
      },
    },
  ];

  const data2 = [
    {
      id: 1,
      titulo: t("faq.reservePolicy.title"),
      data: [
        {
          id: 1,
          pregunta: t("faq.reservePolicy.question1"),
          respuesta: t("faq.reservePolicy.answer1")
        },
        {
          id: 2,
          pregunta: t("faq.reservePolicy.question2"),
          respuesta: t("faq.reservePolicy.answer2")
        },
        {
          id: 3,
          pregunta: t("faq.reservePolicy.question3"),
          respuesta: t("faq.reservePolicy.answer3")
        },
        {
          id: 4,
          pregunta: t("faq.reservePolicy.question4"),
          respuesta: t("faq.reservePolicy.answer4")
        },
        {
          id: 5,
          pregunta: t("faq.reservePolicy.question5"),
          respuesta: t("faq.reservePolicy.answer5")
        },
        {
          id: 6,
          pregunta: t("faq.reservePolicy.question6"),
          respuesta: t("faq.reservePolicy.answer6")
        },
        {
          id: 7,
          pregunta: t("faq.reservePolicy.question7"),
          respuesta: t("faq.reservePolicy.answer7")
        },
        {
          id: 8,
          pregunta: t("faq.reservePolicy.question8"),
          respuesta: t("faq.reservePolicy.answer8")
        },
        {
          id: 9,
          pregunta: t("faq.reservePolicy.question9"),
          respuesta: t("faq.reservePolicy.answer9")
        },
        {
          id: 10,
          pregunta: t("faq.reservePolicy.question10"),
          respuesta: t("faq.reservePolicy.answer10")
        },
        {
          id: 11,
          pregunta: t("faq.reservePolicy.question11"),
          respuesta: t("faq.reservePolicy.answer11")
        },
        {
          id: 12,
          pregunta: t("faq.reservePolicy.question12"),
          respuesta: t("faq.reservePolicy.answer12")
        },
        {
          id: 13,
          pregunta: t("faq.reservePolicy.question13"),
          respuesta: t("faq.reservePolicy.answer13")
        },
        {
          id: 14,
          pregunta: t("faq.reservePolicy.question14"),
          respuesta: t("faq.reservePolicy.answer14")
        },
        {
          id: 15,
          pregunta: t("faq.reservePolicy.question15"),
          respuesta: t("faq.reservePolicy.answer15")
        },
        {
          id: 16,
          pregunta: t("faq.reservePolicy.question16"),
          respuesta: t("faq.reservePolicy.answer16")
        },
        {
          id: 17,
          pregunta: t("faq.reservePolicy.question17"),
          respuesta: t("faq.reservePolicy.answer17")
        },
        {
          id: 18,
          pregunta: t("faq.reservePolicy.question18"),
          respuesta: t("faq.reservePolicy.answer18")
        },
      ],
    },
    {
      id: 2,
      titulo: t("faq.activityTour.title"),
      data: [
        {
          id: 1,
          pregunta: t("faq.activityTour.question1"),
          respuesta: t("faq.activityTour.answer1")
        },
        {
          id: 2,
          pregunta: t("faq.activityTour.question2"),
          respuesta: t("faq.activityTour.answer2")
        },
        {
          id: 3,
          pregunta: t("faq.activityTour.question3"),
          respuesta: t("faq.activityTour.answer3")
        },
        {
          id: 4,
          pregunta: t("faq.activityTour.question4"),
          respuesta: t("faq.activityTour.answer4")
        },
      ],
    },
    {
      id: 3,
      titulo: t("faq.infrastructure.title"),
      data: [
        {
          id: 1,
          pregunta: t("faq.infrastructure.question1"),
          respuesta: t("faq.infrastructure.answer1")
        },
        {
          id: 2,
          pregunta: t("faq.infrastructure.question2"),
          respuesta: t("faq.infrastructure.answer2")
        },
        {
          id: 3,
          pregunta: t("faq.infrastructure.question3"),
          respuesta: t("faq.infrastructure.answer3")
        },
        {
          id: 4,
          pregunta: t("faq.infrastructure.question4"),
          respuesta: t("faq.infrastructure.answer4")
        },
      ],
    },
    {
      id: 4,
      titulo: t("faq.contacts.title"),
      data: [
        {
          id: 1,
          pregunta: t("faq.contacts.question1"),
          respuesta: t("faq.contacts.answer1")
        },
        {
          id: 2,
          pregunta: t("faq.contacts.question2"),
          respuesta: t("faq.contacts.answer2")
        },
        {
          id: 3,
          pregunta: t("faq.contacts.question3"),
          respuesta: t("faq.contacts.answer3")
        },
      ],
    },
  ];

  const [activeTab, setActiveTab] = useState<number | null>(1);

  const handleTabClick = (activity: any) => {
    setActiveTab(activity.id);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BannerSlider data={banner} gradiente />
      <Section bgImage={Texture} isTitle={false} isBg>
        <div className="container-questions">
          <div>
            <p className="titulo-questions">{t("faq.title")}</p>
          </div>
          <div className="tabsfaq-section">
            <div className="tabs-container">
              <div className="tabs">
                {data2.map((activity: any) => (
                  <div
                    key={activity.id}
                    className={activeTab === activity.id ? "active" : ""}
                    onClick={() => handleTabClick(activity)}
                  >
                    <Text
                      text={activity.titulo}
                      className={activeTab === activity.id ? "active-bold" : ""}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="content">
              {data2.map((activity: any) => (
                <section
                  key={activity.id}
                  className={activeTab === activity.id ? "active" : ""}
                >
                  <div className="ta-left">
                    {activity.data.map((item: any) => (
                      <Accordion
                        title={item.pregunta}
                        description={item.respuesta}
                        key={item.id}
                        lineColor="#e9e6e6"
                      />
                    ))}
                  </div>
                </section>
              ))}
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default PreguntasFrecuentes;
