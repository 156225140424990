import { BannerSlider } from 'components/organisms'
import { banner_data_centrado, valuations } from './data'
import './styles.scss'
import Section from 'components/molecules/section'
import { Carousel, IconText, Text } from 'components/atoms'
import Map from './map.png'
import Icon from 'components/atoms/icon'
import SectionCardsTypes from 'components/organisms/section-cards-types'
import { useIntl } from 'react-intl'
import { messages } from 'utils/lang'

const QuienesSomos = () => {
  const { locale } = useIntl()
  return (
    <>
      <BannerSlider data={banner_data_centrado} />
      <Section>
        <div className='quienessomos'>
          <div className='quienessomos__container'>
            <p>
             {messages[locale].quienessomosuno}
            </p>
            <div className='quienessomos__container-mapa'>
              <img src={Map} alt='mapa' />
            </div>
            <h2>{messages[locale].quienessomosdos}</h2>
          </div>
        </div>
      </Section>
      <Carousel />
      <Section>
        <div className='quienessomos2'>
          <div className='quienessomos2__container'>
            <h2>{messages[locale].quienessomostres}</h2>
            <div className='quienessomos2__container-text'>
              <div className='content'>
                <Icon name={'dot'} list={'items'} width={20} height={20}/>
                <Text type='paragraph' text={messages[locale].quienessomoscuatro} className={'title'} />
                <Text type='paragraph' text={messages[locale].quienessomoscinco} className={'paragraph'} />
              </div>
              <div className='content'>
                <Icon name={'dot'} list={'items'} width={20} height={20}/>
                <Text type='paragraph' text={messages[locale].quienessomosseis} className={'title'} />
                <Text type='paragraph' text={messages[locale].quienessomossiete} className={'paragraph'} />
              </div>
            </div>
            <h2>{messages[locale].quienessomosocho}</h2>
            <div className='quienessomos2__container-iconos'>
              <IconText
                nameIcon='cultura'
                listIcon={'items'}
                type='bottom2'
                text1='Cultura de'
                text2='integridad'
                className1='text1'
                className2='text2'
                w={112}
                h={112}
              />
              <IconText
                nameIcon='vocacion'
                listIcon={'items'}
                type='bottom2'
                text1='Vocación de'
                text2='servicio'
                className1='text1'
                className2='text2'
                w={112}
                h={112}
              />
              <IconText
                nameIcon='trabajo'
                listIcon={'items'}
                type='bottom2'
                text1='Trabajo en'
                text2='equipo'
                className1='text1'
                className2='text2'
                w={112}
                h={112}
              />
              <IconText
                nameIcon='atencion'
                listIcon={'items'}
                type='bottom2'
                text1='Atención al'
                text2='detalle'
                className1='text1'
                className2='text2'
                w={112}
                h={112}
              />
              <IconText
                nameIcon='superacion'
                listIcon={'items'}
                type='bottom2'
                text1='Espíritu de'
                text2='superación'
                className1='text1'
                className2='text2'
                w={112}
                h={112}
              />
            </div>
          </div>
        </div>
      </Section>
      <div className='bordercolumn'>
        <div className='bordercolumn__text'>
          <p className='title'>{messages[locale].quienessomoscatorce}</p>
          <p>
          {messages[locale].quienessomosquince}
          </p>
          <div className='lista'>
            <p>{messages[locale].quienessomosdieciseis}</p>
            <p>
            {messages[locale].quienessomosdiecisiete}
            </p>
          </div>
          <div className='lista'>
            <p>{messages[locale].quienessomosdieciocho}</p>
            <p>
            {messages[locale].quienessomosdiecinueve}
            </p>
          </div>
          <div className='lista'>
            <p>{messages[locale].quienessomosveinte}</p>
            <p>
            {messages[locale].quienessomosveintiuno}
            </p>
          </div>
          <div className='final'>
            <p>{messages[locale].quienessomosveintidos}</p>
          </div>
        </div>
        <div>
          <img
            src={
              'https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/media/b793570d-e349-4043-9309-67217e419eaf.jpg'
            }
            alt='imagen'
            width={460}
            height={360}
          />
        </div>
      </div>

      <SectionCardsTypes
        data={valuations}
        type='equipo'
        title={messages[locale].quienessomosveintitres}
      />

      <SectionCardsTypes
        data={valuations}
        type='reconocimiento'
        title={messages[locale].quienessomosveinticuatro}
      />

      <SectionCardsTypes
        data={valuations}
        type='logros'
        title={messages[locale].quienessomosveinticinco}
      />

      {/* <Valuations
        data={valuations}
        isLogoValuation={false}
        title={'Nuestros reconocimientos'}
      /> */}
    </>
  )
}

export default QuienesSomos
