import { useState } from 'react'
import { CardCustom, Modal } from 'components/molecules'
import { IconText, Text } from 'components/atoms'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Thumbs } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import './styles.scss'
import SwiperCore from 'swiper'
import Gallery from 'components/molecules/gallery'
import LightGallery from 'lightgallery/react'
import lgZoom from 'lightgallery/plugins/zoom'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'

SwiperCore.use([Navigation, Thumbs])

const GalleryHotels = ({ data, nameHotel }: any) => {
  const [galleryModal, setGalleryModal] = useState(false)
  const [galleryModalMob, setGalleryModalMob] = useState(false)
  const [selectedActivityIndex, setSelectedActivityIndex] = useState<
    number | null
  >(null)

  const slicedData = data.slice(0, 6)

  const toggleActivitiesModal = (itemIndex: number | null) => {
    setSelectedActivityIndex(itemIndex)
    setGalleryModal(!galleryModal)
  }

  const toggleActivitiesModal2 = (itemIndex: number | null) => {
    setSelectedActivityIndex(itemIndex)
  }

  const HighlightedText = ({ text, color }: any) => {
    const keywords = ['Premium', 'Standard', 'Select']

    const highlightKeywords = (text: string, keywords: string[]) => {
      const regex = new RegExp(`\\b(${keywords.join('|')})\\b`, 'gi')
      return text.replace(
        regex,
        match => `<span style="color: ${color}">${match}</span>`
      )
    }

    const formattedText = highlightKeywords(text, keywords)

    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />
  }

  const onBeforeSlide = (detail: any) => {
    const { index, prevIndex } = detail
    console.info(index, prevIndex)
  }

  const handleGallerMob = (itemIndex: number | null) => {
    setGalleryModalMob(!galleryModalMob)
    setSelectedActivityIndex(itemIndex)
  }

  const toggleRoomsModal2 = (itemIndex: number | null) => {
    setSelectedActivityIndex(itemIndex)
  }

  const selectedActivity =
    selectedActivityIndex !== null ? data[selectedActivityIndex] : null

  return (
    <div className='galleryhotels'>
      <div className='galleryhotels__container'>
        <div className='ismobile'>
          <Swiper
            slidesPerView={'auto'}
            grabCursor={true}
            className='galleryhotels__swiper'
          >
            {slicedData.map((item: any, index: number) => (
              <SwiperSlide key={index}>
                <CardCustom type={'gallery'} data={item} onClick={() => handleGallerMob(index)}/>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className='isdesktop'>
          <div className={`galleryhotels__container--items length-${data.length}`}>
            {data.map((item: any, index: number) => (
                <div className='textgallery'
                key={item.id}
                onClick={() => toggleActivitiesModal(index)}
                style={{ backgroundImage: `url(${item.img_xs?.path})` }}>
                  <div className='textgallery__content'>
                    <Text
                      text={item.titulo}
                      type='paragraph'
                      className='paragraph'
                    />
                    <IconText
                      type='left'
                      text={`1/${item.cantidad}`}
                      nameIcon='img'
                      listIcon={'items'}
                      className='count'
                      w={16}
                      h={16}
                    />
                  </div>

              </div>
            ))}
          </div>
        </div>

      </div>

      <Modal
        breadcrumb={true}
        showModal={galleryModalMob}
        close={handleGallerMob}
        title={HighlightedText({ text: nameHotel, color: '#D69C4F' })}
        route={'Galeria'}
        allowButtons={false}
        btninactive={toggleActivitiesModal}
        isSubtitle={true}
        subtitleCustom={
          <>
            <div className='galleriestabs'>
              <Swiper
                slidesPerView={'auto'}
                grabCursor={true}
                navigation={false}
                className='tabactivitys__swiper'
              >
                {data.map((index: number) => (
                  <Swiper
                    key={index}
                    slidesPerView={'auto'}
                    grabCursor={true}
                    navigation={false}
                    className='tabactivitys__swiper'
                  >
                    {data.map((activity: any, index: number) => (
                      <SwiperSlide key={index}>
                        <div
                          key={activity.id}
                          className={`tabactivityborder ${index === selectedActivityIndex ? 'active' : ''
                            }`}
                          onClick={() => toggleRoomsModal2(index)}
                        >
                          <Text
                            text={activity.titulo}
                            type='paragraph'
                            className={
                              `activity-tab` === activity.id ? 'active-bold' : ''
                            }
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ))}
              </Swiper>
            </div>
          </>
        }
      >
        {selectedActivity && (
          <div className='columnroom'>
            <div className='parentcontainer2'>
              <div className='parentcontainer2__container'>
                <LightGallery
                  plugins={[lgZoom]}
                  mode='lg-fade'
                  elementClassNames='parentcontainer2__container--item'
                  onBeforeSlide={onBeforeSlide}
                  showZoomInOutIcons={true}
                  thumbnail={true}
                >
                  {selectedActivity.img.slice(1).concat(selectedActivity.img.slice(0, 1)).map((item: any, index: number) => (
                    <a
                      key={index}
                      href={item?.path}
                      className={`length-${index}`}
                    // data-sub-html={'<p>' + item?.alt + '</p>'}
                    >
                      <img src={item?.path} alt="" className='parentcontainer2__container--img' />
                    </a>
                  ))}
                </LightGallery>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        breadcrumb={true}
        showModal={galleryModal}
        close={toggleActivitiesModal}
        title={HighlightedText({ text: nameHotel, color: '#D69C4F' })}
        route={'Galeria'}
        allowButtons={false}
        btninactive={toggleActivitiesModal}
        btnactive={'Contactar'}
        isSubtitle={true}
        subtitleCustom={
          <>
            <div className='galleriestabs'>
              <Swiper
                slidesPerView={'auto'}
                grabCursor={true}
                navigation={false}
                className='galleriestabs__swiper'
              >
                {data.map((activity: any, index: number) => (
                  <SwiperSlide key={index}>
                    <div
                      key={activity.id}
                      className={`galleriestabsborder ${index === selectedActivityIndex ? 'active' : ''
                        }`}
                      onClick={() => toggleActivitiesModal2(index)}
                    >
                      <Text
                        text={activity.titulo}
                        type='paragraph'
                        className={
                          `activity-tab` === activity.id ? 'active-bold' : ''
                        }
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        }
      >
        <div className='scrollrooms'>
          <div className='isdesktop'>
            <div>
              {selectedActivity && (
                <>
                  <Gallery data={selectedActivity.img} />
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default GalleryHotels
