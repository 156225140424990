import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import {
  Activities,
  Banner,
  BannersHeader,
  CalendarTypes,
  RestaurantCity,
  Rooms,
  SectionCardsHotelPackage,
  ServicesCommodities,
  SliderSixImg,
  SliderTagSection,
  TabsHotel,
  Valuations,
} from "components/organisms";
import { useAppSelector } from "hooks/useAppSelector";
import { Modal } from "components/molecules";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/molecules/loader";
import { getHotelesDestinations } from "actions/hotel/services";
import { ScrollTopButton } from "components/molecules";
import { useIntl } from "react-intl";
import SectionCards from "components/organisms/section-cards";
import { getColorIconFromText } from "utils/function";
import { useTranslation } from "react-i18next";
import { Icon, Text } from "components/atoms";
import SectionCardsHotel from "components/organisms/section-cards-hotel";

const HotelCity = () => {
  const { locale } = useIntl();
  const { place = "" } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const {
    language,
    coin,
    loading,
    data,
    rooms,
    services,
    commodities,
    valuations,
    ota,
    better_prices,
    price_days,
    price_month,
    banner_tira,
    areas,
    restaurants,
    activities,
    ambientes,
    notification,
    promociones_sin_paquete,
    promociones_paquete,
    promociones_paquete_exclusivo
  } = useAppSelector((state) => ({
    language: state.config.language,
    loading: state.language.loading,
    coin: state.config.coin,
    data: state.language.dataHotelesDestination,
    rooms: state.language.dataHotelesDestination?.habitaciones || [],
    services: state.language.dataHotelesDestination?.servicios || [],
    commodities:
      state.language.dataHotelesDestination?.serviciosDestacados || [],
    valuations: state.language.dataHotelesDestination?.reseñas || [],
    areas: state.language.dataHotelesDestination?.areas || [],
    better_prices: state.language.dataHotelesDestination?.mejores_precios || [],
    price_days:
      state.language.dataHotelesDestination?.precios_ultimos_30_dias || [],
    price_month:
      state.language.dataHotelesDestination?.precios_ultimos_mes || [],
    ota: state.language.dataHotelesDestination?.ota,
    restaurants: state.language.dataHotelesDestination?.restaurantes || [],
    activities: state.language.dataHotelesDestination?.actividades || [],
    ambientes: state.language.dataHotelesDestination?.ambientes || [],
    promociones_sin_paquete: state.language.dataHotelesDestination?.promociones_sin_paquete || [],
    promociones_paquete: state.language.dataHotelesDestination?.promociones_paquete || [],
    promociones_paquete_exclusivo: state.language.dataHotelesDestination?.promociones_paquete_exclusivo || [],
    banner_tira: state.language.dataHotelesDestination?.banner_tira || [],
    notification: state.language.dataHotelesDestination?.notificacion || {},
  }));

  const bannerRef = useRef(null);
  const tabsHotelRef = useRef(null);
  const servicesRef = useRef(null);
  const discountsRef = useRef(null);
  const roomsRef = useRef(null);
  const activitiesRef = useRef(null);
  const restaurantCityRef = useRef(null);
  const valuationsRef = useRef(null);
  const galleryRef = useRef(null);

  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    getHotelesDestinations({ place, language, coin, navigate });
    window.scrollTo(0, 0);
  }, [place, language, coin, navigate]);

  useEffect(() => {
    if (notification && notification.titulo && notification.descripcion) {
      setModalOpen(true);
    }
  }, [notification]);

  const scrollToSection = (ref: any) => {
    if (ref.current) {
      const marginTop = -322;

      const topOffset = ref.current.offsetTop - marginTop;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  const [activeSection, setActiveSection] = useState(0);

  const colorIcon = getColorIconFromText(data?.titulo_superior_banner);
  const typehotel = data?.tipo_hotel?.slug;
  const bannerImg =
    window.innerWidth > 768
      ? data?.img[0]?.path
      : data?.img_mobile?.path || data?.img[0]?.path;

  useEffect(() => {
    if(!data) return;

    const dataSchema = {
      "@context": "https://schema.org",
      "@type": "Hotel",
      identifier: data?.id_travel_click,
      address: data?.direccion,
      description: data?.descripcion,
      url: data?.meta_data?.[`url_canonica_${i18n.language}`],
      hasMap: data?.htl_google_maps_link ?? '',
      image: data?.img[0]?.path,
      priceRange: `Noche desde ${data?.precio.simbolo} ${data?.precio.precio}`,
      name: data?.titulo,
      telephone: data?.telefono,
      amenityFeature: [
        {
          "@type": "LocationFeatureSpecification",
          name: data?.texto_atributo_destacado_banner,
          value: true
        }
      ],
      checkoutTime: data?.check_in,
      checkinTime: data?.check_out,
      starRating: {
        "@type": "Rating",
        ratingValue: data?.ota?.calificacion,
      },
      aggregateRating: {
        "@type": "AggregateRating",
        reviewCount: data?.numero_reseñas,
        ratingValue: data?.ota?.calificacion,
        bestRating: 5,
      },
      // containsPlace: data?.habitaciones?.map((room: any) => ({
      //   "@type": "HotelRoom",
      //   name: room.titulo,
      //   description: room.descripcion,
      //   occupancy: {
      //     "@type": "QuantitativeValue",
      //     value: room.numero_ocupacion_total,
      //     unitCode: room.hbt_id_travel_click,
      //     additionalType: "https://schema.org/Occupancy",
      //     description: room.ocupacion
      //   },
      // })),
      // offers: better_prices?.map((price: any) => ({
      //   "@type": "Offer",
      //   price: price.precio.precio.precio,
      //   priceCurrency: price.precio.moneda,
      //   validFrom: price.fecha_inicio,
      //   validThrough: price.fecha_fin,
      //   availability: "https://schema.org/InStock",
      //   url: data?.htl_google_maps_link ? new URL(data?.htl_google_maps_link, window.location.origin).href : '',
      //   priceSpecification: {
      //     "@type": "UnitPriceSpecification",
      //     price: data?.precio.precio,
      //     priceCurrency: data?.precio.moneda,
      //     unitText: t("hotelCity.night"),
      //   }
      // })),
    };

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(dataSchema);

    document.head.appendChild(script);

    // Limpieza para eliminar el script cuando se desmonte el componente
    return () => {
      document.head.removeChild(script);
    };
  }, [data]);

  if (loading || !data) return <Loader />;

  return (
    <>
      <BannersHeader
        img={bannerImg}
        titulo={data.titulo_inferior_banner}
        subtitulo={data.titulo_superior_banner}
        description={data.texto_atributo_destacado_banner}
        data={data}
        bannerRef={bannerRef}
        valuationsRef={valuationsRef}
      />

      <SliderTagSection
        tabsHotelRef={tabsHotelRef}
        servicesRef={servicesRef}
        discountsRef={discountsRef}
        roomsRef={roomsRef}
        activitiesRef={activitiesRef}
        restaurantCityRef={restaurantCityRef}
        valuationsRef={valuationsRef}
        galleryRef={galleryRef}
        activeSection={activeSection}
        scrollToSection={scrollToSection}
        data={data}
        nameHotel={data.titulo}
      >
        <TabsHotel
          tabsHotelRef={tabsHotelRef}
          servicesRef={servicesRef}
          discountsRef={discountsRef}
          roomsRef={roomsRef}
          activitiesRef={activitiesRef}
          restaurantCityRef={restaurantCityRef}
          valuationsRef={valuationsRef}
          galleryRef={galleryRef}
          activeSection={activeSection}
          scrollToSection={scrollToSection}
          data={data}
        />

        {better_prices && price_days && price_month && (
          <CalendarTypes
            better_prices={better_prices}
            price_days={price_days}
            price_month={price_month}
            id_hotel={data.id_travel_click}
            coin={coin}
            type="calendar"
            title={data?.subtitulos ? data?.subtitulos.subtitulo_1 : t("hotelCity.findBestPrices")}
          />
        )}

        <ServicesCommodities
          colorIcon={colorIcon}
          servicios={commodities}
          commodities={services}
          servicesRef={servicesRef}
          titleServices={data?.subtitulos ? data?.subtitulos.subtitulo_2 : t("hotelCity.servicesTitle")}
          titleCommodities={data?.subtitulos ? data?.subtitulos.subtitulo_3 : t("hotelCity.commoditiesTitle")}
        />

        {ambientes.length > 0 && (
          <SliderSixImg
            data={ambientes}
            galleryRef={galleryRef}
            title={data?.subtitulos ? data?.subtitulos.subtitulo_4 : t("hotelCity.galleryTitle", { hotel: data.nombre_corto })}
            nameHotel={data.nombre_corto}
          />
        )}

        {banner_tira.length > 0 && (
          <div className="isdesktop">
            <Banner data={banner_tira} />
          </div>
        )}

        {promociones_sin_paquete.length > 0 && (
          <SectionCardsHotel
            data={promociones_sin_paquete}
            title={data?.subtitulos ? data?.subtitulos.subtitulo_5 :  t("hotelCity.titleOffers")}
            sectionref={discountsRef}
            textoTooltipIgv={data?.texto_tooltip_igv}
            textoTooltipIgvCard={data?.texto_tooltip_igv_card}
            coin={coin}
            idTravelClick={data?.id_travel_click}
          />
        )}

        {promociones_paquete.length > 0 && (
          <SectionCardsHotelPackage
            data={promociones_paquete.concat(promociones_paquete_exclusivo)}
            title={data?.subtitulos?.subtitulo_10 ? data?.subtitulos.subtitulo_10 :  t("hotelCity.titlePckages")}
            sectionref={discountsRef}
            textoTooltipIgv={data?.texto_tooltip_igv}
            textoTooltipIgvCard={data?.texto_tooltip_igv_card}
            coin={coin}
            idTravelClick={data?.id_travel_click}
          />
        )}

        {rooms.length > 0 && (
          <Rooms
            sectionref={roomsRef}
            data={rooms}
            idTravelHotel={data?.id_travel_click}
            nameHotel={data.nombre_corto}
            title={data?.subtitulos ? data?.subtitulos.subtitulo_6 : t("hotelCity.ourRoomsAndSuites")}
          />
        )}

        {activities.length > 0 && (
          <Activities
            data={activities}
            title={data?.subtitulos ? data?.subtitulos.subtitulo_7 : `${t("hotelCity.activitiesInCity1")} ${data.nombre_corto} ${t("hotelCity.activitiesInCity2")}`}
            sectionref={activitiesRef}
            namehotel={data.nombre_corto}
          />
        )}

        {restaurants.length > 0 && (
          <RestaurantCity
            data={restaurants}
            title={data?.subtitulos ? data?.subtitulos.subtitulo_8 : `${t("hotelCity.exploreOurRestaurantsIn")} ${data.titulo}`}
            sectionref={restaurantCityRef}
            type="column"
          />
        )}

        {data.ota.calificacion !== null &&
          (areas?.length > 0 || valuations?.length > 0) && (
            <Valuations
              title={data?.subtitulos ? data?.subtitulos.subtitulo_9 : t("hotelCity.guestReviews")}
              sectionref={valuationsRef}
              data={valuations}
              areas={areas}
              ota={ota}
              logros={data.logro?.img?.path}
              namehotel={data.nombre_corto}
            />
          )}

        {bannerRef && (
          <ScrollTopButton
            scrollToSection={scrollToSection}
            descriptionRef={bannerRef}
          />
        )}
      </SliderTagSection>

      {modalOpen && (
        <Modal
          isTitle={false}
          showModal={modalOpen}
          onlyClose={false}
          close={closeModal}
          changeWidth={true}
        >
          <div className="notificationmodal">
            <h2 className="notificationmodal__header">{notification.titulo}</h2>
            <Text
              html={{ __html: notification?.descripcion }}
              type="paragraph"
              className="notificationmodal__description"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default HotelCity;
