import { useEffect, useState } from "react";
import { Icon, Paragraph, Text } from "components/atoms";
import { useIntl } from "react-intl";
import { messages } from "utils/lang";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.scss";
import "swiper/css";
import "swiper/css/navigation";
import { Modal } from "components/molecules";
import { ILineValues, IReview, IValuations, IValuationsProps } from "./props";
import { changeFormatDate } from "utils/function";
import { useTranslation } from "react-i18next";

const LineValues = ({ realValue, limitValue }: ILineValues) => {
  const [percentageValue, setPercentageValue] = useState(0);

  const styleLine = {
    position: "relative" as const,
    width: "100%",
    height: "7px",
  };

  const styleBackground = {
    position: "absolute" as const,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#D9D9D9",
    borderRadius: "10px",
    zIndex: 1,
  };

  const styleCount = {
    position: "absolute" as const,
    top: 0,
    left: 0,
    width: `${percentageValue}%`,
    height: "100%",
    background: "#D69C4F",
    borderRadius: "10px",
    zIndex: 2,
  };

  useEffect(() => {
    const nuevoPorcentajeVerde = (realValue / limitValue) * 100;
    setPercentageValue(nuevoPorcentajeVerde);
  }, [realValue, limitValue]);

  return (
    <div style={styleLine}>
      <div style={styleBackground}></div>
      <div style={styleCount}></div>
    </div>
  );
};

const Valuations = ({
  data,
  sectionref,
  isLogoValuation,
  title,
  ota,
  logros,
  areas,
  namehotel,
}: IValuationsProps) => {
  const { t } = useTranslation();
  const { locale } = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const toggleModal = (item: any) => {
    setSelectedItem(item);
    setIsModalOpen(!isModalOpen);
  };

  const Stars = ({ rating }: any) => {
    const stars = new Array(5).fill(null).map((_, i) => {
      if (i < Math.floor(rating)) {
        return <Icon key={i} name="starfull" color="#FFC400" />;
      }
      if (i === Math.ceil(rating) - 1 && rating % 1 > 0) {
        return rating % 1 > 0.5 ? (
          <Icon key={i} name="starmedia" color="#FFC400" />
        ) : (
          <Icon key={i} name="starless" color="#FFC400" />
        );
      }

      return <Icon key={i} name="starempty" color="#FFC400" />;
    });

    return <div style={{ display: "flex", gap: "8px" }}>{stars}</div>;
  };

  const HighlightedText = ({ text, color }: any) => {
    const keywords = ["Premium", "Standard", "Select"];

    const highlightKeywords = (text: string, keywords: string[]) => {
      const regex = new RegExp(`\\b(${keywords.join("|")})\\b`, "gi");
      return text.replace(
        regex,
        (match) => `<span style="color: ${color}">${match}</span>`
      );
    };

    const formattedText = highlightKeywords(text, keywords);

    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
  }, [isModalOpen]);

  return (
    <div className="sectionvaluations" ref={sectionref}>
      <div className="sectionvaluations__container">
        <div className="titlecontent">
          <Text text={title} type="subtitle" className={"subtitle"} />
          {isLogoValuation && (
            <div className="contentlogo">
              {logros && (
                <img src={logros} alt={ota.nombre} width={86} height={86} />
              )}
              <div>
                <Text
                  type="paragraph"
                  className={"paragraph"}
                  text={t("hotelCity.rating")}
                />
                <p className="review">
                  {ota.calificacion_descripción} {ota.calificacion}
                  <span>
                    <Icon name="starfull" list="items" width={28} height={28} />
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="values">
          {areas.map((item: IValuations, index: number) => (
            <div className="values__line" key={index}>
              <ul className="values__line--name">
                <li>{item.nombre}</li>
              </ul>
              <div className="values__line--content">
                <LineValues realValue={item.puntuacion} limitValue={5} />
                <div className="linetext">
                  <p className="linetext__title">{item.descripcion}</p>
                  <p className="linetext__scores">{item.puntuacion}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="sectionvaluations__description">
          <Swiper
            slidesPerView={"auto"}
            grabCursor={true}
            navigation={true}
            className="sectionvaluations__description--swiper"
          >
            {data.map((review: IReview, index: number) => (
              <SwiperSlide key={index}>
                <div
                  className="sectionvaluations__description--card"
                  onClick={() => toggleModal(review)}
                >
                  <Icon
                    name={"comillas"}
                    list="items"
                    width={40}
                    height={40}
                    color="#002855"
                  />
                  <div className="sectionvaluations__description--card--container">
                    <div className="cardrating">
                      <div>
                        <Stars rating={review.calificacion} />
                      </div>

                      <div className="cardtitle">
                        <div>
                          <p className="cardtitle__title">
                            {review.titulo.length > 40
                              ? `${review.titulo.slice(0, 40)}...`
                              : review.titulo}
                          </p>
                          <div className="cardtitle__paragraph">
                            <Text
                              text={
                                review.descripcion.length > 210
                                  ? `"${review.descripcion.slice(0, 210)}..."`
                                  : `"${review.descripcion}"`
                              }
                            />
                          </div>
                        </div>
                        <div className="cardtitle__valuations">
                          {review.ota.imagen && (
                            <img
                              src={review.ota?.imagen}
                              alt={review.ota?.nombre}
                              className="imgtrip"
                            />
                          )}
                          {review.pais?.imagen && (
                            <img
                              src={review.pais?.imagen}
                              alt={review.pais?.nombre}
                              className="imgmap"
                            />
                          )}
                          <div>
                            {review.nombre_cliente.length > 10 ? (
                              <p className="tagcardicon">
                                {review.nombre_cliente} <br />{" "}
                                {changeFormatDate(review.fecha)}
                              </p>
                            ) : (
                              <Text
                                text={`${
                                  review.nombre_cliente
                                }, ${changeFormatDate(review.fecha)}`}
                                className="tagcardicon"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <Modal
        isTitle={true}
        breadcrumb={true}
        showModal={isModalOpen}
        close={toggleModal}
        title={HighlightedText({ text: namehotel, color: "#D69C4F" })}
        route={messages[locale].guestReviews}
      >
        {selectedItem && (
          <div className="scrollcontent">
            <div className="cardcenter">
              <div className="card__valuationsmodal">
                <Icon
                  name={"comillas"}
                  list="items"
                  width={40}
                  height={40}
                  color="#002855"
                />
                <div className="card__valuationsmodal--container">
                  <div className="cardrating">
                    <div>
                      <Stars rating={selectedItem.calificacion} />
                    </div>

                    <div className="cardtitle">
                      <p className="cardtitle__title">{selectedItem.titulo}</p>
                      <div className="cardtitle__paragraph">
                        <Paragraph
                          text={selectedItem.descripcion}
                          family={"tagcardicon"}
                          className="carddescription__description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card__valuationsmodal--carddescription">
                  {selectedItem.ota?.imagen && (
                    <img
                      src={selectedItem.ota?.imagen}
                      alt={selectedItem.ota?.alt}
                      className="imgtrip"
                    />
                  )}
                  {selectedItem.pais?.imagen && (
                    <img
                      src={selectedItem.pais?.imagen}
                      alt={selectedItem.pais?.nombre}
                      className="imgmap"
                    />
                  )}
                  <div>
                    {selectedItem.nombre_cliente?.length > 10 ? (
                      <p className="tagcardicon">
                        {selectedItem.nombre_cliente} <br />{" "}
                        {changeFormatDate(selectedItem.fecha)}
                      </p>
                    ) : (
                      <Text
                        text={`${
                          selectedItem.nombre_cliente
                        }, ${changeFormatDate(selectedItem.fecha)}`}
                        className="tagcardicon"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

Valuations.defaultProps = {
  isLogoValuation: true,
  isTitle: true,
};

export default Valuations;
