import { ICard } from "./props";
import IconText from "../iconText";
import Icon from "../icon";
import Paragraph from "../paragraph";
import { useIntl } from "react-intl";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./styles.scss";
import Text from "../text";
import { SliderHotelMobile } from "components/organisms";
import { Tooltip } from "react-tooltip";
import { format } from "date-fns";
import Trip from "assets/images/tripadvisor.webp";
import { validateTravelClickIsInMainteinnace } from "utils/function";
import {
  MAX_DESCRIPTION_RESTAURANT_LENGTH,
  MAX_DIRECCION_LENGTH,
} from "utils/values";
import {
  getDateWithDay,
  getDateWithDayAndYear,
  getDateWithoutTime,
  handleTravelClickWhithoutDate,
} from "utils/function";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Card = ({
  restaurant,
  explore,
  visit,
  type,
  className,
  data,
  titlepromotion,
  select,
  redirection,
  direction,
  isTag,
  index,
  titulo,
  logo,
  fondo,
  color,
  lineOffers,
  fontOffers,
  isReview,
  height,
  id_hotel,
  coin,
}: ICard) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { lang } = useParams();

  const formattedText = (text: string) => {
    if (typeof text === "string") {
      return text.replace(/\b(home|en pareja|Enámorate en)\b/gi, (match) => {
        if (match.toLowerCase() === "home") {
          return `<span class="txhome">${match}</span>`;
        }
        if (match.toLowerCase() === "en pareja") {
          return `<span class="txpareja">${match}</span>`;
        }
        if (match.toLowerCase() === "Enámorate en") {
          return `<span class="txenamorate">${match}</span>`;
        }
        return match;
      });
    }
    return text;
  };

  const handleTravelClick = (better: any) => {
    const id = id_hotel;
    const startDate = better.fecha_inicio;
    const endDate = better.fecha_fin;

    const formatDate = (dateString: string) => {
      const date = new Date(dateString + "T00:00:00-05:00");
      return format(date, "MM/dd/yyyy");
    };

    const dateIn = formatDate(startDate);
    const dateOut = formatDate(endDate);

    const bookingLink = () => {
      const maintLink = validateTravelClickIsInMainteinnace()
      if (maintLink) return maintLink;

      if (id) {
        return `https://bookings.casa-andina.com/${id}?LanguageID=2&Rooms=1&datein=${encodeURIComponent(
          dateIn
        )}&dateout=${encodeURIComponent(dateOut)}&currency=${coin}`;
      }
      return "";
    };

    const link = bookingLink();
    if (link) {
      window.open(link, "_blank");
    }
  };

  const handleTravelClick2 = (better: any) => {
    const id = id_hotel;
    const startDate = better.fecha_inicio;

    const formatDate = (dateString: string) => {
      const date = new Date(dateString + "T00:00:00-05:00");
      return format(date, "MM/yyyy");
    };

    const dateIn = formatDate(startDate);

    const bookingLink = () => {
      const maintLink = validateTravelClickIsInMainteinnace()
      if (maintLink) return maintLink;

      if (id) {
        return `https://bookings.casa-andina.com/${id}?LanguageID=2&Rooms=1&startmonth=${dateIn}&currency=${coin}`;
      }
      return "";
    };

    const link = bookingLink();

    if (link) {
      window.open(link, "_blank");
    }
  };


  const cardTypes = () => {
    const routesLocale = [
      `/${lang}/${t("routes.hotels")}`,
      `/${lang}/${t("routes.destinations")}`,
      `/${lang}/${t("routes.restaurants")}`,
      `/${lang}/${t("routes.events")}`,
    ];

    switch (type) {
      case "relax":
        return (
          <Link className="nlink" to={redirection || ""}>
            <div
              className="promotionspackages__description--card"
              style={{
                backgroundImage: `url(${data.img?.path})`,
                height: height,
              }}
            >
              <div
                className="promotionspackages__description--card--container"
                style={{ backgroundColor: data.color }}
              >
                <div className="promotionspackages__description--card--content">
                  {data?.logo?.path ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={data.logo?.path}
                        alt={data.logo?.alt}
                        style={{
                          maxWidth: 140,
                          height: 44,
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  ) : (
                    <Text
                      html={{ __html: formattedText(data.titulo_card) }}
                      style={{ color: data.color_secundario }}
                      type="paragraph"
                      className="paragraph"
                    />
                  )}
                  <div
                    className="promotionspackages__description--card--description"
                    style={{ color: data.color_secundario }}
                  >
                    <Text
                      text={data.titulo}
                      type="paragraph"
                      className="condition"
                    />
                    <Text
                      text={`${data.precio
                          ? data.precio.precio !== undefined &&
                            data.precio.precio !== 0
                            ? `${data.precio.simbolo} ${data.precio.precio}*`
                            : "$ 0*"
                          : "$ 0*"
                        }`}
                      type="paragraph"
                      className="price"
                    />
                    <Text
                      text={data.subtitulo}
                      type="paragraph"
                      className="condition"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        );
      case "restaurant":
        return (
          <Link
            className="restaurantscards__description--card nlink"
            to={restaurant.slug}
          >
            <div
              className="restaurantscards__description--card--logo"
              style={{ backgroundColor: restaurant.tipo_restaurante?.color }}
            >
              <img
                src={restaurant.tipo_restaurante?.img?.path}
                alt={restaurant.tipo_restaurante?.img?.alt}
                width={65}
                height={65}
              />
            </div>
            <div className="restaurantscards__description--card--img">
              <SliderHotelMobile
                images={restaurant?.img}
                isTag={false}
                autoplay={false}
              />
            </div>
            <div className="restaurantscards__description--card--container">
              <div
                className="cardheaderr"
              >
                <Text
                  text={restaurant.titulo}
                  type="subtitle2"
                  className="ttrestaurant"
                  style={{
                    color: restaurant.tipo_restaurante?.color,
                    width: isReview ? "180px" : "100%",
                  }}
                />
                {isReview && (
                  <div className="review">
                    <Text text={restaurant.calificacion} type="paragraph" />
                    <IconText
                      text={`${restaurant.reseña} ${t("general.reviews")}`}
                      nameIcon="star2"
                      listIcon="items"
                      w={16}
                      h={16}
                      type="zero"
                    />
                  </div>
                )}
              </div>
              <IconText
                nameIcon="place-gray"
                listIcon="items"
                text={restaurant.direccion}
                className="sbrestaurant"
                w={20}
                h={20}
              />

              <Text
                html={{
                  __html:
                    restaurant.tipo_restaurante.descripcion &&
                      restaurant.tipo_restaurante.descripcion.length >
                      MAX_DESCRIPTION_RESTAURANT_LENGTH
                      ? `${restaurant.tipo_restaurante.descripcion.slice(
                        0,
                        MAX_DESCRIPTION_RESTAURANT_LENGTH
                      )}...`
                      : restaurant.tipo_restaurante.descripcion,
                }}
                type="paragraph"
                className="description"
              />
              <div className={"icontext"}>
                <IconText
                  type="right"
                  nameIcon="right"
                  listIcon="arrows"
                  text={t("general.learnMore")}
                  w={20}
                  h={20}
                />
              </div>
            </div>
          </Link>
        );
      case "explore":
        return (
          <Link className="card explora" to={explore.redireccion}>
            <img src={explore.img?.path} alt={explore.img?.alt} />
            <div className="cardheadere">
              <Text
                text={explore.titulo || explore.nombre}
                type="paragraph"
                className={"paragraph"}
              />
              {/* <p className='ttexplore'>{explore.titulo || explore.nombre}</p> */}
              <IconText
                nameIcon="hotel"
                listIcon="items"
                text={`${explore.hoteles} ${explore.hoteles === 1
                    ? `${t("general.hotel")}`
                    : `${t("general.hotels")}`
                  }`}
                type="paragraph"
                className="icon"
                // family='sbhotel'
                w={20}
                h={20}
              />
            </div>
          </Link>
        );
      case "restaurant2":
        return (
          <Link className="card__explora" to={redirection || ""}>
            <div className="card__explora--image">
              <img
                src={data.img?.path}
                alt={data.img?.alt}
                className="content-img"
              />
              {isTag && (
                <div
                  className="explora-istag"
                  style={{ backgroundColor: data.tipo_restaurante?.color }}
                >
                  <img
                    src={data.tipo_restaurante?.img?.path}
                    alt={data.tipo_restaurante?.img?.alt}
                    width={45}
                    height={45}
                  />
                </div>
              )}
            </div>
            <div className="card__explora--cardheadere">
              <p className="card__explora--ttexplore">{data.titulo}</p>
              <div className="card__explora--sbtexplore">
                <Icon name={"place"} list={"items"} width={20} height={20} />
                <Paragraph text={data?.subtitulo} family={"sbhotel"} />
              </div>
            </div>
          </Link>
        );
      case "hotel-direccion":
        const isValidRestaurantTitle = (title: string) => {
          const validTitles = [
            "La Plaza",
            "Sama Restaurante Café",
            "Alma",
            "Sama Healthy",
          ];
          return validTitles.includes(title);
        };
        return (
          <div
            className="promotionsexplore__description--card"
            onClick={() => navigate(data.slug || data[0]?.slug)}
          >
            <div className="promotionsexplore__description--card--slider">
              <div className="promotionsexplore__description--card--cardexploreimg">
                <img
                  src={data.img?.path || data.img[0]?.path}
                  alt={data.img?.alt || data.img[0]?.alt}
                  className="content-img"
                />
                {isTag &&
                  isValidRestaurantTitle(data.tipo_restaurante?.titulo) && (
                    <div
                      className="promotionsexplore__description--card--exploraistag"
                      style={{
                        backgroundColor:
                          data.tipo_restaurante?.color ||
                          data[0]?.tipo_restaurante?.color,
                      }}
                    >
                      <img
                        src={
                          data.tipo_restaurante?.img?.path ||
                          data[0]?.tipo_restaurante?.img?.path
                        }
                        alt={
                          data.tipo_restaurante?.img?.alt ||
                          data[0]?.tipo_restaurante?.img?.alt
                        }
                        width={40}
                        height={40}
                      />
                    </div>
                  )}
              </div>
            </div>
            <div className="promotionsexplore__description--card--container">
              <h3 className="promotionsexplore__description--card--ttexplore">
                {titulo}
              </h3>
              <div className="promotionsexplore__description--card--sbtexplore">
                <div style={{ width: "20px" }}>
                  <Icon
                    name={"place-gray"}
                    list={"items"}
                    width={20}
                    height={20}
                  />
                </div>
                <Text
                  text={
                    direction && direction.length > MAX_DIRECCION_LENGTH
                      ? `${direction.slice(0, MAX_DIRECCION_LENGTH)}...`
                      : direction
                  }
                  className={"paragraph"}
                />
              </div>
            </div>
          </div>
        );
      case "food":
        return (
          <Link className="card food" to={redirection || ""}>
            <img src={data.img?.path} alt={data.img?.path} />
            <div className="cardfood">
              <div className="cardfood__title">
                <Text type="paragraph" className="title" text={data.titulo} />
                <Text
                  text={data.subtitulo}
                  type="paragraph"
                  className="subtitle"
                />
              </div>
              <div className="cardfood__text">
                <Text
                  type="paragraph"
                  className="paragraphup"
                  text={data.texto_encima_precio}
                />
                <Text
                  type="paragraph"
                  className="paragraphvalor"
                  text={`S/${data.valor}`}
                />
                <Text
                  type="paragraph"
                  className="paragraphdown"
                  text={data.condicion_abajo_precio}
                />
              </div>
            </div>
          </Link>
        );
      case "restaurant-food":
        return (
          <Link className="card food" to={redirection || ""} key={index}>
            <img
              src={data.card?.img?.path || data.img?.path || data.imagen?.path}
              alt={data.card?.img?.alt || data.img?.path || data.imagen?.alt}
            />
            <div
              className="cardfood"
              style={{
                display:
                  parseFloat(
                    data?.card?.precio?.precio || data?.precio?.precio
                  ) !== 0
                    ? "grid"
                    : "initial",
              }}
            >
              {(data.card?.subtitulo || data.subtitulo) && (
                <div className="cardfood__title">
                  <Text
                    type="subtitle2"
                    className="cardfood__title--title"
                    text={titlepromotion}
                  />
                  <div className="cardfood__title--subtitle">
                    <ul>
                      <li>{data.card?.subtitulo || data.subtitulo}</li>
                    </ul>
                  </div>
                </div>
              )}
              {parseFloat(data.card?.precio?.precio || data.precio?.precio) !==
                0 && (
                  <div className="cardfood__text">
                    {(data.card?.texto_encima_precio ||
                      data.texto_encima_precio) && (
                        <Text
                          type="paragraph"
                          className="cardfood__text--paragraphup"
                          text={
                            data.card?.texto_encima_precio ||
                            data.texto_encima_precio
                          }
                        />
                      )}
                    {data.card?.precio && (
                      <Text
                        type="paragraph"
                        className="cardfood__text--paragraphvalor"
                        text={`${data.card?.precio?.simbolo} ${data.card?.precio?.precio}`}
                      />
                    )}
                    {data.precio && (
                      <Text
                        type="paragraph"
                        className="cardfood__text--paragraphvalor"
                        text={`${data.precio?.simbolo} ${data.precio?.precio}`}
                        style={{ fontSize: data.card?.precio?.precio.length < 2 ? 26 : 22 }}
                      />
                    )}
                    {(data.card?.condicion_abajo_precio ||
                      data.condicion_abajo_precio) && (
                        <Text
                          type="paragraph"
                          className="cardfood__text--paragraphdown"
                          text={
                            data.card?.condicion_abajo_precio ||
                            data.condicion_abajo_precio
                          }
                          style={{ fontSize: data.card?.precio?.precio.length < 16 ? 12 : 10 }}
                        />
                      )}
                  </div>
                )}
            </div>
          </Link>
        );
      case "food-offers":
        return (
          <Link className="nlink" to={redirection || ""}>
            <div
              className="promotionsfoods__description--card"
              style={{ height: height }}
            >
              <div
                className="promotionsfoods__description--card--slider"
                style={{ height: height ? "192px" : "225px" }}
              >
                <img
                  src={data.img?.path || data.imagen?.path}
                  alt={data.img?.path || data.imagen?.alt}
                />
              </div>
              <div className="promotionsfoods__description--card--container">
                <div className="promotionsfoods__description--card--left">
                  <Text
                    type="paragraph"
                    className="promotionsfoods__description--title"
                    text={data.titulo}
                  />
                  <Text
                    type="paragraph"
                    className="promotionsfoods__description--subtitle"
                    text={data.subtitulo}
                  />
                </div>
                <div className="promotionsfoods__description--card--right">
                  <Text
                    type="paragraph"
                    className="promotionsfoods__description--paragraphup"
                    text={data.texto_encima_precio}
                  />
                  <Text
                    type="paragraph"
                    className="promotionsfoods__description--paragraphvalor"
                    text={`S/${data.valor}`}
                  />
                  <Text
                    type="paragraph"
                    className="promotionsfoods__description--paragraphdown"
                    text={data.condicion_abajo_precio}
                  />
                </div>
              </div>
            </div>
          </Link>
        );
      case "hotel":
        return (
          <Link className="card hotel" to={"/"}>
            <div className="cardhotel-slider">
              <div>
                {/* <SliderHotelMobile
                tipo={data.tipo_hotel.titulo}
                images={data.img}
              /> */}
              </div>
            </div>
            <div className="cardhotel">
              <div className="cardhotel__title">
                <Text type="paragraph" family="txdestiny" text={data.titulo} />
              </div>
              <div className="cardhotel__text">
                <p className="sbbcardicon">
                  {data.calificacion}
                  <span>
                    <Icon
                      name="star"
                      list="items"
                      width={12}
                      height={12}
                      className="mr-0-2rem"
                    />
                  </span>
                  ({data.reseña} {t("general.reviews")})
                </p>
                <div className="pt-0-3rem">
                  <Text
                    type="paragraph"
                    family="sbbcardicon"
                    text={t("betterPrices.nightFrom")}
                  />
                  <Text
                    type="paragraph"
                    family="tcarddiscount"
                    text={`S/ ${data.precio}`}
                  />
                </div>
              </div>
            </div>
          </Link>
        );
      case "visit":
        return (
          <Link to={visit.slug} className="nlink">
            <div className={`cardvisit visits ${className}`}>
              <div className="visit-img">
                <LazyLoadImage
                  src={visit.imagen?.path}
                  alt={visit.imagen?.alt}
                  loading="lazy"
                  effect="blur"
                />
              </div>
              <div className="content">
                <div className="content__description">
                  <div>
                    <Text
                      text={visit.id === 4 ? "69" : visit.numero}
                      type="paragraph"
                      className="content__description--number"
                    />
                  </div>
                  <div>
                    <Text
                      text={visit.titulo}
                      type="paragraph"
                      className="content__description--title"
                    />
                    <Text
                      text={visit.subtitulo}
                      type="paragraph"
                      className="content__description--subtitle"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        );
      case "select":
        return (
          <div
            className="promoselect nlink"
            style={{ backgroundImage: `url(${data.img[0]?.path || fondo})` }}
            onClick={() => handleTravelClickWhithoutDate(data, coin)}
          >
            <div className="promoselect__promo">
              <div
                className="promoselect__promo--inner"
                style={{ backgroundColor: lineOffers }}
              >
                <div className="promoselect__promo--inner--description">
                  <div>
                    <Text className="title" text={data.nombre_corto_marca} style={{ color: fontOffers }} />
                    <Text
                      className="subtitlename"
                      style={{
                        fontSize:
                          data.nombre_corto_sin_marca.length > 26 ? 16 : 18,
                        color: fontOffers
                      }}
                      text={data.nombre_corto_sin_marca}
                    />
                  </div>
                  <div className="content">
                    <Text className="discount" text={data.descuento} style={{ color: fontOffers }} />
                    <div className="content--right">
                      <Text className="percentage" text={"%"} style={{ color: fontOffers }} />
                      <Text className="dscto" text={"dscto."} style={{ color: fontOffers }} />
                    </div>
                  </div>
                  {data.descuento_life !== null &&
                    data.descuento_life !== 0 && (
                      <Text
                        text={`+${data.descuento_life}%`}
                        text2={t("offers.forCasaAndinaLifeMembers")}
                        type="paragraph2"
                        className="textlife"
                        className2="textlife2"
                        style={{ color: fontOffers }}
                      />
                    )}

                  {logo && (
                    <div className="promoselect__promo--inner--container">
                      <div className="img-promo">
                        <img src={logo} alt="logo" width={"100%"} height={46} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case "calendar":
        return (
          <>
            <div className="promocalendar">
              <div className="promocalendar__promo">
                <div
                  className="promocalendar__promo--inner"
                  onClick={() => handleTravelClick(data)}
                >
                  <Text
                    text={data ? data.titulo : ""}
                    type="paragraph"
                    className="badge"
                    style={{ backgroundColor: data.color }}
                  />
                  <div className="colum">
                    <Text
                      text={t("betterPrices.nightFrom")}
                      type="paragraph"
                      className="text"
                    />
                    <Text
                      text={`${data.precio?.precio?.simbolo} ${data.precio?.precio?.precio}`}
                      type="paragraph"
                      className={
                        data.precio?.precio?.precio?.length >= 4
                          ? "price"
                          : "price"
                      }
                    />
                    <div
                      className="igv-tooltip"
                    >
                      <IconText
                        text={`+${data.precio?.igv?.simbolo} ${data.precio?.igv?.precio} ${data.texto_tooltip_igv_card}`}
                        nameIcon="question-card"
                        listIcon="items"
                        w={16}
                        h={16}
                        type="right"
                        className="igv"
                        className2={`tooltip-price-${data.id}`}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="terminos">
                      Valido para estadías del <br />
                      {getDateWithoutTime(data.fecha_inicio)} al{" "}
                      {getDateWithoutTime(data.fecha_fin)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Tooltip
              clickable={true}
              anchorSelect={`.tooltip-price-${data.id}`}
              place="bottom-end"
              opacity={1}
              style={{
                backgroundColor: "white",
                color: "#222",
                boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                fontFamily: "Quicksand",
                fontWeight: "300",
                width: "200px",
                fontSize: "10px",
                zIndex: 10,
              }}
            >
              {data.texto_tooltip_igv}
            </Tooltip>
          </>
        );
      case "calendar2":
        const getBorderStyleCalendar2 = () => {
          if (data.es_menor_precio) return "borde-verde";
          if (data.es_mayor_precio) return "borde-rojo";
          return "";
        };

        return (
          <>
            {data.disponible === 0 ? (
              <div className="promocalendar2 nlink">
                <div className="promocalendar2__promo">
                  <div
                    className="promocalendar2__promo--inner"
                    style={{ backgroundColor: color }}
                  >
                    <div style={{ height: 6 }} />
                    <div style={{ paddingInline: 20, height: 110 }}>
                      <Text
                        text={getDateWithDay(data.fecha_inicio)}
                        type="paragraph"
                        className="badge"
                      />
                      <div className="promocalendar2__promo--night2">
                        <Text
                          text={t("betterPrices.noAvailability")}
                          type="paragraph"
                          className="no-disponible"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`promocalendar2`}>
                <div className="promocalendar2__promo">
                  <div
                    className={`promocalendar2__promo--inner nlink`}
                    onClick={() => handleTravelClick(data)}
                    style={{ backgroundColor: color }}
                  >
                    <div
                      className={getBorderStyleCalendar2()}
                      style={{ height: 6 }}
                    />
                    <div style={{ paddingInline: 20, height: 110 }}>
                      <Text
                        text={getDateWithDay(data.fecha_inicio)}
                        type="paragraph"
                        className="badge"
                      />
                      <div className="promocalendar2__promo--night">
                        <Text
                          text={t("betterPrices.nightFrom")}
                          type="paragraph"
                          className="text"
                        />
                        {/* style={{ backgroundColor: data.color ? data.color : 'white' }} */}
                        <div className="prices">
                          <Text
                            text={`${data.precio?.simbolo} ${data.precio?.precio}`}
                            type="paragraph"
                            className="price"
                          />
                        </div>
                      </div>
                      {data.igv?.precio > 0 && (
                        <div
                          className="igv-tooltip"
                        >
                          <IconText
                            text={`+${data.igv?.simbolo} ${data.igv?.precio} ${data.texto_tooltip_igv_card}`}
                            nameIcon="question-card"
                            listIcon="items"
                            w={16}
                            h={16}
                            type="right"
                            className="igv"
                            className2={`tooltip-price-${data.id}`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Tooltip
              clickable={true}
              anchorSelect={`.tooltip-price-${data.id}`}
              place="bottom-end"
              opacity={1}
              style={{
                backgroundColor: "white",
                color: "#222",
                boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                fontFamily: "Quicksand",
                fontWeight: "300",
                width: "200px",
                fontSize: "10px",
                zIndex: 10,
              }}
            >
              {data.texto_tooltip_igv}
            </Tooltip>
          </>
        );
      case "calendar3":
        const getBorderStyleCalendar3 = () => {
          if (data.es_menor_precio) return "borde-verde";
          if (data.es_mayor_precio) return "borde-rojo";
          return "";
        };

        return data.disponible === 0 ? (
          <div className="promocalendar3 nlink">
            <div className="promocalendar3__promo">
              <div
                className="promocalendar3__promo--inner"
                style={{ backgroundColor: color }}
              >
                <div style={{ height: 6 }} />
                <div style={{ paddingInline: 20, height: 110 }}>
                  <Text
                    text={
                      data.fecha_inicio
                        ? getDateWithDayAndYear(data.fecha_inicio)
                        : "2024"
                    }
                    type="paragraph"
                    className="badge"
                  />
                  <div className="promocalendar3__promo--night2">
                    <Text
                      text={t("betterPrices.noAvailability")}
                      type="paragraph"
                      className="no-disponible"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={`promocalendar3`}>
            <div className="promocalendar3__promo">
              <div
                className="promocalendar3__promo--inner"
                onClick={() => handleTravelClick2(data)}
                style={{ backgroundColor: color }}
              >
                <div
                  className={getBorderStyleCalendar3()}
                  style={{ height: 6 }}
                />
                <div style={{ paddingInline: 20, height: 110 }}>
                  <Text
                    text={
                      data.fecha_inicio
                        ? getDateWithDayAndYear(data.fecha_inicio)
                        : "2024"
                    }
                    type="paragraph"
                    className="badge"
                  />
                  <div className="promocalendar3__promo--night">
                    <Text
                      text={t("betterPrices.nightFrom")}
                      type="paragraph"
                      className="text"
                    />
                    {/* style={{ backgroundColor: data.color ? data.color : 'white' }} */}
                    <div className="prices">
                      <Text
                        text={`${data.precio?.simbolo} ${data.precio?.precio}`}
                        type="paragraph"
                        className="price"
                      />
                    </div>
                  </div>
                  <div
                    className="igv-tooltip"
                  >
                    <IconText
                      text={`+${data.igv?.simbolo} ${data.igv?.precio} ${data.texto_tooltip_igv_card}`}
                      nameIcon="question-card"
                      listIcon="items"
                      w={16}
                      h={16}
                      type="right"
                      className="igv"
                      className2={`tooltip-price-${data.id}`}
                    />
                    <Tooltip
                      clickable={true}
                      anchorSelect={`.tooltip-price-${data.id}`}
                      place="top-end"
                      opacity={1}
                      style={{
                        backgroundColor: "white",
                        color: "#222",
                        boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                        fontFamily: "Quicksand",
                        fontWeight: "300",
                        width: "200px",
                        fontSize: "10px",
                        zIndex: 10,
                      }}
                    >
                      {data.texto_tooltip_igv}
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "logros":
        return (
          <Link
            className="promoslogros nlink"
            style={{ backgroundImage: `url(${fondo})` }}
            to={redirection || ""}
          >
            <div className="promoslogros__promo">
              <div className="promoslogros__promo--inner">
                <Icon name="cultura" list="items" width={72} height={72} />
                <Text
                  text="Mejores Hoteles a nivel mundial"
                  type="paragraph"
                  className="title"
                />
                <Text
                  text="Studious, el tour operador alemán más importante y uno de los más grandes del mundo nombró a Casa Andina Premium Arequipa y Miraflores como unos de los  mejores hoteles a nivel mundial"
                  type="paragraph"
                  className="parragraph"
                />
              </div>
            </div>
          </Link>
        );
      case "reconocimiento":
        return (
          <Link
            className="promosreconocimiento nlink"
            style={{ backgroundImage: `url(${fondo})` }}
            to={redirection || ""}
          >
            <div className="promosreconocimiento__promo">
              <div className="promosreconocimiento__promo--inner">
                <img src={Trip} alt="Trip" width={150} height={150} />
              </div>
            </div>
          </Link>
        );
      case "equipo":
        return (
          <Link
            className="promosequipos nlink"
            style={{ backgroundImage: `url(${fondo})` }}
            to={redirection || ""}
          >
            <div className="promosequipos__promo">
              <div className="promosequipos__promo--inner">
                <img src={Trip} alt="Trip" width={200} height={200} />

                <Text
                  text="Carlos Bustamante"
                  type="paragraph"
                  className="title"
                />
                <Text
                  text="Fundador de Casa Andina"
                  type="paragraph"
                  className="parragraph"
                />
                <div className="terminos">
                  <Text
                    text="conéctate con"
                    type="paragraph"
                    className="parragraph"
                  />
                  <img src={Trip} alt="Trip" width={18} height={18} />
                </div>
              </div>
            </div>
          </Link>
        );
    }
  };

  return <>{cardTypes()}</>;
};

Card.defaultProps = {
  type: "default",
  isTag: false,
  isReview: true,
};

export default Card;
