import { Button, Card, Icon, Text } from "components/atoms";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { ISectionCardsPromotions } from "./props";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.scss";
import { CardCustom } from "components/molecules";
import { Link, useNavigate, useParams } from "react-router-dom";
import { messages } from "utils/lang";
import { useIntl } from "react-intl";
import { splitCasaAndina } from "utils/function";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import BannerTira from "../banners/banner";
import { useAppSelector } from "hooks/useAppSelector";

const SectionCardsPromotions = ({
  data,
  title,
  banner,
  titleColor,
}: ISectionCardsPromotions) => {
  const { locale } = useIntl();
  const { lang } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const coin = useAppSelector((state) => state.config.coin);

  function promotionsType({ items }: any) {
    const promociones = items.promociones || [];

    const color = items.promociones.map((item: any) => item.card?.color);
    const categorias = promociones.map((item: any) =>
      item.categoria?.titulo.toLowerCase()
    );

    const redireccion = promociones.map((item: any) => item?.redireccion);
    const itemsForLoop = items.promociones.map((item: any) => item.id);

    const bgColor =
      promociones.map((item: any) => item.color_promocion) || "white";

    const alimentosPromocion = promociones.map(
      (item: any) => item.promocion?.categoria?.titulo === "alimentos y bebidas"
    );
    const paquete = () => {
      return (
        <div
          className="promotionspackages"
          style={{ backgroundColor: bgColor }}
        >
          <div
            className="promotionspackages__container"
            style={{ color: titleColor }}
          >
            <Text
              text={items.titulo}
              type="subtitle"
              className="subtitle"
            />

            <div className="promotionspackages__description">
              <Swiper
                slidesPerView={"auto"}
                grabCursor={true}
                navigation={true}
                className="promotionspackages__description--swiper"
              >
                {items.promociones.map((item: any, index: number) => (
                  <SwiperSlide key={index}>
                    <Card
                      type="relax"
                      data={item.card}
                      redirection={item.redireccion}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      );
    };

    const campaña = () => {
      return (
        <div
          className="sectioncardspromotions"
          style={{ backgroundColor: bgColor }}
        >
          <div
            className="sectioncardspromotions__container"
            style={{ color: "white" }}
          >
            {title && (
              <Text text={title} type="subtitle" className="subtitle" />
            )}

            <div className="ismobile">
              <Text
                text={
                  items.titulo ||
                  "Conoce el Perú primero, aprovecha nuestros descuentos"
                }
                type="subtitle"
                className="subtitle2"
              />
            </div>

            <div className="sectioncardspromotions__container--description">
              <div className="ismobile">
                <Swiper
                  slidesPerView={"auto"}
                  loop={itemsForLoop.length > 3}
                  autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  className="sectioncardspromotions__container--description--swiper"
                >
                  {items.promociones.map((item: any) =>
                    item.hoteles?.map((hotel: any, index: number) => {
                      //Quita la palabra CASA ANDINA del título
                      const textoCompleto = hotel.nombre_corto;
                      let textoFinal = "";

                      if (textoCompleto && typeof textoCompleto === "string") {
                        const palabras = textoCompleto.split(" ");
                        const palabrasSinCasaPrimera = palabras.filter(
                          (palabra) =>
                            palabra.toLowerCase() !== "casa" &&
                            palabra.toLowerCase() !== "andina"
                        );
                        textoFinal = palabrasSinCasaPrimera.join(" ");
                      } else {
                        textoFinal = "No hay texto disponible";
                      }
                      return (
                        <SwiperSlide key={index}>
                          <Card
                            type="select"
                            data={hotel}
                            coin={coin}
                            titulo={textoFinal}
                            redirection={item.redireccion}
                            logo={item.card.logo?.path}
                            fondo={item.card.img?.path}
                            color={item.card?.color}
                          />
                        </SwiperSlide>
                      );
                    })
                  )}
                  <SwiperSlide>
                    <div
                      className="promoselect nlink"
                      style={{ backgroundColor: color }}
                    >
                      <div className="promoselect__promo">
                        <div className="promoselect__promo--final">
                          <div className="btn">
                            <Icon name="next" list="arrows" />
                          </div>
                          <Text
                            type="paragraph"
                            text={messages[locale].findTheBestDiscounts}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>

              <div className="isdesktop">
                <div className="sectioncardspromotions__container--description--web">
                  <div className="final">
                    <Text
                      text={items.titulo}
                      type="subtitle"
                      className="title"
                    />
                    <Text
                      html={{ __html: items.descripcion }}
                      type="subtitle"
                      className={"title"}
                    />
                    {redireccion && (
                      <Button
                        type="borders"
                        onClick={() =>
                          navigate(
                            redireccion.length > 0 ? redireccion[0] : "/"
                          )
                        }
                      >
                        {t("offers.learnMore")}
                      </Button>
                    )}
                  </div>
                  <Swiper
                    slidesPerView={"auto"}
                    grabCursor={true}
                    navigation={true}
                    pagination={{
                      clickable: true,
                      dynamicBullets: true,
                    }}
                    modules={[Pagination, Navigation]}
                    className="sectioncardspromotions__container--description--swiper"
                  >
                    {items.promociones.map((item: any) =>
                      item.hoteles?.map((hotel: any, index: number) => {
                        const titulo = splitCasaAndina(hotel.nombre_corto);
                        return (
                          <SwiperSlide key={index}>
                            <Card
                              type="select"
                              data={hotel}
                              coin={coin}
                              titulo={titulo}
                              redirection={item.redireccion}
                              logo={item.card.logo?.path}
                              fondo={item.card.img?.path}
                              color={item.card?.color}
                              lineOffers={item.card?.color_secundario}
                              fontOffers={item.card?.color_fuente}
                            />
                          </SwiperSlide>
                        );
                      })
                    )}
                    <SwiperSlide>
                      <Link
                        className="promoselect nlink"
                        style={{ backgroundColor: color }}
                        to={redireccion.length > 0 ? redireccion[0] : "/"}
                      >
                        <div className="promoselect__promo">
                          <div className="promoselect__promo--final">
                            <div className="btn">
                              <Icon name="next" list="arrows" />
                            </div>
                            <Text
                              type="paragraph"
                              text={messages[locale].findTheBestDiscounts}
                              family={"titlecarddiscount"}
                            />
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const alimentos = () => {
      return (
        <div className="promotionsfoods" style={{ backgroundColor: bgColor }}>
          <div
            className="promotionsfoods__container"
            style={{ color: titleColor }}
          >
            <Text
              type="subtitle"
              text={items.titulo || messages[locale].enjouyAndTreatYourself}
              className="subtitle"
            />

            <div className="promotionsfoods__description">
              <Swiper
                slidesPerView={"auto"}
                grabCursor={true}
                navigation={true}
                className="promotionsfoods__description--swiper"
              >
                {items?.promociones.map((item: any, index: number) => (
                  <SwiperSlide key={index}>
                    <Card
                      type="restaurant-food"
                      data={item.card}
                      redirection={item.redireccion}
                      titlepromotion={item.nombre}
                    />
                  </SwiperSlide>
                ))}
                <SwiperSlide>
                  <Link className="nlink" to={`/${lang}/restaurantes`}>
                    <div className="promotionsfoods__description--card">
                      <div className="promotionsfoods__description--card--extra">
                        <div className="border">
                          <Icon
                            name="next"
                            list="arrows"
                            width={14}
                            height={14}
                          />
                        </div>
                        <Text
                          type="paragraph"
                          text={t("offers.findBestRestaurant")}
                          className={"paragraph"}
                        />
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      );
    };

    const bancos = () => {
      return (
        <div
          className="promotionsbanks"
          // style={{ backgroundColor: bgColor }}
        >
          <div
            className="promotionsbanks__container"
            style={{ color: titleColor }}
          >
            <Text
              type="subtitle"
              text={items.titulo || t("offers.discountsyourBanks")}
              className="subtitle"
            />

            <div className="promotionsbanks__description">
              <Swiper
                slidesPerView={"auto"}
                grabCursor={true}
                navigation={true}
                className="promotionsbanks__description--swiper"
              >
                {items?.promociones.map((item: any, index: number) => (
                  <SwiperSlide key={index}>
                    <CardCustom
                      // type={item.card.tipo_disenio.titulo}
                      type={"home"}
                      data={item.card}
                      entidad={item?.entidad}
                      redirection={item.redireccion}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      );
    };

    if (categorias.includes("paquete") || categorias.includes("paquete exclusivo")) {
      return paquete();
    }
    if (categorias.includes("campaña")) {
      return campaña();
    }
    if (categorias.includes("banco")) {
      return bancos();
    }

    if (alimentosPromocion) {
      return alimentos();
    }

    return null;
  }

  return (
    <section>
      <section>
        {data.map((item: any, index: number) => (
          <Fragment key={index}>
            {index === data.length - 1 && (
              <BannerTira type={banner.tipo?.tbt_titulo} data={banner} />
            )}
            <div>{promotionsType({ items: item })}</div>
          </Fragment>
        ))}
      </section>
    </section>
  );
};

SectionCardsPromotions.defaultProps = {
  bgColor: "white",
  titleColor: "black",
};

export default SectionCardsPromotions;
