import React, { useEffect } from "react";
import { Text } from "components/atoms";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { updateMetadata } from "utils/function";

const Maintenance = () => {
  const navigate = useNavigate();

  useEffect(() => {
    updateMetadata({
      title: "Página en mantenimiento",
      description:
        "Página en mantenimiento",
      canonicalUrl: "https://www.casa-andina.com/es/mantenimiento",
      hreflang: [
        {
          hreflang: "es",
          href: "https://www.casa-andina.com/es/mantenimiento",
        },
      ],
    });
  }, []);

  return (
    <section className="maintenance">
      <div className="notfound__container">
        <div className="notfound__container--text">
          <Text type="title" text="¡Volveremos pronto!" />
          <br />
          <Text
            type="subtitle2"
            text="Estamos realizando un mantenimiento programado. Volveremos a estar en línea en breve. ¡Gracias por tu paciencia!"
          />
          <br />
          <Text
            type="paragraph"
            text="Volver al inicio."
            onClick={() => navigate("/")}
            className="redirect"
          />
        </div>
      </div>
    </section>
  );
};

export default Maintenance;
