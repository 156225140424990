import React from 'react'
import Icon from '../icon'
import Paragraph from '../paragraph'
import { IIconText } from './props'
import './styles.scss'
import Text from '../text'

const IconText = ({
  nameIcon,
  listIcon,
  text,
  text1,
  text2,
  type,
  div,
  w,
  h,
  className,
  className1,
  className2,
  family,
  onClick,
  onClick2,
  color,
  isActive
}: IIconText) => {
  const iconTextTypes = () => {
    switch (type) {
      case 'left':
        return (
          <div className={'icon-left'}>
            <div>
            <Icon name={nameIcon} list={listIcon} width={w} height={h}  color={color}/>
            </div>
            <Text text={text} type='paragraph' className={className}/>
          </div>
        )
      case 'right':
        return (
          <div className={onClick ? 'icon-right cursor' : 'icon-right'} onClick={onClick}>
            <Text text={text} type='paragraph' className={className} />
            <Icon name={nameIcon} list={listIcon} width={w} height={h} className={className2} color={color}/>
          </div>
        )
      case 'left2':
        return (
          <div className={`${className} d-flex ai-center`}>
            <Paragraph text={text} family={family} />
            <Icon
              name={nameIcon}
              list={listIcon}
              width={w}
              height={h}
              className={isActive ? 'iconActive' : ''}
              color={color}
            />
          </div>
        )
      case 'bottom':
        return (
          <div
            className={`${className} d-flex fd-column ai-center`}
            onClick={onClick}
          >
            <Icon name={nameIcon} list={listIcon} width={w} height={h}  color={color}/>
            <Paragraph text={text} family={family} />
          </div>
        )
      case 'txt':
        return (
          <div className='icon-txt' onClick={onClick}>
            <div>
              <Icon name={nameIcon} list={listIcon} width={20} height={20}  color={color}/>
            </div>
            <Text text={text} type='paragraph' className={className2}/>
          </div>
        )
      case 'bottom2':
        return (
          <div className='bottom-txt'>
            <div>
              <Icon name={nameIcon} list={listIcon} width={w} height={h} />
            </div>
            <Text text={text1} type='paragraph' className={className1} />
            <Text text={text2} type='paragraph' className={className2} />
          </div>
        )
      case 'zero':
        return (
          <div className='icon-zero' onClick={onClick}>
            <Icon name={nameIcon} list={listIcon} width={w} height={h}  color={color}/>
            <Text text={text} type='paragraph' className={className} />
          </div>
        )
      case 'footer':
        return (
          <div className={'icon-footer'}>
            <div style={{ width: div, height: div}} >
              <Icon name={nameIcon} list={listIcon} width={w} height={h}  color={color}/>
            </div>
            <div>
              <Text text={text} type='paragraph' className={className} />
            </div>
          </div>
        )
      case 'paragraph':
        return (
          <div className={'icon-paragraph'} onClick={onClick}>
            <Icon name={nameIcon} list={listIcon} width={w} height={h}  color={color}/>
            <Text text={text} type='paragraph' className={className} />
          </div>
        )
      default:
        return (
          <div className={'icon-default'} onClick={onClick}>
            <Icon name={nameIcon} list={listIcon} width={w} height={h}  color={color} onClick={onClick2} className={className2}/>
            <Text text={text} type='paragraph' className={className} />
          </div>
        )
    }
  }

  return <>{iconTextTypes()}</>
}

IconText.defaultProps = {
  type: 'default',
  w: 24,
  h: 24
}

export default IconText
