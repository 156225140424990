import React, { useState } from "react";
import { IconText, Text } from "components/atoms";
import { IDescriptionLocal } from "./props";
import { useTranslation } from "react-i18next";
import { MAX_DESCRIPTION_LENGTH } from "utils/values";
import "./styles.scss";
import Animations from "../animations";

const DescriptionLocal = ({
  altitude,
  data,
  temperature,
  description,
}: IDescriptionLocal) => {
  const { t } = useTranslation();

  const [isToggled, setIsToggled] = useState(false);

  const toogleMore = () => setIsToggled(!isToggled);

  const descriptionComplete = `${description}`;

  const shortenedDescription = isToggled
    ? descriptionComplete
    : descriptionComplete.slice(0, MAX_DESCRIPTION_LENGTH) + "...";

  return (
    <>
      <div className="ismobile content-mob">
        {description && description.trim() !== "" ? (
          <>
            <Text
              html={{ __html: shortenedDescription }}
              className="description"
            />

            <div className="toogle">
              <Text
                text={
                  isToggled
                    ? t("general.descriptionToogleLess")
                    : t("general.descriptionToogleMore")
                }
                onClick={toogleMore}
                className="toogle__more"
              />
            </div>
          </>
        ) : (
          <Animations type="skeletonContent">
            <Text
              text={
                "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequuntur, officia quaerat cumque vitae numquam architecto modi molestiae in porro explicabo iusto doloribus, animi praesentium eum illum laboriosam rem error at."
              }
              style={{ color: "transparent" }}
            ></Text>
          </Animations>
        )}

        <div className="contenicon">
          <IconText
            nameIcon={
              data?.tipo_transporte?.titulo === "Terrestre"
                ? "auto"
                : "airplane"
            }
            listIcon="items"
            text={data?.referencia}
          />
          <IconText
            nameIcon="temperature"
            listIcon="items"
            text={temperature}
          />
          <IconText
            nameIcon="sun"
            listIcon="items"
            text={`${altitude} m.s.n.m`}
          />
        </div>
      </div>

      <div className="isdesktop content-des">
        {description && description.trim() !== "" ? (
          <Text html={{ __html: descriptionComplete }} className="description" />
        ) : (
          <Animations type="skeletonContent">
            <Text
              text={
                "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequuntur, officia quaerat cumque vitae numquam architecto modi molestiae in porro explicabo iusto doloribus, animi praesentium eum illum laboriosam rem error at."
              }
              style={{ color: "transparent" }}
            ></Text>
          </Animations>
        )}

        <div className="contenicon">
          <IconText
            nameIcon={
              data?.tipo_transporte?.titulo === "Terrestre"
                ? "auto"
                : "airplane"
            }
            listIcon="items"
            text={data?.referencia}
            className="pr-1rem br-1px-secondary-color5"
          />
          <IconText
            nameIcon="temperature"
            listIcon="items"
            text={temperature}
            className="pr-1rem br-1px-secondary-color5 "
          />
          <IconText
            nameIcon="sun"
            listIcon="items"
            text={`${altitude} m.s.n.m`}
          />
        </div>
      </div>
    </>
  );
};

export default DescriptionLocal;
