import { Icon, IconText, Text } from "components/atoms";
import { SliderHotelMobile } from "components/organisms";
import { Swiper, SwiperSlide } from "swiper/react";
import { Tooltip } from "react-tooltip";
import "./styles.scss";
import "swiper/css";
import { useNavigate } from "react-router-dom";
import { MIN_ATRIBUTO_BANK_LENGTH } from "utils/values";
import { Animations } from "components/molecules";
import { estadiasDate, handleTravelClick } from "utils/function";
import { Autoplay } from "swiper/modules";
import { useAppSelector } from "hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getMenuOferta } from "actions/menu/services";

const BetterPrices = ({ data, restaurantRef, title }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const coin = useAppSelector((state) => state.config.coin);
  const language = useAppSelector((state) => state.config.language);

  const countryCode = useAppSelector(
    (state) => state.language.dataCurrencies?.client_info
  );

  const [dataMenu, setDataMenu] = useState<any>([]);

  const handleRedirect = () => {
    navigate(
      dataMenu?.urls_navbar.length === 0
        ? "/es/mejores-precios"
        : dataMenu?.urls_navbar[0]?.redireccion
    );
  };

  const [tooltipStates, setTooltipStates] = useState<boolean[]>(
    new Array(data.length).fill(false)
  );

  const handleTooltipClick = (e: any, index: number) => {
    const newTooltipStates = [...tooltipStates];
    newTooltipStates[index] = !newTooltipStates[index];
    setTooltipStates(newTooltipStates);
    e.stopPropagation();
  };

  const fetchMenu = () => getMenuOferta({ language, setDataMenu, navigate });

  useEffect(() => {
    if (language && coin) {
      fetchMenu();
    }
  }, [language, coin]);

  return (
    <div className="betterprices" ref={restaurantRef}>
      <div className="betterprices__container">
        <Text text={title} type="subtitle" className={"subtitle"} />
        <div className="betterprices__description">
          <Swiper
            slidesPerView={"auto"}
            grabCursor={true}
            navigation={true}
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="betterprices__description--swiper"
          >
            {data && Array.isArray(data) && data.length > 0 ? (
              <>
                {data.map((better: any, index: number) => (
                  <SwiperSlide key={index}>
                    {/* <Link
                  to={better.slug}
                  className='nlink'
                  target='_blank'
                  rel='noopener noreferrer'
                > */}
                    <div
                      className="betterprices__description--card"
                      onClick={() => handleTravelClick(better, coin)}
                    >
                      <div className="betterprices__description--card--slider">
                        <SliderHotelMobile
                          tipo={better.tipo_hotel?.titulo}
                          images={better.img}
                          city={better.destino}
                          bgColor={better.tipo_hotel?.color}
                        />
                      </div>
                      <div className="betterprices__description--card--container">
                        {better.etiqueta_precio.texto && (
                          <div
                            className="tagposition"
                            style={{
                              backgroundColor: better.etiqueta_precio.color,
                            }}
                          >
                            <Text
                              text={better.etiqueta_precio.texto}
                              type="paragraph"
                              className="tag"
                            />
                          </div>
                        )}
                        <div className="cardheaderr">
                          <Text
                            text={better.nombre_corto}
                            type="subtitle2"
                            className="title"
                          />
                          <div>
                            {better.calificacion > 0 && (
                              <div className="review">
                                <Text
                                  text={better.calificacion}
                                  type="paragraph"
                                />

                                <IconText
                                  text={`(${
                                    better.reseña !== null ? better.reseña : 0
                                  } ${t("betterPrices.reviews")})`}
                                  nameIcon="star2"
                                  listIcon="items"
                                  w={16}
                                  h={16}
                                  type="zero"
                                />
                              </div>
                            )}
                            {better?.atributo_destacado_hotel
                              .atributo_destacado && (
                              <div className="estilosicon">
                                {better.atributo_destacado_hotel
                                  ?.icon_atributo_destacado === null ? (
                                  <div>
                                    <Icon
                                      name="check"
                                      list="items"
                                      width={14}
                                      height={14}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src={
                                      better.atributo_destacado_hotel
                                        ?.icon_atributo_destacado?.path
                                    }
                                    alt={
                                      better.atributo_destacado_hotel
                                        ?.icon_atributo_destacado?.alt
                                    }
                                    width={12}
                                    height={12}
                                  />
                                )}
                                <Text
                                  text={
                                    better?.atributo_destacado_hotel
                                      .atributo_destacado &&
                                    better?.atributo_destacado_hotel
                                      ?.atributo_destacado.length >
                                      MIN_ATRIBUTO_BANK_LENGTH
                                      ? `${better?.atributo_destacado_hotel.atributo_destacado.slice(
                                          0,
                                          MIN_ATRIBUTO_BANK_LENGTH
                                        )}...`
                                      : better?.atributo_destacado_hotel
                                          .atributo_destacado
                                  }
                                  className="atributo"
                                  type="paragraph"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="betterprices__description--card--content">
                          <div
                            className="cardprices"
                            // style={{
                            //   paddingTop:
                            //     better.precio_anterior.precio >
                            //     better.precio.precio
                            //       ? '0px'
                            //       : '20px'
                            // }}
                          >
                            <div className="cardprices__services">
                              <div className="cardprices__services--better">
                                {better.atributo_destacado
                                  .slice(0, 4)
                                  .map((item: any, index: number) => (
                                    <div className="estilos" key={index}>
                                      <Icon
                                        name={"check"}
                                        list="items"
                                        width={12}
                                        height={12}
                                        color={
                                          item.color ? "#14C67A" : "#7E7E7E"
                                        }
                                      />
                                      <p
                                        style={{
                                          color: item.color || "#7E7E7E",
                                        }}
                                      >
                                        {item.texto}
                                      </p>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div
                              className="cardprices__card"
                              // style={{
                              //   alignItems:
                              //     better.precio_anterior.precio >
                              //     better.precio.precio
                              //       ? 'center'
                              //       : 'flex-start'
                              // }}
                            >
                              <div>
                                <p
                                  className="ttcardprices"
                                  style={{
                                    marginBottom:
                                      better.precio_anterior.precio >
                                      better.precio.precio
                                        ? "0px"
                                        : "20px",
                                  }}
                                >
                                  {t("betterPrices.nightFrom")}
                                  {parseFloat(better.precio_anterior.precio) >
                                    parseFloat(better.precio.precio) && (
                                    <span>
                                      {better.precio_anterior.simbolo}{" "}
                                      {better.precio_anterior.precio}
                                    </span>
                                  )}
                                </p>
                              </div>
                              <div className="carddescuento">
                                {parseFloat(better.precio_anterior.precio) >
                                  parseFloat(better.precio.precio) && (
                                  <Text
                                    text={`- ${better.descuento}%`}
                                    className="ttdescuento"
                                    style={{
                                      backgroundColor:
                                        better.etiqueta_precio.color ||
                                        better.tipo_hotel?.color,
                                    }}
                                    type="paragraph"
                                  />
                                )}
                                <Text
                                  text={`${better.precio.simbolo} ${better.precio.precio}`}
                                  className="sbcardprices"
                                  type="paragraph"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text">
                            <div className="date">
                              {better.fecha_inicio && better.fecha_fin && (
                                <div>
                                  {/* {t('betterPrices.validateDates')} <br />
                              {t('betterPrices.validateDates2', {
                                startDate: getDateWithoutTime(
                                  better.fecha_inicio
                                ),
                                endDate: getDateWithoutTime(better.fecha_fin)
                              })} */}
                                  <Text
                                    html={{
                                      __html: estadiasDate(
                                        better.fecha_inicio,
                                        better.fecha_fin,
                                        t,
                                        false
                                      ),
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="column">
                              {Boolean(better.texto_tooltip_igv) &&
                                better.igv.precio > 0 && (
                                  <div
                                    className="sbbcardprices"
                                    onClick={(e) =>
                                      handleTooltipClick(e, better.id)
                                    }
                                  >
                                    {better.igv !== null &&
                                      better.igv.precio > 0 && (
                                        <>
                                          <Text
                                            text={`+ ${better.igv.simbolo} ${better.igv.precio} ${better.texto_tooltip_igv_card}`}
                                            type="paragraph"
                                          />
                                          <Icon
                                            name="question-card"
                                            list="items"
                                            color="gray"
                                            width={14}
                                            height={14}
                                            className={`tooltip-price-${better.id}`}
                                          />
                                        </>
                                      )}
                                    <Tooltip
                                      // isOpen={tooltipStates[index]}
                                      anchorSelect={`.tooltip-price-${better.id}`}
                                      place="top-end"
                                      clickable={true}
                                      opacity={1}
                                      style={{
                                        backgroundColor: "white",
                                        color: "#222",
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                                        maxWidth: "280px",
                                        width: "100%",
                                        zIndex: 101,
                                      }}
                                    >
                                      {better.texto_tooltip_igv}
                                    </Tooltip>
                                  </div>
                                )}
                              {better.descuento_life !== null &&
                                better.descuento_life !== 0 && (
                                  <div className="bg">
                                    <Icon
                                      name={"tag"}
                                      list={"items"}
                                      width={14}
                                      height={14}
                                      className="tag"
                                    />
                                    <Text
                                      text={t("betterPrices.dsctoLife", {
                                        descuento_life: better.descuento_life,
                                      })}
                                      type="paragraph"
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </Link> */}
                  </SwiperSlide>
                ))}
              </>
            ) : (
              <>
                {[...Array(3)].map((_, index) => (
                  <SwiperSlide key={index}>
                    <div className="betterprices__description--card">
                      <div className="betterprices__description--card--slider">
                        <Animations type="skeletonContent">
                          <div style={{ height: 216, width: "100%" }}></div>
                        </Animations>
                      </div>
                      <div className="betterprices__description--card--container">
                        <div className="cardheaderr">
                          <Animations type="skeletonContent">
                            <Text
                              style={{ width: 60, height: 20, marginRight: 5 }}
                              type="subtitle2"
                              className="title"
                            />
                          </Animations>
                        </div>
                        <div className="betterprices__description--card--content">
                          <div className="cardprices">
                            <div className="cardprices__services">
                              <div className="cardprices__services--better">
                                <div className="estilos">
                                  <p
                                    style={{
                                      color: "#7E7E7E",
                                    }}
                                  >
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="cardprices__card">
                              <div>
                                <Animations type="skeletonContent">
                                  <p
                                    className="ttcardprices"
                                    style={{
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <span style={{ width : 50, height : 10 }}></span>
                                    <span></span>
                                  </p>
                                </Animations>
                              </div>
                                <div className="carddescuento">
                                  <Animations type="skeletonContent">
                                    <Text
                                      style={{ width : 35, height : 40 }}
                                      className="sbcardprices"
                                      type="paragraph"
                                    />
                                  </Animations>
                                </div>
                            </div>
                          </div>
                          <div className="text">
                            <div className="date">
                              <div>
                                <Animations type="skeletonContent">
                                  <Text
                                    style={{ width : 120, height : 20 }}
                                  />
                                </Animations>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </>
            )}
            <SwiperSlide>
              <Animations type="scaleMin">
                <div className="betterprices__description--card">
                  <div
                    className="betterprices__description--card--extra"
                    onClick={handleRedirect}
                  >
                    <div className="border">
                      <Icon name="next" list="arrows" width={14} height={14} />
                    </div>
                    <Text
                      type="paragraph"
                      text={t("betterPrices.discoverOurPrices")}
                      className={"paragraph"}
                    />
                  </div>
                </div>
              </Animations>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default BetterPrices;
