import { useState } from "react";
import { Icon, IconText, Text } from "components/atoms";
import { messages } from "utils/lang";
import { useIntl } from "react-intl";
import "./styles.scss";
import Hr from "components/atoms/hr";

const ServicesCommodities = ({
  servicios,
  commodities,
  servicesRef,
  titleServices,
  titleCommodities,
  commoditiesRef,
  colorIcon,
}: any) => {
  const { locale } = useIntl();

  const itemsToShowInitially = 8;
  const [showAll, setShowAll] = useState(false);
  const visibleCommodities = showAll
    ? commodities
    : commodities.slice(0, itemsToShowInitially);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      <div className="servicescommodities" ref={servicesRef}>
        <div className="servicescommodities__container">
          {servicios.length > 0 && (
            <div
              className="servicescommodities__container--services"
              ref={servicesRef}
            >
              <Text
                type="subtitle"
                className={"subtitle"}
                text={titleServices}
              />

              <div className="description">
                <div className="services">
                  {servicios.map((item: any, index: number) => (
                    <div className="column" key={index}>
                      <div>
                        <Icon
                          name={item.titulo_icono.toLowerCase()}
                          list="premium"
                          color={colorIcon}
                          width={40}
                          height={40}
                        />
                      </div>
                      <div className="text">
                        <Text type="paragraph" text={item.titulo} />
                        <Text type="paragraph" text={item.subtitulo} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <Hr />

          {commodities.length > 0 && (
            <div
              className="servicescommodities__container--commodities"
              ref={commoditiesRef}
            >
              <Text
                type="subtitle"
                className={"subtitle"}
                text={titleCommodities}
              />

              <div className="description">
                <div className="services">
                  {visibleCommodities.map((item: any, index: number) => (
                    <div className="column" key={index}>
                      <div>
                        <Icon
                          name={item.titulo_icono.toLowerCase()}
                          list="premium"
                          color={colorIcon}
                          width={20}
                          height={20}
                        />
                      </div>
                      <div className="text">
                        <Text type="paragraph" text={item.titulo} />
                      </div>
                    </div>
                  ))}
                </div>
                {commodities.length > itemsToShowInitially && (
                  <div onClick={toggleShowAll} className="more">
                    <IconText
                      type="right"
                      listIcon="arrows"
                      text={
                        showAll
                          ? messages[locale].showless
                          : messages[locale].showmore
                      }
                      nameIcon={showAll ? "up" : "down"}
                      className="more__btn"
                      w={20}
                      h={20}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ServicesCommodities;
