import React from 'react'
import { IIconLink } from './props'
import { Icon } from 'components/atoms'

interface IconLink extends IIconLink {
  [x : string] : any,
}

const IconLink = ({ iconName, itemList, linkIcon, className, ...rest } : IconLink) => {
  return (
    <a href={linkIcon} target='_blank' rel='noreferrer' className={className} {...rest}>
      <Icon name={iconName} list={itemList} width={30} height={30} />
    </a>
  )
}

export default IconLink
