import { BannerSlider } from 'components/organisms'
import PromotionsCard from 'components/organisms/promotions-card'
import PromotionsYTQP from 'components/organisms/promotions-ytqp'
import { useTranslation } from "react-i18next";
import './styles.scss'

const PromotionsCampaign = ({ data, idFilter, skeleton }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <BannerSlider data={data.banners} />

      <PromotionsYTQP data={data} />

      {data?.inicio_de_promocion && (
        <PromotionsCard
          title={`${t('promotions.takeBeneffitOfOurDiscounts')}`}
          data={data.hoteles}
          bank={data.entidad}
          codigo={data.codigo}
          type='campaign'
          idFilter={idFilter}
          skeleton={skeleton}
          pT={32}
        />
      )}

      {data?.inicio_de_promocion === false && (
        <div className='soon-discounts-for-you'>
          <h1>{t('promotions.SoonDiscountsForYou')}</h1>
        </div>
      )}
    </>
  )
}

export default PromotionsCampaign
