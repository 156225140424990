import { useEffect } from "react";
import { BannerSlider } from "components/organisms";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const TerminosYCondicionesdeReserva = () => {
  const { t } = useTranslation();

  const banner_data_centrado = [
    {
      id: 1,
      titulo: t("termsAndConditions.associatedHotelsTerms"),
      subtitulo: "",
      color_fondo_logo: "rgba(62, 145, 41, 1)",
      img_desktop: {
        name: "banner-terminos-y-condiciones",
        path: "https://s3.us-east-1.amazonaws.com/ca-webprod/media/9f8b746c-bfb1-42f1-b07c-e35ae15dea99.jpg",
        width: 1440,
        height: 400,
        size: 794666,
        title: "Banner Términos y Condiciones",
        alt: "Banner Términos y Condiciones",
        description: null,
        caption: "Banner Términos y Condiciones",
      },
      img_mobile: [],
      descuento: "",
      descripcion_boton: "",
      redireccion_boton: "",
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: "landings",
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BannerSlider data={banner_data_centrado} textLarge />
      <div className="associatedhotels">
        <div className="associatedhotels__container">
          <div className="associatedhotels__container--section">
            <h1>{t("termsAndConditions.generalTermsAndConditions")}</h1>

            <ul>
              <li>
                <span>{t("termsAndConditions.associatedHotels")}</span>
                {t("termsAndConditions.associatedHotelsDescription")}
              </li>
              <li>
                <span>{t("termsAndConditions.casaAndina")}</span>
                {t("termsAndConditions.casaAndinaDescription")}
              </li>
              <li>
                <span>{t("termsAndConditions.client")}</span>
                {t("termsAndConditions.clientDescription")}
              </li>
              <li>
                <span>{t("termsAndConditions.noShow")}</span>
                {t("termsAndConditions.noShowDescription")}
              </li>
              <li>
                <span>{t("termsAndConditions.reservation")}</span>
                {t("termsAndConditions.reservationDescription")}
              </li>
            </ul>
          </div>
          <div className="associatedhotels__container--section2">
            <h2>{t("termsAndConditions.generalConditionsTitle")}</h2>

            <p>{t("termsAndConditions.generalConditionsDescription")}</p>

            <p>
              <span>{t("termsAndConditions.customerServicePolicyTitle")}</span>{" "}
              {t("termsAndConditions.customerServicePolicyDescription")}
            </p>

            <ul>
              <li>{t("termsAndConditions.billingInfo")}</li>
              <li>{t("termsAndConditions.currencyInfo")}</li>
              <li>{t("termsAndConditions.errorAndDuplicationPolicy")}</li>
              <li>{t("termsAndConditions.forceMajeurePolicy")}</li>
              <li>{t("termsAndConditions.roomAccessPolicy")}</li>
              <li>{t("termsAndConditions.smokingPolicy")}</li>
              <li>{t("termsAndConditions.archaeologicalPiecesPolicy")}</li>
              <li>{t("termsAndConditions.protectedSpeciesPolicy")}</li>
              <li>{t("termsAndConditions.drugPolicy")}</li>
              <li>{t("termsAndConditions.sexualExploitationPolicy")}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TerminosYCondicionesdeReserva;
