import React, { useState } from 'react'
import { IAccordionCheckbox } from './props';
import { Checkbox, Icon, Text } from 'components/atoms';
import './styles.scss'

const AccordionCheckbox = ({ title, items, selected, itemsSelected }: IAccordionCheckbox) => {
  const [isOpen, setIsOpen] = useState(true);

  const toogleAccordion = () => {
    setIsOpen(!isOpen);
  }

  const handleCheckboxChange = (id: number) => {
    const updatedValues = itemsSelected.includes(id)
      ? itemsSelected.filter(val => val !== id)
      : [...itemsSelected, id];
      selected(updatedValues);
  };

  return (
    <div className='accordioncheckbox'>
      <div onClick={toogleAccordion}>
        <div className='accordioncheckbox__container'>
          <Text text={title} className={'title'} />
          <Icon name={isOpen ? 'up' : 'down'} list="arrows" width={24} height={24} />
        </div>
      </div>
      {isOpen && (
        items.slice(0,10).map((checkbox) => (
          <Checkbox
            key={checkbox.id}
            id={checkbox.id}
            name={checkbox.titulo}
            titulo={checkbox.titulo}
            checked={itemsSelected.includes(checkbox.id)}
            onChange={() => handleCheckboxChange(checkbox.id)}
          />
        ))
      )}
    </div>
  )
}

export default AccordionCheckbox;