import React from 'react'
import { Icon } from 'components/atoms'
import './styles.scss'
import { Tooltip } from 'react-tooltip'
import { useTranslation } from "react-i18next";

const ScrollTopButton = ({descriptionRef, scrollToSection}: any) => {
  const { t } = useTranslation();

  const handleScrollToTop = () => {
    scrollToSection(descriptionRef);
  };

  return (
    <div className='scrolltop'>
      <div className='scrolltop__container'>
        <div className="scrolltop__container--line"></div>

        <div className='scrolltop__container--arrow tooltip-scrolltop' >
          <div className='arrowcontent' onClick={handleScrollToTop}>
            <Icon name='up' list='arrows' width={20} height={20}/>
          </div>
        </div>
        <div className="scrolltop__container--line"></div>

      </div>
      <Tooltip anchorSelect=".tooltip-scrolltop" place="top" openOnClick={false} opacity={1}
        style={{
          backgroundColor: "white",
          fontFamily: 'Sofia',
          fontWeight: 200,
          fontSize: "16px",
          color: "#222",
          boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
        }}
      >
        {t("general.buttonBackToTop")}
      </Tooltip>
    </div>
  )
}

export default ScrollTopButton