export const SUPPORT_LANG = ['es', 'en', 'pt'];

export const MOTIVATION = [
  "Reserva de Hotel",
  "Evento",
];

export const DESTINATIONS_FORMS = [
  "Arequipa",
  "Chiclayo",
  "Chincha",
  "Miraflores, Lima",
  "Moquegua",
  "Pucallpa",
  "Isidro, Lima",
  "Talara",
  "Trujillo",
  "Sagrado, Cusco",
  "Zorritos, Tumbes",
];

export const COUNTRY_CODE = [
  "Perú (+51)",
  "Afganistán (+93)",
  "Albania (+355)",
  "Alemania (+49)",
  "Andorra (+376)",
  "Angola (+244)",
  "Anguila (+1264)",
  "Antártida (+672)",
  "Antigua y Barbuda (+1268)",
  "Arabia Saudita (+966)",
  "Argelia (+213)",
  "Argentina (+54)",
  "Armenia (+374)",
  "Aruba (+297)",
  "Australia (+61)",
  "Austria (+43)",
  "Azerbaiyán (+994)",
  "Bélgica (+32)",
  "Bahamas (+1242)",
  "Bahrein (+973)",
  "Bangladesh (+880)",
  "Barbados (+1246)",
  "Belice (+501)",
  "Benín (+229)",
  "Bhután (+975)",
  "Bielorrusia (+375)",
  "Birmania (+95)",
  "Bolivia (+591)",
  "Bosnia y Herzegovina (+387)",
  "Botsuana (+267)",
  "Brasil (+55)",
  "Brunéi (+673)",
  "Bulgaria (+359)",
  "Burkina Faso (+226)",
  "Burundi (+257)",
  "Cabo Verde (+238)",
  "Camboya (+855)",
  "Camerún (+237)",
  "Canadá (+1)",
  "Chad (+235)",
  "Chile (+56)",
  "China (+86)",
  "Chipre (+357)",
  "Ciudad del Vaticano (+39)",
  "Colombia (+57)",
  "Comoras (+269)",
  "República del Congo (+242)",
  "República Democrática del Congo (+243)",
  "Corea del Norte (+850)",
  "Corea del Sur (+82)",
  "Costa de Marfil (+225)",
  "Costa Rica (+506)",
  "Croacia (+385)",
  "Cuba (+53)",
  "Curazao (+5999)",
  "Dinamarca (+45)",
  "Dominica (+1767)",
  "Ecuador (+593)",
  "Egipto (+20)",
  "El Salvador (+503)",
  "Emiratos Árabes Unidos (+971)",
  "Eritrea (+291)",
  "Eslovaquia (+421)",
  "Eslovenia (+386)",
  "España (+34)",
  "Estados Unidos de América (+1)",
  "Estonia (+372)",
  "Etiopía (+251)",
  "Filipinas (+63)",
  "Finlandia (+358)",
  "Fiyi (+679)",
  "Francia (+33)",
  "Gabón (+241)",
  "Gambia (+220)",
  "Georgia (+995)",
  "Ghana (+233)",
  "Gibraltar (+350)",
  "Granada (+1473)",
  "Grecia (+30)",
  "Groenlandia (+299)",
  "Guadalupe (+590)",
  "Guam (+1671)",
  "Guatemala (+502)",
  "Guayana Francesa (+594)",
  "Guernsey (+44)",
  "Guinea (+224)",
  "Guinea Ecuatorial (+240)",
  "Guinea-Bissau (+245)",
  "Guyana (+592)",
  "Haití (+509)",
  "Honduras (+504)",
  "Hong kong (+852)",
  "Hungría (+36)",
  "India (+91)",
  "Indonesia (+62)",
  "Irán (+98)",
  "Irak (+964)",
  "Irlanda (+353)",
  "Isla Bouvet (+47)",
  "Isla de Man (+44)",
  "Isla de Navidad (+61)",
  "Isla Norfolk (+672)",
  "Islandia (+354)",
  "Islas Bermudas (+1441)",
  "Islas Caimán (+1345)",
  "Islas Cocos (Keeling) (+61)",
  "Islas Cook (+682)",
  "Islas de Åland (+358)",
  "Islas Feroe (+298)",
  "Islas Georgias del Sur y Sandwich del Sur (+500)",
  "Islas Heard y McDonald (+61)",
  "Islas Maldivas (+960)",
  "Islas Malvinas (+500)",
  "Islas Marianas del Norte (+1670)",
  "Islas Marshall (+692)",
  "Islas Pitcairn (+870)",
  "Islas Salomón (+677)",
  "Islas Turcas y Caicos (+1649)",
  "Islas Ultramarinas Menores de Estados Unidos (+246)",
  "Islas Vírgenes Británicas (+1284)",
  "Islas Vírgenes de los Estados Unidos (+1340)",
  "Israel (+972)",
  "Italia (+39)",
  "Jamaica (+1876)",
  "Japón (+81)",
  "Jersey (+44)",
  "Jordania (+962)",
  "Kazajistán (+7)",
  "Kenia (+254)",
  "Kirguistán (+996)",
  "Kiribati (+686)",
  "Kuwait (+965)",
  "Líbano (+961)",
  "Laos (+856)",
  "Lesoto (+266)",
  "Letonia (+371)",
  "Liberia (+231)",
  "Libia (+218)",
  "Liechtenstein (+423)",
  "Lituania (+370)",
  "Luxemburgo (+352)",
  "México (+52)",
  "Mónaco (+377)",
  "Macao (+853)",
  "Macedônia (+389)",
  "Madagascar (+261)",
  "Malasia (+60)",
  "Malawi (+265)",
  "Mali (+223)",
  "Malta (+356)",
  "Marruecos (+212)",
  "Martinica (+596)",
  "Mauricio (+230)",
  "Mauritania (+222)",
  "Mayotte (+262)",
  "Micronesia (+691)",
  "Moldavia (+373)",
  "Mongolia (+976)",
  "Montenegro (+382)",
  "Montserrat (+1664)",
  "Mozambique (+258)",
  "Namibia (+264)",
  "Nauru (+674)",
  "Nepal (+977)",
  "Nicaragua (+505)",
  "Niger (+227)",
  "Nigeria (+234)",
  "Niue (+683)",
  "Noruega (+47)",
  "Nueva Caledonia (+687)",
  "Nueva Zelanda (+64)",
  "Omán (+968)",
  "Países Bajos (+31)",
  "Pakistán (+92)",
  "Palau (+680)",
  "Palestina (+970)",
  "Panamá (+507)",
  "Papúa Nueva Guinea (+675)",
  "Paraguay (+595)",
  "Polinesia Francesa (+689)",
  "Polonia (+48)",
  "Portugal (+351)",
  "Puerto Rico (+1)",
  "Qatar (+974)",
  "Reino Unido (+44)",
  "República Centroafricana (+236)",
  "República Checa (+420)",
  "República Dominicana (+1809)",
  "República de Sudán del Sur (+211)",
  "Reunión (+262)",
  "Ruanda (+250)",
  "Rumanía (+40)",
  "Rusia (+7)",
  "Sahara Occidental (+212)",
  "Samoa (+685)",
  "Samoa Americana (+1684)",
  "San Bartolomé (+590)",
  "San Cristóbal y Nieves (+1869)",
  "San Marino (+378)",
  "San Martín (Francia) (+1599)",
  "San Pedro y Miquelón (+508)",
  "San Vicente y las Granadinas (+1784)",
  "Santa Elena (+290)",
  "Santa Lucía (+1758)",
  "Santo Tomé y Príncipe (+239)",
  "Senegal (+221)",
  "Serbia (+381)",
  "Seychelles (+248)",
  "Sierra Leona (+232)",
  "Singapur (+65)",
  "Sint Maarten (+1721)",
  "Siria (+963)",
  "Somalia (+252)",
  "Sri lanka (+94)",
  "Sudáfrica (+27)",
  "Sudán (+249)",
  "Suecia (+46)",
  "Suiza (+41)",
  "Surinám (+597)",
  "Svalbard y Jan Mayen (+47)",
  "Swazilandia (+268)",
  "Tayikistán (+992)",
  "Tailandia (+66)",
  "Taiwán (+886)",
  "Tanzania (+255)",
  "Territorio Británico del Océano Índico (+246)",
  "Territorios Australes y Antárticas Franceses (+33)",
  "Timor Oriental (+670)",
  "Togo (+228)",
  "Tokelau (+690)",
  "Tonga (+676)",
  "Trinidad y Tobago (+1868)",
  "Tunez (+216)",
  "Turkmenistán (+993)",
  "Turquía (+90)",
  "Tuvalu (+688)",
  "Ucrania (+380)",
  "Uganda (+256)",
  "Uruguay (+598)",
  "Uzbekistán (+998)",
  "Vanuatu (+678)",
  "Venezuela (+58)",
  "Vietnam (+84)",
  "Wallis y Futuna (+681)",
  "Yemen (+967)",
  "Yibuti (+253)",
  "Zambia (+260)",
  "Zimbabue (+263)",
];

export const GENDER = ["Masculino", "Femenino"];
