import axios from "axios";
import errorsHttp from "utils/errorsHttp";
import { IAxios } from "./props";
import { REACT_APP_ENDPOINT_CA } from "typings/env";

let cancelToken: () => void;

function getValueLocalStorage(name: string) {
  return window.localStorage.getItem(name)
}

class Axios {
  static cancel = () => cancelToken();

  static async request({
    baseURL = REACT_APP_ENDPOINT_CA,
    method = 'get',
    url,
    isAuth,
    payload,
    params,
    headers = {},
  }: IAxios) {
    const methodTemplate: any = {
      'get': 'GET',
      'post': 'POST',
      'put': 'PUT',
      'del': 'DELETE',
    }
  
    const headersTemplate: any = headers

    if (isAuth) {
      headersTemplate.Authorization = `Bearer ${getValueLocalStorage('token')}`
    }
    
    return axios.create({ baseURL })({
      method: methodTemplate[method],
      headers: headersTemplate,
      data: payload,
      url,
      params,
      cancelToken: new axios.CancelToken((c) => cancelToken = c),
    })
      .then((response) => response)
      .catch((error) => {
        if (error?.message === errorsHttp.NetworkError) {
          throw new Error(
            `El servidor no responde o no tienes conexión a internet`
          );
        }
        throw error;
      });
  }
}

export default Axios;
