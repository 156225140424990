import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import './styles.scss'
import { ISliderHotel } from './props'

const SliderHotelMobile = ({
  images,
  tipo,
  isTag,
  autoplay,
  city,
  bgColor,
  height
}: ISliderHotel) => {
  const autoplayConfig = autoplay
    ? { delay: 7000, disableOnInteraction: false }
    : false

  const itemsForLoop = images.map((item: any) => item.id)

  return (
    <div>
      <div style={{ width: 'auto', height: '100%' }}>
        <Swiper
          className='hotelmobslider'
          loop={itemsForLoop.length > 3}
          pagination={{
            clickable: true
          }}
          autoplay={autoplayConfig}
          modules={[Autoplay, Pagination]}
        >
          {images.map((img: any, index: number) => (
            <SwiperSlide key={index}>
              <div style={{ width: '100%' }} key={index}>
                <div className='slidediv'>
                  {isTag && (
                    <div className='slidediv__tag' style={{backgroundColor: bgColor || 'pink'}}>
                      {tipo && <p className='tipo'>{tipo}</p>}
                      {city && <p className='city'>{city}</p>}
                    </div>
                  )}
                  <img
                    src={img?.path}
                    alt={img?.alt}
                    style={{ width: '100%', height: height }}
                    className='slidediv__img'
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

SliderHotelMobile.defaultProps = {
  isTag: true,
  autoplay: true,
  height: '216px',
}

export default SliderHotelMobile
