// Limitante de descripcion de ciudad en destinos ciudad
export const MAX_DESCRIPTION_LENGTH = 280;
export const MIN_DESCRIPTION_LENGTH = 56;

// Limitante de descripcion para card de destinos ciudad
export const MIN_CARD_LENGTH = 280;

// Limitante de atributos en card home
export const MIN_ATRIBUTO_LENGTH = 18;

// Limitante de direccion en card restaurantes
export const MAX_DIRECCION_LENGTH = 100;

// Limitante de description en card restaurantes
export const MAX_DESCRIPTION_RESTAURANT_LENGTH = 142;

// Limitante de atributos en card ofertas
export const MIN_ATRIBUTO_BANK_LENGTH = 30;


export const DESCRIPTIONTOOLTIP = '(*) Total no incluye el Impuesto General a las Ventas (IGV). Personas domiciliadas en Perú o con más de 60 días consecutivos dentro del país estarán sujetas al pago del 18% de IGV al momento del Check-In. En reserva de paquetes se aplica IGV en todos los casos. Importante: La aplicación de impuestos para agencias de viaje y/o empresas podría variar según las condiciones acordadas.'