import { useEffect, useRef, useState } from 'react'
import { Icon } from 'components/atoms'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './styles.scss'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SliderHotel = ({ images, tipo, city, bgColor }: any) => {
  const swiperRef = useRef<SwiperCore | null>(null)
  const [canSlideNext, setCanSlideNext] = useState<boolean>(true)
  const [canSlidePrev, setCanSlidePrev] = useState<boolean>(false)

  useEffect(() => {
    const swiperInstance = swiperRef.current
    if (swiperInstance) {
      swiperInstance.on('slideChange', () => {
        setCanSlideNext(!swiperInstance.isEnd)
        setCanSlidePrev(!swiperInstance.isBeginning)
      })
    }
  }, [])

  const hideArrows = images.length === 1

  return (
    <div className='hotelcontain'>
      <div>
        {!hideArrows && (
          <>
            <div
              className={`arrow-left arrow cursor ${
                canSlidePrev ? 'active' : ''
              }`}
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <Icon list='arrows' name='slider-prev' width={12} height={22} />
            </div>
            <div
              className={`arrow-right arrow cursor ${
                canSlideNext ? 'active' : ''
              }`}
              onClick={() => swiperRef.current?.slideNext()}
            >
              <Icon list='arrows' name='slider-next' width={12} height={22} />
            </div>
          </>
        )}
        <Swiper
          className='hoteleslider'
          // loop={true}
          pagination={{
            clickable: true
          }}
          // autoplay={{
          //   delay: 7000,
          //   disableOnInteraction: false
          // }}
          // observer={true}
          // observeParents={true}
          onInit={swiper => {
            swiperRef.current = swiper
            setCanSlidePrev(false)
            setCanSlideNext(!swiper.isEnd)
          }}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {images.map((img: any, index: number) => (
            <SwiperSlide key={index}>
              <div className='hoteleslider__content'>
                <div>
                  <span
                    className={'hoteleslider__content--tags'}
                    style={{ backgroundColor: bgColor, zIndex : 10 }}
                  >
                    {tipo && <p className='tipo'>{tipo}</p>}
                    {city && <p className='city'>{city}</p>}
                  </span>
                  <LazyLoadImage src={img?.path} alt={img?.alt} height={245} effect='blur'
                    style={{ width: '100%', height: '245px' }}/>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div></div>
      </div>
    </div>
  )
}

export default SliderHotel
