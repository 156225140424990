import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { ICardTemplate, IFilterTemplate } from "./props";
import {
  Checkbox,
  Dropdown,
  Hr,
  Icon,
  IconText,
  Rating,
  SearchDropdown,
  Text,
} from "components/atoms";
import { Tooltip } from "react-tooltip";
import { SliderHotel, SliderHotelMobile } from "components/organisms";
import { DESCRIPTIONTOOLTIP } from "utils/values";
import { useIntl } from "react-intl";
import { messages } from "utils/lang";
import MultiRangeSlider from "multi-range-slider-react";
import { AccordionCheckbox } from "components/molecules";
import { Link, useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Pin from "assets/icons/pin.svg";
import {
  estadiasDate,
  handleTravelClick,
  handleTravelClickWhithoutDate,
} from "utils/function";
import { useTranslation } from "react-i18next";

export const CardTemplate = ({
  data,
  coin,
  clearFilter,
  onSelect,
  loading,
}: ICardTemplate) => {
  const { locale } = useIntl();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleMouseDown = () => {
    onSelect(data.titulo);
  };

  const handleMouseUp = () => {
    onSelect(null);
  };

  const [visibleItems, setVisibleItems] = useState(6);
  const lastCardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && visibleItems < data.length) {
          setVisibleItems((prevVisibleItems) => prevVisibleItems + 2);
        }
      },
      { threshold: 0.5 }
    );

    if (lastCardRef.current) {
      observer.observe(lastCardRef.current);
    }

    return () => {
      if (lastCardRef.current) {
        observer.unobserve(lastCardRef.current);
      }
    };
  }, [visibleItems, data?.length]);

  return (
    <>
      {loading
        ? Array.from({ length: 4 }).map((_, index) => (
            <div className="cardnewhotel" key={index}>
              <div className="cardnewhotel__hotel">
                <div className="skeleton">
                  <div className="skeleton__container"></div>
                </div>
              </div>
              <div className="cardnewhotel__description">
                <div className="cardheader">
                  <div className="skeleton2">
                    <div className="skeleton__container"></div>
                  </div>
                  <div className="skeleton2">
                    <div className="skeleton__container"></div>
                  </div>

                  <div className="skeleton">
                    <div className="skeleton__container"></div>
                  </div>

                  <div className="skeleton2">
                    <div className="skeleton__container"></div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : data?.length > 0 &&
          data.slice(0, visibleItems).map((card: any, index: number) => (
            <div
              className="cardnewhotel"
              key={index}
              ref={index === visibleItems - 1 ? lastCardRef : null}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              <div className="cardnewhotel__hotel">
                <SliderHotel
                  images={card.img}
                  tipo={card.tipo_hotel?.titulo}
                  city={card.destino}
                  bgColor={card.tipo_hotel?.color}
                />
              </div>
              <div className="cardnewhotel__description">
                <div
                  className="cardheader"
                  // style={{
                  //   justifyContent:
                  //     card.calificacion > 0 ? 'space-between' : 'space-around'
                  // }}
                >
                  <div className="cardheader__content">
                    <Text
                      text={card.titulo}
                      type="subtitle2"
                      className={"title"}
                    />
                    {card.calificacion > 0 && (
                      <p className="review">
                        {card.calificacion}
                        <span>
                          <Icon
                            name="star"
                            list="items"
                            width={14}
                            height={14}
                            className="mr-0-2rem"
                          />
                        </span>
                        ({card.reseña} {messages[locale].hotelsReview})
                      </p>
                    )}
                  </div>

                  <div className="cardheader__prices">
                    <div className="cardheader__prices--left">
                      {card.atributo_destacado_hotel && (
                        <div className="icontext">
                          {card.atributo_destacado_hotel
                            ?.icon_atributo_destacado === null ? (
                            <div>
                              <Icon
                                name="check"
                                list="items"
                                width={14}
                                height={14}
                                color="gray"
                              />
                            </div>
                          ) : (
                            <img
                              src={
                                card.atributo_destacado_hotel
                                  ?.icon_atributo_destacado?.path
                              }
                              alt={
                                card.atributo_destacado_hotel
                                  ?.icon_atributo_destacado?.alt
                              }
                              width={12}
                              height={12}
                            />
                          )}
                          <Text
                            text={
                              card.atributo_destacado_hotel?.atributo_destacado
                            }
                            className="atributo"
                            type="paragraph"
                          />
                        </div>
                      )}
                      <div
                        className="list"
                        style={{
                          paddingTop: card.atributo_destacado_hotel ? 16 : 0,
                        }}
                      >
                        <div>
                          {card.atributo_destacado
                            .slice(0, 4)
                            .map((item: any, index: number) => (
                              <div className="icontext" key={index}>
                                <img
                                  src={item.icono?.path}
                                  alt={item.icono?.alt}
                                  width={16}
                                  height={16}
                                />

                                <p
                                  style={{
                                    color: index === 0 ? "#14C67A" : "#7E7E7E",
                                  }}
                                >
                                  {item.titulo}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="cardheader__prices--night">
                      {card.fecha_inicio && card.fecha_fin && (
                        // <p className='ttcardprices'>
                        //   Valido para el {getDateWithoutTime(card.fecha_inicio)}{' '}
                        //   al {getDateWithoutTime(card.fecha_fin)}
                        // </p>
                        <div>
                          <Text
                            html={{
                              __html: estadiasDate(
                                card.fecha_inicio,
                                card.fecha_fin,
                                t,
                                true
                              ),
                            }}
                            className="ttcardprices"
                          />
                        </div>
                      )}
                      <div>
                        {/* <p className='ttcardprices'>Noche desde</p> */}
                        <div
                          className="cardprices__card"
                          style={{
                            alignItems:
                              card.precio_anterior.precio > card.precio.precio
                                ? "flex-end"
                                : "flex-start",
                          }}
                        >
                          <div className="night">
                            <p> {t("betterPrices.nightFrom")}</p>
                            {parseFloat(card.precio_anterior.precio) >
                              parseFloat(card.precio.precio) && (
                              <p className="pricebefore">
                                {card.precio_anterior.simbolo}{" "}
                                {card.precio_anterior.precio}
                              </p>
                            )}
                          </div>
                          <div className="carddescuento">
                            {parseFloat(card.precio_anterior.precio) >=
                              parseFloat(card.precio.precio) && (
                              <Text
                                text={`${card.precio.simbolo} ${card.precio.precio}`}
                                className="sbcardprices"
                                type="paragraph"
                              />
                            )}
                          </div>
                        </div>
                      </div>

                        <div className="cardheader__igv">
                          {card.igv !== null && card.igv.precio > 0 && (
                            <>
                              <Text
                                text={`+ ${card.igv.simbolo} ${card.igv.precio} ${card.texto_tooltip_igv_card}`}
                                type="paragraph"
                              />
                              <Icon
                                name="question-card"
                                list="items"
                                width={14}
                                height={14}
                                className={`tooltip-price-${index}`}
                              />
                            </>
                          )}
                          <Tooltip
                            anchorSelect={`.tooltip-price-${index}`}
                            place="left-start"
                            opacity={1}
                            style={{
                              backgroundColor: "white",
                              color: "#222",
                              width: "100%",
                              maxWidth: "400px",
                              boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                            }}
                          >
                            {card.texto_tooltip_igv}
                          </Tooltip>
                        </div>
                    </div>
                  </div>

                  <div className="cardheader__btn">
                    <div
                      className="linknext nlink"
                      onClick={() => navigate(card.slug)}
                    >
                      <p className="text">Ver Hotel</p>
                      <Icon
                        name="arrowrr"
                        list="arrows"
                        width={20}
                        height={20}
                      />
                    </div>
                    <button
                      className="btnreserva"
                      onClick={() => handleTravelClickWhithoutDate(card, coin)}
                    >
                      Reservar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
    </>
  );
};

export const FilterTemplate = ({
  filtro,
  params,
  clearFilter,
  selectedOption,
  handleSelect,
  scategorias,
  setRatingsValue,
  selectedRating,
  setSelectedRating,
  selectedTitle,
  setInputValue,
  inputValue,
  setSelectedTitle,
  resetRatings,
  min,
  max,
  minValue,
  maxValue,
  handleChange,
  selectedExperiencia,
  setSelectedExperiencia,
  selectedViajes,
  setSelectedViajes,
  selectedServicios,
  setSelectedServicios,
  setLabel,
  isButtonVisible,
}: IFilterTemplate) => {
  const { locale } = useIntl();

  return (
    <div className="scrollfilter pb-2rem">
      <div className="d-flex jc-space-between ai-center">
        <Text text={messages[locale].searchByName} className={"search"} />
        <button className="cleanfilter" onClick={clearFilter}>
          Borrar
        </button>
      </div>

      <div className="ismobile">
        <div style={{ paddingTop: "16px" }}>
          <Dropdown
            ordenarPor={filtro.ordenar_por}
            selectedOption={selectedOption}
            onSelect={handleSelect}
          />
          <div style={{ paddingTop: "16px" }}>
            <Hr />
          </div>
        </div>
      </div>
      <SearchDropdown
        options={filtro.destinos}
        selectedTitle={selectedTitle}
        setSelectedTitle={setSelectedTitle}
        setInputValue={setInputValue}
        inputValue={inputValue}
      />
      <div className="py-1rem">
        <Hr />
      </div>

      <Text text={messages[locale].filterBy} className={"filterby"} />
      <Text text={messages[locale].pricePerNight} className={"price"} />

      <div className="range-slider-container">
        <MultiRangeSlider
          min={min}
          max={max}
          minValue={minValue}
          maxValue={maxValue}
          onInput={handleChange}
          label={false}
          // onChange={handleChange2}
          ruler={false}
          style={{ border: "none", boxShadow: "none", padding: "15px 10px" }}
          className="slider-range"
        />
      </div>

      <Hr />

      <div>
        <Text text={messages[locale].hotelCategory} className={"category"} />
        <div className="contentstarts">
          {filtro &&
            filtro.categorias
              ?.slice(0, 3)
              .map((categoria: any) => (
                <div key={categoria.id}>
                  {Number.isNaN(parseInt(categoria.titulo)) ? (
                    <Rating
                      data={categoria}
                      setRatingsValue={setRatingsValue}
                      setSelectedRating={setSelectedRating}
                      selectedRating={selectedRating}
                      onlyText
                      text={categoria.titulo}
                      reset={resetRatings}
                      setLabel={setLabel}
                    />
                  ) : (
                    <Rating
                      data={categoria}
                      setRatingsValue={setRatingsValue}
                      setSelectedRating={setSelectedRating}
                      number={parseInt(categoria.titulo)}
                      selectedRating={selectedRating}
                      reset={resetRatings}
                      setLabel={setLabel}
                    />
                  )}
                </div>
              ))}
        </div>
        <div></div>
      </div>

      <Hr />

      {filtro && filtro.experiencias && (
        <AccordionCheckbox
          title={messages[locale].experiences}
          items={filtro.experiencias.map((experiencia: any) => ({
            id: experiencia.id,
            titulo: experiencia.titulo,
          }))}
          itemsSelected={selectedExperiencia}
          selected={setSelectedExperiencia}
        />
      )}

      <Hr />

      {filtro && filtro.tipo_viajes && (
        <AccordionCheckbox
          title={messages[locale].travelType}
          items={filtro.tipo_viajes.map((viaje: any) => ({
            id: viaje.id,
            titulo: viaje.titulo,
          }))}
          itemsSelected={selectedViajes}
          selected={setSelectedViajes}
        />
      )}

      <Hr />

      {filtro && filtro.servicios && (
        <AccordionCheckbox
          title={messages[locale].services}
          items={filtro.servicios.map((servicio: any) => ({
            id: servicio.id,
            titulo: servicio.titulo,
          }))}
          itemsSelected={selectedServicios}
          selected={setSelectedServicios}
        />
      )}
    </div>
  );
};

const containerStyle = {
  width: "400px",
  height: "400px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

export function GoogleMapComponent() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "YOUR_API_KEY",
  });

  const [map, setMap] = React.useState(null);

  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  const showInfoWindow = () => {
    setInfoWindowOpen(true);
  };

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker position={center} icon={Pin} onClick={showInfoWindow} />
      {infoWindowOpen && (
        <InfoWindow onCloseClick={() => setInfoWindowOpen(false)}>
          <h1>Hi I am Info Window</h1>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export const CardMapNew = ({ data }: any) => {
  const { locale } = useIntl();
  const { t } = useTranslation();

  return (
    <Link to={data.slug} className="nlink">
      <div className="componentcontainer__description--card">
        <div className="componentcontainer__description--card--slider">
          <SliderHotelMobile
            tipo={data.tipo_hotel?.titulo}
            images={data.img}
            city={data.destino}
            bgColor={data.tipo_hotel?.color}
            height="134px"
            isTag={false}
          />
        </div>
        <div className="componentcontainer__description--card--container">
          <div
            className="tagposition"
            style={{
              backgroundColor: data.etiqueta_precio.color,
            }}
          >
            <Text
              text={data.etiqueta_precio.texto}
              type="paragraph"
              className="tag"
            />
          </div>
          <div
            className="cardheaderr"
          >
            <Text
              text={data.nombre_corto}
              type="subtitle2"
              className="title"
            />
            {data.calificacion > 0 && (
              <div>
                <div className="review">
                  <Text text={data.calificacion} type="paragraph" />
                  <IconText
                    text={`(${data.reseña !== null ? data.reseña : 0} ${
                      messages[locale].hotelsReview
                    })`}
                    nameIcon="star2"
                    listIcon="items"
                    w={16}
                    h={16}
                    type="zero"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="componentcontainer__description--card--content">
            <div className="cardprices">
              <div className="cardprices__card">
                <div className="night">
                  <p
                    style={{
                      marginBottom:
                        data.precio_anterior.precio > data.precio.precio
                          ? 0
                          : 20,
                    }}
                  >
                    {t("betterPrices.nightFrom")}
                  </p>
                  <p className="pricebefore">
                    {parseFloat(data.precio_anterior.precio) >
                      parseFloat(data.precio.precio) && (
                      <span>
                        {data.precio_anterior.simbolo}{" "}
                        {data.precio_anterior.precio}
                      </span>
                    )}
                  </p>
                </div>
                <div className="carddescuento">
                  <Text
                    text={`${data.precio.simbolo} ${data.precio.precio}`}
                    className="sbcardprices"
                    type="paragraph"
                  />
                </div>
              </div>
            </div>
            <div className="text">
              <div className="column">
                <div className="sbbcardprices">
                  {data.igv !== null && data.igv.precio > 0 && (
                    <>
                      <Text
                        text={`+ ${data.igv.simbolo} ${data.igv.precio} ${data.texto_tooltip_igv_card}`}
                        type="paragraph"
                      />
                      <Icon
                        name="question-card"
                        list="items"
                        width={14}
                        height={14}
                        className={`tooltip-price-${data.index}`}
                      />
                    </>
                  )}
                  <Tooltip
                    anchorSelect={`.tooltip-price-${data.index}`}
                    place="top-end"
                    opacity={1}
                    style={{
                      backgroundColor: "white",
                      color: "#222",
                      boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                      width: "210px",
                      zIndex: 101,
                    }}
                  >
                    {DESCRIPTIONTOOLTIP}
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
