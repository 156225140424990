import { configureStore } from '@reduxjs/toolkit'
import reducer from 'redux/slices'

const store = configureStore({
  reducer,
  devTools: false,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store
