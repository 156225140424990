
export const banner_data_centrado = [
    {
      id: 1,
      titulo: 'CASA ANDINA',
      subtitulo: 'DIRECTORES',
      color_fondo_logo: 'rgba(62, 145, 41, 1)',
      img_desktop: {
        name: 'banner-directores',
        path: 'https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/media/4cf525a7-bb12-469a-8a03-f87eed1a1857.jpg',
        width: 1440,
        height: 400,
        size: 794666,
        title: 'Banner Directores',
        alt: 'Banner Directores',
        description: null,
        caption: 'Banner Directores'
      },
      img_mobile: [],
      descuento: '',
      descripcion_boton: '',
      redireccion_boton: '',
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: 'Título/subtitulo collage logo'
      }
    }
  ]