import { BannerSlider } from 'components/organisms'
import { banner_data_centrado } from './data'
import './styles.scss';

const Directores = () => {
  return (
    <>
    <BannerSlider data={banner_data_centrado} />
    <div className="directores directores-element">
      <div className="directores__container px-1rem">
        
        <div className='item-director'>
            <h2>
                <strong>JULIO CÉSAR LUQUE BADENES</strong>
            </h2>
            <h4>
                <strong>Masculino </strong>
            </h4>
            <h4>
                <strong>62 años – 15/02/1960</strong>
            </h4>
            <p>
                <span>Director titular y dependiente de la Compañía desde marzo del 2003 y Presidente del Directorio desde 2009. Su experiencia anterior incluye su participación en el directorio de diversas empresas del Grupo Intecorp. Tiene un título de Máster en Economía y Dirección de Empresas por el Instituto de Estudios Superiores de Empresa (IESE) en Barcelona, España y es Ingeniero Mecánico por la Universidad Simón Bolívar, Venezuela. </span>
            </p>
            <p>
                <span>Actualmente es Presidente Ejecutivo de Métrica S.A, empresa consultora especializada en marketing y ventas, Presidente del Directorio de Nessus Hoteles, miembro del directorio de Inretail y Colegios Peruanos, así como de otras compañías del Grupo Intercorp y de diversas empresas como Transportadora de Gas del Peru, Redondos, San Miguel Industrial, Cineplanet, Talma, Aeropuertos del Peru y Dinet. Ha sido Presidente de IPAE Acción Empresarial y es miembro del Consejo Directivo del Consejo Privado de Competitividad y de Empresarios por la Integridad.</span>
            </p>
        </div>
        
        <div className='item-director'>
            <h2>
                <strong>JUAN STOESSEL FLOREZ</strong>
            </h2>
            <h4>
                <strong>Masculino</strong>
            </h4>
            <h4>
                <strong>54 años – 25/08/1967</strong>
            </h4>
            <p>
                <span>Director titular y dependiente. Secretario desde marzo 2012 Estudió la carrera de Hospitality Management y Hotel &amp; Restaurant Management en la Universidad Johnson &amp; Wales; perfeccionándose después en la Universidad de Cornell en Administración Hotelera para Gerentes Generales.</span>
            </p>
            <p>
                <span>Se desempeñó como Gerente General de la cadena de hoteles Sonesta Posadas de Inca hasta el año 2002. Además, es Socio y Presidente del Directorio de Cusco Restaurants S.A.C. (cadena de restaurantes en Cusco) y Vicepresidente de la Cámara de Turismo de Cusco – CARTUC.</span>
            </p>
        </div>

        <div className='item-director'>
            <h2>
                <strong>FELIPE CEBRECOS REVILLA</strong>
            </h2>
            <h4>
                <strong>Masculino</strong>
            </h4>
            <h4>
                <strong>89 años – 19/06/1933</strong>
            </h4>
            <p>
                <span>Director titular y dependiente. desde marzo del 2003. Tiene un título de Máster in Public Administration (MPA) y un título del Advanced Management Program (AMP) otorgados ambos por Harvard University y es Ingeniero Civil de la Pontificia Universidad Católica del Perú. Su trayectoria profesional incluye altos cargos ejecutivos y participación en el directorio de diversas instituciones públicas y privadas como el Banco Central de Reserva, el Banco Popular, la Superintendencia de Banca y Seguros, el Banco Central Hipotecario, la Caja de Ahorros de Lima, AFP Horizonte, etc.</span>
            </p>
        </div>

        <div className='item-director'>
            <h2>
                <strong>REHDER CASTRO BERNARDO JAVIER ESTEBAN </strong>
            </h2>
            <h4>
                <strong>Masculino</strong>
            </h4>
            <h4>
                <strong>61 años – 26/12/1960</strong>
            </h4>
            <p>
                <span>Director titular y dependiente. Desde mediados del 2013 hasta junio del 2016 ocupó el cargo de managing director y gerente general adjunto de Marsh Rehder. Anteriormente, fue responsable de la gerencia general de Rehder y Asociados durante 20 años. Actualmente es consejero de Marsh Rehder y director de Cineplanet.</span>
            </p>
            <p>
                <span>Es bachiller en administración de empresas de la Universidad del Pacífico. También es graduado del College of Insurance en Nueva York, donde obtuvo el Certificado en Riesgos y Seguros. Hizo su maestría en administración de empresas en la escuela de negocios de la Universidad de Pensilvania (The Wharton School).</span>
            </p>
            <p>
                <span>Ha seguido programas de especialización para ejecutivos en las universidades de Harvard, Pensilvania y de Piura. Fue profesor del curso de seguros del programa de maestría en finanzas de la Universidad del Pacífico y de la Universidad de Lima.</span>
            </p>
        </div>

        <div className='item-director'>
            <h2>
                <strong>EDWARD ROEKAERT EMBRECHTS</strong>
            </h2>
            <h4>
                <strong>Masculino </strong>
            </h4>
            <h4>
                <strong>57 años – 27/04/1965</strong>
            </h4>
            <p>
                <span>Director titular y dependiente. Dr. Edward Roekaert Embrechts, Rector, Universidad Peruana de Ciencias Aplicadas – UPC (desde el 2014)</span>
            </p>
            <p>
                <span>Doctor en Administración Hotelera y Turismo en The Hong Kong Polytechnic University, China. Máster en Administración Hotelera y Máster en Ingeniería Industrial e Investigación de Operaciones por Cornell University, Ithaca, Estados Unidos. Magíster en Alta Dirección y Liderazgo por la Universidad Peruana de Ciencias Aplicadas (UPC) y Máster en Alta Dirección y Liderazgo por la Universidad Politécnica de Cataluña. Ha sido Rector de la Universidad San Ignacio de Loyola. Fue electo al cargo de Accreditation Commissioner de la Comisión de Acreditación para Programas de Administración Hotelera (ACPHA) en los Estados Unidos.</span>
            </p>
        </div>

        <div className='item-director'>
            <h2>
                <span>CARLOS FERNANDO MINGYAR ARCE GAMARRA</span>
            </h2>
            <h4>
                <span>Masculino </span>
            </h4>
            <h4>
                <span>48 años – 22/05/1974</span>
            </h4>
            <p>
                <span>Director titular y dependiente. Dr. Edward Roekaert Embrechts, Rector, Universidad Peruana de Ciencias Aplicadas – UPC (desde el 2014)</span>
            </p>
            <p>
                <span>Su experiencia anterior incluye participación en el directorio de diversas empresas, gerencias generales, cargos de primer nivel en empresas transnacionales y consultoría. Tiene un título de Máster en Administración de Negocios por Kellogg School of Management en Northwestern University, Chicago, USA y estudió Ingeniería Industrial en la Universidad de Lima.</span>
            </p>
            <p>
                <span>Dr. Edward Roekaert Embrechts, Rector, Universidad Peruana de Ciencias Aplicadas – UPC (desde el 2014)</span>
            </p>
            <p>
                <span>Actualmente es Gerente General de NG Restaurantes (empresa operadora de más de 300 restaurantes en el Perú), Socio de Nexus Partners Group (firma de Private Equity), miembro de Directorio de Cineplanet y La Tinka, y del Comité de Sostenibilidad de Intercorp. Ha sido Gerente General de Inkafarma, Associate Principal en McKinsey &amp; Co. y Gerente de Marca en Procter and Gamble (además de cargos previos en logística y ventas)</span>
            </p>
        </div>


      </div> 
    </div>      
    </>
  )
}

export default Directores