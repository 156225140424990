import { useEffect, useState } from "react";
import { Modal } from "components/molecules";
import { useNavigate } from "react-router-dom";
import Loader from "components/molecules/loader";
import { getHotel } from "actions/hotel/services";
import { useAppSelector } from "hooks/useAppSelector";
import { ChangeResult } from "multi-range-slider-react";
import { messages } from "utils/lang";
import { useIntl } from "react-intl";
import { Icon, Dropdown, Text } from "components/atoms";
import {
  IDestino,
  IHotel,
  IOption,
} from "components/atoms/searchDropdown/props";
import "./styles.scss";

import { IconText } from "components/atoms";
import { CardTemplate, FilterTemplate } from "./components/template/templates";

import PromotionsCard from "components/organisms/promotions-card";
import ModalScreen from "components/molecules/modal-screen";
import { GoogleMapHotels } from "./components/map";
import { GoogleMapMob } from "./components/map/index";

const Hotels = () => {
  const { locale } = useIntl();
  const navigate = useNavigate();
  const {
    language,
    loading,
    coin,
  } = useAppSelector((state) => ({
    language: state.config.language,
    coin: state.config.coin,
    loading: state.language.loading,
  }));

  const [loadingHotel, setLoadingHotel] = useState(true);
  const [dataHotel, setDataHotel] = useState<any>({})

  const [selectedTitle, setSelectedTitle] = useState("");
  const [inputValue, setInputValue] = useState(selectedTitle || "");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [selectedExperiencia, setSelectedExperiencia] = useState([]);

  const [selectedViajes, setSelectedViajes] = useState([]);
  const [selectedServicios, setSelectedServicios] = useState([]);
  const [resetRatings, setResetRatings] = useState(false);
  const [selectedRating, setSelectedRating] = useState("");
  const [ratingsValue, setRatingsValue] = useState("");
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [label, setLabel] = useState("");

  const [params, setParams] = useState({
    experiencia: "",
    busqueda: "",
    tipo_viaje: "",
    servicios: "",
    sort_by: "",
    precio_max: "",
    precio_min: "",
    categoria: "",
  });

  const handleFilter = () => {
    const experienciaValue = selectedExperiencia.join(",");
    const viajesValue = selectedViajes.join(",");
    const serviciosValue = selectedServicios.join(",");

    const precioMinCambiado = minValues !== parseInt(minValue);
    const precioMaxCambiado = maxValues !== parseInt(maxValue);

    setParams({
      experiencia: experienciaValue,
      busqueda: selectedTitle,
      tipo_viaje: viajesValue,
      servicios: serviciosValue,
      sort_by: selectedOrder,
      precio_max: precioMaxCambiado ? maxValues.toString() : undefined,
      precio_min: precioMinCambiado ? minValues.toString() : undefined,
      categoria: ratingsValue,
    });

    const urlParams = [];

    if (experienciaValue) urlParams.push(`experiencia=${experienciaValue}`);
    if (viajesValue) urlParams.push(`tipo_viaje=${viajesValue}`);
    if (serviciosValue) urlParams.push(`servicios=${serviciosValue}`);
    if (selectedTitle) urlParams.push(`busqueda=${selectedTitle}`);
    if (selectedOrder) urlParams.push(`sort_by=${selectedOrder}`);
    if (precioMinCambiado) urlParams.push(`precio_min=${minValues}`);
    if (precioMaxCambiado) urlParams.push(`precio_max=${maxValues}`);
    if (ratingsValue) urlParams.push(`categoria=${ratingsValue}`);

    const queryString = urlParams.length > 0 ? `?${urlParams.join("&")}` : "";
    navigate(queryString);

    setIsButtonVisible(true);
  };

  const handleFilterMob = async () => {
    toogleFiltroMedio();
    await handleFilter();
  };

  const handleFilterMobF = async () => {
    toogleMobFilter();
    await handleFilter();
  };

  /********* RANGE  ********/
  const maxValue = dataHotel?.filtro?.precios_por_noche?.max.precio;
  const minValue = dataHotel?.filtro?.precios_por_noche?.min.precio;

  const [minValues, setMinValues] = useState(minValue);
  const [maxValues, setMaxValues] = useState(maxValue);

  const handleChange = (result: ChangeResult) => {
    setMinValues(result.minValue);
    setMaxValues(result.maxValue);
  };

  const style = document.createElement("style");
  style.innerHTML = `
    .slider-range .caption .max-caption::before,
    .slider-range .caption .min-caption::before {
      content: "${dataHotel?.filtro?.precios_por_noche?.max.simbolo} ";
    }
  `;

  document.head.appendChild(style);

  const clearFilter = () => {
    setParams({
      experiencia: "",
      tipo_viaje: "",
      servicios: "",
      busqueda: "",
      sort_by: "",
      precio_max: "",
      precio_min: "",
      categoria: "",
    });

    setSelectedExperiencia([]);
    setSelectedViajes([]);
    setSelectedServicios([]);
    setSelectedTitle("");
    setInputValue("");
    setResetRatings(true);
    setSelectedRating("");
    setRatingsValue("");
    setMinValues(minValue);
    setMaxValues(maxValue);
    setSelectedOrder("");

    navigate("/es/hoteles");
  };

  useEffect(() => {
    setMinValues(minValue);
    setMaxValues(maxValue);
  }, [minValue, maxValue]);

  /********* DROPDOWN  ********/
  const scategorias: IOption[] =
    dataHotel?.filtro?.destinos.map((destino: IDestino) => {
      return {
        id: destino.id,
        titulo: destino.titulo,
        hoteles: destino.hoteles.map((hotel: IHotel) => {
          return {
            htl_id: hotel.htl_id,
            htl_titulo: hotel.htl_titulo,
          };
        }),
      };
    });

  const [selectedOption, setSelectedOption] = useState(false);

  const handleSelect = (option: any) => {
    setSelectedOrder(option.titulo);
  }

  //FILTRO ORDENAR POR
  const handleSelectDesktop = (option: any) => {
    setSelectedOption(option.titulo);

    const selectedOrderBy = option.accion;

    setParams({
      ...params,
      sort_by: selectedOrderBy,
    });

    const sort_byGeneral = selectedOrderBy ? `sort_by=${selectedOrderBy}` : "";

    const urlParams = `?${sort_byGeneral}`;

    navigate(urlParams, { replace: true });
  };

  const [filtroMedio, setFiltroMedio] = useState(false);
  const [filtroMap, setFiltroMap] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  const toogleFiltroMedio = () => setFiltroMedio(!filtroMedio);
  const toogleFiltroMap = () => setFiltroMap(!filtroMap);

  const [filterMapMob, setFilterMapMob] = useState(false);
  const [mobfilter, setMobFilter] = useState(false);

  const toogleMapFilterMob = () => setFilterMapMob(!filterMapMob);
  const toogleMobFilter = () => setMobFilter(!mobfilter);

  const [openInfoWindowIndex, setOpenInfoWindowIndex] = useState<number | null>(
    null
  );

  const handleResetItem = (itemType: string, value?: string | number) => {
    let updatedParams = { ...params };

    switch (itemType) {
      case "experiencia":
        const updatedExperiencias = selectedExperiencia.filter(
          (id) => id !== value
        );
        updatedParams.experiencia = updatedExperiencias.join(",");

        if (updatedExperiencias.length === selectedExperiencia.length) {
          break;
        }

        setSelectedExperiencia(updatedExperiencias);
        break;
      case "tipo_viaje":
        const updatedTiposViaje = selectedViajes.filter((id) => id !== value);
        updatedParams.tipo_viaje = updatedTiposViaje.join(",");

        setSelectedViajes(updatedTiposViaje);
        break;

      case "servicios":
        const updatedServicios = selectedServicios.filter((id) => id !== value);
        updatedParams.servicios = updatedServicios.join(",");

        setSelectedServicios(updatedServicios);
        break;

      case "busqueda":
        updatedParams.busqueda = "";
        setSelectedTitle("");
        setInputValue("");
        break;

      case "precio":
        updatedParams.precio_min = "";
        updatedParams.precio_max = "";
        setMinValues(0);
        setMaxValues(0);
        break;

      case "categoria":
        updatedParams.categoria = "";
        setSelectedRating("");
        break;

      default:
        break;
    }

    setParams(updatedParams);

    const busquedaParams = selectedTitle ? `busqueda=${selectedTitle}` : "";
    const experienciaParams = updatedParams.experiencia
      ? `experiencia=${updatedParams.experiencia}&${busquedaParams}`
      : "";
    const tipoViajeParams = updatedParams.tipo_viaje
      ? `tipo_viaje=${updatedParams.tipo_viaje}&${busquedaParams}`
      : "";
    const serviciosParams = updatedParams.servicios
      ? `servicios=${updatedParams.servicios}&${busquedaParams}`
      : "";

    const urlParams = `?${busquedaParams}&${experienciaParams}&${tipoViajeParams}&${serviciosParams}`;

    navigate(urlParams, { replace: true });
  };

  const renderPriceButton = (min?: any, max?: any) => {
    let text = "";

    if (min && max) {
      text = `Precio min: ${dataHotel?.filtro?.precios_por_noche?.max.simbolo} ${min} - Precio max: ${dataHotel?.filtro.precios_por_noche?.max.simbolo} ${max}`;
    } else if (min) {
      text = `Precio min: ${dataHotel?.filtro?.precios_por_noche?.max.simbolo} ${min}`;
    } else if (max) {
      text = `Precio max: ${dataHotel?.filtro?.precios_por_noche?.max.simbolo} ${max}`;
    }

    return (
      <div className="buttonclose" onClick={() => handleResetItem("precio")}>
        <Text type="paragraph" text={text} family={"txsmall"} />
        <Icon
          name="close"
          list="items"
          width={12}
          height={12}
          className="closeb"
        />
      </div>
    );
  };

  const FilterComponent = () => {
    return (
      <FilterTemplate
        filtro={dataHotel.filtro}
        params={params}
        clearFilter={clearFilter}
        selectedOption={selectedOrder}
        handleSelect={handleSelect}
        scategorias={scategorias}
        selectedTitle={selectedTitle}
        setSelectedTitle={setSelectedTitle}
        setInputValue={setInputValue}
        inputValue={inputValue}
        resetRatings={resetRatings}
        setLabel={setLabel}
        setSelectedRating={setSelectedRating}
        setRatingsValue={setRatingsValue}
        selectedRating={selectedRating}
        min={minValue}
        max={maxValue}
        minValue={minValues}
        maxValue={maxValues}
        handleChange={handleChange}
        selectedExperiencia={selectedExperiencia}
        setSelectedExperiencia={setSelectedExperiencia}
        selectedViajes={selectedViajes}
        setSelectedViajes={setSelectedViajes}
        selectedServicios={selectedServicios}
        setSelectedServicios={setSelectedServicios}
        isButtonVisible={isButtonVisible}
      />
    );
  };

  const GoogleComponent = () => {
    return (
      <GoogleMapHotels
        hotelesdata={dataHotel.hoteles}
        dataHoteles={dataHotel}
        selectedTitle={selectedTitle}
        latitud={dataHotel.latitud_promedio}
        longitud={dataHotel.longitud_promedio}
        zoom={dataHotel.zoom}
        openInfoWindowIndex={openInfoWindowIndex}
        setOpenInfoWindowIndex={setOpenInfoWindowIndex}
        setNumberHotels={() => {}}
      />
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 120;

      setIsScrolled(scrollY > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (filtroMedio) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
  }, [filtroMedio]);

  useEffect(() => {
    clearFilter();
  }, []);

  useEffect(() => {
    if (language && coin) {
      getHotel({ language, params, coin, setDataHotel, setLoadingHotel, navigate });
    }
  }, [language, coin, params]);

  if (loadingHotel || !dataHotel) return <Loader />;

  const btnTags = () => {
    return (
      <div className="btnfiltros">
        {isButtonVisible && (
          <div className="contentexperiencias">
            {params.busqueda && (
              <div
                className="buttonclose"
                onClick={() => handleResetItem("busqueda")}
              >
                <Text
                  type="paragraph"
                  text={params.busqueda}
                  family={"txsmall"}
                />
                <Icon
                  name="close"
                  list="items"
                  width={12}
                  height={12}
                  className="closeb"
                />
              </div>
            )}

            {params.categoria && (
              <div
                className="buttonclose"
                onClick={() => handleResetItem("categoria")}
              >
                <IconText
                  type="right"
                  text={label}
                  nameIcon={label === "Lodge" ? "" : "starfull"}
                  listIcon={"items"}
                  color="#FFC400"
                  w={12}
                  h={12}
                />
                <Icon
                  name="close"
                  list="items"
                  width={12}
                  height={12}
                  className="closeb"
                />
              </div>
            )}

            {(params.precio_min || params.precio_max) &&
              renderPriceButton(params.precio_min, params.precio_max)}

            {params.experiencia &&
              params.experiencia.split(",").map((value: any, index: number) => {
                const experiencia = dataHotel?.filtro.experiencias.find(
                  (experiencia: { id: number; titulo: string }) =>
                    experiencia.id === parseInt(value)
                );

                if (experiencia) {
                  return (
                    <div
                      key={index}
                      className="buttonclose"
                      onClick={() =>
                        handleResetItem("experiencia", experiencia.id)
                      }
                    >
                      <Text
                        type="paragraph"
                        text={experiencia.titulo}
                        family={"txsmall"}
                      />
                      <Icon
                        name="close"
                        list="items"
                        width={12}
                        height={12}
                        className="closeb"
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            {params.tipo_viaje &&
              params.tipo_viaje.split(",").map((value: any, index: number) => {
                const experiencia = dataHotel?.filtro.tipo_viajes.find(
                  (experiencia: { id: number; titulo: string }) =>
                    experiencia.id === parseInt(value)
                );

                if (experiencia) {
                  return (
                    <div
                      key={index}
                      className="buttonclose"
                      onClick={() =>
                        handleResetItem("tipo_viaje", experiencia.id)
                      }
                    >
                      <Text
                        type="paragraph"
                        text={experiencia.titulo}
                        family={"txsmall"}
                      />
                      <Icon
                        name="close"
                        list="items"
                        width={12}
                        height={12}
                        className="closeb"
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            {params.servicios &&
              params.servicios.split(",").map((value: any, index: number) => {
                const experiencia = dataHotel?.filtro.servicios.find(
                  (experiencia: { id: number; titulo: string }) =>
                    experiencia.id === parseInt(value)
                );

                if (experiencia) {
                  return (
                    <div
                      key={index}
                      className="buttonclose"
                      onClick={() =>
                        handleResetItem("servicios", experiencia.id)
                      }
                    >
                      <Text
                        type="paragraph"
                        text={experiencia.titulo}
                        family={"txsmall"}
                      />
                      <Icon
                        name="close"
                        list="items"
                        width={12}
                        height={12}
                        className="closeb"
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="hotelspage">
      <div className="hotelspage__container">
        <div className="ismobile">
          <div className="containermobile">
            <div
              style={{
                paddingTop: "82px",
                paddingInline: "16px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                type="title"
                text={`${
                  dataHotel.numero_hoteles
                    ? "Encontramos " +
                      dataHotel.numero_hoteles +
                      " " +
                      (dataHotel.numero_hoteles > 1 ? "hoteles" : "hotel") +
                      " para ti"
                    : "Cargando listado de hoteles..."
                }`}
                className="subtitle"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <div className="btn-google" onClick={toogleMobFilter}>
                  <IconText
                    listIcon={"items"}
                    nameIcon="filter-white"
                    text={messages[locale].sortAndFilter}
                    w={25}
                    h={25}
                  />
                </div>
                <div className="btn-google" onClick={toogleMapFilterMob}>
                  <IconText
                    listIcon={"social"}
                    nameIcon="google-maps"
                    text={"Ver en el mapa"}
                    w={25}
                    h={25}
                  />
                </div>
              </div>
            </div>

            <div>{btnTags()}</div>

            <PromotionsCard
              data={dataHotel.hoteles}
              type="hoteles-mob"
              isFilter={false}
              bgLife={false}
            />
          </div>
        </div>
        <div className="isdesktop">
          <div className="hotelspage__description">
            <div className="left">
              <div className="left__content">
                <div className="left__content--filter">
                  <Text
                    type="title"
                    text={`${
                      dataHotel.numero_hoteles
                        ? "Encontramos " +
                          dataHotel.numero_hoteles +
                          " " +
                          (dataHotel.numero_hoteles > 1 ? "hoteles" : "hotel") +
                          " para ti"
                        : "Cargando listado de hoteles..."
                    }`}
                    className="titlepage"
                  />
                  {/* <div className={`tfilters ${isScrolled ? 'sticky' : ''}`}> */}
                  <div className={"tfilters"}>
                    <Text
                      text="Elige el hotel perfecto para tu viaje"
                      className="tfilters__paragraph"
                      type="paragraph"
                    />
                    <div className="tfilters__buttons">
                      <Dropdown
                        ordenarPor={dataHotel.filtro?.ordenar_por}
                        selectedOption={selectedOption}
                        onSelect={handleSelectDesktop}
                      />
                      {/* <Dropdown
                        ordenarPor={filtro.destinos}
                        selectedOption={selectedOptionCity}
                        onSelect={handleSelectCity}
                        title={'Ciudad'}
                      /> */}

                      <button
                        className="open-button"
                        onClick={toogleFiltroMedio}
                      >
                        <IconText
                          nameIcon="filter"
                          listIcon="items"
                          text={"Filtros"}
                          family={"sbsection"}
                        />
                      </button>
                      <button className="open-maps" onClick={toogleFiltroMap}>
                        <IconText
                          listIcon={"social"}
                          nameIcon="google-maps"
                          text={"Ver en el mapa"}
                          w={25}
                          h={25}
                        />
                      </button>
                      {isButtonVisible && (
                        <button className="clearfilter" onClick={clearFilter}>
                          Borrar
                        </button>
                      )}
                    </div>
                    {btnTags()}
                  </div>
                </div>
                <div className="left__content--cards">
                  <CardTemplate
                    data={dataHotel.hoteles}
                    clearFilter={clearFilter}
                    onSelect={() => {}}
                    loading={loadingHotel}
                    coin={coin}
                  />
                </div>
              </div>
            </div>

            <div className="right">
              <div className="right__content">{GoogleComponent()}</div>
            </div>

            <Modal
              isTitle={true}
              onlyClose={true}
              showModal={filtroMedio}
              close={toogleFiltroMedio}
              title={messages[locale].sortAndFilter}
              changeWidth={true}
            >
              <div className="filtromodal">
                <div className="filtromodal__filtro">
                  <div className="filters">
                    {FilterComponent()}

                    <div className="buttonfiltro" onClick={handleFilterMob}>
                      <div className="icongold">{messages[locale].ready}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>

            <ModalScreen
              showModal={filtroMap}
              close={toogleFiltroMap}
              title={<p>Encuentra tu hotel en el mapa</p>}
              padd="0"
            >
              {GoogleComponent()}
            </ModalScreen>
          </div>
        </div>

        <ModalScreen
          showModal={filterMapMob}
          close={toogleMapFilterMob}
          title={<p>Encuentra tu hotel en el mapa</p>}
          padd="0"
        >
          <GoogleMapMob
            hotelesdata={dataHotel.hoteles}
            dataHoteles={dataHotel}
            selectedTitle={selectedTitle}
            latitud={dataHotel.latitud_promedio}
            longitud={dataHotel.longitud_promedio}
            zoom={dataHotel.zoom}
            openInfoWindowIndex={openInfoWindowIndex}
            setOpenInfoWindowIndex={setOpenInfoWindowIndex}
            setNumberHotels={() => {}}
          />
        </ModalScreen>

        <ModalScreen
          showModal={mobfilter}
          close={toogleMobFilter}
          title={messages[locale].sortAndFilter}
          padd="0"
        >
          <div className="filtromodal" style={{ width: "100%", padding: 16 }}>
            <div className="filtromodal__filtro ismobile">
              <div className="filters">
                {FilterComponent()}

                <div
                  className="filters__buttonfiltro"
                  onClick={handleFilterMobF}
                >
                  <div className="icongold">{messages[locale].ready}</div>
                </div>
              </div>
            </div>
          </div>
        </ModalScreen>
      </div>
    </div>
  );
};

export default Hotels;
