import React, { useLayoutEffect, useRef, useState } from 'react'
import { BannerSlider } from 'components/organisms'
import PromotionsCard from 'components/organisms/promotions-card'
import { useAppSelector } from 'hooks/useAppSelector'
import { getBetterPrices } from 'actions/better-prices/services'
import Loader from 'components/molecules/loader'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const PromotionsBetterPrices = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();

  const isFirstRender = useRef(true);

  const banners = [
    {
      id: 5,
      titulo: t('betterPrices.takeAdvantageOfTheBestPrices'),
      subtitulo: t('betterPrices.prices at the moment'),
      color_fondo_logo: null,
      img_desktop: {
        name: 'banner-miraflores',
        path: require('assets/images/better-prices.webp'),
        width: 323,
        height: 192,
        size: 133714,
        title: 'banner miraflores',
        alt: 'banner miraflores',
        description: null,
        caption: 'banner miraflores'
      },
      img_mobile: null,
      descuento: '0',
      descripcion_boton: '/',
      redireccion_boton: '/',
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: 'subtitulo/título collage'
      }
    }
  ]

  const { coin, loading, language, data } = useAppSelector(state => ({
    loading: state.language.loading,
    coin: state.config.coin,
    language: state.config.language,
    data: state.language.dataBetterPrice
  }))

  const [localParams, setLocalParams] = useState({
    sort_by: '',
    destinos: '',
  })

  const handleIdFilter = (id: any, destination: any) => {
    id = id ?? ''
    destination = destination ?? ''

    setLocalParams({
      sort_by: id,
      destinos: destination
    })

    // const sort_byGeneral = id ? `sort_by=${id}` : ''
    // const destinationQuery = destination ? `destinos=${destination}` : ''

    // const urlParams = `?${sort_byGeneral}&${destinationQuery}`
    // navigate(urlParams, { replace: true })
  }

  useLayoutEffect(() => {
    getBetterPrices({ language, coin, navigate, params: localParams })

    if (isFirstRender.current) {
      window.scrollTo(0, 0);
      isFirstRender.current = false;
    }
  }, [coin, language, localParams])

  if (loading || !data) return <Loader />

  return (
    <>
      <BannerSlider data={banners} />

      <PromotionsCard
        data={data?.mejores_precios}
        title={t('betterPrices.bookYourStayAtTheBestPrice')}
        type='better-prices'
        idFilter={handleIdFilter}
        pT={40}
      />

    </>
  )
}

export default PromotionsBetterPrices
