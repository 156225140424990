import { useEffect, useRef, useState } from "react";
import arrowsList from "./lists/arrows.svg";
import buttonsList from "./lists/buttons.svg";
import itemsList from "./lists/items.svg";
import sliderList from "./lists/slider.svg";
import socialList from "./lists/social.svg";
import premiumList from "./lists/premium.svg";
import selectList from "./lists/select.svg";

import { IIcon } from "./props";

const Icon = ({
  name,
  className,
  list,
  onClick,
  width,
  height,
  color,
  iconRef,
  datatip,
}: IIcon) => {
  const icon = useRef<SVGSVGElement>(null);
  const [hasStroke, setHasStroke] = useState(false);

  const lists: { [key: string]: string } = {
    items: itemsList,
    buttons: buttonsList,
    arrows: arrowsList,
    social: socialList,
    slider: sliderList,
    premium: premiumList,
    select: selectList,
  };

  useEffect(() => {
    const iconPath = icon?.current?.querySelector('use')?.getAttribute('href')?.split('#')?.[1];
    setHasStroke(iconPath?.includes('stroke') || false);
  }, []);
  

  useEffect(() => {
    if (iconRef && typeof iconRef === 'object') {
      iconRef.current = icon.current;
    }
  }, [iconRef]);

  return (
    <svg
      data-tip={datatip}
      ref={icon}
      onClick={onClick}
      className={`
        ${className || ''}
        ${hasStroke ? `stroke-${color}` : `fill-${color}`}
      `}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
    >
      <use href={`${lists[list || 'items']}#${name}`} fill={hasStroke ? color : color} stroke={hasStroke ? color : color} />
    </svg>
  );
};

Icon.defaultProps = {
  width: 24,
  height: 24,
  color: '#D69C4F'
};

export default Icon;
