import BCP from "assets/images/home/bcp.webp";
import OH from "assets/images/home/oh.webp";
import BBVA from "assets/images/home/bbva.webp";
import { bankEntities } from "assets/images/home/bank-entities";
import BannerPackage from 'assets/images/banner-package.webp';

export const copy = {
  titulo:
    "Relájate en Casa Andina, deja el estrés afuera y vive una experiencia relajante con quien más quieras",
  subtitulo: "",
  color: "#0e48bd",
  titulo_termino: null,
  titulo_codigo: "El paquete incluye: ",
  list: [
    { titulo: "Habitación doble o matrimonial" },
    { titulo: "Acceso al sauna y piscina" },
    { titulo: "2 Welcome drinks" },
    { titulo: "Desayuno buffet" },
    { titulo: "Masaje descontracturante (45 min)" },
    { titulo: "Late check-out (Sujeto a disponibilidad)" },
    { titulo: "Botella de vino o espumante" },
    { titulo: "Estacionamiento gratuito" },
    { titulo: "15% de Dscto. en el restaurante" },
  ],
};

export const banners = [
  {
    id: 1,
    titulo: "BENEFICIOS",
    subtitulo: "Con tu cuenta sueldo ",
    color_fondo_logo: "rgb(47, 28, 216)",
    img_desktop: {
      name: "ambiente-bar",
      path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/HomeBanner/banner-home.png",
      width: 354,
      height: 405,
      size: 302962,
      title: "ambiente-bar",
      alt: "ambiente-bar",
      description: "ambiente-bar",
      caption: "ambiente-bar",
    },
    img_mobile: null,
    descuento: "",
    descripcion_boton: "",
    redireccion_boton: "",
    img_logos: [
      {
        id: 1,
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Promociones/logo-ibk-white.png",
        alt: "Interbank",
        title: "Interbank",
      },
      { id: 2, path: BCP, alt: "BCP", title: "BCP" },
      { id: 3, path: OH, alt: "OH", title: "OH" },
      { id: 4, path: BBVA, alt: "BBVA", title: "BBVA" },
    ],
    tipo_banner: {
      id: 1,
      titulo: "bancos",
    },
  },
  {
    id: 4,
    titulo: "CUOTAS SIN INTERESES",
    subtitulo: "Disfruta tus vacaciones y paga en",
    color_fondo_logo: "rgb(47, 28, 216)",
    img_desktop: {
      name: "ambiente-bar",
      path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/HomeBanner/banner-home.png",
      width: 354,
      height: 405,
      size: 302962,
      title: "ambiente-bar",
      alt: "ambiente-bar",
      description: "ambiente-bar",
      caption: "ambiente-bar",
    },
    img_mobile: null,
    descuento: "",
    descripcion_boton: "",
    redireccion_boton: "",
    img_logos: [
      {
        name: "logo-ibk-white",
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Promociones/logo-ibk-white.png",
        width: 196,
        height: 42,
        size: 5738,
        title: null,
        alt: "logo-ibk-white",
        description: null,
        caption: null,
      },
    ],
    tipo_banner: {
      id: 1,
      titulo: "bancos",
    },
  },
];

export const dataPromotionsQuota = [
  {
    id: 1,
    titulo: "cuotas sin intereses",
    subtitulo: "Disfruta tus vacaciones y paga en",
    color_fondo_logo: "rgb(47, 28, 216)",
    img_desktop: {
      name: "ambiente-bar",
      path: bankEntities.background,
      width: 354,
      height: 405,
      size: 302962,
      title: "ambiente-bar",
      alt: "ambiente-bar",
      description: "ambiente-bar",
      caption: "ambiente-bar",
    },
    img_mobile: null,
    descuento: "",
    descripcion_boton: "",
    redireccion_boton: "",
    img_logos: [
      {
        id: 1,
        path: bankEntities.bcpWhite,
        alt: "bcp",
        title: "bcp",
        color: "rgb(47, 28, 216)",
      },
      {
        id: 2,
        path: bankEntities.ibkWhite,
        alt: "ibk",
        title: "ibk",
        color: "",
      },
      {
        id: 3,
        path: bankEntities.bbvaWhite,
        alt: "bbva",
        title: "bbva",
        color: "",
      },
      {
        id: 4,
        path: bankEntities.dinnersWhite,
        alt: "dinners",
        title: "dinners",
        color: "",
      },
    ],
    tipo_banner: {
      id: 1,
      titulo: "bancos",
    },
  },
];

export const dataSeccionBancos = [
  {
    description: [
      {
        id: 1,
        content:
          "Válido del 17 de julio al 31 de diciembre del 2023 en toda la cadena Casa Andina a nivel nacional. (No válido para hoteles asociados).",
      },
      {
        id: 2,
        content:
          "Válido únicamente para servicios de alojamiento asociados a la reserva. (No aplica para consumos de restaurantes, bar, spa o lavandería).",
      },
      {
        id: 3,
        content:
          "Válido pagando solo con Tarjeta de Crédito Interbank. (No aplica para compras con Apple Pay ni para Tarjetas Empresariales ni Rappicard).",
      },
      {
        id: 4,
        content:
          "Compras de 7 a 36 cuotas (a excepción de 3 y 6) aplicará Tasa preferencial. En caso el cliente seleccione un número distinto de cuotas, se  aplicará la tasa de interés respectiva.",
      },
      {
        id: 5,
        content:
          "Válido seleccionando la tarifa de meses sin intereses en la web de Casa Andina. El pago se realizará en el hotel al momento de hacer uso de la reserva.",
      },
      {
        id: 6,
        content:
          "Interbank no se responsabiliza por los productos y/o servicios ofrecidos en el establecimiento.",
      },
      {
        id: 7,
        content:
          "Consulta las tasa de interés, comisiones, gastos y penalidades en el Tarifario, en Tiendas Interbank o en www.interbank.com.pe, conforme a la normativa vigente.",
      },
    ],
    id: 3,
    nombre: "PROMOCION INTERBANK ",
    redireccion: "/es/ofertas/promo",
    color_promocion: "rgba(20, 51, 255, 1)",
    entidad: {
      id: 2,
      titulo: "INTERBANK",
      color: "rgba(114, 170, 58, 0.85)",
      logo: {
        name: "logo-ibk-white",
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Promociones/logo-ibk-white.png",
        width: 244,
        height: 69,
        size: 10406,
        title: null,
        alt: "logo-ibk-white",
        description: null,
        caption: null,
      },
    },
    categoria: {
      id: 2,
      titulo: "BANCO",
    },
    is_formulario: false,
    card: {
      tipo_disenio: {
        id: 1,
        titulo: "Oferta con imagen de fondo cuotas",
      },
      titulo_card: "",
      condicion_abajo_precio: "",
      texto_encima_precio: "",
      valor: "3 y 6",
      titulo: "Hasta",
      subtitulo: "Cuotas sin intereses, comprando con tus tarjetas",
      logo: {
        name: "logo-ibk",
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Ofertas/logo-ibk.png",
        width: 105,
        height: 20,
        size: 2320,
        title: null,
        alt: "ibk",
        description: null,
        caption: null,
      },
      img: {
        name: "fondo-oferta-2",
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Ofertas/fondo-oferta-2.png",
        width: 191,
        height: 194,
        size: 90219,
        title: "Fondo Oferta 2",
        alt: "Fondo Oferta 2",
        description: null,
        caption: "Fondo Oferta 2",
      },
      color: "rgba(6, 190, 79, 0.85)",
      color_secundario: null,
      precio_con_descuento: 0,
      precio: 0,
    },
  },
  {
    description: [
      {
        id: 1,
        content:
          "Beneficio exclusivo para Tarjeta de Crédito BCP, se podrá fraccionar el pago de 2 a 6 cuotas sin intereses.",
      },
      {
        id: 2,
        content:
          "El fraccionamiento en cuotas sin intereses no será válido para los hoteles asociados de la cadena.",
      },
      {
        id: 3,
        content:
          "El cargo a la tarjeta y fraccionamiento se realizará al momento del check-in en el hotel y es válido solo para pagos con tarjetas de Crédito del BCP.",
      },
      {
        id: 4,
        content:
          "El fraccionamiento en cuotas sin intereses es válido únicamente para consumos de alojamiento y no para consumos del restaurante, bar, spa u otros servicios que brinde el hotel.",
      },
      {
        id: 5,
        content:
          "Aplica solo para pago de reservas con forma de pago “PAGO EN EL HOTEL”, mediante uso de POS (la reserva se puede realizar por la web o a través del contact center de Casa Andina pero el pago debe ser directamente en la recepción del hotel al momento de hacer check-in).",
      },
    ],
    id: 3,
    nombre: "PROMOCION INTERBANK ",
    redireccion: "/es/ofertas/promo",
    color_promocion: "rgba(20, 51, 255, 1)",
    entidad: {
      id: 2,
      titulo: "INTERBANK",
      color: "rgba(41, 212, 13, 1)",
      logo: {
        name: "logo-ibk-white",
        path: BCP,
        width: 244,
        height: 69,
        size: 10406,
        title: null,
        alt: "logo-ibk-white",
        description: null,
        caption: null,
      },
    },
    categoria: {
      id: 2,
      titulo: "BANCO",
    },
    is_formulario: false,
    card: {
      tipo_disenio: {
        id: 1,
        titulo: "Oferta con imagen de fondo cuotas",
      },
      titulo_card: "",
      condicion_abajo_precio: "",
      texto_encima_precio: "",
      valor: "6",
      titulo: "Hasta",
      subtitulo: "Cuotas sin intereses, comprando con tus tarjetas",
      logo: {
        name: "logo-ibk",
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Ofertas/logo-ibk.png",
        width: 105,
        height: 20,
        size: 2320,
        title: null,
        alt: "ibk",
        description: null,
        caption: null,
      },
      img: {
        name: "fondo-oferta-2",
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Ofertas/fondo-oferta-2.png",
        width: 191,
        height: 194,
        size: 90219,
        title: "Fondo Oferta 2",
        alt: "Fondo Oferta 2",
        description: null,
        caption: "Fondo Oferta 2",
      },
      color: "rgba(0, 42, 142, 0.85)",
      color_secundario: null,
      precio_con_descuento: 0,
      precio: 0,
    },
  },
  {
    description: [
      {
        id: 1,
        content:
          "Es un programa que aplica exclusivamente a las tarjetas de crédito VISA y Mastercard del BBVA. No aplica para tarjetas empresariales (con excepción de la Tarjeta Capital de Trabajo del BBVA).",
      },
      {
        id: 2,
        content:
          "Aplica solo para pago de reservas con forma de pago “PAGO EN EL HOTEL”, mediante uso de POS (la reserva se puede realizar por la web o a través del contact center de Casa Andina pero el pago debe ser directamente en la recepción del hotel al momento de hacer check-in).",
      },
      {
        id: 3,
        content:
          " Se podrá fraccionar el pago en 3 y 6 cuotas sin intereses. El fraccionamiento en cuotas sin intereses no será válido para los hoteles asociados de la cadena. El cargo a la tarjeta y fraccionamiento se realizará al momento del check-in en el hotel y es válido solo para pagos con tarjetas de crédito del BBVA.",
      },
    ],
    id: 3,
    nombre: "PROMOCION INTERBANK ",
    redireccion: "/es/ofertas/promo",
    color_promocion: "rgba(20, 51, 255, 1)",
    entidad: {
      id: 2,
      titulo: "INTERBANK",
      color: "rgba(41, 212, 13, 1)",
      logo: {
        name: "logo-ibk-white",
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Promociones/logo-ibk-white.png",
        width: 244,
        height: 69,
        size: 10406,
        title: null,
        alt: "logo-ibk-white",
        description: null,
        caption: null,
      },
    },
    categoria: {
      id: 2,
      titulo: "BANCO",
    },
    is_formulario: false,
    card: {
      tipo_disenio: {
        id: 1,
        titulo: "Oferta con imagen de fondo cuotas",
      },
      titulo_card: "",
      condicion_abajo_precio: "",
      texto_encima_precio: "",
      valor: "3 y 6",
      titulo: "Hasta",
      subtitulo: "Cuotas sin intereses, comprando con tus tarjetas",
      logo: {
        name: "logo-ibk",
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Ofertas/logo-ibk.png",
        width: 105,
        height: 20,
        size: 2320,
        title: null,
        alt: "ibk",
        description: null,
        caption: null,
      },
      img: {
        name: "fondo-oferta-2",
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Ofertas/fondo-oferta-2.png",
        width: 191,
        height: 194,
        size: 90219,
        title: "Fondo Oferta 2",
        alt: "Fondo Oferta 2",
        description: null,
        caption: "Fondo Oferta 2",
      },
      color: "rgba(14, 69, 126, 0.85)",
      color_secundario: null,
      precio_con_descuento: 0,
      precio: 0,
    },
  },
  {
    description: [
      {
        id: 1,
        content:
          "Es un programa EXCLUSIVO de Diners Club que permite comprar en cuotas sin pagar intereses con tu tarjeta de crédito Diners Club puedes comprar con hasta 6 cuotas sin intereses.",
      },
      {
        id: 2,
        content:
          "Aplica solo para pago de reservas con forma de pago “PAGO EN EL HOTEL”, mediante uso de POS (la reserva se puede realizar por la web o a través del contact center de Casa Andina pero el pago debe ser directamente en la recepción del hotel al momento de hacer check-in).",
      },
      {
        id: 3,
        content:
          "Tarifa no reembolsable. Se podrá fraccionar el pago hasta en 6 cuotas sin intereses. El fraccionamiento en cuotas sin intereses no será válido para los hoteles asociados de la cadena. El cargo a la tarjeta y fraccionamiento se realizará al momento del check-in en el hotel y es válido solo para pagos con tarjetas de crédito DINERS CLUB INTERNACIONAL.",
      },
    ],
    id: 3,
    nombre: "PROMOCION INTERBANK ",
    redireccion: "/es/ofertas/promo",
    color_promocion: "rgba(20, 51, 255, 1)",
    entidad: {
      id: 2,
      titulo: "INTERBANK",
      color: "rgba(41, 212, 13, 1)",
      logo: {
        name: "logo-ibk-white",
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Promociones/logo-ibk-white.png",
        width: 244,
        height: 69,
        size: 10406,
        title: null,
        alt: "logo-ibk-white",
        description: null,
        caption: null,
      },
    },
    categoria: {
      id: 2,
      titulo: "BANCO",
    },
    is_formulario: false,
    card: {
      tipo_disenio: {
        id: 1,
        titulo: "Oferta con imagen de fondo cuotas",
      },
      titulo_card: "",
      condicion_abajo_precio: "",
      texto_encima_precio: "",
      valor: "6",
      titulo: "Hasta",
      subtitulo: "Cuotas sin intereses, comprando con tus tarjetas",
      logo: {
        name: "logo-ibk",
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Ofertas/logo-ibk.png",
        width: 105,
        height: 20,
        size: 2320,
        title: null,
        alt: "ibk",
        description: null,
        caption: null,
      },
      img: {
        name: "fondo-oferta-2",
        path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Ofertas/fondo-oferta-2.png",
        width: 191,
        height: 194,
        size: 90219,
        title: "Fondo Oferta 2",
        alt: "Fondo Oferta 2",
        description: null,
        caption: "Fondo Oferta 2",
      },
      color: "rgba(73, 125, 156, 0.85)",
      color_secundario: null,
      precio_con_descuento: 0,
      precio: 0,
    },
  },
];

export const tipo_restaurante = {
  id: 3,
  titulo: "Alma",
  slug: "tipo_restaurante/alma",
  img: {
    name: "alma-restaurante",
    path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/media/alma-restaurante.png",
    width: 607,
    height: 228,
    size: 13298,
    title: "alma-bar-restaurante",
    alt: "alma-restaurante",
    description: null,
    caption: null,
  },
  color: "rgba(0, 44, 85, 1)",
};

export const data_centrado = [
  {
    id: 1,
    titulo: "APROVECHA LOS MEJORES",
    subtitulo: "DESCUENTOS",
    color_fondo_logo: "rgba(62, 145, 41, 1)",
    img_desktop: {
      name: "banner-home",
      path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Banner/banner-home.png",
      width: 1440,
      height: 400,
      size: 794666,
      title: "Banner Home",
      alt: "Banner Home",
      description: null,
      caption: "Banner Home",
    },
    img_mobile: [],
    descuento: "25",
    descripcion_boton: "",
    redireccion_boton: "",
    img_logos: [],
    tipo_banner: {
      id: 1,
      titulo: "texto centrado",
    },
  },
];

export const data_centrado2 = [
  {
    id: 1,
    titulo: "",
    subtitulo: "NUESTROS BUFFETS LIMA",
    color_fondo_logo: "rgba(62, 145, 41, 1)",
    img_desktop: {
      name: "banner-home",
      path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/HomeBanner/banner-home.png",
      width: 1440,
      height: 400,
      size: 794666,
      title: "Banner Home",
      alt: "Banner Home",
      description: null,
      caption: "Banner Home",
    },

    img_mobile: [],
    descuento: "25",
    descripcion_boton: "",
    redireccion_boton: "",
    img_logos: [],
    tipo_banner: {
      id: 1,
      titulo: "título/subtitulo collage logo",
    },
  },
  {
    id: 22,
    titulo: "Pasa un gran momento en nuestro Happy Hour",
    subtitulo: "",
    color_fondo_logo: null,
    img_desktop: {
      name: "casa-andina-select-arequipa-plaza-piscina",
      path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Ambientes/casa-andina-select-arequipa-plaza-piscina.jpg",
      width: 799,
      height: 533,
      size: 129267,
      title: "Casa Andina Select Arequipa Plaza - Piscina",
      alt: "Casa Andina Select Arequipa Plaza - Piscina",
      description: null,
      caption: null,
    },
    img_mobile: {
      name: "casa-andina-select-arequipa-plaza-piscina",
      path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Ambientes/casa-andina-select-arequipa-plaza-piscina.jpg",
      width: 799,
      height: 533,
      size: 129267,
      title: "Casa Andina Select Arequipa Plaza - Piscina",
      alt: "Casa Andina Select Arequipa Plaza - Piscina",
      description: null,
      caption: null,
    },
    descuento: "",
    descripcion_boton: "",
    redireccion_boton: "",
    img_logos: [],
    tipo_banner: {
      id: 8,
      titulo: "título/subtitulo collage logo",
    },
  },
];


export const bannerPackage = [
  {
    id: 1,
    titulo: "Exclusivas en",
    subtitulo: "casa andina premium",
    description: "el golf",
    img_desktop: {
      name: "banner-home",
      path: "https://s3.us-east-1.amazonaws.com/ca-webdev/media/e913dfa3-9c2b-4213-b75f-df9d3421e4d3.webp",
      title: "Banner Package",
      alt: "Banner Package",
      caption: "Banner Package",
    },
    img_mobile: [],
    tipo_banner: {
      id: 1,
      titulo: "package",
    },
  },
];