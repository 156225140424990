import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import es from './locales/es.json';
import en from './locales/en.json';
import pt from './locales/pt.json';

export const languages = Object.entries({ es, en, pt }).map(([key, value]) => ({ key, value }));

export const removeLngPrefix = (pathname: string) => {
  for (const lng of languages) {
    if (pathname.startsWith(`/${lng}/`) || pathname === `/${lng}`) {
      return pathname.replace(`/${lng}`, '');
    }
  }

  return pathname;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      es: { translation: es },
      en: { translation: en },
      pt: { translation: pt },
    },
    lng: document.querySelector('html')?.lang || 'es',
    fallbackLng: 'es',
    interpolation: { escapeValue: false },
    supportedLngs: ['es', 'en', 'pt'],
    detection: {
      order: ['path', 'navigator', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    }
  });

export default i18n;