import React from 'react'
import { Icon } from 'components/atoms'
import './styles.scss'

const Loader = () => {
  return (
    <div className="view-container">
      <div className="background"></div>
      <div className="icon-container">
        <Icon list="items" name="logo" width={200} height={100} className='loading-icon'/>
      </div>
    </div>
  )
}

export default Loader