import React from 'react'
import { Text } from 'components/atoms'
import { ISection } from './props'
import './styles.scss'

const Section = ({ title, children, isTitle, pT, pB, isBg, bgColor, bgImage }: ISection) => {
  return (
    <div className={`sectionmolecule ${isBg && 'sectionactive'}`} style={{ paddingBottom: `${pB}px`, paddingTop: `${pT}px`, backgroundColor: bgColor, backgroundImage: `url(${bgImage})`, backgroundPosition: 'center' }}>
      {isTitle ? (
        <div className='sectionmolecule__container'>
          <Text
            text={title}
            type='title'
            className={'subtitle'}
          />
          <div>{children}</div>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </div>
  )
}

Section.defaultProps = {
  isTitle: true,
  pB: '50',
  pT: '50',
  isBg: false
}

export default Section
