import React, { useEffect } from "react";
import { Text } from "components/atoms";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { updateMetadata } from "utils/function";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    updateMetadata({
      title: "404 - Página no encontrada",
      description:
        "Página no encontrada, Ocurrio un error inesperado. Regresa y reservar en nuestros 43 hoteles.",
      canonicalUrl: "https://www.casa-andina.com/es/404",
      hreflang: [
        {
          hreflang: "es",
          href: "https://www.casa-andina.com/es/404",
        },
      ],
    });
  }, []);

  return (
    <section className="notfound">
      <div className="notfound__container">
        <div className="notfound__container--text">
          <Text type="title" text="404" />
          <Text
            type="subtitle"
            text="Página no encontrada, Ocurrio un error inesperado."
          />
          <Text
            type="paragraph"
            text="Regresa y reservar en nuestros 43 hoteles."
            onClick={() => navigate("/")}
            className="redirect"
          />
        </div>
      </div>
    </section>
  );
};

export default NotFound;
