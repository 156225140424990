import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import './styles.scss'

const Gallery = ({data}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  return (
    <section className='gallerysection'>
      <div className='gallerysection__contain'>
        <Swiper
          spaceBetween={10}
          navigation={true}
          thumbs={{
            swiper: (thumbsSwiper && !thumbsSwiper.destroyed )? thumbsSwiper : null
          }}
          // modules={[FreeMode, Navigation, Thumbs]}
          loop={false}
          slidesPerView={1}
          className='gallery-swiper'
          key={data.length}
        >
          {data.map(item => (
            <SwiperSlide key={item.path}>
              <img src={item?.path} alt={item?.alt} height={150} className='img-animation' />
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className='gallery-swipertabs'
          key={data.length + 1}
        >
          {data.map(item => (
            <SwiperSlide key={item.path} style={{ height: 150 }}>
              <img src={item?.path} alt={item?.alt} className='img-animation' />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}

export default Gallery
