import React, { useEffect } from "react";
import { BannerRestaurants } from "components/organisms";
import SectionCardGrid from "components/organisms/section-card-grid";
import SectionCardsTypes from "components/organisms/section-cards-types";
import { getRestaurantsPlace } from "actions/restaurants/services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "hooks/useAppSelector";
import Loader from "components/molecules/loader";
import Section from "components/molecules/section";
import { useTranslation } from "react-i18next";

const RestaurantsTemplate = () => {
  const { places } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [route, setRoute] = React.useState<string | undefined>(undefined);

  const {
    loading,
    language,
    coin,
    tipo_restaurante,
    slider_restaurante,
    banner,
    restaurantes,
    categoria,
    data,
  } = useAppSelector((state) => ({
    loading: state.language.loading,
    language: state.config.language,
    coin: state.config.coin,
    data: state.language.dataRestaurantsPlaces,
    banner: state.language.dataRestaurantsPlaces?.banner,
    tipo_restaurante: state.language.dataRestaurantsPlaces?.tipo_restaurante,
    restaurantes: state.language.dataRestaurantsPlaces?.restaurantes || [],
    slider_restaurante: state.language.dataRestaurantsPlaces?.imagenes || [],
    categoria:
      state.language.dataRestaurantsPlaces?.categoria_promocion_restaurante ||
      [],
  }));

  useEffect(() => {
    if (location.pathname !== route) {
      setRoute(location.pathname);
    }
  }, [location.pathname, route]);

  useEffect(() => {
    if (places && language && coin) {
      getRestaurantsPlace({ places, language, coin, navigate });
      window.scrollTo(0, 0);
    }
  }, [places, language, coin, navigate]);

  if (loading || !data) return <Loader />;

  return (
    <>
      {banner && (
        <BannerRestaurants
          types="restaurantes"
          data={banner}
          place={tipo_restaurante}
        />
      )}

      {restaurantes.length > 0 && (
        <SectionCardsTypes
          data={restaurantes}
          type="hotel-direccion"
          title={data?.subtitulos ? data?.subtitulos[0].subtitulo : t("restaurantPlace.discoverTheCulinaryRichnessOfPeru")}
        />
      )}

      {categoria.length > 0 && (
        <SectionCardGrid
          title={data?.subtitulos ? data?.subtitulos[1].subtitulo : t("restaurantPlace.enjoyOurPromotions")}
          data={categoria}
          type="restaurant-food"
        />
      )}

      {slider_restaurante.length > 0 && (
        <>
          <Section
            title={data?.subtitulos ? data?.subtitulos[2].subtitulo : t("restaurantPlace.aUniqueCulinaryExperience")}
            pB={0}
          />

          <SectionCardsTypes
            data={slider_restaurante}
            type="slider-restaurante"
          />
        </>
      )}
    </>
  );
};

export default RestaurantsTemplate;
