import React, { useState } from 'react'
import { Button, Hr, Text } from 'components/atoms'
import { Swiper, SwiperSlide } from 'swiper/react'
import { toast } from "sonner";
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './styles.scss'
import { REACT_APP_ENDPOINT_CA } from "typings/env";
import { logSentryErrorResponse } from "utils/function";
import * as Sentry from '@sentry/react';
import { useTranslation } from "react-i18next";

const PromotionsForm = ({ data }: any) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    nombres: '',
    apellidos: '',
    tipoDocumento: '', // Nuevo campo para almacenar el tipo de documento
    dni: '',
    telefono: '',
    email: '',
    aceptaPublicidad: false
  })

  const accordion = {
    identificacion: [
      {
        id: 1,
        titulo: 'DNI'
      },
      {
        id: 2,
        titulo: 'Carne de extranjeria'
      },
      {
        id: 3,
        titulo: 'Pasaporte'
      }
    ]
  }

  const [selectedValue, setSelectedValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  // const handleInputChange = (event: any) => {
  //   setSelectedValue(event.target.value)
  // }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionSelect = (option: any) => {
    setSelectedValue(option)
    setIsOpen(false)
  }

  const handleInputChange = (event: any) => {
    const { name, value, type, checked } = event?.target
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  const isFormValid = formData.aceptaPublicidad

  const handleFormSubmit = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_ENDPOINT_CA}/api/form/relajate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        }
      )

      if (response.ok) {
        setFormData({
          nombres: '',
          apellidos: '',
          tipoDocumento: '',
          dni: '',
          telefono: '',
          email: '',
          aceptaPublicidad: false
        })
      } else {
        logSentryErrorResponse(response, 'Form - Relajate Form');
        console.error(response);
        toast.error(t("forms.errorSendingData"), { position: "top-right" });
      }
    } catch (error) {
      // alert('Ocurrió un error inesperado');
      Sentry.captureException(error); // Captura el error en Sentry
      console.error(error);
      toast.error(t("forms.errorSendingData"), { position: "top-right" });
    }
  }

  const itemsForLoop = data.map((item: any) => item.id)

  return (
    <div className='promotionsform py-3-5rem'>
      <div className='promotionsform__container'>
        <div className='promotionsform__container--slider'>
          <Swiper
            className='promotionslider'
            slidesPerView={'auto'}
            loop={itemsForLoop.length > 3}
            pagination={{
              clickable: true
            }}
            autoplay={{
              delay: 7000,
              disableOnInteraction: false
            }}
            modules={[Autoplay, Pagination]}
          >
            {data.map((better: any, index: number) => (
              <SwiperSlide key={index}>
                <div className='swiperpromotion'>
                  <img src={better?.path} alt={'alt'} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className='promotionsform__container--form'>
          <Text
            type='paragraph'
            text='Déjanos tus datos y te contactaremos pronto'
            family={'txnormal'}
          />

          <div className='forminput'>
            <div className='input-form'>
              <div className='inputtwolines'>
                <input
                  type='text'
                  name='nombres'
                  value={formData.nombres}
                  onChange={handleInputChange}
                  placeholder='Nombres'
                />
                <input
                  type='text'
                  name='apellidos'
                  value={formData.apellidos}
                  onChange={handleInputChange}
                  placeholder='Apellidos'
                />
              </div>
            </div>

            <div>
              <div className='inputtwolines'>
                <div className='inputtwolinesc'>
                  <div className='line'>
                    <div className='dropdown'>
                      <div
                        className='dropdowns-toggles'
                        // onClick={toggleDropdown}
                      >
                        {/* <input
                          type='text'
                          name='tipoDocumento'
                          value={formData.tipoDocumento}
                          onChange={handleInputChange}
                          placeholder='Tipo de documento'
                        /> */}

                        <div>DNI</div>
                      </div>
                      {isOpen && (
                        <ul className='dropdowns-list'>
                          {accordion.identificacion.map(
                            (option: any, index: number) => (
                              <li
                                onClick={() =>
                                  handleOptionSelect(option.titulo)
                                }
                                key={index}
                              >
                                {option.titulo}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className='linedoc'>
                    <input
                      type='number'
                      name='dni'
                      value={formData.dni}
                      onChange={handleInputChange}
                      placeholder='N° documento'
                    />
                  </div>
                </div>
                <div className='input-form'>
                  <input
                    type='number'
                    name='telefono'
                    value={formData.telefono}
                    onChange={handleInputChange}
                    placeholder='Teléfono'
                  />
                </div>
              </div>
            </div>

            <div className='input-form'>
              <input
                type='text'
                name='email'
                value={formData.email}
                onChange={handleInputChange}
                placeholder='Correo electrónico'
              />
            </div>
          </div>

          <Hr color='#C6C6C6' />

          <div className='box'>
            {/* <Checkbox
              id={1}
              name='aceptaPublicidad'
              titulo={
                'He leído y acepto el envío de la publicidad, promociones y las Políticas de Protección de Datos Personales.'
              }
              checked={formData.aceptaPublicidad}
              onChange={handleInputChange}
            /> */}
            {/* <label>
              He leído y acepto el envío de la publicidad, promociones y las
              Políticas de Protección de Datos Personales.
              <input
                type='checkbox'
                name='aceptaPublicidad'
                checked={formData.aceptaPublicidad}
                onChange={handleInputChange}
              />
            </label> */}

            <label className={`inputcheckbox`}>
              <div className='inputcheckbox_content'>
                <input
                  type='checkbox'
                  name='aceptaPublicidad'
                  checked={formData.aceptaPublicidad}
                  onChange={handleInputChange}
                />
              </div>
              He leído y acepto el envío de la publicidad, promociones y las
              Políticas de Protección de Datos Personales.
            </label>
          </div>

          <div
            className='d-flex jc-center ai-center pt-0-5rem'
            onClick={handleFormSubmit}
          >
            <Button
              type={isFormValid ? 'gold' : 'disabled'}
              onClick={handleFormSubmit}
            >
              Enviar mi información
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

PromotionsForm.defaultProps = {}

export default PromotionsForm
