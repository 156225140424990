import { Card, Text } from "components/atoms";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.scss";
import { Animations } from "components/molecules";

const RestaurantsCards = ({ data, title, restaurantRef, isReview }: any) => {
  return (
    <div className="restaurantscards" ref={restaurantRef}>
      <div className="restaurantscards__container">
        {title && title.trim() !== "" ? (
          <Text text={title} type="subtitle" className={"subtitle"} />
        ) : (
          <Animations type="skeletonContent" style={{ width : "fit-content" }}>
            <Text text={"Lorem ipsum dolor sit amet consectetur."} type="subtitle" style={{ color : "transparent" }} className={"subtitle"} />
          </Animations>
        )}

        <div className="restaurantscards__description">
          {/* <SliderRestaurant data={data} type={'restaurant'}/> */}
          <Swiper
            slidesPerView={"auto"}
            grabCursor={true}
            navigation={true}
            className="restaurantscards__description--swiper"
          >
            {data && data.length > 0 ? (
              <>
                {data.map((item: any, index: number) => (
                  <SwiperSlide key={index}>
                    <Animations type="scaleMin">
                      <Card
                        type="restaurant"
                        restaurant={item}
                        isReview={isReview}
                      />
                    </Animations>
                  </SwiperSlide>
                ))}
              </>
            ) : (
              <>
                {[...Array(4)].map((_, index) => (
                  <SwiperSlide key={index}>
                    <div style={{ height : "300px", borderRadius : "10px", overflow : "hidden" }}>
                      <Animations type="skeletonContent">
                      </Animations>
                    </div>
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default RestaurantsCards;
