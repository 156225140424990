import { useState } from "react";
import { ICardPromotionHotel } from "./props";
import { Button, Icon, IconText, Text } from "components/atoms";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { handleTravelClickWhithoutDate, redirectUrlExternal } from "utils/function";
import { Tooltip } from "react-tooltip";
import "./styles.scss";

const CardPromotionHotel = ({
  data,
  textoTooltipIgv,
  textoTooltipIgvCard,
  coin,
  idTravelClick
}: ICardPromotionHotel) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const cardTypes = () => {
    switch (data.categoria.id) {
      case 2:
      case 5:
        return (
          <div className="card-promotion-hotel">
            <div className="card-promotion-hotel--top-container"
              style={{ backgroundColor: data.color }}>
              <img
                src={data.logo?.path}
                alt={data.logo?.alt}
                width={"auto"}
                height={24}
                loading="lazy"
              />

              <div className="discount-container">
                <Text text={data.valor} />
                <Text text={"%"} />
              </div>
              <Text className="content__percentage" text={t('promotions.ofDiscount')} />
            </div>
            <div className="card-promotion-hotel--bottom-container">
              <div className="prices-container">
                <div className="night-from-container">
                  <Text className="night-text" text={t("betterPrices.nightFrom")} />
                  <Text className="promo-price-original" text={`${data.precio_anterior.simbolo} ${data.precio_anterior.precio}`} />
                </div>
                <Text className="promo-price" text={`${data.precio.simbolo} ${data.precio.precio}`} />
              </div>
              {data.igv !== null && data.igv.precio > 0 && (
                <div className="promo-igv-container">
                  <IconText
                    text={`+${data.igv.simbolo} ${data.igv.precio} ${textoTooltipIgvCard}`}
                    nameIcon="question-card"
                    listIcon="items"
                    w={16}
                    h={16}
                    type="right"
                    className="promo-igv"
                    className2={`tooltip-promotion-${data.id}`}
                  />
                </div>
              )}
              <div className="buttons-container">
                <div
                  className="btn-arrow"
                  onClick={() => navigate(data.redireccion || '/es/ofertas')}
                >
                  <Text
                    text={t('promotions.seePromotion')}
                    type="paragraph"
                    className="learnmore"
                  />
                  <Icon
                    name={"right"}
                    list={"arrows"}
                    width={20}
                    height={20}
                  />
                </div>

                <Button type="icongold" onClick={() => handleTravelClickWhithoutDate({ id_travel_click: idTravelClick }, coin, data.codigo)}>
                  {t("general.book")}
                </Button>
              </div>
            </div>

            <Tooltip
              clickable={true}
              anchorSelect={`.tooltip-promotion-${data.id}`}
              place="bottom-end"
              opacity={1}
              className="tooltip-promo"
            >
              {textoTooltipIgv}
            </Tooltip>
          </div>
        );
      case 9:
        return (
          <div className="card-promotion-hotel">
            <div className="card-promotion-hotel--top-container"
              style={{ backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${data.imagen_fondo?.path})` }}>
              <img
                src={data.logo?.path}
                alt={data.logo?.alt}
                width={"auto"}
                height={24}
                loading="lazy"
              />

              <div className="ayb-container discount-container">
                <Text className="content__texto_ayb" text={data.texto_encima_precio} />
                <Text text={`${data.precio.simbolo}${data.precio.precio}`} />
              </div>
              <Text className="content__texto_ayb_abajo_precio" text={data.condicion_abajo_precio} />
            </div>
            <div className="card-promotion-hotel--bottom-container">
              <div className="horario-container">
                <IconText
                  text={data.subtitulo}
                  nameIcon="question-card"
                  listIcon="items"
                  w={16}
                  h={16}
                  type="right"
                  className="horario_ayb"
                  className2={`tooltip-promotion-${data.id}`}
                />
              </div>
              <div className="buttons-container">
                <div
                  className="btn-arrow"
                  onClick={() => navigate(data.redireccion || '/es/ofertas')}
                >
                  <Text
                    text={t('promotions.seePromotion')}
                    type="paragraph"
                    className="learnmore"
                  />
                  <Icon
                    name={"right"}
                    list={"arrows"}
                    width={20}
                    height={20}
                  />
                </div>

                <Button type="icongold" onClick={() => data.redireccion_reserva ? redirectUrlExternal(data.redireccion_reserva): ''}>
                  {t("general.book")}
                </Button>
              </div>
            </div>
            <Tooltip
              clickable={true}
              anchorSelect={`.tooltip-promotion-${data.id}`}
              place="bottom-end"
              opacity={1}
              className="tooltip-promo"
            >
              {textoTooltipIgv}
            </Tooltip>
          </div>
        );
    }
  };

  return <>{cardTypes()}</>;
};

export default CardPromotionHotel;