export const dataBanner = [
  {
    id: 1,
    titulo: 'ENCUENTRA LOS DATOS DE TU HOTEL',
    subtitulo: 'CASA ANDINA',
    color_fondo_logo: 'rgba(62, 145, 41, 1)',
    img_desktop: {
      name: 'banner-home',
      path: 'https://s3.us-east-1.amazonaws.com/ca-webprod/media/contacto-casa-andina.png',
      width: 1440,
      height: 400,
      size: 794666,
      title: 'Banner Home',
      alt: 'Banner Home',
      description: null,
      caption: 'Banner Home'
    },
    img_mobile: [],
    descuento: '25',
    descripcion_boton: '',
    redireccion_boton: '',
    img_logos: [],
    tipo_banner: {
      id: 1,
      titulo: 'Subtitulo/título collage'
    }
  }
]
