import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { IconText } from 'components/atoms';
import { messages } from 'utils/lang';
import { useIntl } from 'react-intl';
import './styles.scss'

const CustomSlideHotelMobile = ({ item, scrollToSection, isActive, handleSetActiveSection }: any) => {
  const handleItemClick = () => {
    scrollToSection(item.link);
    handleSetActiveSection(item.link);
  };

  return (
    <div style={{ width: '100%', padding: '10px 0px' }}>
      <div className={`activity ${isActive ? 'activetext' : 'inactivetext'}`}>
        <div className="activity__card">
          <div className="activitycardcontainer">
            <IconText
              nameIcon={item.nameIconActive}
              color={isActive ? '#D69C4F' : 'gray'}
              listIcon="slider"
              text={item.text}
              family="sliderItem"
              type="bottom"
              onClick={handleItemClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SliderItems = ({scrollToSection, descriptionRef, hotelesRef, restaurantRef, preguntasRef}: any) => {
  const { locale } = useIntl()

  const [activeSection, setActiveSection] = useState(null)

  const handleSetActiveSection = (itemLink: any) => {
    setActiveSection(itemLink);
  };

  const settings = {
    spaceBetween: 1,
    slidesPerView: 2.3,
    grabCursor: true,
    breakpoints: {
      320: {
        slidesPerView: 2.8,
      },
      375: {
        slidesPerView: 3.2,
      },
      425: {
        slidesPerView: 4,
      }
    },
  };

  const item = [
    {
      id: 1,
      text: messages[locale].description,
      listIcon: "slider",
      nameIconActive: "descripcion",
      link: descriptionRef
    },
    {
      id: 1,
      text: messages[locale].hotels,
      listIcon: "slider",
      nameIconActive: "hoteles",
      link: hotelesRef
    },
    {
      id: 1,
      text: messages[locale].restaurants,
      listIcon: "slider",
      nameIconActive: "restaurante",
      link: restaurantRef
    },
    {
      id: 1,
      text: messages[locale].questions,
      listIcon: "slider",
      nameIconActive: "preguntas",
      link: preguntasRef
    },
  ]

  useEffect(() => {
    const sections = [
      { ref: descriptionRef, id: 'description' },
      { ref: hotelesRef, id: 'hoteles' },
      { ref: restaurantRef, id: 'restaurant' },
      { ref: preguntasRef, id: 'preguntas' },
    ];

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const activeSection = sections.find(section => section.ref.current && currentScrollY >= section.ref.current.offsetTop);
      if (activeSection) {
        setActiveSection(activeSection.ref);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [descriptionRef, hotelesRef, restaurantRef, preguntasRef]);

  return (
    <div className='custom-slider-nav ismobile'>
      <div style={{width: '100%', height:'100%'}}>
        <Swiper {...settings} className='custom-slider-navm' >
          {
            item.map((item, index) => (
              <SwiperSlide key={index}>
                {item.link && (<CustomSlideHotelMobile
                  item={item}
                  scrollToSection={scrollToSection}
                  isActive={activeSection === item.link}
                  handleSetActiveSection={handleSetActiveSection}
                  />)}
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </div>
  )
}

export default SliderItems