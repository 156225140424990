import { BannerSlider } from 'components/organisms'
import { banner_data_centrado } from './data'
import './styles.scss'
import Section from 'components/molecules/section'
import { messages } from 'utils/lang'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'

const TerminosyCondiciones = () => {
  const { locale} = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <BannerSlider data={banner_data_centrado} gradiente/>
      <Section>
        <div className='terminos_condiciones terminos_condiciones-element'>
          <div className='terminos_condiciones__container'>
            <h1>{messages[locale].terminosYcondicionesUno}</h1>
            <h2>
              <span>{messages[locale].terminosYcondicionesDos}</span>
            </h2>
            <p>
              <strong>CASA ANDINA:</strong>
              <span> {messages[locale].terminosYcondicionesTres} </span>
              <strong>CLIENTE:</strong>
              <span>
                {' '}
                Pasajero que toma los servicios de CASA ANDINA (mayor de edad).{' '}
              </span>
              <strong>CHECK–IN:</strong>
              <span>
                {' '}
                Proceso de registro de entrada del cliente en hotel antes de
                ingresar a la Habitación.{' '}
              </span>
              <strong>CHECK-OUT:</strong>
              <span>
                {' '}
                Proceso de registro de salida del cliente al momento de terminar
                de usar los servicios del hotel.{' '}
              </span>
              <strong>NO-SHOW:</strong>
              <span>
                {' '}
                Es la no presentación del pasajero en el hotel antes de las
                23:59 horas del día previsto de llegada, sin comunicación previa
                por parte del cliente.{' '}
              </span>
              <strong>RESERVA:</strong>
              <span>
                {' '}
                Acuerdo entre Casa Andina y el Cliente para reservar la(s)
                habitación(es) para las fechas solicitadas por el cliente en
                cualquier hotel de la cadena CASA ANDINA.{' '}
              </span>
              <strong>CARGO POR SERVICIOS:</strong>
              <span>
                {' '}
                Recargo del 10% al consumo por servicios prestados de Casa
                Andina.{' '}
              </span>
              <strong>IMPUESTO GENERAL A LAS VENTAS (IGV):</strong>
              <span> Impuesto correspondiente al 18% de la tarifa.&nbsp;</span>
            </p>
            <h2>
              <strong>Condiciones generales</strong>
            </h2>
            <p>
              <strong>
                El Cliente al realizar la reserva por Casa Andina Web declara
                haber sido informado y/o tenido acceso al contenido de la
                sección “Preguntas Frecuentes” consignados en nuestro sitio web{' '}
              </strong>
              <a
                href='http://www.casa-andina.com/'
                target='_blank'
                rel='noreferrer'
              >
                <strong>http://www.casa-andina.com/</strong>
              </a>
              <strong>
                , las cuales forman parte de los términos del presente contrato.
                Así mismo acepta incondicionalmente los siguientes términos y
                condiciones. Ningún agente, servidor, representante, o tercera
                persona, tiene la facultad o la capacidad para modificar,
                restringir o ampliar los términos de este contrato. Los términos
                y condiciones figuradas aplican para todos los hoteles que
                pertenezcan a la cadena CASA ANDINA. Estos términos son un
                contrato entre usted y Nessus Hoteles Perú S.A. o Nessus Hoteles
                Perú Selva S.A. y los bienes y/o servicios serán brindados por
                Nessus Hoteles Perú S.A. o Nessus Hoteles Perú Selva S.A.
                directamente, según corresponda. La razón social que finalmente
                facture y brinde el servició dependerá del hotel reservado:
                Hotel Compañía Nessus Hoteles Perú S.A. Casa Andina Premium
                Arequipa, Casa Andina Select Arequipa, Casa Andina Standard
                Arequipa, Casa Andina Standard Colca, Casa Andina Select Tacna,
                Casa Andina Select Moquegua, Casa Andina Premium Puno, Casa
                Andina Standard Puno, Casa Andina Premium Cusco, Casa Andina
                Premium Valle Sagrado &amp; Villas, Casa Andina Standard Cusco
                Catedral, Casa Andina Standard Cusco Koricancha, Casa Andina
                Standard Cusco Machu Picchu, Casa Andina Standard Cusco San
                Blas, Casa Andina Cusco Plaza, Casa Andina Premium Miraflores,
                Casa Andina Premium San Isidro, Casa Andina Select Miraflores,
                Casa Andina Standard Miraflor
              </strong>
              <strong>
                es San Antonio, Centro by Casa Andina, Casa Andina Standard
                Miraflores Benavides, C
              </strong>
              <strong>
                asa Andina Standard Chincha, Casa Andina Standard
                Nasca,&nbsp;Casa Andina Premium Piura, Casa Andina Standard
                Piura, Casa Andina Select Chiclayo, Casa Andina Select Zorritos
                Tumbes, Casa Andina Standard Talara, Casa Andina Standard
                Trujillo. Hotel Compañía Nessus Hoteles Perú Selva S.A.{' '}
              </strong>
              <strong>
                Casa Andina Select Pucallpa. Condiciones para la reserva vía
              </strong>
              &nbsp;
              <a
                href='http://www.casa-andina.com/'
                target='_blank'
                rel='noreferrer'
              >
                http://www.casa-andina.com/.
              </a>
            </p>
            <ul>
              <li>
                Las tarifas mostradas en el primer paso del proceso de compra
                son netas, no incluyen el cargo por IGV ni cargo por servicios.
                En el último paso del proceso de compra solo se adiciona el
                cargo por servicios correspondiente al 10% en todos los hoteles
                Casa Andina, con excepción de Casa Andina Select Pucallpa y
                Centro by Casa Andina en donde se adiciona el 5%. El IGV, en
                caso aplique, siempre será pagado en la recepción del
                hotel.&nbsp;&nbsp;
              </li>
            </ul>
            <ul>
              <li>
                Las tarifas son expresadas por noche/habitación, no por
                pasajero. El costo de camas adicionales en la habitación depende
                de la disponibilidad del hotel y del tipo de habitación. De
                acuerdo a la disponibilidad del hotel, se brindarán una cama
                adicional, un rollaway o sofá cama, y de uso máximo para niños
                hasta los 12 años. Para mayor información comunicarse con{' '}
                <a
                  href='mailto:reservasonline@casa-andina.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  reservasonline@casa-andina.com
                </a>
                .
              </li>
            </ul>
            <h2>
              <strong>Políticas de cancelación:</strong>
            </h2>
            <ul>
              <li>
                <strong>Cancelación 1 día:</strong>
                <span>
                  {' '}
                  Se puede modificar o cancelar la reserva hasta 1 día antes del
                  Check-in (hora peruana) sin cargos adicionales.&nbsp;
                </span>
              </li>
              <li>
                <strong>Cancelación 7 días:</strong>
                <span>
                  {' '}
                  Se puede modificar o cancelar la reserva hasta 7 días antes
                  del Check-in (hora peruana) sin cargos adicionales.&nbsp;
                </span>
              </li>
              <li>
                <strong>No Reembolsable:</strong>
                <span>
                  {' '}
                  Reservas prepagadas, no se aceptan modificaciones,
                  cancelaciones, transferencias ni devoluciones.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  La penalidad por No-Show corresponde al total del monto de la
                  estadía.&nbsp;
                </span>
              </li>
              <li>
                Todas las tarifas serán cobradas en dólares americanos (USD). Y
                el comprobante de compra será emitido en dólares.&nbsp;
              </li>
              <li>
                La confirmación de la reserva será en un plazo máximo de 24
                horas hábiles de realizada la reserva.&nbsp;
              </li>
              <li>
                Para el caso de las reservas con condición prepagadas, el cargo
                de la totalidad de la estadía a la tarjeta de crédito será
                efectuado en las próximas 48 horas hábiles luego de haber
                generado la reserva. En el caso de reservas generadas un día
                antes o el mismo día del check in, el cargo de la totalidad de
                la estadía a la tarjeta de crédito se realizará en el check
                in.&nbsp;
              </li>
              <li>
                <span>Para el caso de las reservas </span>
                con condición de “pago en el hotel”, la página web de Casa
                Andina, no acepta tarjetas de débito. En caso de ingresar una
                Tarjeta de Débito, la reserva quedaría anulada, sin previo aviso
                <span>.&nbsp;</span>
              </li>
              <li>
                &nbsp;En caso de las reservas “prepagadas” realizadas mediante
                la página web de Casa Andina, el cargo se realizará
                inmediatamente a la tarjeta de crédito o débito
                registrada.&nbsp;
              </li>
              <li>
                <span>
                  En caso la tarjeta de crédito muestre los mensajes:
                  “denegada”, “robada”, “fondos insuficientes”, “fecha vencida”,
                  se procederá a la anulación de la reserva, sin previo
                  aviso.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  Nos reservamos el derecho a cancelar o modificar reservaciones
                  en aquellos casos donde se considere que el cliente haya
                  realizado una actividad fraudulenta o inapropiada.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  En caso de error y/o duplicidad en la reserva realizada por el
                  cliente, Casa Andina no asume responsabilidad por este hecho y
                  aplicará la penalidad según la política de la reserva.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  En caso de reservas de grupo mayores a 5 habitaciones Casa
                  Andina se reserva el derecho de aplicar políticas y plazos
                  especiales.&nbsp;
                </span>
              </li>
            </ul>
            <h2>
              <strong>
                Condiciones para el proceso de Check–in y Check-out
              </strong>
            </h2>
            <ul>
              <li>
                Prohibido el ingreso de alimentos a los hoteles, salvo por
                alguna restricción médica.&nbsp;
              </li>
              <li>
                Es requisito presentar Documento de Identidad (DNI) o pasaporte,
                carnet de extranjería de todos los ocupantes de la habitación
                (incluidos niños) y visitas.&nbsp;En caso el huésped desee
                ingresar con invitados a su habitación, es indispensable que
                estos sean debidamente identificados y registrados mediante el
                documento de identidad. Casa Andina permitirá el ingreso de
                menores de edad al hotel únicamente cuando estén acompañados por
                algunos de sus padres, tutores, curadores, responsables y con
                autorización expresa de ellos para ingresar a los hoteles
                (Permiso Notarial).&nbsp;
              </li>
              <li>
                Las visitas permitidas por cada habitación serán máximo de 02
                personas.&nbsp;
              </li>
              <li>
                Las personas domiciliadas en el Perú estarán sujetas al pago del
                18% del Impuesto General a las Ventas (IGV) en la recepción del
                hotel (aplica para reservas realizadas en la página web de Casa
                Andina). Las reservas realizadas por el contact center incluirán
                los conceptos de impuestos y servicios en la tarifa. En el caso
                de Casa Andina Select Pucallpa el pago del IGV queda
                exonerado.&nbsp;
              </li>
              <li>
                Para la exoneración del Impuesto General a las Ventas (IGV) es
                indispensable presentar el pasaporte vigente y la Tarjeta Andina
                de Migración (TAM) con un plazo de ingreso no mayor a 60
                días.&nbsp;
              </li>
              <li>
                Los huéspedes peruanos que no residen en el Perú deben acreditar
                su residencia en el país extranjero con la documentación
                correspondiente.&nbsp;
              </li>
              <li>
                El horario de Check-in y Check–out varía de acuerdo al hotel en
                donde se realizó la reserva, y será detallado en la confirmación
                de reserva.&nbsp;&nbsp;
              </li>
              <li>
                <span>
                  Cualquier solicitud para Early Check-in y/o Late Check–out
                  está sujeta a un cargo adicional y debe ser solicitado al
                  correo:{' '}
                </span>
                <a
                  href='mailto:reservasonline@casa-andina.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  reservasonline@casa-andina.com
                </a>{' '}
                o directamente en la recepción del{' '}
                <span>hotel (sujeto a disponibilidad).&nbsp;</span>
              </li>
              <li>
                <span>
                  Indispensable presentar una TARJETA DE CRÉDITO al momento del
                  Check-in para garantizar alojamiento y extras durante estadía.
                  A esta TARJETA DE CRÉDITO se le hará una
                  pre-autorización.&nbsp;
                </span>
              </li>
              <li>
                &nbsp;En Casa Andina admitimos el ingreso de huéspedes
                acompañados de su perro (Mascota) siempre que se cumpla con las
                presente Políticas de Admisión. Solo se permiten dos perros por
                habitación con un precio de $63.00 por perro (incluye impuestos
                y servicios) por cada noche. Al momento de la reserva, el
                huésped deberá hacer entrega de los certificados emitidos por el
                veterinario colegiado (u órgano gubernamental competente) que
                acrediten las vacunaciones y desparasitaciones actualizadas de
                su mascota, con una antigüedad no mayor de 1 año. Es necesario
                portar los referidos certificados durante su estadía en el hotel
                y presentarlos en caso de requerimiento del personal de CASA
                ANDINA. Para mayor información consultar:
                <a
                  href='https://www.casa-andina.com/es/dog-friendly'
                  target='_blank'
                  rel='noreferrer'
                >
                  https://www.casa-andina.com/es/dog-friendly
                </a>
              </li>
              <li>
                En caso se trate de perros guía se debe presentar la constancia
                del CONADIS, acreditando que es necesaria la compañía del perro
                (discapacidad visual, emocional, medición de glucosa u otro
                tipo), de no contar con la certificación de CONADIS, el huésped
                asumirá el cobro por noche de estadía de su mascota. Se deberá
                coordinar con el hotel al menos una semana antes de su
                llegada.&nbsp;
              </li>
            </ul>
            <h2>
              <strong>Condiciones para la estadía en el hotel</strong>
            </h2>
            <ul>
              <li>
                Se ofrece servicio de movilidad gratuita compartida o “shuttle”
                en Pucallpa, Piura, Chiclayo en horarios prestablecidos y sujeto
                al itinerario que establezca el hotel. Para coordinar el
                traslado, deben comunicarse con cada una de las recepciones con
                48 horas de anticipación de la fecha del check in.&nbsp;&nbsp;
              </li>
              <li>
                <span>
                  Contamos con traslado Aeropuerto–Hotel-Aeropuerto en servicio
                  privado con cargo adicional.&nbsp;
                </span>
              </li>
              <li>
                De acuerdo a la disponibilidad de cada hotel y del tipo de
                habitación, se permite hasta dos (02) niños (menores de 12 años)
                compartiendo habitación con dos adultos sin cargo extra en
                alojamiento.&nbsp;&nbsp;
              </li>
              <li>
                El desayuno buffet incluido por habitación es considerado para
                dos personas, entre adultos o niños. Si en la habitación se
                hospedan niños, se le realizará el cobro del desayuno buffet de
                acuerdo a las edades de los menores.&nbsp;
              </li>
              <li>
                El cobro de los desayunos adicionales se realizará de la
                siguiente manera:&nbsp;
              </li>
              <li>
                <strong>
                  <u>Casa andina Standard: </u>
                </strong>
                <span>Se permite máximo 01 niño en la habitación&nbsp;</span>0 a
                7 años: Se permitirá máximo 01 niño, será liberado de pago de
                habitación y desayuno, compartiendo cama con los padres.&nbsp;
              </li>
              <li>
                8 a 12 años: Se permitirá máximo 01 niño, será liberado de pago
                de habitación, compartiendo cama con los padres. Se debe pagar
                el desayuno.&nbsp;
              </li>
              <li>
                <strong>Casa andina Select y Casa andina Premium:</strong>
                Se permite máximo 02 niños en la habitación&nbsp;
              </li>
              <li>
                0 a 7 años: Se permitirá al 1er niño liberado de pago de
                habitación y desayuno. El 2do niño pagará desayuno. El (los)
                niño(s) comparten cama con los padres.&nbsp;
              </li>
              <li>
                8 a 12 años: Se permitirá máximo 02 niños liberados sólo del
                pago de habitación, compartiendo cama con los padres. Se debe
                pagar el desayuno.&nbsp;
              </li>
              <li>
                Solo es posible colocar camas extras, rollaways o sofás cama, en
                algunas de nuestras habitaciones de determinados hoteles. Se
                requiere previa coordinación e impli
                <span>cará cargo adicional.&nbsp;</span>
              </li>
              <li>
                <span>
                  No es posible el juntar las camas de las habitaciones.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  Contamos con cunas y/o sets de “pack &amp; play” gratis en
                  todas las unidades (sujeto a disponibilidad), consulte al
                  momento de realizar el check-in.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  Las habitaciones y áreas comunes, en algunas unidades, cuentan
                  con instalaciones y condiciones de acceso para personas con
                  necesidades especiales.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  Si desea extender su estadía, le pedimos que informe a la
                  Recepción del hotel con al menos 24 horas de anticipación a su
                  fecha de salida. La extensión dependerá de la disponibilidad
                  de habitaciones.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  Dada la situación – hecho de fuerza mayor o caso fortuito –
                  que CASA ANDINA no esté capacitada para cumplir con el
                  alojamiento del cliente, esta se compromete a brindar una
                  solución ofreciendo alojamiento en cualquiera de sus hoteles
                  más cercanos, o en otros hoteles cercanos de similar o
                  superior categoría.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  De acuerdo a la Ley General para la Prevención y Control de
                  Los Riesgos del Consumo de Tabaco, Ley Número 29517, está
                  prohibido fumar en las habitaciones. En caso se verifique el
                  incumplimiento por un huésped de lo especificado en el
                  presente párrafo, se aplicará a dicho huésped u
                </span>
                na penalidad ascendente a 250 USD diarios.&nbsp;
              </li>
              <li>
                Todos los hoteles cuentan con WiFi gratuito en áreas públicas y
                habitaciones; y High Speed WiFi con costo adicional, con
                excepción de Casa Andina Standard Colca que sólo cuenta con WiFi
                gratuito en la recepción, restaurante y bar.&nbsp;
              </li>
              <li>
                No todos nuestros hoteles cuentan con estacionamiento dentro de
                las instalaciones del hotel, por lo que no garantizamos plazas
                para ningún pasajero (sujeto a disponibilidad se
                <span>
                  gún orden de llegada). En caso se confirme la disponibilidad
                  de estacionamiento en el hotel, este no tiene costo.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  El hotel no se responsabiliza por la pérdida o dinero, joyas
                  y/u otros objetos de valor dejados en la habitación. Rogamos a
                  nuestros huéspedes dejar las puertas de sus habitaciones
                  debidamente cerradas, así como utilizar las cajas de seguridad
                  que se encuentran en sus habitaciones o declarar los mismos y
                  dejarlos en custodia en la recepción del hotel. La
                  disponibilidad de una caja de seguridad en la recepción podría
                  variar según el hotel.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  Todos los objetos personales olvidados por nuestros huéspedes
                  y encontrados por algún trabajador del hotel serán almacenados
                  de acuerdo al protocolo de almacenamiento por categoría.
                </span>
              </li>
              <li>
                <span>
                  Con el fin de proporcionar una cómoda estadía a todos nuestros
                  huéspedes exigimos un horario de silencio entre las 11 p.m. y
                  las 9 a.m.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  Está prohibido el ingreso a las habitaciones de cualquier
                  persona que no esté registrada en el hotel.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  En caso desee consumir bebidas alcohólicas adquiridas fuera
                  del hotel, se aplicará una tarifa de descorche comunicada por
                  el hotel.&nbsp;
                </span>
              </li>
              <li>
                &nbsp;
                <span>
                  No ofrecemos tours, pero podemos contactarlos con un operador
                  local.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  Casa Andina está en contra del tráfico de piezas arqueológicas
                  pertenecientes al Patrimonio Cultural Peruano.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  Casa Andina está en contra del tráfico de especies
                  protegidas.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  Está prohibida la tenencia y consumo de drogas alucinógenas
                  dentro de las instalaciones del hotel.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  La explotación y el abuso sexual de menores de edad son
                  castigados penal y civilmente conforme las disposiciones
                  legales vigentes.&nbsp;
                </span>
              </li>
            </ul>
            <h2>
              <strong>
                Términos y Condiciones de reservas en Hoteles Asociados
              </strong>
            </h2>
            <p>
              <strong>HOTELES ASOCIADOS:</strong>
              <span>
                {' '}
                Conjunto de hoteles que tienen la calidad de Asociados de Casa
                Andina y que utilizan para distinguirse la marca “Hoteles
                Asociados Casa Andina”{' '}
              </span>
              <strong>CASA ANDINA</strong>
              <span>: Gestora de Ventas y Reservas. </span>
              <strong>CLIENTE</strong>
              <span>
                : Pasajero que toma los servicios de los Hoteles Asociados
                (mayor de edad).{' '}
              </span>
              <strong>CHECK–IN:</strong>
              <span>
                {' '}
                Proceso de registro de entrada del cliente en hotel antes de
                ingresar a la habitación.
              </span>
              <strong> CHECK-OUT</strong>
              <span>
                : Proceso de registro de salida del cliente al momento de
                terminar de usar los servicios del hotel.
              </span>
              <strong> NO-SHOW</strong>
              <span>
                : Es la no presentación del pasajero en el hotel antes de las
                23:59 horas del día previsto de llegada, sin comunicación previa
                por parte del cliente.{' '}
              </span>
              <strong>RESERVA</strong>
              <span>
                : Acuerdo entre el Hotel Asociado y el Cliente para reservar
                la(s) habitación(es) para las fechas solicitadas por el cliente.{' '}
              </span>
              <strong>CARGO POR SERVICIOS:</strong>
              <span>
                {' '}
                Recargo del 10% al consumo por servicios prestados de Casa
                Andina, de ser aplicable.
              </span>
              <strong> IMPUESTO GENERAL A LAS VENTAS (IGV)</strong>
              <span>
                : Impuesto correspondiente al 18% de la tarifa, de ser
                aplicable.&nbsp;
              </span>
            </p>
            <p>
              <strong>Condiciones generales:</strong>
            </p>
            <p>
              <span>
                El Cliente al realizar la reserva por Casa Andina Web declara
                aceptar incondicionalmente los siguientes términos y
                condiciones. Ningún agente, servidor, representante, o tercera
                persona, tiene la facultad o la capacidad para modificar,
                restringir o ampliar los términos y condiciones. Los términos y
                condiciones aplican para todos los Hoteles Asociados que
                pertenezcan a la Red de Asociados Casa Andina.
              </span>
            </p>
            <ul>
              <li>
                <strong>Política de Atención al Cliente:</strong>
                <span>
                  {' '}
                  Casa Andina no es parte de la relación contractual entre el
                  Hotel Asociado y el Cliente, cualquier reclamo o queja sobre
                  el servicio de hospedaje debe ser canalizado con el Hotel
                  Asociado.
                </span>
              </li>
              <li>
                <strong>Tarifas: </strong>
                <span>
                  Las tarifas mostradas en el primer paso del proceso de compra
                  son netas, no incluyen el cargo por IGV ni cargo por
                  servicios. En el último paso del proceso de compra solo se
                  adiciona el cargo por servicios, de ser aplicable, pero el
                  IGV, en caso aplique, siempre será pagado en la recepción del
                  hotel. Las tarifas son expresadas por noche/habitación, no por
                  pasajero. El costo de camas adicionales en la habitación
                  depende de la disponibilidad de espacio en la habitación y
                  camas en el hotel, por lo que deberá de comunicarse
                  directamente con el Hotel Asociado.
                </span>
              </li>
              <li>
                <span>
                  La razón social de la facturación o boleta de venta dependerá
                  del Hotel Asociado elegido por el Cliente.
                </span>
              </li>
              <li>
                <span>
                  Al reservar un alojamiento por la presente página web, Casa
                  Andina es responsable de la Plataforma y en consecuencia de
                  los Términos y Condiciones Generales establecidos en este
                  documento, pero no del servicio de alojamiento realizado por
                  el Hotel Asociado y las políticas de Atención Cliente
                  reguladas por este último en su página web.
                </span>
              </li>
            </ul>
            <p>
              <strong>Políticas de cancelación:&nbsp;</strong>
            </p>
            <ul>
              <li>
                <strong>Flexible:</strong>
                <span>
                  {' '}
                  Se puede modificar o cancelar la reserva hasta 7 días antes
                  del Check-in (hora peruana) sin cargos adicionales.
                </span>
              </li>
              <li>
                <strong>No Reembolsable:</strong>
                <span>
                  {' '}
                  Reservas prepagadas, no se aceptan modificaciones,
                  cancelaciones, transferencias ni devoluciones.
                </span>
              </li>
              <li>
                <span>
                  La penalidad por No-Show corresponde al total del monto de la
                  estadía.
                </span>
              </li>
              <li>
                <span>
                  Todas las tarifas serán cobradas en dólares americanos (USD).
                  Y el comprobante de compra será emitido en dólares.
                </span>
              </li>
              <li>
                <span>
                  La confirmación de la reserva será en un plazo máximo de 24
                  horas realizada la reserva.
                </span>
              </li>
              <li>
                <span>
                  Para el caso de las reservas con condición prepagadas, el
                  cargo de la totalidad de la estadía a la tarjeta de crédito
                  será efectuado en las 24 próximas horas luego de haber
                  generado la reserva.
                </span>
              </li>
              <li>
                <span>
                  Para el caso de las reservas que no tienen condición de
                  prepagadas, el Hotel Asociado tiene la potestad de verificar
                  la tarjeta de crédito a través de pre autorización.
                </span>
              </li>
              <li>
                <span>
                  La web (www.casa-andina.com) no acepta tarjetas de débito. En
                  caso de ingresar una Tarjeta de Débito, la reserva quedaría
                  anulada, sin previo aviso.
                </span>
              </li>
              <li>
                <span>
                  En caso la tarjeta de crédito muestre los mensajes:
                  “denegada”, “robada”, “fondos insuficientes”, “fecha vencida”,
                  se procederá a la anulación de la reserva, sin previo aviso.
                </span>
              </li>
              <li>
                <span>
                  Nos reservamos el derecho a cancelar o modificar reservaciones
                  en aquellos casos donde se considere que el cliente haya
                  realizado una actividad fraudulenta o inapropiada.
                </span>
              </li>
              <li>
                <span>
                  En caso de error y/o duplicidad en la reserva realizada por el
                  cliente, Casa Andina no asume responsabilidad por este hecho y
                  aplicará la penalidad según la política de la reserva.
                </span>
              </li>
              <li>
                <span>
                  Dada la situación – hecho de fuerza mayor o caso fortuito –
                  que el Hotel Asociado no esté capacitado para cumplir con el
                  alojamiento del cliente, esta se compromete a brindar una
                  solución ofreciendo alojamiento en cualquiera de sus hoteles
                  más cercanos, o en otros hoteles cercanos de similar o
                  superior categoría.&nbsp;
                </span>
              </li>
              <li>
                <span>
                  De acuerdo a la Ley General para la Prevención y Control de
                  Los Riesgos del Consumo de Tabaco, Ley Número 29517, está
                  prohibido fumar en las habitaciones. En caso se verifique el
                  incumplimiento por un huésped de lo especificado en el
                  presente párrafo, se aplicará a dicho huésped una penalidad
                  ascendente a 250 USD diarios.
                </span>
              </li>
              <li>
                <span>
                  Está prohibido el ingreso a las habitaciones de cualquier
                  persona que no esté registrada en el hotel.
                </span>
              </li>
              <li>
                <span>
                  Casa Andina y los Hoteles Asociados están en contra del
                  tráfico de piezas arqueológicas pertenecientes al Patrimonio
                  Cultural Peruano.
                </span>
              </li>
              <li>
                <span>
                  Casa Andina y los Hoteles Asociados están en contra del
                  tráfico de especies protegidas.
                </span>
              </li>
              <li>
                <span>
                  Está prohibida la tenencia y consumo de drogas alucinógenas
                  dentro de las instalaciones del Hotel Asociado.
                </span>
              </li>
              <li>
                <span>
                  La explotación y el abuso sexual de menores de edad son
                  castigados penal y civilmente conforme las disposiciones
                  legales vigentes.
                </span>
              </li>
            </ul>
            <p>
              <strong>Procedimiento de Cliente o Visitante No Grato</strong>
            </p>
            <p>
              <span>
                En Casa Andina nos preocupamos por brindar un ambiente seguro y
                de respeto entre nuestros colaboradores, huéspedes, clientes y
                comunidad. Por ello, establecimos un proceso interno que detalla
                los motivos para que Casa Andina declare a un cliente o
                visitante como NO GRATO.
              </span>
            </p>
            <p>
              <strong>
                Motivos para determinar a un cliente o visitante NO GRATO:
              </strong>
            </p>
            <ul>
              <li>
                <span>
                  Dejar el hotel sin realizar el check out correspondiente
                  dejando consumos u otras deudas pendientes por pagar.
                </span>
              </li>
              <li>
                <span>
                  &nbsp;Registrarse en el hotel con información falsa o se
                  suplante la identidad de otra persona.
                </span>
              </li>
              <li>
                <span>
                  &nbsp;Intentar pagar con dinero falso reincidentemente o con
                  documentación falsa como DNI, pasaporte, tarjeta de crédito o
                  débito falsa.
                </span>
              </li>
              <li>
                <span>
                  &nbsp;Cometer agresión física, verbal, acoso, hostigamiento o
                  amenazar a algún huésped, colaborador, proveedor o visitante
                  siempre y cuando esté probada la responsabilidad del agresor.
                </span>
              </li>
              <li>
                <span>
                  Cometer hurtos o robos, en cualquiera de sus modalidades,
                  durante su estadía o visita al hotel (incluidos aquellos
                  realizados contra proveedores, visitantes, etc.)
                </span>
              </li>
              <li>
                <span>
                  &nbsp;&nbsp;Ingresar al hotel con un menor de edad y no
                  registrarlo en la recepción (en caso no sean padres del menor
                  se debe contar con una carta notarial o documento que acredite
                  que son tutores del menor).
                </span>
              </li>
              <li>
                <span>
                  &nbsp;Ingresar con acompañantes no identificados, con
                  Documento Nacional de Identidad, Carnet de Extranjería u otro
                  documento oficial de identidad emitido por alguna autoridad
                  nacional o extranjera competente que acredite fehacientemente
                  dicha información (previa validación del hotel), a las
                  habitaciones del hotel.
                </span>
              </li>
              <li>
                <span>
                  No hacerse responsable por daños a las instalaciones del hotel
                  (previa validación de la responsabilidad del cliente).
                </span>
              </li>
              <li>
                <span>
                  &nbsp;&nbsp;En caso el cliente o visitante cuente con armas y
                  no presente o se oponga a mostrar su “licencia para portar
                  armas”
                </span>
              </li>
              <li>
                <span>
                  &nbsp;Por abandono de bienes cuyo valor sea de 1000 dólares
                  americanos a más.
                </span>
              </li>
              <li>
                <span>
                  Incurrir en actos fraudulentos mediante los cuales se busque
                  obtener un beneficio mediante engaño al personal del hotel,
                  previa validación de la responsabilidad del cliente.
                </span>
              </li>
              <li>
                <span>
                  &nbsp;Otros comportamientos peligrosos, dañinos, de alto
                  riesgo o que contravengan con el bienestar, orden moral y
                  buenas costumbres de clientes, visitantes, proveedores y/o
                  colaboradores. Dichos motivos serán evaluados por Customer
                  Experience.&nbsp;
                </span>
              </li>
            </ul>
            <p>
              <strong>
                Consecuencias de declarar a un cliente o visitante NO GRATO
              </strong>
            </p>
            <ul>
              <li>
                <span>
                  Todo cliente o visitante declarado NO GRATO será previamente
                  notificado vía carta notarial sobre la decisión adoptada por
                  Casa Andina y el motivo de aquella.
                </span>
              </li>
              <li>
                <span>
                  &nbsp;Los clientes o visitantes no gratos no podrán realizar
                  reservas ni hospedarse nuevamente en los hoteles Casa Andina.
                </span>
              </li>
            </ul>
            <p>
              <span>
                &nbsp;En caso un cliente o visitante declarado no grato ingrese
                a alguno de los hoteles Casa Andina, el personal del hotel
                activará el protocolo de seguridad e invitará al visitante a
                retirarse del hotel. Si el cliente o visitante se rehúsa a salir
                de las instalaciones del hotel, se llamará al personal de
                seguridad y a la Policía Nacional del Perú.
              </span>
            </p>
          </div>
        </div>
      </Section>
    </>
  )
}

export default TerminosyCondiciones
