import { BannerSlider } from "components/organisms";
import "./styles.scss";
import Section from "components/molecules/section";
import { Text } from "components/atoms";
import { useTranslation } from "react-i18next";

const News = () => {
  const { t } = useTranslation();

  const banner_data_centrado = [
    {
      id: 1,
      titulo: "HOTEL PARACAS",
      subtitulo: t("reservationAgents.reserveNow"),
      subtitulo2: t("reservationAgents.nowOpen"),
      color_fondo_logo: "rgba(62, 145, 41, 1)",
      img_desktop: {
        name: "banner-home",
        path: "https://s3.us-east-1.amazonaws.com/ca-webprod/Hoteles/16933f02-0e94-45f8-8c36-c9d356de0d73.jpg",
        width: 1440,
        height: 400,
        size: 794666,
        title: "Banner News",
        alt: "Banner News",
        description: null,
        caption: "Banner News",
      },
      img_mobile: [],
      descuento: "",
      descripcion_boton: "",
      redireccion_boton: "",
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: "Título/subtitulo collage logo",
      },
    },
  ];

  return (
    <>
      <BannerSlider data={banner_data_centrado} />
      <Section>
        <div className="news">
          <div className="news__container">
            <Text text={t("news.title")} type="title" />

            <div>
              <Text text={t("news.statement1.title")} type="subtitle" />
              <Text text={t("news.statement1.text1")} type="paragraph" />
              <Text text={t("news.statement1.text2")} type="paragraph" />
              <Text text={t("news.statement1.text3")} type="paragraph" />
              <Text text={t("news.statement1.text4")} type="paragraph" />
              <p>
                {t("news.statement1.text5")}{" "}
                <a
                  href="mailto:centraldereservas@casa-andina.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  centraldereservas@casa-andina.com
                </a>
              </p>
            </div>

            <div>
              <Text text={t("news.statement2.title")} type="subtitle" />
              <Text text={t("news.statement2.text1")} type="paragraph" />

              <ul>
                <li>{t("news.statement2.text2")}</li>
                <li>{t("news.statement2.text3")}</li>
                <li>{t("news.statement2.text4")}</li>
                <li>{t("news.statement2.text5")}</li>
                <li>{t("news.statement2.text6")}</li>
              </ul>

              <Text text={t("news.statement2.text7")} type="paragraph" />
              <Text text={t("news.statement2.text8")} type="paragraph" />
            </div>

            <div>
              <Text text={t("news.communityActions.title")} type="subtitle" />
              <Text
                text={t("news.communityActions.subtitle")}
                type="subtitle2"
              />

              <Text text={t("news.communityActions.text1")} type="paragraph" />
              <Text text={t("news.communityActions.text2")} type="paragraph" />
              <Text text={t("news.communityActions.text3")} type="paragraph" />
              <Text text={t("news.communityActions.text4")} type="paragraph" />
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default News;
