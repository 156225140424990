import React, { useEffect } from "react";
import { BannerSlider, ListCards } from "components/organisms";
import Section from "components/molecules/section";
import { Text } from "components/atoms";
import "./styles.scss";
import { bankEntities } from "assets/images/home/bank-entities";
import { useTranslation } from "react-i18next";

const PromotionsQuota = () => {
  const { t } = useTranslation();

  const listPromotionQuota = [
    {
      description: [
        {
          id: 1,
          content: t("offers-banks.validityPeriod"),
        },
        {
          id: 2,
          content: t("offers-banks.validForServices"),
        },
        {
          id: 3,
          content: t("offers-banks.paymentConditions"),
        },
        {
          id: 4,
          content: t("offers-banks.installments"),
        },
        {
          id: 5,
          content: t("offers-banks.webRate"),
        },
        {
          id: 6,
          content: t("offers-banks.interbankDisclaimer"),
        },
        {
          id: 7,
          content: t("offers-banks.interestRates"),
        },
      ],
      id: 3,
      nombre: "PROMOCION INTERBANK ",
      redireccion: "/es/ofertas/promo",
      color_promocion: "rgba(20, 51, 255, 1)",
      entidad: {
        id: 2,
        titulo: "INTERBANK",
        color: "rgba(114, 170, 58, 0.85)",
        logo: {
          name: "logo-ibk-white",
          path: bankEntities.bcpColor,
          width: 244,
          height: 69,
          size: 10406,
          title: null,
          alt: "logo-ibk-white",
          description: null,
          caption: null,
        },
      },
      categoria: {
        id: 2,
        titulo: "BANCO",
      },
      is_formulario: false,
      card: {
        tipo_disenio: {
          id: 1,
          titulo: "Oferta con imagen de fondo cuotas",
        },
        titulo_card: "",
        condicion_abajo_precio: "",
        texto_encima_precio: "",
        valor: `${t("offers-banks.quotasAnd", { quota1: 3, quota2: 6 })}`,
        titulo: t("offers-banks.upTo"),
        subtitulo: t("offers-banks.installmentsWithYourCards"),
        logo: {
          name: "logo-ibk",
          path: bankEntities.ibkColor,
          width: 105,
          height: 20,
          size: 2320,
          title: null,
          alt: "ibk",
          description: null,
          caption: null,
        },
        img: {
          name: "fondo-oferta-2",
          path: bankEntities.Card1,
          width: 191,
          height: 194,
          size: 90219,
          title: "Fondo Oferta 2",
          alt: "Fondo Oferta 2",
          description: null,
          caption: "Fondo Oferta 2",
        },
        color: "rgba(6, 190, 79, 0.85)",
        color_secundario: null,
        precio_con_descuento: 0,
        precio: 0,
      },
    },
    {
      description: [
        {
          id: 1,
          content: t("offers-banks.bcpBenefit"),
        },
        {
          id: 2,
          content: t("offers-banks.bcpExclusions"),
        },
        {
          id: 3,
          content: t("offers-banks.bcpPayment"),
        },
        {
          id: 4,
          content: t("offers-banks.bcpValidity"),
        },
        {
          id: 5,
          content: t("offers-banks.bcpConditions"),
        },
      ],
      id: 3,
      nombre: "PROMOCION BCP",
      redireccion: "/es/ofertas/promo",
      color_promocion: "rgba(20, 51, 255, 1)",
      entidad: {
        id: 2,
        titulo: "BCP",
        color: "rgba(41, 212, 13, 1)",
        logo: {
          name: "logo-ibk-white",
          path: bankEntities.bcpColor,
          width: 244,
          height: 69,
          size: 10406,
          title: null,
          alt: "logo-ibk-white",
          description: null,
          caption: null,
        },
      },
      categoria: {
        id: 2,
        titulo: "BANCO",
      },
      is_formulario: false,
      card: {
        tipo_disenio: {
          id: 1,
          titulo: "Oferta con imagen de fondo cuotas",
        },
        titulo_card: "",
        condicion_abajo_precio: "",
        texto_encima_precio: "",
        valor: "6",
        titulo: t("offers-banks.upTo"),
        subtitulo: t("offers-banks.installmentsWithYourCards"),
        logo: {
          name: "logo-ibk",
          path: bankEntities.bcpColor,
          width: 105,
          height: 20,
          size: 2320,
          title: null,
          alt: "ibk",
          description: null,
          caption: null,
        },
        img: {
          name: "fondo-oferta-2",
          path: bankEntities.Card2,
          width: 191,
          height: 194,
          size: 90219,
          title: "Fondo Oferta 2",
          alt: "Fondo Oferta 2",
          description: null,
          caption: "Fondo Oferta 2",
        },
        color: "rgba(0, 42, 142, 0.85)",
        color_secundario: null,
        precio_con_descuento: 0,
        precio: 0,
      },
    },
    {
      description: [
        {
          id: 1,
          content: t("offers-banks.bbvaProgram"),
        },
        {
          id: 2,
          content: t("offers-banks.bbvaConditions"),
        },
        {
          id: 3,
          content: t("offers-banks.bbvaInstallments"),
        },
      ],
      id: 3,
      nombre: "PROMOCION INTERBANK ",
      redireccion: "/es/ofertas/promo",
      color_promocion: "rgba(20, 51, 255, 1)",
      entidad: {
        id: 2,
        titulo: "INTERBANK",
        color: "rgba(41, 212, 13, 1)",
        logo: {
          name: "logo-ibk-white",
          path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Promociones/logo-ibk-white.png",
          width: 244,
          height: 69,
          size: 10406,
          title: null,
          alt: "logo-ibk-white",
          description: null,
          caption: null,
        },
      },
      categoria: {
        id: 2,
        titulo: "BANCO",
      },
      is_formulario: false,
      card: {
        tipo_disenio: {
          id: 1,
          titulo: "Oferta con imagen de fondo cuotas",
        },
        titulo_card: "",
        condicion_abajo_precio: "",
        texto_encima_precio: "",
        valor: `${t("offers-banks.quotasAnd", { quota1: 3, quota2: 6 })}`,
        titulo: t("offers-banks.upTo"),
        subtitulo: t("offers-banks.installmentsWithYourCards"),
        logo: {
          name: "logo-ibk",
          path: bankEntities.bbvaColor,
          width: 105,
          height: 20,
          size: 2320,
          title: null,
          alt: "ibk",
          description: null,
          caption: null,
        },
        img: {
          name: "fondo-oferta-2",
          path: bankEntities.Card3,
          width: 191,
          height: 194,
          size: 90219,
          title: "Fondo Oferta 2",
          alt: "Fondo Oferta 2",
          description: null,
          caption: "Fondo Oferta 2",
        },
        color: "rgba(14, 69, 126, 0.85)",
        color_secundario: null,
        precio_con_descuento: 0,
        precio: 0,
      },
    },
    {
      description: [
        {
          id: 1,
          content: t("offers-banks.dinersProgram"),
        },
        {
          id: 2,
          content: t("offers-banks.dinersConditions"),
        },
        {
          id: 3,
          content: t("offers-banks.nonRefundableRate"),
        },
      ],
      id: 3,
      nombre: "PROMOCION INTERBANK ",
      redireccion: "/es/ofertas/promo",
      color_promocion: "rgba(20, 51, 255, 1)",
      entidad: {
        id: 2,
        titulo: "INTERBANK",
        color: "rgba(41, 212, 13, 1)",
        logo: {
          name: "logo-ibk-white",
          path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Promociones/logo-ibk-white.png",
          width: 244,
          height: 69,
          size: 10406,
          title: null,
          alt: "logo-ibk-white",
          description: null,
          caption: null,
        },
      },
      categoria: {
        id: 2,
        titulo: "BANCO",
      },
      is_formulario: false,
      card: {
        tipo_disenio: {
          id: 1,
          titulo: "Oferta con imagen de fondo cuotas",
        },
        titulo_card: "",
        condicion_abajo_precio: "",
        texto_encima_precio: "",
        valor: "6",
        titulo: t("offers-banks.upTo"),
        subtitulo: t("offers-banks.installmentsWithYourCards"),
        logo: {
          name: "logo-ibk",
          path: bankEntities.dinnersColor,
          width: 105,
          height: 20,
          size: 2320,
          title: null,
          alt: "ibk",
          description: null,
          caption: null,
        },
        img: {
          name: "fondo-oferta-2",
          path: bankEntities.Card4,
          width: 191,
          height: 194,
          size: 90219,
          title: "Fondo Oferta 2",
          alt: "Fondo Oferta 2",
          description: null,
          caption: "Fondo Oferta 2",
        },
        color: "rgba(73, 125, 156, 0.85)",
        color_secundario: null,
        precio_con_descuento: 0,
        precio: 0,
      },
    },
  ];

  const bannerPromotionQuota = [
    {
      id: 1,
      titulo: t("offers-banks.interestFreeInstallments"),
      subtitulo: t("offers-banks.enjoyYourVacation"),
      color_fondo_logo: "rgb(47, 28, 216)",
      img_desktop: {
        name: "ambiente-bar",
        path: bankEntities.background,
        width: 354,
        height: 405,
        size: 302962,
        title: "ambiente-bar",
        alt: "ambiente-bar",
        description: "ambiente-bar",
        caption: "ambiente-bar",
      },
      img_mobile: null,
      descuento: "",
      descripcion_boton: "",
      redireccion_boton: "",
      img_logos: [
        {
          id: 1,
          path: bankEntities.bcpWhite,
          alt: "bcp",
          title: "bcp",
          color: "rgb(47, 28, 216)",
        },
        {
          id: 2,
          path: bankEntities.ibkWhite,
          alt: "ibk",
          title: "ibk",
          color: "",
        },
        {
          id: 3,
          path: bankEntities.bbvaWhite,
          alt: "bbva",
          title: "bbva",
          color: "",
        },
        {
          id: 4,
          path: bankEntities.dinnersWhite,
          alt: "dinners",
          title: "dinners",
          color: "",
        },
      ],
      tipo_banner: {
        id: 1,
        titulo: "bancos",
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BannerSlider data={bannerPromotionQuota} />

      <Section title={t("offers-banks.yourTrip")}>
        <div className="quota">
          <Text
            type="paragraph"
            text={
              t("offers-banks.stopDreaming")
            }
            className="quota__description"
          />

          <div className="quota__list">
            <Text
              type="paragraph"
              text={`*${t("offers-banks.notApplicable")}`}
              className="paragraph"
            />
            <Text
              type="paragraph"
              text={`*${t("offers-banks.applicableRates")}`}
            />

            <Text
              type="paragraph"
              text={t("offers-banks.paymentOptions")}
            />
          </div>
        </div>
      </Section>

      <Section bgColor="#F5F5F5" isTitle={false}>
        <ListCards list={listPromotionQuota} isList />
      </Section>
    </>
  );
};

export default PromotionsQuota;
