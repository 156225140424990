import { useState } from "react";
import { ICardPromotionHotelPackage } from "./props";
import { Button, Icon, IconText, Text } from "components/atoms";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import "./styles.scss";
import { handleTravelClickWhithoutDate } from "utils/function";

const CardPromotionHotelPackage = ({
  data,
  textoTooltipIgv,
  textoTooltipIgvCard,
  coin,
  idTravelClick,
}: ICardPromotionHotelPackage) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const redirectPromotionBooking = () => {
    if (data.producto_id) {
      return handleTravelClickWhithoutDate({ id_travel_click: idTravelClick, product_id: data.producto_id }, coin, data.codigo);
    }
    navigate(data.redireccion || '/es/ofertas');
  }

  return <div className="card-promotion-hotel-package">
    <div className="left-container">
      <div>
        <div className="logo-container">
          {data.logo?.path ? (
            <img
              src={data.logo?.path}
              alt={data.logo?.alt}
              width={"auto"}
              className="logo"
            />
          ) : (
            <h1 className="titulo-logo">{data.titulo}</h1>
          )}
        </div>

        <p>{t('promotions.packageIncludes')}:</p>
        <ul>
          {data.servicios_paquete.slice(0, 5).map((servicio) => (
            <li className="service-item">
              {servicio.icono ? (
                <img
                  src={servicio.icono?.path}
                  alt={servicio.icono?.alt}
                  loading="lazy"
                  className="service-image"
                />
              )
                : (
                  <span className="service-empty-image" />
                )}
              {servicio.titulo}
            </li>
          ))}
        </ul>
      </div>
      <div className="bottom-container">
        <div
          className="btn-arrow"
          onClick={() => navigate(data.redireccion || '/es/ofertas')}
        >
          <Text
            text={t('promotions.seePackage')}
            type="paragraph"
            className="learnmore"
          />
          <Icon
            name={"right"}
            list={"arrows"}
            width={20}
            height={20}
          />
        </div>
        <div className="prices-container">
          {data.titulo && <Text className="price-text" text={data.titulo} />}
          <Text className="package-price" text={`${data.precio.simbolo} ${data.precio.precio}`} />
          {data.subtitulo ?
            <IconText
              text={`${data.subtitulo}`}
              nameIcon="question-card"
              listIcon="items"
              w={16}
              h={16}
              type="right"
              className="price-text"
              className2={`tooltip-promotion-${data.id}`}
            />
            : <p style={{height: "13px"}}></p>
          }
        </div>
      </div>
    </div>
    <div className="right-container" style={{ backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0) 50%), url(${data.imagen_fondo?.path})` }}>
      <Button type="icongold" className="book-button " onClick={redirectPromotionBooking}>
        {t("general.book")}
      </Button>
    </div>

    <Tooltip
      clickable={true}
      anchorSelect={`.tooltip-promotion-${data.id}`}
      place="bottom-end"
      opacity={1}
      className="tooltip-promo"
    >
      {textoTooltipIgv}
    </Tooltip>
  </div>;
};

export default CardPromotionHotelPackage;