import { Text } from 'components/atoms'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ISectionCards } from './props'
import 'swiper/css'
import 'swiper/css/navigation'
import './styles.scss'
import { Animations, CardCustom } from 'components/molecules'

const SectionCards = ({
  data,
  title,
  titleColor,
  bgColor,
  typehotel,
  sectionref,
  height
}: ISectionCards) => {
  return (
    <section>
      <div
        className='promotionstypes'
        style={{ backgroundColor: bgColor }}
        ref={sectionref}
      >
        <div
          className='promotionstypes__container'
          style={{ color: titleColor }}
        >
          <Text type='subtitle' text={title} className={'subtitle'} />

          <div className='promotionsrelax__container--description'>
            {/* <Swiper
              slidesPerView={'auto'}
              grabCursor={true}
              navigation={true}
              className='promotionsrelax__container--description--swiper'
            >
              {data.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  {item.categoria?.titulo.toLowerCase() === 'paquete' ? (
                    <Card
                      type='relax'
                      data={item.card}
                      redirection={item.redireccion}
                    />
                  ) : item.categoria?.titulo.toLowerCase() === 'campaña' ? (
                    <Swiper
                      slidesPerView={'auto'}
                      grabCursor={true}
                      navigation={true}
                    >
                      {item.hoteles?.map((hotel: any, index: number) => (
                        <SwiperSlide key={index}>
                          <Card
                            type='select'
                            data={hotel}
                            titulo={splitCasaAndina(hotel.nombre_corto)}
                            redirection={hotel.slug}
                            color={item.card?.color}
                            fondo={hotel.img[0]?.path}
                            logo={item.card?.logo.path}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : item.categoria?.titulo.toLowerCase() ===
                    'alimentos y bebidas' ? (
                    <Card
                      type='food-offers'
                      data={item.card}
                      redirection={item.redireccion}
                    />
                  ) : item.categoria?.titulo.toLowerCase() ===
                    'tasas y cuotas' ? (
                    <CardCustom
                      key={index}
                      type={item.card.tipo_disenio.titulo}
                      data={item.card}
                      redirection={item.redireccion}
                    />
                  ) : (
                    item.categoria?.titulo.toLowerCase() === 'banco' && (
                      <CardCustom
                        key={index}
                        type={item.card.tipo_disenio.titulo}
                        data={item.card}
                        redirection={item.redireccion}
                      />
                    )
                  )}
                </SwiperSlide>
              ))}
            </Swiper> */}

            <Swiper
              slidesPerView={'auto'}
              grabCursor={true}
              navigation={true}
              className='promotionsrelax__container--description--swiper'
            >
              {data && Array.isArray(data) && data.length > 0 ? (
                <>
                  {data.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <CardCustom
                        key={index}
                        type={'home'}
                        data={item.card}
                        redirection={item.redireccion}
                        height={height}
                        typehotel={typehotel}
                        category={item.categoria.titulo.toLowerCase()}
                      />
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                <>
                  <SwiperSlide>
                    <Animations type='skeletonContent'>
                      <div style={{ height : 218, width : 218 }}>
                      </div>
                    </Animations>
                  </SwiperSlide>
                </>
              )}

            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

SectionCards.defaultProps = {
  bgColor: 'white',
  titleColor: 'black'
}

export default SectionCards
