import React, { useEffect, useState } from 'react';
import './styles.scss';

interface FlipUnitProps {
  value: string;
  backgroundColor?: string;
  color?: string;
}

const FlipUnit: React.FC<FlipUnitProps> = ({ value, backgroundColor, color }) => {
  const [flipped, setFlipped] = useState(false);
  const [prevValue, setPrevValue] = useState(value);

  useEffect(() => {
    if (value !== prevValue) {
      setFlipped(true);
      const timer = setTimeout(() => {
        setFlipped(false);
		setPrevValue(value);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [value, prevValue]);


  return (
    <div className={`flip-unit ${flipped ? 'flip' : ''}`}>
      <div className="top" style={{ backgroundColor, color }}>{value}</div>
      <div className="top-flip" style={{ backgroundColor, color }}>{prevValue}</div>
      <div className="bottom" style={{ backgroundColor, color }}>{prevValue}</div>
      <div className="bottom-flip" style={{ backgroundColor, color }}>{value}</div>
    </div>
  );
};

export default FlipUnit;
