import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getRecaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error('Ejecutar recaptcha no disponible')
      return null;
    }

    try {
      const token = await executeRecaptcha('form_submit');
      return token;
    } catch (error) {
      console.error('Error ejecutando reCAPTCHA:', error);
      return null;
    }
  }, [executeRecaptcha]);

  return { getRecaptchaToken };
};

export default useRecaptcha;