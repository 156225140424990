import { BannerSlider } from 'components/organisms'
import { banner_data_centrado } from './data'
import './styles.scss'
import Section from 'components/molecules/section'

const TerminosyCondicionesLatamPass = () => {
  return (
    <>
      <BannerSlider data={banner_data_centrado} />
      <Section>
        <div className='terminos_condiciones_latam_pass terminos_condiciones_latam_pass-element'>
          <div className='terminos_condiciones_latam_pass__container'>
            <h1>Términos y Condiciones Latam Pass</h1>

            <p>
              Los siguientes Términos y Condiciones (en adelante, los
              “T&amp;C"), vigentes desde el 15 de marzo de 2024 rigen el
              programa de acumulación de Millas LATAM Pass en alianza con la
              cadena de hoteles <strong>Casa Andina</strong>, denominado
              “Programa LATAM Pass” (en adelante, el “Programa”). Su
              participación en este Programa estará sujeta a los presentes
              T&amp;C, por lo que lo invitamos a leerlos y comprenderlos en su
              totalidad.
            </p>

            <p>
              Si requiere alguna aclaración o mayor información, puede enviar un
              correo electrónico a la siguiente dirección:&nbsp;
              <a
                href='mailto:millaslatam@casa-andina.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                millaslatam@casa-andina.com
              </a>
              .{' '}
            </p>

            <p>
              Los términos “Socios”, “usted/es” y "suyo/s" se utilizan en los
              presentes T&amp;C para hacer referencia a los Socios individuales
              que están inscritos en el Programa y que han creado una Cuenta de
              Socio (en adelante, la “Cuenta”).
            </p>

            <p>Los T&amp;C describen la siguiente información principal:</p>

            <p>
              <strong>
                I.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ACERCA
                DEL PROGRAMA
              </strong>
            </p>
            <p>
              <strong>
                II.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DEFINICIONES
              </strong>
            </p>
            <p>
              <strong>
                III.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CÓMO INSCRIBIRSE
                EN EL PROGRAMA
              </strong>
            </p>
            <p>
              <strong>
                IV.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CÓMO ACUMULAR
                MILLAS LATAM PASS
              </strong>
            </p>
            <p>
              <strong>
                V.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOBRE EL
                SERVICIO DE ATENCIÓN AL SOCIO
              </strong>
            </p>
            <p>
              <strong>
                VI.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOBRE HOTELES
                ASOCIADOS CASA ANDINA
              </strong>
            </p>
            <p>
              <strong>
                VII.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OTROS TÉRMINOS Y CONDICIONES
                DE PARTICIPACIÓN EN EL PROGRAMA
              </strong>
            </p>

            <p>
              A continuación, el desarrollo de cada una de las secciones en
              mención.
            </p>

            <h3>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ACERCA
              DEL PROGRAMA
            </h3>

            <p>
              El Programa es administrado por Nessus Hoteles Perú S.A. (en
              adelante, <strong>“Casa Andina”</strong>), empresa que puede a su
              única discreción, designar ciertas tareas relacionadas con la
              administración del Programa a cualquiera de sus filiales o
              subsidiarias designadas. El Programa está administrado por{' '}
              <strong>Casa Andina</strong> en su propio nombre, así como el de
              sus filiales y los hoteles que operan bajo la marca{' '}
              <strong>Casa Andina</strong>, incluyendo aquellos pertenecientes a
              Nessus Hoteles Perú Selva S.A.
            </p>

            <p>
              Cabe precisar que, este Programa también considera a los Hoteles
              Asociados -conforme dicho término se encuentra definido en la
              Sección II siguiente- en los términos y condiciones particulares
              que se especifican en el punto V siguiente.
            </p>

            <p>
              Los presentes T&amp;C detallan las condiciones de la relación
              entre <strong>Casa Andina</strong> y los Socios del Programa
              (quienes serán denominados indistintamente “Socio(s) LATAM Pass” o
              simplemente “Socios”), incluida la forma en que los Socios
              obtienen Millas LATAM Pass. Se rigen por las leyes de la República
              del Perú y deben interpretarse en virtud de estas.
            </p>

            <p>
              Al colocar su número de socio para realizar una reserva a través
              de la web{' '}
              <a
                href='http://www.casa-andina.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                www.casa-andina.com
              </a>{' '}
              o a través del contact center en el correo{' '}
              <a
                href='mailto:centraldereservas@casa-andina.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                centraldereservas@casa-andina.com
              </a>{' '}
              o el teléfono +51 391-6500, usted manifiesta, a su vez, (i) que ha
              leído y acepta los presentes T&amp;C; y, (ii) que otorga su
              consentimiento para la recopilación, uso y divulgación de sus
              datos personales por parte de <strong>Casa Andina</strong> y el
              Programa, de acuerdo con las disposiciones establecidas en el
              presente documento y en nuestra Política de Protección de Datos
              Personales.
            </p>

            <p>
              La acumulación de Millas LATAM Pass en los Hoteles está sujeta a
              los términos y condiciones dispuestos por{' '}
              <strong>Casa Andina, </strong>quien puede cambiarlos sin previo
              aviso. El Programa no posee una fecha predeterminada de
              finalización y puede continuar hasta que{' '}
              <strong>Casa Andina</strong> decida finalizarlo total o
              parcialmente, en cuyo caso avisará a los Socios con una
              anticipación mínima de dos (02) meses, tiempo en el cual, los
              Socios dispondrán de dicho plazo para acumular millas.{' '}
            </p>

            <p>
              <strong>Casa Andina</strong> se reserva el derecho de añadir,
              limitar, modificar, corregir o eliminar cualquiera de los
              procedimientos, reglas, condiciones, o cualquier otro aspecto de
              los T&amp;C correspondientes a la Alianza, en cualquier momento,
              con o sin previo aviso, aun cuando dichos cambios pueden afectar
              al valor de las millas. Los Socios del Programa son responsables
              de conocer en todo momento los T&amp;C, cualquier política
              relacionada y cualquier cambio de estas. Su participación continua
              en el Programa constituirá su aceptación de dichas modificaciones.{' '}
            </p>

            <p>
              La acumulación, canje, uso y demás condiciones aplicables a los
              beneficios otorgados por LATAM Pass se rigen bajo su propio
              reglamento publicado en:{' '}
              <a
                href='https://latampass.latam.com/es_pe/descubre-latam-pass/terminos-y-condiciones'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://latampass.latam.com/es_pe/descubre-latam-pass/terminos-y-condiciones
              </a>
            </p>

            <h3>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;II.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DEFINICIONES
            </h3>

            <p>
              De manera previa al detalle sobre el funcionamiento del Programa,
              y en forma adicional a las definiciones que se incluyan dentro de
              los presente T&amp;C, lo invitamos a tener en consideración las
              siguientes definiciones:
            </p>

            <p>
              <strong>A.&nbsp;Alianza:</strong> se refiere a la acción comercial
              entre Casa Andina y LATAM Pass en la cual se otorgarán Millas
              LATAM Pass a los huéspedes de Casa Andina que se alojen en sus
              hoteles y cumplan con determinadas condiciones.{' '}
            </p>

            <p>
              <strong>
                B.&nbsp;Programa de Pasajero Frecuente, Programa LATAM Pass, o
                LATAM Pass:
              </strong>{' '}
              es el programa de pasajero frecuente de LATAM Airlines Group S.A.,
              mediante el cual, es posible acumular Millas LATAM Pass, Puntos
              Calificables y Segmentos Calificables, volando en todas las rutas
              operadas por LATAM Airlines y Líneas Aéreas Asociadas, así como
              utilizando los servicios de otras empresas asociadas; todo ello
              sujeto a las condiciones y requisitos que se detallan en:{' '}
              <a
                href='https://latampass.latam.com/es_pe/descubre-latam-pass/terminos-y-condiciones'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://latampass.latam.com/es_pe/descubre-latam-pass/terminos-y-condiciones
              </a>{' '}
            </p>

            <p>
              <strong>C.&nbsp;Socio del programa LATAM Pass o socio:</strong> es
              la persona que cumple con los requisitos del programa, que ha
              completado y entregado el formulario de inscripción a través de
              cualquiera de las formas establecidas en los términos y
              condiciones del Programa LATAM Pass, cuya solicitud ha sido
              aceptada por LATAM Airlines y que su calidad de socio no ha sido
              revocada o dejada sin efecto conforme con las normas de su
              reglamento. El socio del programa tendrá una cuenta en donde
              acumulará Millas LATAM Pass, Puntos Calificables y Segmentos
              Calificables. La cuenta es unipersonal, reconociendo un único
              titular, no admitiendo la posibilidad de socios adicionales.
            </p>

            <p>
              <strong>D.&nbsp;Millas LATAM Pass:</strong> son la unidad de
              medida del programa LATAM Pass y sus características se definen
              los términos y condiciones del Programa LATAM Pass. Son canjeables
              por pasajes u otros servicios que determine el programa según sus
              términos y condiciones. LATAM Airlines se reserva el derecho de
              modificar dicha denominación, unilateralmente, en cualquier
              momento, notificando oportunamente a los Socios en caso de darse
              dicho cambio de denominación.
            </p>

            <p>
              <strong>E.&nbsp;Número de Socio:</strong> Número asignado al Socio
              en su Tarjeta LATAM Pass la cual acredita su calidad se socio y se
              emite en formato digital al momento de su inscripción, sin
              necesidad de solicitarla, con su nombre y un número único el cual
              lo identificará en el Programa. Dicha tarjeta se descarga a través
              de la aplicación LATAM Airlines (iOS o Android).
            </p>

            <p>
              <strong>
                F.&nbsp;&nbsp;Comercios Asociados e Instituciones Financieras
                Asociadas:
              </strong>{' '}
              son todas las empresas participantes en el programa LATAM Pass,
              excepto las Líneas Aéreas Asociadas, cuyos servicios permiten
              acumular Millas LATAM Pass y/o disfrutar los premios o beneficios
              del programa LATAM Pass; ello sujeto a las condiciones y
              requisitos de su propio reglamento y a las que dichas empresas
              señalen.
            </p>

            <p>
              <strong>G.&nbsp;Canal(es) de Casa Andina</strong>: están
              conformados por el Sitio Web, correos electrónicos, central de
              llamadas o comunicaciones dirigidas directamente a los contactos
              publicados en la Central de Reservas de{' '}
              <strong>Casa Andina</strong>.{' '}
            </p>

            <p>
              <strong>H.&nbsp;Cargos a la Habitación Acumulables</strong>: Los
              <strong></strong>consumos en los que el Socio ha incurrido durante
              las Noches Acumulables, que sean cargados en su cuenta por las
              habitaciones de las cuales sea Titular de la Reserva, según se
              detalla en el numeral 4.2.
            </p>

            <p>
              <strong>I.&nbsp;&nbsp;&nbsp;Hotel(es):</strong> son las unidades
              hoteleras y otros establecimientos de estadía provisional que
              actúan bajo la marca <strong>Casa Andina</strong>{' '}
              <strong>
                <em>(“Standard”, “Select” o “Premium”)</em>
              </strong>{' '}
              registrado bajo la razón social NESSUS HOTELES PERÚ S.A. o NESSUS
              HOTELES PERÚ SELVA S.A. Los alojamientos en estos hoteles se
              pueden reservar mediante los Canales de{' '}
              <strong>Casa Andina</strong>.
            </p>

            <p>
              <strong>J.&nbsp;&nbsp;Hotel(es) Asociado(s):</strong> son las
              unidades hoteleras y otros establecimientos de estadía provisional
              operados por terceros que, han sido seleccionados por CASA ANDINA
              para pertenecer a la red de asociados de CASA ANDINA. Para tal
              efecto, éstos deberán haber suscrito con CASA ANDINA el Contrato
              de Afiliación a la Red de Asociados de CASA ANDINA (en adelante,
              el “Contrato de Afiliación"), cuya alianza comercial se detalla en
              la Sección V siguiente del presente Programa, y, por el cual,
              podrán utilizar la marca “Hoteles Asociados Casa Andina” de
              propiedad de Nessus Hoteles Perú S.A. y Nessus Hoteles Perú Selva
              S.A. El alojamiento en los Hoteles Asociados se puede reservar
              mediante los Canales de <strong>Casa Andina</strong>.
            </p>

            <p>
              <strong>K.&nbsp;Noches Acumulables:</strong> Serán consideradas
              Noches Acumulables, todas las noches de alojamiento en
              <strong>Casa Andina</strong> y/o en los Hoteles Asociados, de un
              Socio, siendo este Titular de la Reserva, y que la reserva sea
              solicitada mediante un Canal de <strong>Casa Andina</strong>,
              según se detalla en el numeral 4.3.
            </p>

            <p>
              <strong>L.&nbsp;&nbsp;Sitio web de Casa Andina:</strong> es el
              sitio web operado por <strong>Casa Andina</strong> o en nombre de
              esta. Actualmente, responde al siguiente sitio web:{' '}
              <a
                href='http://www.casa-andina.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                www.casa-andina.com
              </a>
              .
            </p>

            <p>
              <strong>M.&nbsp;Sitio web del Programa:</strong> es el sitio web
              operado LATAM Airlines o en nombre de esta, exclusivamente para
              los fines del Programa. Actualmente, responde al siguiente sitio
              web:{' '}
              <a
                href='https://latampass.latam.com/es_pe/'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://latampass.latam.com/es_pe/
              </a>
              &nbsp;
            </p>

            <p>
              <strong>N.&nbsp;Reservas No Acumulables</strong>:
            </p>
            <p>Son aquellas reservas realizadas:</p>

            <p>
              (i)&nbsp;&nbsp;&nbsp;Mediante un tercero o una plataforma no
              perteneciente a los Canales de <strong>Casa Andina</strong>, ya
              sea un operador turístico o canal de viajes online (OTA).
            </p>
            <p>
              (ii)&nbsp;&nbsp;&nbsp;Mediante un operador turístico o mayorista,
              o cualquier tarifa corporativa y tarifas para tripulaciones
              aéreas.
            </p>
            <p>(iii)&nbsp;&nbsp;Con tarifas de series o tarifas layover </p>
            <p>
              (iv)&nbsp;&nbsp;Con tarifas especiales, como por ejemplo con
              descuentos de colaborador, agente de viajes, guías turísticos,
              entre otros.
            </p>
            <p>(v)&nbsp;&nbsp;&nbsp;A manera de cortesía o canje.</p>
            <p>
              (vi)&nbsp;&nbsp;Si el socio LATAM Pass no se presenta a su reserva
              (condición No-Show). Acumulará Millas LATAM Pass por las reservas
              que se encuentren en condición de pagada.{' '}
            </p>
            <p>
              (vii)&nbsp;Reservas realizadas directamente en la recepción de
              cualquiera de los Hoteles Casa Andina u Hoteles Asociados
              (Walk-in).
            </p>

            <p>
              <strong>O.&nbsp;&nbsp;&nbsp;Titular de la Reserva</strong>:
              Persona a nombre de la cual se realiza la reserva, para uso
              personal de una habitación y se registra como tal al momento de la
              llegada en el hotel (también llamado check-in).{' '}
            </p>

            <p>
              <strong>P.&nbsp;&nbsp;&nbsp;Viajes Personales</strong>: aquellas
              estadías por motivo de ocio y/o que se facturan directamente al
              Socio, es decir, que no se facturan a una persona jurídica.
            </p>

            <h3>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;III.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CÓMO
              INSCRIBIRSE EN EL PROGRAMA
            </h3>

            <p>
              <strong>3.1.&nbsp;&nbsp;&nbsp;ELEGIBILIDAD.</strong>
            </p>

            <p>
              La membresía del Programa se encuentra disponible de manera
              gratuita en el Sitio Web del Programa para cualquier persona que
              cumpla con los requisitos indicados en sus términos y condiciones,
              numeral 2, detallados en:{' '}
              <a
                href='https://latampass.latam.com/es_pe/descubre-latam-pass/terminos-y-condiciones'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://latampass.latam.com/es_pe/descubre-latam-pass/terminos-y-condiciones
              </a>{' '}
            </p>

            <p>
              <strong>
                3.2.&nbsp;&nbsp;&nbsp;PROCEDIMIENTO DE INSCRIPCIÓN COMO SOCIO.
              </strong>
            </p>

            <p>
              Cualquier persona que cumpla con los requisitos de elegibilidad
              podrá presentar su solicitud para inscribirse en el Programa a
              través de una solicitud en el Sitio Web del Programa de forma
              completa y válida. Esta solicitud será respondida oportunamente
              por LATAM Airlines.
            </p>

            <p>
              Una vez aprobada la inscripción, se le asignará al nuevo Socio una
              Tarjeta LATAM Pass de manera automática con su nombre y Número de
              Socio con los cuales se encontrará apto para la obtención de
              Millas LATAM Pass.
            </p>

            <p>
              <strong>
                3.3.&nbsp;&nbsp;&nbsp;PRINCIPALES DEBERES DE LOS SOCIOS.
              </strong>
            </p>

            <p>
              Los Socios son responsables de leer y entender los T&amp;C, la
              Política de Protección de Datos Personales de{' '}
              <strong>Casa Andina</strong>, así como otros comunicados de{' '}
              <strong>Casa Andina</strong> relacionados al Programa, a fin de
              comprender sus derechos, beneficios, y responsabilidades.{' '}
            </p>

            <p>
              Asimismo, cada Socio es responsable de mantener la seguridad de
              sus datos de acceso a la Cuenta y de notificar a LATAM Airlines
              cualquier cambio en la información de contacto. En ningún caso{' '}
              <strong>Casa Andina</strong> será responsable de reembolsar Millas
              canjeadas u otros daños o pérdidas producidas como consecuencia de
              un acceso no autorizado.{' '}
            </p>

            <p>
              Los Socios son responsables, en todo momento, de consignar de
              manera correcta su número de Socio para realizar sus reservas
              según el canal:{' '}
            </p>

            <p>
              (i)&nbsp;&nbsp;Reservas por la web: deberá incluir su número de
              socio al momento de ingresar todos los datos de su reserva,
              durante el último paso de su compra en el campo “Código de Socio
              LATAM Pass”. Este código de socio se deberá ingresar para cada
              habitación que genera la reserva. En caso de haber más de un Socio
              LATAM Pass dentro de la reserva, se deberá indicar el número de
              socio correspondiente por cada habitación.{' '}
            </p>

            <p>
              (ii)&nbsp;Reservas por el contact center: Al momento de generar su
              reserva deberá indicar su número de socio en el correo electrónico
              enviado a{' '}
              <a
                href='mailto:centraldereservas@casa-andina.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                centraldereservas@casa-andina.com
              </a>{' '}
              o a través de la central telefónica +51 391-6500. En caso de que
              haya más de una habitación dentro de dicha reserva, el Socio
              deberá indicar el Número de Socio para cada habitación.
            </p>

            <p>
              En el caso de que el Socio no consigne la información de forma
              correcta, no podrá reclamar las Millas LATAM Pass correspondientes
              a esa reserva.
            </p>

            <p>
              <strong>
                3.4.&nbsp;&nbsp;&nbsp;OTRAS DISPOSICIONES RELATIVAS A LA
                MEMBRESÍA.
              </strong>
            </p>

            <p>
              Casa Andina no es responsable de enviar ningún tipo de
              comunicación y/o estado de cuenta en relación a la acumulación de
              las Millas que los socios generen. Esta información será
              proporcionada directamente por el Programa LATAM Pass al correo
              consignado al momento del registro. Para cualquier información
              relacionada a la acumulación de Millas, beneficios propios del
              programa, entre otros; el socio deberá comunicarse a través de los
              canales disponibles y publicados en el Sitio Web del Programa.{' '}
            </p>

            <p>
              <strong>Casa Andina</strong> se reserva el derecho de asignar las
              Millas LATAM Pass a los socios, en cualquier momento con efecto
              inmediato y sin notificación previa, cuando identifique indicios
              de prácticas contrarias a los T&amp;C o al objetivo del Programa,
              incluidas entre otros, (i) el incumplimiento de normas locales
              vigentes; (ii) la violación de alguna disposición de los T&amp;C,
              como la venta o comercialización de los Millas, premios y
              beneficios; (iii) la participación en alguna conducta fraudulenta,
              deshonesta o delictiva vinculada al programa; (iv) la
              participación en alguna conducta abusiva, fraudulenta,
              irrespetuosa, no apropiada, ofensiva u hostil, de cualquier
              naturaleza, hacia algún Hotel, sus empleados o huéspedes; y, (v)
              la falta de pago de los consumos realizados en cualquier Hotel,
              después de haberse retirado del mismo. En estos casos, la
              cancelación dará lugar a la pérdida de todos las millas
              acumuladas. Sin perjuicio de ello,
              <strong>Casa Andina</strong> se reserva el derecho de ejercer
              cualquier derecho o acción legal que corresponda por las
              situaciones descritas en el párrafo anterior.
            </p>

            <p>
              Los Millas LATAM Pass no tienen valor en efectivo y{' '}
              <strong>Casa Andina</strong> no compensará ni pagará en efectivo
              por ninguna Milla perdida o no utilizada.
            </p>

            <p>
              Cada vez que un Socio se ponga en contacto con el servicio de
              atención al usuario de <strong>Casa Andina</strong>, esta puede a
              su plena discreción realizarle ciertas preguntas de seguridad para
              verificar la identidad del Socio y supervisar o grabar las
              llamadas telefónicas para mejorar la calidad el Programa.
            </p>

            <h3>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IV.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CÓMO
              ACUMULAR MILLAS LATAM PASS
            </h3>

            <p>
              <strong>
                4.1.&nbsp;&nbsp;&nbsp;Reglas para la obtención de Millas LATAM
                Pass
              </strong>
            </p>

            <p>
              Para acumular Millas LATAM Pass, el Socio deberá realizar Cargos a
              la Habitación Acumulables. Por cada $2.50 dólares pagados por el
              Socio (descontando tasas, impuestos y servicios adicionales) en
              Cargos a la Habitación Acumulables dentro de los Hoteles, este
              ganará 1 Milla LATAM Pass.{' '}
            </p>

            <p>
              Para calcular las Millas LATAM Pass para Cargos a la Habitación
              Acumulables que se paguen en divisas distintas de los dólares
              estadounidenses, <strong>Casa Andina</strong> convertirá primero
              el importe del pago a dicha moneda usando el tipo de cambio del
              día de cada Hotel. Luego, calculará los puntos tomando como
              referencia el importe del pago convertido.
            </p>

            <p>
              Un socio podrá acumular Millas LATAM Pass por todas las
              habitaciones que tengan su número de socio asignadas, siempre y
              cuando hayan sido consignadas oportunamente al momento de realizar
              la reserva.
            </p>

            <p>
              <strong>
                4.2.&nbsp;&nbsp;&nbsp;Respecto a los Cargos a la Habitación
                Acumulables:
              </strong>
            </p>

            <p>
              Los consumos en los que el Socio ha incurrido durante Noches
              Acumulables, que sean cargados en su cuenta de la habitación de la
              que es Titular de la Reserva. Estos cargos dependerán del Hotel
              del cual se trate y son:
            </p>

            <p>
              <strong>Hoteles operados bajo la marca Casa Andina:</strong>
            </p>

            <p>
              (i)&nbsp;&nbsp;&nbsp;&nbsp;Importe pagado por la Reserva de la
              Habitación.
            </p>
            <p>
              (ii)&nbsp;&nbsp;Consumos en alimentos y bebidas en restaurantes y
              bares propios del Hotel, incluyendo el servicio a la Habitación
              (Room Service), solo si estos están asociados a la cuenta de la
              habitación.
            </p>

            <p>
              <strong>En Hoteles Asociados:</strong>
            </p>

            <p>
              (i)&nbsp;&nbsp;&nbsp;Solo se considerará el importe pagado por la
              Reserva de la Habitación
            </p>

            <p>
              Por el contrario, no serán considerados Cargos a la Habitación
              Acumulables, aquellos que incluyan servicios gratuitos, vinculados
              a servicios ya incluidos en la tarifa; por compras realizadas en
              locales de terceros; propinas al personal; cargos por cancelación
              tardía o por no presentarse (siempre y cuando no hayan sido
              prepagados); ni cargos obligatorios o automáticos.
            </p>

            <p>
              El Recargo al Consumo (servicios o propinas) y los impuestos y
              tasas no son montos válidos para acumular Puntos.
            </p>

            <p>
              <strong>
                4.3.&nbsp;&nbsp;&nbsp;Respecto a las Noches Acumulables:
              </strong>
            </p>

            <p>
              Serán consideradas Noches Acumulables, todas las noches de
              alojamiento en <strong>Casa Andina</strong> u Hoteles Asociados de
              un Socio, siendo este Titular de la Reserva, y que la reserva sea
              solicitada mediante un Canal de <strong>Casa Andina</strong>. No
              acumulan puntos las Reservas No Acumulables, que son aquellas
              reservas realizadas como se detalla a continuación:
            </p>

            <p>
              (i)&nbsp;&nbsp;Mediante un tercero o una plataforma no
              perteneciente a los Canales de <strong>Casa Andina</strong>, ya
              sea un operador turístico o canal de viajes online (OTA).
            </p>
            <p>
              (ii)&nbsp;&nbsp;Mediante un operador turístico o mayorista, o
              cualquier tarifa corporativa y tarifas para tripulaciones aéreas.
            </p>
            <p>(iii)&nbsp;&nbsp;Con tarifas de series o tarifas layover </p>
            <p>
              (iv)&nbsp;&nbsp;Con tarifas especiales, como por ejemplo con
              descuentos de colaborador, agente de viajes, guías turísticos,
              entre otros.
            </p>
            <p>(v)&nbsp;&nbsp;&nbsp;A manera de cortesía o canje.</p>
            <p>
              (vi)&nbsp;&nbsp;Si el socio LATAM Pass no se presenta a su reserva
              (condición No-Show). Acumulará Millas LATAM Pass por las reservas
              que se encuentren en condición de pagada.{' '}
            </p>
            <p>
              (vii)&nbsp;&nbsp;Reservas realizadas directamente en la recepción
              de cualquiera de los Hoteles Casa Andina u Hoteles Asociados
              (Walk-in).
            </p>

            <p>
              Un Socio solo puede obtener Millas LATAM Pass los consumos
              asignados a su habitación durante su alojamiento, siempre y
              cuando: (i) haya indicado su número de socio y/o los números de
              socio a acumular al momento de realizar su reserva en cualquiera
              de los Canales Casa Andina; y, (ii) el Socio sea Titular de la
              Reserva. Asimismo, los Socios no pueden obtener ni recibir
              beneficios en varios Hoteles por la misma fecha de alojamiento.
            </p>

            <p>
              Las Millas LATAM Pass se asignarán de forma mensual durante la
              primera semana de cada mes, siendo esta asignación correspondiente
              al mes anterior y considerando únicamente aquellas reservas que ya
              cuenten con registro de salida (Check Out). Si el Socio no se
              presenta a su reserva (condición de No-Show), acumulará Millas
              únicamente si dicha reserva se encuentra en condición pagada y es
              Titular de la misma.{' '}
            </p>

            <p>
              Si un Hotel deja de ser parte de la cadena
              <strong>Casa Andina</strong>, o deja de ser asociado a esta, todas
              las estadías posteriores a la fecha en que ello ocurra no
              cumplirán los requisitos para obtener Millas LATAM Pass,
              independientemente de la fecha en que se realizó la reserva.
            </p>

            <p>
              <strong>4.4.&nbsp;&nbsp;&nbsp;Carácter intransferible.</strong>
            </p>

            <p>
              Las Millas LATAM Pass acumuladas por un Socio en Casa Andina o sus
              Hoteles Asociados son intransferibles. Es decir, estas Millas
              serán asignadas únicamente al Socio Titular de la Reserva. Sin
              embargo, una vez que estas hayan sido cargadas a la cuenta del
              Socio, este podrá disponer de las Millas según los términos y
              condiciones, numeral 7 del programa LATAM Pass como se detallada
              en:{' '}
              <a
                href='https://latampass.latam.com/es_pe/descubre-latam-pass/terminos-y-condiciones'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://latampass.latam.com/es_pe/descubre-latam-pass/terminos-y-condiciones
              </a>{' '}
            </p>

            <h3>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;V.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOBRE
              EL SERVICIO DE ATENCIÓN AL SOCIO
            </h3>

            <p>
              <strong>Casa Andina</strong> pone a disposición del Socio la
              dirección de correo electrónico:{' '}
              <a
                href='mailto:millaslatam@casa-andina.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                millaslatam@casa-andina.com
              </a>{' '}
              para que pueda realizar consultas sobre el Programa y la
              acumulación de Millas LATAM Pass en los hoteles.&nbsp;&nbsp;
            </p>

            <p>
              El socio podrá solicitar las Millas LATAM Pass faltantes en su
              perfil a través del correo de atención al Socio con un máximo de
              90 días calendario después de su fecha de salida del hotel o
              check-out.
            </p>

            <p>
              Solo podrá solicitar la corrección o asignación de Millas LATAM
              Pass de acuerdo a los términos y condiciones del programa. De ser
              necesario, el equipo de atención al cliente de Casa Andina podrá
              derivar al socio para que siga su caso directamente con el equipo
              de LATAM Pass a través del teléfono +51 231-8200
            </p>

            <h3>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VI.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOBRE
              HOTELES ASOCIADOS CASA ANDINA.
            </h3>

            <p>
              Los Hoteles Asociados, conforme se indicó en la Sección II
              anterior, son las unidades hoteleras y otros establecimientos de
              estadía provisional que son operados por terceros y que han sido
              seleccionados por CASA ANDINA para pertenecer a la red de
              asociados de CASA ANDINA suscribiendo para tal efecto el Contrato
              de Afiliación a la Red de Asociados de CASA ANDINA.{' '}
            </p>

            <p>
              En virtud de esta alianza comercial, (el/los) Hotel(es)
              Asociado(s) seguirán siendo operados por terceros bajo la marca
              “Hoteles Asociados Casa Andina” de propiedad de Nessus Hoteles
              Perú S.A. y Nessus Hoteles Perú Selva S.A. y deberán cumplir
              durante el período de tiempo que pertenezcan a la red de asociados
              de CASA ANDINA con los atributos que se detallan a continuación:
              servicio de Wifi, habitación y baño impecable, desayuno americano,
              TV y cable, kit de aseo personal y/o amenities (en adelante, los
              “Atributos”), mientras que Casa Andina, tiene a su cargo de forma
              exclusiva la gestión y comercialización de las Ventas y Reservas
              de la unidad hotelera asociada mediante los Canales de Casa
              Andina.
            </p>

            <h3>
              &nbsp;&nbsp;&nbsp;&nbsp;VII.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OTROS
              TÉRMINOS Y CONDICIONES DE PARTICIPACIÓN EN EL PROGRAMA
            </h3>

            <p>
              <strong>
                7.1.&nbsp;&nbsp;&nbsp;Política de Protección de Datos
                Personales.
              </strong>
            </p>

            <p>
              La información que un Socio proporciona a
              <strong>Casa Andina</strong> al realizar sus reservas se procesa
              de acuerdo con la Política de Protección de Datos Personales de{' '}
              <strong>Casa Andina</strong>. Contar con esta información personal
              relevante es importante para administrar el Programa y para que
              cada Socio pueda acumular Millas LATAM Pass de manera correcta.{' '}
            </p>

            <p>
              Para llevar a cabo las finalidades descritas en la presente
              política de privacidad, es obligatorio nos proporcione los
              siguientes datos personales: nombre y apellidos, número del
              documento nacional de identidad o número de pasaporte, carnet de
              extranjería, dirección de correo electrónico, género, número de
              celular y número de Socio LATAM Pass. No utilizaremos datos
              sensibles. De no proporcionar los datos antes mencionados, no se
              le podrá brindar el servicio.
            </p>

            <p>
              De conformidad con lo dispuesto en la Ley N° 29733, Ley de
              Protección de Datos Personales y su Reglamento aprobado por
              Decreto Supremo N° 003-2013-JUS,
              <strong>Casa Andina</strong> informa que los datos personales
              proporcionados serán incluidos en el Banco de datos de “(*)”
              inscrito en el Registro Nacional de Protección de Datos Personales
              con el código RNPDP N° (*) de titularidad de Nessus Hoteles Perú
              S.A. (“<strong>Casa Andina</strong>”) ubicado en Av. La Paz N°
              463, Miraflores, Lima. Los datos serán conservados hasta que el
              titular del dato personal revoque su consentimiento.{' '}
              <strong>Casa Andina</strong> podrá tratar los datos personales
              proporcionados a fin de (i) mantener informado al Socio sobre el
              estado del programa y cualquier cambio que Casa Andina considere
              pertinente comunicar; (ii) evaluar si reúne los requisitos para
              acceder a la acumulación de Millas LATAM Pass; (iii) acumular y
              procesar los cargos incurridos por el Socio en los Hoteles; (iv)
              ofrecer al Socio productos y servicios adicionales; (v) para
              enviarle periódicamente encuestas de satisfacción o de estudio de
              mercado; (vi) ejecutar el Programa; y/o (vii) enviar publicidad y
              promociones comerciales{' '}
            </p>

            <p>
              Para tales efectos, <strong>Casa Andina</strong> les informa que
              sus datos son compartidos con las siguientes personas, empresas,
              entidades públicas distintas a nosotros: a: (i) Orion Perú SAC.,
              empresa encargada de brindar el servicio de gestión de
              instalaciones informáticas; (ii) Amazon S.L, empresa encargada de
              brindar el servicio de alojamiento en nube. Ubicación: Seattle;
              Washington, Estados Unidos (iii) Newhotel, software encargado de
              brindar el servicio de almacenamiento y envío de datos personales
              a las empresas MyHotel (San Francisco, California, Estados Unidos)
              y MyHotel (Vitacura, Santiago, Chile) a efectos de realizar
              encuestas en relación con la calidad del servicio brindado por{' '}
              <strong>Casa Andina</strong>; (iv) Ahora es Travelclick, empresa
              encargada de brindar el servicio de motor de reservas. Ubicación:
              New York, Estados Unidos; (v) Pagos Online Perú SAC, empresa
              encargada de proveer el servicio de pagos en línea. Ubicación: Cl.
              99 #14 49, Bogotá, Colombia y, (vi) LATAM Airlines (incluir info).
              Como usted podrá advertir, algunos de los servicios antes
              indicados son prestados por proveedores de servicios ubicados
              fuera del país, siendo que dicho tratamiento de datos personales
              se efectúa conforme a la normativa sobre protección de datos
              personales.
            </p>

            <p>
              <strong>Casa Andina</strong> no revelará sus datos personales a
              ningún otro tercero con excepción a los antes indicados. Los datos
              suministrados por usted son esenciales para las finalidades antes
              indicadas. Usted podrá revocar su consentimiento o ejercer los
              derechos de acceso, rectificación, oposición o cancelación
              remitiendo una comunicación a la siguiente dirección:{' '}
              <a
                href='mailto:protecciondedatos@casa-andina.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                protecciondedatos@casa-andina.com
              </a>{' '}
              y{' '}
              <a
                href='mailto:millaslatam@casa-andina.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                millaslatam@casa-andina.com
              </a>{' '}
              o ingresando al siguiente enlace web{' '}
              <a
                href='https://www.casa-andina.com/arco/'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://www.casa-andina.com/arco/
              </a>
              &nbsp;
            </p>

            <p>
              De considerar que no ha sido atendido en el ejercicio de sus
              derechos puede presentar una reclamación ante la Autoridad
              Nacional de Protección de Datos Personales.
            </p>

            <p>
              Con la solicitud de inscripción en el Programa y su permanencia en
              el mismo, manifiesta encontrarse informado del tratamiento de sus
              Datos Personales por
              <strong>Casa Andina</strong> para las finalidades mencionadas.
            </p>

            <p>
              <strong>7.2.&nbsp;&nbsp;&nbsp;Actividad de supervisión.</strong>
            </p>

            <p>
              <strong>Casa Andina</strong> se reserva el derecho a supervisar la
              acumulación de Millas LATAM Pass acumuladas en los Hoteles, en
              cualquier momento y sin previo aviso, para asegurarse de que se
              estén cumpliendo los presentes T&amp;C y validez de los Millas
              acumuladas.{' '}
            </p>

            <p>
              En cualquier momento y a entera discreción de
              <strong>Casa Andina</strong>, esta puede corregir la cantidad de
              Millas LATAM Pass que cumplan los requisitos que aparecen como
              acreditadas en la cuenta de un Socio.
            </p>

            <p>
              <strong>7.3.&nbsp;&nbsp;&nbsp;Otras disposiciones.</strong>
            </p>

            <p>
              Si un Hotel abandona el Programa, el Socio no acumulará Millas
              LATAM Pass, por sus alojamientos en el mismo, incluso si se
              hubiese realizado una reserva antes de la fecha de abandono.
            </p>

            <p>
              <strong>Casa Andina</strong> no ofrece garantías ni declaraciones,
              ya sean explícitas o implícitas, con respecto a la idoneidad,
              tipo, calidad o aptitud de los bienes o servicios proporcionados a
              través del Programa.
            </p>

            <p>
              Todas las controversias que surjan del uso o en relación con el
              Programa serán tratadas de manera individual, sin recurrir a
              ninguna forma de acción colectiva, y serán regidas, interpretadas
              y ejecutadas conforme a las leyes de la República del Perú.
              Cualquier reclamo o acción que pueda surgir en torno al presente
              Programa, será resuelto en la jurisdicción exclusiva de los
              tribunales judiciales ordinarios de la República del Perú.
            </p>

            <p>
              Los presentes T&amp;C, juntos con las políticas que se mencionan
              en él, constituyen la totalidad del acuerdo entre{' '}
              <strong>Casa Andina</strong> y los Socios con relación al
              Programa, el cual no podrá ser objeto de enmienda o modificación,
              salvo que se haga por escrito o que dichas enmiendas o
              modificaciones estén disponibles en el Sitio Web del Programa.
            </p>
          </div>
        </div>
      </Section>
    </>
  )
}

export default TerminosyCondicionesLatamPass
