import { Icon, Text } from "components/atoms";
import "./styles.scss";
import CountdownTimer from "components/molecules/countdownTimer";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface ICountdownProps {
  color_promocion: string;
  color_secundario: string;
  color_fuente: string;
  contador: string;
  logo: {
    path: string;
    alt: string;
  };
  mensaje: string;
  mensaje_segundo: string;
  nombre: string;
  redireccion: string;
  inicio_de_promocion: boolean;
}

interface IBannerChronometer {
  onClose: () => void;
  countdown: ICountdownProps | null;
}

const BannerChronometer = ({ onClose, countdown }: IBannerChronometer) => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const shouldShowPopup = !(
    location.pathname == countdown?.redireccion
  );

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
  }, []);

  return (
    <>
      {(countdown !== null && shouldShowPopup) && (
        <div
          className="bannerchronometer"
          style={{ backgroundColor: countdown?.color_promocion, color: countdown?.color_fuente }}
        >
          <div className="bannerchronometer__container" style={{ display: isScrolled ? "none" : "" }}>
            <div className="bannerchronometer__container--img">
              {countdown?.logo && (
                <img src={countdown.logo?.path} alt={countdown.logo?.alt} />
              )}
            </div>

            <div className="bannerchronometer__container--text">
              <Text
                type="paragraph"
                text={countdown?.mensaje}
                className="title"
              />
              <Text
                type="paragraph"
                text={countdown?.mensaje_segundo}
                className="title-secondary"
              />
            </div>

            <CountdownTimer
              contador={countdown?.contador}
              colorBg={countdown?.color_secundario || "#3300E5"}
              colorFuente={countdown?.color_fuente || "white"}
            />

            {countdown?.inicio_de_promocion && (
              <div className="button-close">
                <button
                  className="redirect gold"
                  onClick={() => navigate(countdown?.redireccion)}
                  style={{ backgroundColor: countdown?.color_secundario, color: countdown?.color_fuente }}
                >
                  {t("bannerTiraPromocion.buttonText")}
                </button>
              </div>
            )}

            <button
              onClick={onClose}
              className="close"
              style={{ color: countdown?.color_fuente || "#3300E5" }}
            >
              <Icon name="close" list="items" width={18} height={18} color={countdown?.color_secundario} />
            </button>
          </div>
        </div>
      )}

      {
        (<div
          className={`chronometer-popup ${isScrolled ? "is-scrolled" : ""}`}
          style={{ backgroundColor: countdown?.color_promocion, color: countdown?.color_fuente, display: shouldShowPopup ? "" : "none" }}
        >
          <div onClick={() => navigate(countdown?.redireccion ?? '/')}>
            <div className="bannerchronometer__container--img">
              {countdown?.logo && (
                <img src={countdown.logo?.path} alt={countdown.logo?.alt} />
              )}
            </div>

            <CountdownTimer
              contador={countdown?.contador}
              colorBg={countdown?.color_secundario || "#3300E5"}
              colorFuente={countdown?.color_fuente || "white"}
              isPopup={true}
            />
          </div>

          <button
            onClick={onClose}
            className="close"
            style={{ backgroundColor: countdown?.color_secundario }}
          >
            <Icon name="close" list="items" width={20} height={20} color={countdown?.color_fuente} />
          </button>
        </div>
        )}
    </>
  );
};

export default BannerChronometer;
