import { Link } from "react-router-dom";
import { Button, Card, Icon, Text } from "components/atoms";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.scss";
import { messages } from "utils/lang";
import { useIntl } from "react-intl";
import { Animations } from "components/molecules";

const SectionCardsTypes = ({ data, type, title, isTag }: any) => {
  const { locale } = useIntl();

  const itemsForLoop = data.map((item: any) => item.id);

  const promotionsType = () => {
    switch (type) {
      case "promotions": {
        return (
          <div className="sectioncardstypes py-3-5rem">
            <div className="sectioncardstypes__container">
              <div className="px-1rem">
                <Text type="subtitle" text={title} family={"tsection"} />
              </div>

              <div className="sectioncardstypes__container--description">
                <div className="ismobile">
                  <Swiper
                    slidesPerView={"auto"}
                    loop={itemsForLoop.length > 3}
                    autoplay={{
                      delay: 7000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className="sectioncardstypes__container--description--swiper"
                  >
                    {data.map((item: any, index: number) => (
                      <SwiperSlide key={index}>
                        <Card type="select" data={item} />
                      </SwiperSlide>
                    ))}
                    <SwiperSlide>
                      <div
                        className="promoselect nlink"
                        style={{ backgroundColor: "#4E7247" }}
                      >
                        <div className="promoselect__promo">
                          <div className="promoselect__promo--final">
                            <div className="btn">
                              <Icon name="next" list="arrows" />
                            </div>
                            <Text
                              type="paragraph"
                              text={messages[locale].findTheBestDiscounts}
                              family={"titlecarddiscount"}
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>

                <div className="isdesktop">
                  <div className="sectioncardstypes__container--description--web">
                    {data.map((item: any, index: number) => (
                      <Card type="select" data={item} key={index} />
                    ))}
                    <div className="final">
                      <Text
                        type="subtitle"
                        text={"Tu siguiente aventura está aquí"}
                        family={"txnormal"}
                      />
                      <Text
                        type="subtitle"
                        text={messages[locale].findTheBestDiscounts}
                        family={"txnormal"}
                      />
                      <Button type="gold">Ver más ofertas</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      case "relax": {
        return (
          <div className="promotionsrelax py-3-5rem">
            <div className="promotionsrelax__container">
              <div className="px-1rem">
                <Text type="subtitle" text={title} family={"tsection"} />
              </div>

              <div className="promotionsrelax__container--description">
                <Swiper
                  slidesPerView={"auto"}
                  grabCursor={true}
                  navigation={true}
                  className="promotionsrelax__container--description--swiper"
                >
                  {data.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <Card type="relax" data={item} />
                    </SwiperSlide>
                  ))}
                  <SwiperSlide>
                    <div
                      className="promosrelax jc-center nlink"
                      style={{ backgroundColor: "#252424" }}
                    >
                      <div className="promosrelax__promo">
                        <div className="promosrelax__promo--final">
                          <div className="btn">
                            <Icon name="next" list="arrows" />
                          </div>
                          <Text
                            type="paragraph"
                            text="La mejor experiencia de relajación, busca tu paquete ideal."
                            family={"titlecarddiscount"}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        );
      }
      case "hoteles": {
        return (
          <div className="promotionshotels py-3-5rem">
            <div className="promotionshotels__container">
              <div className="px-1rem">
                <Text type="subtitle" text={title} family={"tsection"} />
              </div>

              <div className="promotionshotels__container--description">
                <Swiper
                  slidesPerView={"auto"}
                  grabCursor={true}
                  navigation={true}
                  className="promotionshotels__container--description--swiper"
                >
                  {data.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <Card type="hotel" data={item} />
                    </SwiperSlide>
                  ))}
                  <SwiperSlide>
                    <Link className="card food" to={"/"}>
                      <div>
                        <div className="cardfood--final">
                          <div className="btn">
                            <Icon name="next" list="arrows" />
                          </div>
                          <Text
                            type="paragraph"
                            text="Fascinantes destinos de Perú te esperan"
                            family={"titlecarddiscount"}
                          />
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        );
      }
      case "comida": {
        return (
          <div className="promotionsfood py-3-5rem">
            <div className="promotionsfood__container">
              <div className="px-1rem">
                <Text type="subtitle" text={title} family={"tsection"} />
              </div>

              <div className="promotionsfood__container--description">
                <Swiper
                  slidesPerView={"auto"}
                  grabCursor={true}
                  navigation={true}
                  className="promotionsfood__container--description--swiper"
                >
                  {data.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <Card type="food" data={item} />
                    </SwiperSlide>
                  ))}
                  <SwiperSlide>
                    <Link className="card food" to={"/"}>
                      <div>
                        <div className="cardfood--final">
                          <div className="btn">
                            <Icon name="next" list="arrows" />
                          </div>
                          <Text
                            type="paragraph"
                            text={messages[locale].findTheBestDiscounts}
                            family={"titlecarddiscount"}
                          />
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        );
      }
      case "hotel-direccion": {
        return (
          <div className="promotionsexplore">
            <div className="promotionsexplore__container">
              <Text text={title} type="subtitle" className={"subtitle"} />

              <div className="promotionsexplore__description">
                <Swiper
                  slidesPerView={"auto"}
                  grabCursor={true}
                  navigation={true}
                  // autoplay={{
                  //   delay: 7000,
                  //   disableOnInteraction: false
                  // }}
                  // modules={[Autoplay]}
                  className="promotionsexplore__description--swiper"
                >
                  {data && data.length > 0 ? (
                    <>
                      {data.map((item: any, index: number) => (
                        <SwiperSlide key={index}>
                          <Animations type="scaleMin">
                            <Card
                              type="hotel-direccion"
                              titulo={item.destino}
                              direction={item.direccion}
                              data={item.restaurante || item.restaurantes}
                              isTag={true}
                            />
                          </Animations>
                        </SwiperSlide>
                      ))}
                    </>
                  ) : (
                    <>
                      {[...Array(6)].map((_, index) => (
                        <SwiperSlide key={index}>
                          <div style={{ height : 220, width : 220, borderRadius : "10px", overflow : "hidden" }}>
                            <Animations type="skeletonContent" />
                          </div>
                        </SwiperSlide>
                      ))}
                    </>
                  )}

                  {/* <SwiperSlide>
                    <Link
                      className='promotionsexplore__description--final nlink'
                      to={'/'}
                    >
                      <div className='cardrestaurant'>
                        <div className='btn'>
                          <Icon name='next' list='arrows' />
                        </div>
                        <Text
                          type='paragraph'
                          text={messages[locale].findTheBestDiscounts}
                          className={'paragraph'}
                        />
                      </div>
                    </Link>
                  </SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        );
      }
      case "tipos-restaurante": {
        return (
          <div className="filtrorestaurante">
            <div className="filtrorestaurante__container">
              <Text text={title} type="title" className={"subtitle"} />

              <div className="filtrorestaurante__container--description">
                <Swiper
                  slidesPerView={"auto"}
                  grabCursor={true}
                  navigation={true}
                  className="filtrorestaurante__container--description--swiper"
                >
                  {data && data.length > 0 ? (
                    <>
                      {data.slice(0, 4).map((item: any, index: number) => (
                        <SwiperSlide key={index}>
                          <Animations type="downSide">
                            <div
                              className="filtrorestaurante__container--description--filtros"
                              style={{ backgroundColor: item.color || "white" }}
                            >
                              <Link className="contenido nlink" to={item.slug}>
                                <img
                                  src={item.img?.path}
                                  alt={"Tipo de restaurante"}
                                  width={80}
                                />
                              </Link>
                            </div>
                          </Animations>
                        </SwiperSlide>
                      ))}
                    </>
                  ) : (
                    <>
                      {[...Array(4)].map((_, index) => (
                        <SwiperSlide key={index}>
                          <div
                            className="filtrorestaurante__container--description--filtros"
                            style={{
                              backgroundColor: "white",
                              overflow: "hidden",
                            }}
                          >
                            <Animations type="skeletonContent">
                              <Link
                                className="contenido nlink"
                                to={"#"}
                                style={{ width: 80 }}
                              ></Link>
                            </Animations>
                          </div>
                        </SwiperSlide>
                      ))}
                    </>
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        );
      }
      case "slider-restaurante": {
        return (
          // <div className='slider-restaurante pt-3-5rem'>
          //   <div className='slider-restaurante__container'>
          //     <Text text={title} type='subtitle' className={'subtitle'} />

          //     <div className='slider-restaurante__container--description'>
          //       <Swiper
          //         slidesPerView={'auto'}
          //         loop={true}
          //         loopedSlides={data.length}
          //         pagination={{
          //           clickable: true
          //         }}
          //         autoplay={{
          //           delay: 7000,
          //           disableOnInteraction: false
          //         }}
          //         modules={[Autoplay]}
          //         direction='horizontal'
          //         effect='fade'
          //         fadeEffect={{ crossFade: true }}
          //         className='slider-restaurante__container--description--swiper'
          //       >
          //         {data.map((item: any, index: number) => (
          //           <SwiperSlide key={index}>
          //             <img
          //               src={item?.path}
          //               alt={item.alt}
          //               width={300}
          //               height={240}
          //             />
          //           </SwiperSlide>
          //         ))}
          //       </Swiper>
          //     </div>
          //   </div>
          // </div>
          <div className="slider-restaurante">
            <div className="slider-restaurante__container">
              {title && (
                <Text text={title} type="subtitle" className={"subtitle"} />
              )}
              <div className="slider-restaurante__container--description">
                <div className="slider-infinito">
                  {[...data, ...data, ...data].map(
                    (item: any, index: number) => (
                      <div className="slider-infinito__item" key={index}>
                        <img
                          src={item.path}
                          alt={item.alt}
                          width={300}
                          height={240}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }
      case "restaurante-categoria": {
        return (
          <div>
            <div>foto</div>
            <div>texto</div>
            <div>boton</div>
          </div>
        );
      }
      case "logros": {
        return (
          <div className="promologros py-3-5rem">
            <div className="promologros__container">
              <Text type="subtitle" text={title} className={"subtitle"} />

              <div className="promologros__container--description">
                <Swiper
                  slidesPerView={"auto"}
                  grabCursor={true}
                  navigation={true}
                  className="promologros__container--description--swiper"
                >
                  {data.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <Card type="logros" data={item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        );
      }
      case "reconocimiento": {
        return (
          <div className="promoreconocimiento py-3-5rem">
            <div className="promoreconocimiento__container">
              <Text type="subtitle" text={title} className={"subtitle"} />

              <div className="promoreconocimiento__container--description">
                <Swiper
                  slidesPerView={"auto"}
                  grabCursor={true}
                  navigation={true}
                  className="promoreconocimiento__container--description--swiper"
                >
                  {data.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <Card type="reconocimiento" data={item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        );
      }
      case "equipo": {
        return (
          <div className="promologros py-3-5rem">
            <div className="promologros__container">
              <Text type="subtitle" text={title} className={"subtitle"} />

              <div className="promologros__container--description">
                <Swiper
                  slidesPerView={"auto"}
                  grabCursor={true}
                  navigation={true}
                  className="promologros__container--description--swiper"
                >
                  {data.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <Card type="equipo" data={item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  return <>{promotionsType()}</>;
};

export default SectionCardsTypes;
