import { Card, Text } from 'components/atoms'
import { BannerSlider } from 'components/organisms'
import { data_centrado2 } from 'pages/promotions/data'
import { useIntl } from 'react-intl'
import { messages } from 'utils/lang'

const PromotionsFood = ({ data }: any) => {
  const { locale } = useIntl()

  return (
    <>
      <BannerSlider data={data_centrado2} />

      <div className='sectioncardgrid'>
        <div className='sectioncardgrid__container'>
          <Text
            text={messages[locale].enjoyOurPromotions}
            type='subtitle'
            className={'subtitle'}
          />
          <div className='sectioncardgrid__description'>
            <Card
              type='restaurant-food'
              data={data}
              redirection={data.redireccion}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PromotionsFood
