
export const dataBanner = [
    {
      id: 1,
      titulo: '',
      subtitulo: '',
      color_fondo_logo: 'rgba(62, 145, 41, 1)',
      img_desktop: {
        name: 'banner-momentos-casa-andina',
        path: 'https://s3.us-east-1.amazonaws.com/ca-webprod/media/02c97ac7-36d7-4298-acf5-11fa0b2fa1b1.jpg',
        width: 1440,
        height: 400,
        size: 794666,
        title: 'Banner Momentos Casa andina',
        alt: 'Banner Momentos Casa andina',
        description: null,
        caption: 'Banner Momentos Casa andina'
      },
      img_mobile: [],
      descuento: '',
      descripcion_boton: '',
      redireccion_boton: '',
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: 'Título/subtitulo collage logo'
      }
    }
  ]