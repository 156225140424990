import React from 'react'
import './styles.scss'
import { Icon, Text } from 'components/atoms'
import { Link } from 'react-router-dom'

const DestinationsLink = ({ data }: any) => {
  return (
    <div className='destinationslink'>
      <div className='destinationslink__container'>
        <div className="colum-container" >
          {
            data.map((item: any, index: number) => (
              <div className="colum" key={index}>
                <Link className='linkdestination link ta-left' to={item.redireccion}>
                  <Text type='paragraph' className='paragraph' text={item.nombre} />
                  <Icon name={'arrow-right'} list='arrows' className='iconactive' />
                </Link>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default DestinationsLink