
export const banner_data_centrado = [
    {
      id: 1,
      titulo: '',
      subtitulo: '',
      color_fondo_logo: 'rgba(62, 145, 41, 1)',
      img_desktop: {
        name: 'banner-tarifas-corporativas',
        path: 'https://s3.us-east-1.amazonaws.com/ca-webprod/media/f05d690e-daf9-4f73-8b0b-8465980386a9.jpg',
        width: 1440,
        height: 400,
        size: 794666,
        title: 'Banner Tarifas Corporativas',
        alt: 'Banner Tarifas Corporativas',
        description: null,
        caption: 'Banner Tarifas Corporativas'
      },
      img_mobile: [],
      descuento: '',
      descripcion_boton: '',
      redireccion_boton: '',
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: 'Título/subtitulo collage logo'
      }
    }
  ]