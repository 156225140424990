const place = 'Valle Sagrado'

export const enMessages = {
  // Language
  spanish: 'Spanish',
  english: 'English',
  portuguese: 'Portuguese',

  // Slugs
  slugdestinations: 'destinations',
  slughotels: 'hotels',
  slugrestaurants: 'restaurants',
  slugoffers: 'offers',
  slugevents: 'events',
  slugenterprises: 'enterprises',
  slugbest_price: 'best-deals',

  // Sections
  description: 'Description',
  hotels: 'Hotels',
  restaurants: 'Restaurants',
  questions: 'Questions',
  place: 'Place',
  schedule: 'Schedule',
  shift: 'Shifts',
  prices: 'Prices',

  // NavBar
  navBarBook: 'Book',
  navBarDestinations: 'Destinations',
  navBarHotels: 'Hotels',
  navBarRestaurants: 'Restaurants',
  navBarCyberCa: 'Cyber CA',
  navBarOffers: 'Offers',
  navBarLife: 'Life',
  navBarCorporate: 'Corporate',
  navBarEvents: 'Events',

  // Buttons
  buttonreservar: 'Book',
  buttonBanner: 'Register for free',
  buttonHotels: 'See hotels',
  buttonHotel: 'See hotel',
  buttonBackToTop: 'Back to top',

  // Section Description
  descriptionTitle: 'Discover our destination',
  descriptionToogleMore: 'See more',
  descriptionToogleLess: 'See less',
  showless: 'Show less',
  showmore: 'Show more',

  // Section Activities
  activitiesTitle: 'Reasons to choose us when traveling in Peru',
  activitiesKnowLess: 'know less',

  // Sections Better Price
  betterPriceTitle: 'The best prices of the moment',

  // Sections Discounts
  discountsTitle: 'Enjoy our discounts',

  // Section Everything Peru
  everythingPeruTitle: 'Know everything we have for you, know about Peru.',

  // Sections Banners
  bannerTitle:
    'Not a member yet? Become our Life partner and redeem free nights.',

  // Section Hotel
  sortAndFilter: 'Sort and filter',
  delete: 'Delete',
  sortBy: 'Sort by',
  sortByRecommended: 'Recommended',
  sortByPriceLowToHigh: 'Discount: Low to High',
  sortByPriceHighToLow: 'Discount: High to Low',
  sortByTopRated: 'Top Rated',
  filterBy: 'Filter by',
  searchByName: 'Search by name',
  chooseHotelOrDestination: 'Choose a hotel or destination',
  pricePerNight: 'Price per night',
  pricePerNightWithoutTax: 'Rate per night before taxes from',
  travelType: 'Travel type',
  hotelCategory: 'Hotel category',
  experiences: 'Experiences',
  services: 'Services',
  ready: 'Ready',

  // Section Hotels
  hotelsTitle: 'Our hotels in',
  hotelsReview: 'Reviews',
  hotelReview: 'Review',
  hotelsPriceFrom: 'From',
  hotelsPricesIgv: 'including VAT and service charges',
  ourServices: 'Our services',
  ourHouseServices: 'Our house services',
  moreAmenities: 'More amenities',
  ourRoomsAndSuites: 'Our rooms and suites',
  enjoyPlaceInOurHouse: `Enjoy your ${place} in our house`,
  maximizeWithBestPromotions:
    'Maximize with the best promotions from our house',
  activitiesInCity: `Activities in ${place}, add moments of joy and fun to your journey`,
  guestReviews: 'What our guests say',
  rating: 'Rating',
  excelent: 'Excellent',
  discoverOurPrices: 'Discover all our best prices of the moment',
  allOurDestinations: 'All our destinations',
  discoverOurDestination: 'Get to know all our best destinations of the moment',
  discoverOurHotels: 'Discover our hotels',

  // Section Restaurants
  learnMore: 'learn more',
  restaurantsTitle: 'Visit our restaurants',
  exploreOurRestaurants: 'Explore our restaurants',
  exploreOurRestaurantsIn: 'Explore our restaurants in',
  discoverPeruvianCulinaryRichness: 'Discover the culinary richness of Peru',
  enjoyOurPromotions: 'Enjoy our promotions',

  // Section Promotions
  day: 'Day',
  days: 'Days',
  hour: 'Hour',
  hours: 'Hours',
  minute: 'Minute',
  minutes: 'Minutes',
  second: 'Second',
  seconds: 'Seconds',
  comingSoon: 'Only a few days left for our',
  specialPackagesForYou:
    'Special packages for you, travel and experience with Casa Andina',
  enjouyAndTreatYourself: 'Enjoy and treat yourself by trying the best',
  findTheBestDiscounts: 'Find the best discount for you',
  discountsyourBanks: 'Discounts with your bank',
  getToKnowPerufirst: 'Get to know Peru first, take advantage of our discounts',

  // Section Explore
  exploreTitle: 'Explore beyond',
  exploreHotel: 'Hotel',
  exploreHotels: 'Hotels',
  exploreTitle2: 'Discover Peru in every destination',

  // Section Better Experience
  betterExperienceTitle: 'Live the best experience in our hotels',

  // Section Questions
  questionsTitle: 'Frequently Asked Questions about',
  questionsSubtitle: 'We answer your questions',

  // Page
  morethan: 'More of',

  // Page Destinations City
  pageDestinationsCityTitle: 'destinations for you',
  pageDestinationsCityDescription: 'Your ideal hotel, your choice',

  // Page Hotels
  pageHotelsTitle: 'hotels to know',

  // Section Footer
  footerFollowUS: 'Follow us on social media',
  footerPayment: 'Payment methods',
  footerCustomerService: 'Customer service',
  footerCalUsForFree: 'Call us for free',
  footerContactUs: 'Contact us',
  footerTermsAndConditions: 'Terms and conditions',
  footerSmv:
    'Access public information about the issuer disseminated on the Stock Market Portal.',
  footerPrivacyPolicy: 'Web Privacy Policy',
  footerAllRightsReserved: 'All rights reserved - Casa Andina',

  //Quienes somos
  quienessomosuno:
    'We are a Peruvian hotel chain founded in February 2003, focused on providing our guests with a unique travel experience. By integrating the characteristics of each destination, from architecture to gastronomy, activities, and music, we create memorable moments for our visitors.',
  quienessomosdos: '20 Years of History: "The Path of Casa Andina"',
  quienessomostres: 'Guided by a Purpose',
  quienessomoscuatro: 'Mission',
  quienessomoscinco:
    'We create authentic experiences that surpass what the traveler expects.',
  quienessomosseis: 'Vision',
  quienessomossiete:
    'To be the leading Peruvian hotel chain globally, thanks to our collaborators, service excellence, and extraordinary results.',
  quienessomosocho: 'The Values that Define Us',
  quienessomosnueve: 'Culture of Integrity',
  quienessomosdiez: 'Vocational Service',
  quienessomosonce: 'Teamwork',
  quienessomosdoce: 'Attention to Detail',
  quienessomostrece: 'Spirit of Overcoming',
  quienessomoscatorce: 'Why Choose Us',
  quienessomosquince:
    'Casa Andina goes beyond being a hotel chain; we are your authentic connection to Peru.',
  quienessomosdieciseis: 'Variety for All Tastes',
  quienessomosdiecisiete:
    'With 5 types of hotels, we tailor our offerings to needs, ensuring each visit is just what they desire.',
  quienessomosdieciocho: 'Passionate Team:',
  quienessomosdiecinueve:
    'We are around 1000 collaborators united by a passion for service, good vibes, and love for Peru.',
  quienessomosveinte: 'Leaders in Peru',
  quienessomosveintiuno:
    'With over 19 years, we are the leading hotel chain in the country, providing not only rooms but an incomparable experience.',
  quienessomosveintidos:
    '¨When you think of traveling through Peru, think of Casa Andina¨',
  quienessomosveintitres: 'Our Team',
  quienessomosveinticuatro: 'Our Recognitions',
  quienessomosveinticinco: 'Our Achievements',

  // Responsabilida Social
  responsabilidadSocialuno: 'PERU CHAMPS',
  responsabilidadSocialdos: 'What solution does Peru Champs provide?',
  responsabilidadSocialtres:
    'It identifies underprivileged students with high academic, artistic, or sports talent and grants them scholarships to receive an excellent education* so that they become future leaders of a sustainable Peru.',
  responsabilidadSocialcuatro:
    'With the support of Casa Andina, the education of thousands of underprivileged children with exceptional talents continues to be enhanced.',
  responsabilidadSocialcinco: 'Why donate to Peru Champs?',
  responsabilidadSocialseis:
    'Because they have a unique, high-impact educational model that has granted approximately 3,000 scholarships, with the goal of awarding 1,000 each year.',
  responsabilidadSocialsiete:
    'In addition, to ensure their comprehensive development, they offer 9 leadership activities such as mentoring, coaching, entrepreneurship, among others. They not only provide scholarships but also strengthen the soft skills of the Champs.',
  responsabilidadSocialocho: 'Learn more at',

  // Eventos
  eventosUno: 'Events',
  eventosDos: 'ORGANIZE YOUR EVENT WITH US',
  eventosTres: 'ROOM',
  eventosCuatro: 'DESTINATIONS',
  eventosCinco: 'Breakfasts',
  eventosSeis: 'Coffee break',
  eventosSiete: 'Lunch/dinner',
  eventosOcho: 'Cocktail',
  eventosNueve: 'Brunch/Tea Time',
  eventosDiez: 'Box lunch',
  eventosOnce: 'Set menu/buffet',
  eventosDoce: 'Open Bar',
  eventosTrece: 'Complete the form to get in touch',
  eventosCatorce: 'Select the type of event',
  eventosQuince: 'Corporate Event',
  eventosDieciséis: 'Social Event',
  eventosDiecisiete: 'Name',
  eventosDieciocho: 'Cell phone',
  eventosDiecinueve: 'Email',
  eventosVeinte: 'Tax Identification Number (RUC)',
  eventosVeintiuno: 'Company Name',
  eventosVeintidós: 'Event Date',
  eventosVeintitrés: 'dd/mm/yyyy',
  eventosVeinticuatro: 'Event Duration',
  eventosVeinticinco: 'Required Catering',
  eventosVeintiséis: 'Required Catering',
  eventosVeintisiete: 'Capacity of people',
  eventosVeintiocho: 'Number of people (from 15 to 300 people)',
  eventosVeintinueve: 'Select the destination of your interest',
  eventosTreinta:
    'I have read and accept the sending of advertising and promotions and the personal data protection policy',
  eventosTreintaYuno: 'Request information',
  eventosTreintaYdos: 'Why choose to celebrate your event with us?',
  eventosTreintaYtres: 'National coverage and strategic location',
  eventosTreintaYcuatro: 'More than 65 venues in 10 destinations',
  eventosTreintaYcinco: '1 contact for nationwide events',
  eventosTreintaYseis: 'Special rates',
  eventosTreintaYsiete: 'Audiovisual options',
  eventosTreintaYocho: 'Payment facilities',
  eventosTreintaYnueve: 'Catering',
  eventosCuarenta: 'Events Your Way with Casa Andina',
  eventosCuarentaYuno: 'Choose your room',
  eventosCuarentaYdos:
    'At Casa Andina, we want to offer you the best experience for you and all your guests. We have the best infrastructure and a service that exceeds expectations with modern rooms that adapt to your needs.',
  eventosCuarentaYtres: 'Room setup',
  eventosCuarentaYcuatro: 'Choose your equipment',
  eventosCuarentaYcinco: 'Projector',
  eventosCuarentaYseis: 'Screen',
  eventosCuarentaYsiete: 'Microphones',
  eventosCuarentaYocho: 'Sound system',
  eventosCuarentaYnueve: 'Laptop',
  eventosCincuenta: '75" TV',
  eventosCincuentaYuno: 'Catering',
  eventosCincuentaYdos: 'Breakfasts',
  eventosCincuentaYtres: 'Coffee break',
  eventosCincuentaYcuatro: 'Lunch/dinner',
  eventosCincuentaYcinco: 'Cocktail',
  eventosCincuentaYseis: 'Brunch/Tea Time',
  eventosCincuentaYsiete: 'Box lunch',
  eventosCincuentaYocho: 'Set menu/buffet',
  eventosCincuentaYnueve: 'Open Bar',
  eventosSesenta: 'Do you want to discover all the options for your events?',
  eventosSesentaYuno:
    'Explore the options for your event, meetings, and guests.',
  eventosSesentaYdos: 'Request information',

  // reservas-agentes
  reservasAgentesUno: 'Enter your agency code to continue browsing',
  reservasAgentesDos: 'Enter your code',
  reservasAgentesTres: 'Submit',
  reservasAgentesCuatro: 'WE ARE OPEN',
  reservasAgentesCinco: 'HOTEL PARACAS',
  reservasAgentesSeis: 'Book Now and Offer Your Clients an Unforgettable Stay!',
  reservasAgentesSiete: 'Choose a destination, hotel',
  reservasAgentesTrece:
    'Please note that only up to 3 adults and 2 children under 10 years old can stay per room, up to a maximum of persons.',
  reservasAgentesCatorce: 'ROOM 1',
  reservasAgentesQuince: 'Adults',
  reservasAgentesDieciséis: 'Children',
  reservasAgentesDiecisiete: 'Add rooms',
  reservasAgentesDieciocho: 'Continue',
  reservasAgentesDiecinueve: 'Enter your code',
  reservasAgentesVeinte: 'Book',
  reservasAgentesVeintiuno: 'Cancel Reservation',
  reservasAgentesVeintidós:
    'Join our broadcast channel where we share updates and useful tools. Click here to join.',
  reservasAgentesVeintitrés: 'Join the group',
  reservasAgentesVeinticuatro: 'Explore with Us: Data Sheets, Menus, and Dates',
  reservasAgentesVeinticinco: 'Technical data sheets',
  reservasAgentesVeintiséis: 'View',
  reservasAgentesVeintisiete: 'Tourist menus',
  reservasAgentesVeintiocho: 'View',
  reservasAgentesVeintinueve: 'Special dates',
  reservasAgentesTreinta: 'View',
  reservasAgentesTreintaYuno: 'Photos of our Casa Andina hotels',
  reservasAgentesTreintaYdos: 'View',

  // dog-friendly
  dogFriendlyUno: 'Dog Friendly',
  dogFriendlyDos: 'PET ADMISSION AND CARE POLICIES',
  dogFriendlyTres:
    'We understand that pets are an important part of the family. In order to make your stay and that of your pet as pleasant as possible and to avoid any inconvenience for you and the other guests, it is necessary for you to be aware of and accept the Pet Admission and Care Policies at CASA ANDINA, detailed below:',
  dogFriendlyCuatro:
    'CASA ANDINA allows the entry of guests accompanied by their dog (Pet) as long as the present Admission Policies are followed.',
  dogFriendlyCinco:
    'Entry of cats is allowed; however, the amenities provided for the stay are for dogs (does not include a litter box for cats or any additional items for cats, only dog-friendly equipment).',
  dogFriendlySeis:
    'Dog Friendly reservations can be made through the Reservations department, Contact Center, online channels, and in-person.',
  dogFriendlySiete:
    'Preferably, reservations should be requested with a 2-business-day notice.',
  dogFriendlyOcho: 'Only the entry of two pets per room will be allowed.',
  dogFriendlyNueve:
    'The Associated Hotels Fundo San José Parque Ecológico & Lodge, Empedrada Ranch & Lodge, Maresta, Hotel Andino Club, El Sauce Resort, Hotel Presidente Huancayo, Hotel Presidente Huancavelica, Irapay Amazon Lodge, AltaVista CasaHotel, La Xalca Hotel, La Ensenada, Santacruz Ecolodge, and Ikam Riverview Amazon Lodge are not dog-friendly.',
  dogFriendlyDiez:
    'At the time of booking, the guest must provide certificates issued by a licensed veterinarian (or competent government body) accrediting the up-to-date vaccinations and deworming of their pet, with an age not exceeding 1 year. It is necessary to carry these certificates during the stay at the hotel and present them if requested by the CASA ANDINA staff.',
  dogFriendlyOnce:
    "In the case of guide dogs, the CONADIS certificate must be presented, accrediting the necessity of the dog's company (visual disability, emotional support, glucose measurement, or another type). If the CONADIS certification is not available, the guest will assume the nightly charge for the pet's stay.",
  dogFriendlyDoce: 'Only pets weighing a maximum of 18 kilograms are allowed.',
  dogFriendlyTrece:
    'The entry of potentially dangerous breeds, as per Peruvian legislation, such as American Pitbull, fila brasilera, tosa japonesa, bullmastiff, doberman, and rottweiler, as well as those trained for fights or that have participated in them, those with a history of aggression towards people and cannot ensure their sociability, temperament, or character, will not be allowed.',
  dogFriendlyCatorce:
    'The entry of your pet has a cost of US$ 49.00 (including taxes and services) per night. The price is per pet.',
  dogFriendlyQuince:
    "A guarantee equivalent to one night's room charge must be left. CASA ANDINA will not be responsible for the feeding of your pet. During their stay, your pet can use, on a loan basis, the following: (i) dog bed; (ii) food bowls; and (iii) a mat. CASA ANDINA will gift, during the pet's stay, (i) a keychain with disposable bags; and (ii) absorbent pads.",
  dogFriendlyDieciséis:
    "Your pet must use the absorbent pads placed in the bathroom of the room for their needs. In any other case, you will be responsible for picking up the waste left by your pet and must immediately inform the CASA ANDINA cleaning staff. The use of the hotel's linen to collect waste and for the grooming of your pet is not allowed.",
  dogFriendlyDiecisiete:
    'Your pet must always be in your company during your stay at CASA ANDINA. It is not allowed for your pet to climb on beds, sofas, armchairs, and other furniture within the room. Likewise, it is not allowed to leave your pet alone in the room or in any common area of CASA ANDINA, restaurant staff will place you at the tables assigned to this program.',
  dogFriendlyDieciocho:
    'Our restaurants are pet-friendly, so you can bring your pet into the areas under supervision.',
  dogFriendlyDiecinueve:
    "When moving through the hotel's common areas, your pet must always be on a leash.",
  dogFriendlyVeinte:
    'You will be solely responsible for the care and behavior of your pet, avoiding barking, noise, and behaviors that may cause discomfort to other guests.',
  dogFriendlyVeintiuno:
    'Any damage or incident caused by your pet to anyone will be entirely assumed by you, and you must cover all expenses and costs of repair, including but not limited to medical expenses of affected individuals. Expenses and costs incurred by CASA ANDINA must be reimbursed immediately by you.',
  dogFriendlyVeintidós:
    'Damages caused by your pet to the furniture within your room and in other CASA ANDINA facilities will be entirely assumed by you at the time of Check Out, taking into consideration the following:',
  dogFriendlyVeintitrés:
    '-US$75 for damages to each piece of furniture or household items.',
  dogFriendlyVeinticuatro:
    '-US$90 for damages to linens (sheets, pillows, towels, etc.) and other goods.',
  dogFriendlyVeinticinco: '-US$350 for damages to each mattress.',
  dogFriendlyVeintiséis:
    'The above does not limit CASA ANDINA from demanding payment of higher amounts if the damaged goods have a higher valuation.',
  dogFriendlyVeintisiete:
    'Any act of mistreatment or cruelty against your pet, or acts that endanger public health, is strictly prohibited. According to Peruvian legislation, these actions constitute crimes that may be punished with imprisonment. CASA ANDINA will immediately report these actions to the competent authorities.',
  dogFriendlyVeintiocho:
    'Guests entering with guide dogs must present certificates accrediting this condition, issued by competent entities.',
  dogFriendlyVeintinueve:
    'We appreciate and hope you have a pleasant stay at CASA ANDINA, accompanied by your pet.',

  //terminos
  terminosYcondicionesUno:
    'Terms and Conditions of Reservations at Casa Andina Hotels',
  terminosYcondicionesDos: 'Contract Terms',
  terminosYcondicionesTres: 'Provider of lodging services.',
  terminosYcondicionesCuatro:
    'CLIENT: Passenger who takes the services of CASA ANDINA (adult).',
  terminosYcondicionesCinco:
    "CHECK-IN: Customer's check-in process at the hotel before entering the room.",
  terminosYcondicionesSeis:
    "CHECK-OUT: Customer's check-out process when finishing the use of hotel services.",
  terminosYcondicionesSiete:
    "NO-SHOW: The passenger's failure to appear at the hotel before 23:59 on the scheduled arrival day without prior communication from the customer.",
  terminosYcondicionesOcho:
    'RESERVATION: Agreement between Casa Andina and the Client to reserve the room(s) for the dates requested by the customer at any hotel in the CASA ANDINA chain.',
  terminosYcondicionesNueve:
    'SERVICE CHARGE: 10% surcharge on Casa Andina services consumption.',
  terminosYcondicionesDiez:
    'GENERAL SALES TAX (IGV): Tax corresponding to 18% of the rate.',
  terminosYcondicionesOnce: 'General Conditions'
}
