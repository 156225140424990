import { useEffect } from "react";
import "./styles.scss";

const CheckinCusco = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='checkin_hotel checkin_hotel-element'>
        <div className='checkin_hotel__container'>
          <h1>Acelera tu check in</h1>

          <div className="item-checkin">            
            <img
                src='https://s3.us-east-1.amazonaws.com/ca-webprod/media/e280cd44-1d6a-41a4-8bdb-6301112bbfc2.jpg' className="item-img"
              ></img>
              <div className="item-content">
                  <h2>Casa Andina Premium Cusco</h2>
                  <p>Ubicado a solo 5 minutos de la Plaza de Armas de Cusco y a pocos pasos de los principales atractivos turísticos de la ciudad. Una hermosa casona del siglo XVII que te transportará a la época republicana gracias a sus patios y balcones coloniales. </p>
                  <a href="https://kiosk.newhotelcloud.com/#/welcome?ht=8246B371C8EE0839E0532B08330A5151" target="_blank">ACELERA TU CHECK IN</a>
              </div>

          </div>

          <div className="item-checkin">            
            <img
                src='https://s3.us-east-1.amazonaws.com/ca-webprod/media/4624b724-61ce-4003-84ac-c4740c227363.jpg' className="item-img"
              ></img>
              <div className="item-content">
                  <h2>Casa Andina Premium Valle Sagrado</h2>
                  <p>Ubicado en el Valle Sagrado de los Incas - Cusco. A solo 1 hora con 20 minutos en auto de la ciudad de Cusco y 20 minutos de Ollantaytambo. Un acogedor ambiente en medio de Urubamba, ideal si quieres relajarte y estar cerca a los principales atractivos turísticos del valle.</p>
                  <a href="https://kiosk.newhotelcloud.com/#/welcome?ht=82475D49D62D5C4BE0532B09330A4B02" target="_blank">ACELERA TU CHECK IN</a>
              </div>

          </div>

          <div className="item-checkin">            
            <img
                src='https://s3.us-east-1.amazonaws.com/ca-webprod/media/27dfe30b-557f-45b4-9dff-b2caddd14940.jpg' className="item-img"
              ></img>
              <div className="item-content">
                  <h2>Casa Andina Standard Cusco Plaza</h2>
                  <p>Ubicado en los portales coloniales de la majestuosa Plaza Regocijo, a solo una cuadra de las Plaza de Armas de Cusco y a 15 minutos del aeropuerto. Aquí podrás disfrutar de un delicioso desayuno buffet y sabrosos platos típicos en nuestro restaurante Sama Café. </p>
                  <a href="https://kiosk.newhotelcloud.com/#/welcome?ht=82461924B9A75346E0532B0D330A2E37" target="_blank">ACELERA TU CHECK IN</a>
              </div>

          </div>

          <div className="item-checkin">            
            <img
                src='https://s3.us-east-1.amazonaws.com/ca-webprod/media/b26c54d5-6eba-4810-88ad-09dc3f818f1c.jpg' className="item-img"
              ></img>
              <div className="item-content">
                  <h2>Casa Andina Standard Machu Picchu</h2>
                  <p>Ubicado en los portales coloniales de la majestuosa Plaza Regocijo, a solo una cuadra de las Plaza de Armas de Cusco y a 15 minutos del aeropuerto. Aquí podrás disfrutar de un delicioso desayuno buffet y sabrosos platos típicos en nuestro restaurante Sama Café.</p>
                  <a href="https://kiosk.newhotelcloud.com/#/welcome?ht=8246DD6BB6DF6DE7E0532B0E330A77EC" target="_blank">ACELERA TU CHECK IN</a>
              </div>

          </div>

        </div>
      </div>            
    </>
  );
};

export default CheckinCusco;
