import { BannerSlider } from "components/organisms";
import Section from "components/molecules/section";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const ResponsabilidadSocial = () => {
  const { t } = useTranslation();

  const banner_data_centrado = [
    {
      id: 1,
      titulo: t("socialResponsability.responsability"),
      subtitulo: t("socialResponsability.social"),
      color_fondo_logo: 'rgba(62, 145, 41, 1)',
      img_desktop: {
        name: 'banner-responsabilidad-social',
        path: 'https://s3.us-east-1.amazonaws.com/multimediaqa.casa-andina.com/images/1440X600_promocionestadiadosnochescasaandinahoteleschimenea.JPG',
        width: 1440,
        height: 400,
        size: 794666,
        title: 'Banner Responsabilidad Social',
        alt: 'Banner Responsabilidad Social',
        description: null,
        caption: 'Banner Responsabilidad Social'
      },
      img_mobile: [],
      descuento: '',
      descripcion_boton: '',
      redireccion_boton: '',
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: 'subtitulo/título collage'
      }
    }
  ]
  return (
    <>
      <BannerSlider data={banner_data_centrado} />
      <Section>
        <div className="social__responsability">
          <h1>{t("socialResponsability.socialResponsabilityTitle")}</h1>

          <div className="social__responsability--div">
            <h2>{t("socialResponsability.socialResponsabilityQuestion1")}</h2>
            <p>{t("socialResponsability.socialResponsabilityQ1Line1")}</p>
            <p>{t("socialResponsability.socialResponsabilityQ1Line2")}</p>
          </div>

          <div className="social__responsability--div">
            <h2>{t("socialResponsability.socialResponsabilityQuestion2")}</h2>
            <p>{t("socialResponsability.socialResponsabilityQ2Line1")}</p>
            <p>{t("socialResponsability.socialResponsabilityQ2Line2")}</p>
            <p>
              {t("socialResponsability.socialResponsabilityQ2Line3")}{" "}
              <a
                href="http://www.peruchamps.org/"
                rel="noopener noreferrer"
                no-target="_blank"
              >
                www.peruchamps.org
              </a>
            </p>
          </div>
        </div>
      </Section>
    </>
  );
};

export default ResponsabilidadSocial;
