import { BannerSlider } from 'components/organisms'
import { banner_data_centrado } from './data'
import './styles.scss'
import Section from 'components/molecules/section'
import { messages } from 'utils/lang'
import { useIntl } from 'react-intl'

const DogFriendly = () => {
  const {locale} = useIntl()
  return (
    <>
      <BannerSlider data={banner_data_centrado} />
      <Section>
        <div className='dog_friendly dog_friendly-element'>
          <div className='dog_friendly__container'>
            <h1>{messages[locale].dogFriendlyUno}</h1>
            <h3>{messages[locale].dogFriendlyDos}</h3>
            <p>
            {messages[locale].dogFriendlyTres}
            </p>
            <ul>
              <li>
              {messages[locale].dogFriendlyCuatro}
              </li>
              <li>
              {messages[locale].dogFriendlyCinco}
              </li>
              <li>
              {messages[locale].dogFriendlySeis}
              </li>
              <li>
              {messages[locale].dogFriendlySiete}
              </li>
              <li>
              {messages[locale].dogFriendlyOcho}
              </li>
              <li>
              {messages[locale].dogFriendlyNueve}
              </li>
              <li>
              {messages[locale].dogFriendlyDiez}
              </li>
              <li>
              {messages[locale].dogFriendlyOnce}
              </li>
              <li>
              {messages[locale].dogFriendlyDoce}
              </li>
              <li>
              {messages[locale].dogFriendlyTrece}
              </li>
              <li>
              {messages[locale].dogFriendlyCatorce}
              </li>
              <li>
              {messages[locale].dogFriendlyQuince}
              </li>
              <li>
              {messages[locale].dogFriendlyDieciséis}
              </li>
              <li>
              {messages[locale].dogFriendlyDiecisiete}
              </li>
              <li>
              {messages[locale].dogFriendlyDieciocho}
              </li>
              <li>
              {messages[locale].dogFriendlyDiecinueve}
              </li>
              <li>
              {messages[locale].dogFriendlyVeinte}
              </li>
              <li>
              {messages[locale].dogFriendlyVeintiuno}
              </li>
              <li>
              {messages[locale].dogFriendlyVeintidós}
              </li>
            </ul>
            <p className='ta-center'>
            {messages[locale].dogFriendlyVeintitrés}
            </p>
            <p className='ta-center'>
            {messages[locale].dogFriendlyVeinticuatro}
            </p>
            <p className='ta-center'>{messages[locale].dogFriendlyVeinticinco}</p>
            <p>
            {messages[locale].dogFriendlyVeintiséis}
            </p>
            <ul>
              <li>
              {messages[locale].dogFriendlyVeintisiete}
              </li>
              <li>
              {messages[locale].dogFriendlyVeintiocho}
              </li>
            </ul>
            <p>
            {messages[locale].dogFriendlyVeintinueve}
            </p>
          </div>
        </div>
      </Section>
    </>
  )
}

export default DogFriendly
