import fechasEspeciales from './fechas-especiales.webp';
import fichasTecnicas from './fichas-tecnicas.webp';
import menuTuristico from './menu-turistico.webp';
import nuestrosHoteles from './nuestros-hoteles.webp';

export const quienes_somos = {
  fechasEspeciales,
  fichasTecnicas,
  menuTuristico,
  nuestrosHoteles
};