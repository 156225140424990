import { Text } from "components/atoms";
import "./styles.scss";
import { IFavDestinations } from "./props";
import { useEffect, useState } from "react";

const FavDestinations = ({ subtitle, title }: IFavDestinations) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const combinedScrolledState = isScrolled;


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`favdestinations ${combinedScrolledState ? "scrolled" : "notscrolled"}`}
    >
      <div className="favdestinations__container">
        <Text text={title} type="title" className="title" />

        <Text text={subtitle} type="subtitle" className="subtitle" />
      </div>
    </div>
  );
};

export default FavDestinations;
