import { BannerSlider } from 'components/organisms'
import { banner_data_centrado } from './data'
import { Icon } from 'components/atoms'
import './styles.scss'
import React, { useState } from 'react'

const AcumulaLatamPass = () => {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  return (
    <>
      <BannerSlider data={banner_data_centrado} />

      <section className='section-descripcion'>
        <div className='alp directores-element'>
          <div className='alp__container px-1rem'>
            <h1>A MÁS COMSUMOS MÁS MILLAS PARA ACUMULAR</h1>

            <div className='section-value'>
              <div className='value-dato'>
                <span className='value-num'>
                  <img
                    src='https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/media/527c18b8-3165-4f9d-8e62-e54bcb63d393.png'
                    alt='item-c'
                  />
                </span>
              </div>
              <span className='value-simbolo'>
                <Icon
                  name={'right-destination-2'}
                  list='arrows'
                  width={40}
                  height={40}
                />
              </span>
              <div className='value-dato'>
                <span className='value-num'>1 milla</span>
              </div>
              <span className='value-simbolo'>
                <Icon name={'close'} list='items' width={40} height={40} />
              </span>
              <div className='value-dato'>
                <span className='value-num'>
                  2.5 <span className='simbol'>USD</span>
                </span>
                <p>(sin impuestos)</p>
              </div>
            </div>

            <h2>Acumulación de millas</h2>
            <p>
              *Todos los consumos de alojamiento, bares y restaurantes
              realizados por el huésped que sean cargados a la habitación (con
              excepción de SPA , LAVANDERÍA, OTROS SERVICIOS).
            </p>
            <p>
              En el caso de los Hoteles Asociados, solo acumulan millas los
              consumos de alojamiento y desayuno.
            </p>
          </div>
        </div>
      </section>

      <div className='alp directores-element'>
        <div className='alp__container px-1rem'>
          <h2>Conoce las condiciones del programa</h2>

          <div className='section-condiciones'>
            <div className='item-c'>
              <img
                src='https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/media/9a9bb94c-eb3b-4e26-bd16-3973508d60d6.jpg'
                alt='item-c'
              />
              <div className='item-c-desc'>
                <h3>
                  + De 40 Hoteles donde
                  <br />
                  podrás acumular millas
                </h3>
                <p>
                  Todos los hoteles Casa Andina y hoteles Asociados a la cadena
                  participan para acumular.
                </p>
              </div>
            </div>
            <div className='item-c'>
              <img
                src='https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/media/bf142ece-0726-47db-a228-38edd0e8b6b6.jpg'
                alt='item-c'
              />
              <div className='item-c-desc'>
                <h3>
                  Canales válidos para
                  <br />
                  acumular{' '}
                </h3>
                <p>
                  Todos las reservas que realices por la página web de Casa
                  Andina y su contact center para viajes personales acumulan
                  millas.
                </p>
              </div>
            </div>
            <div className='item-c'>
              <img
                src='https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/media/2e38f10f-2c5a-44b8-83fb-8af5816cad29.jpg'
                alt='item-c'
              />
              <div className='item-c-desc'>
                <h3>
                  Acumula punto
                  <br />
                  Casa Andina Life
                </h3>
                <p>
                  FE y Millas LATAM Pass, siempre y cuando esté registrado en
                  ambos programas y haya consignado la información
                  correspondiente.
                </p>
              </div>
            </div>
          </div>

          <div className='layout'>
            <label className='nav nav-inactive'></label>
            <input
              name='nav'
              type='radio'
              className='nav home-radio'
              id='home'
              checked
            />
            <div className='page home-page'>
              <div className='page-contents'>
                <div className='accordion'>
                  <input
                    type='radio'
                    name='toggle'
                    className='accordion-toggle'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div className='accordion-header'>
                    ¿Qué es el Programa LATAM Pass?
                  </div>
                  <div className='accordion-content'>
                    <p>
                      Es el programa de pasajero frecuente de LATAM Airlines
                      mediante el cual, es posible acumular Millas LATAM Pass,
                      Puntos Calificables y Segmentos Calificables, volando en
                      todas las rutas operadas por LATAM Airlines y Líneas
                      Aéreas Asociadas, así como utilizando los servicios de
                      otras empresas asociadas; todo ello sujeto a las
                      condiciones y requisitos que se detallan en:
                      <a
                        href='https://latampass.latam.com/es_pe/descubre-latam-pass/terminos-y-condiciones'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        https://latampass.latam.com/es_pe/descubre-latam-pass/terminos-y-condiciones
                      </a>
                    </p>
                    <p>
                      Consulta los
                      <strong>
                        <u>términos y condiciones</u>
                      </strong>
                      para obtener todos los detalles sobre el programa.
                    </p>
                  </div>
                  <input
                    type='radio'
                    name='toggle'
                    className='accordion-toggle'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div className='accordion-header'>
                    ¿Cómo formo parte del Programa LATAM Pass?
                  </div>
                  <div className='accordion-content'>
                    <p>
                      La membresía del Programa se encuentra disponible de
                      manera gratuita en el Sitio Web del Programa para
                      cualquier persona que cumpla con los requisitos indicados
                      en sus términos y condiciones, numeral 2, detallados en:
                      <a
                        href='https://latampass.latam.com/es_pe/descubre-latam-pass/terminos-y-condiciones'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        https://latampass.latam.com/es_pe/descubre-latam-pass/terminos-y-condiciones
                      </a>
                    </p>
                  </div>
                  <input
                    type='radio'
                    name='toggle'
                    className='accordion-toggle'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div className='accordion-header'>
                    ¿Las Millas LATAM Pass reemplazan mis puntos como Socio Casa
                    Andina Life?
                  </div>
                  <div className='accordion-content'>
                    <p>
                      Ninguno de estos programas es excluyente. Esto quiere
                      decir que somo socio Casa Andina Life puedes acumular
                      puntos y millas por tus estadías siempre y seas socio de
                      ambos programas y hayas consignado tu número de Socio Casa
                      Andina Life y número de Socio LATAM Pass al momento de
                      realizar tu reserva.&nbsp;
                    </p>
                  </div>
                  <input
                    type='radio'
                    name='toggle'
                    className='accordion-toggle'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div className='accordion-header'>
                    ¿Cómo hago una consulta que no aparece en esta página?
                  </div>
                  <div className='accordion-content'>
                    <p>
                      Puedes escribirnos a nuestra dirección de correo
                      electrónico:
                      <a
                        href='mailto:millaslatam@casa-andina.com'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        millaslatam@casa-andina.com
                      </a>
                      .
                    </p>
                    <p>
                      Consulta los
                      <strong>
                        <u>términos y condiciones</u>
                      </strong>
                      para obtener todos los detalles sobre el programa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <label className='nav nav-item' htmlFor='home'>
              <span>GENERAL</span>
            </label>

            <input name='nav' type='radio' className='about-radio' id='about' />
            <div className='page about-page'>
              <div className='page-contents'>
                <div className='accordion'>
                  <input
                    type='radio'
                    name='toggle'
                    className='accordion-toggle'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div className='accordion-header'>
                    ¿Cómo acumulo Millas LATAM Pass?
                  </div>
                  <div className='accordion-content'>
                    <p>
                      Para acumular Millas, debes estar registrado en el
                      programa LATAM Pass y hospedarte en cualquier hotel Casa
                      Andina u Hoteles Asociados. Debes identificarte con tu
                      número de Socio LATAM Pass al momento de realizar tu
                      reserva, a través de la web o contact center.{' '}
                    </p>

                    <p>
                      <strong>Criterios para la acumulación:</strong>
                    </p>

                    <ul>
                      <li>
                        Deberás registrar tu número de Socio LATAM Pass en la
                        web o contact center al momento de realizar su reserva
                        para cada habitación. De haber más de un socio LATAM
                        Pass dentro de la reserva, se deberá indicar el número
                        correspondiente para cada habitación.{' '}
                      </li>
                      <li>
                        Debes ser el titular de la reserva. Solo puedes obtener
                        Millas por los consumos acumulables que estén asociados
                        a habitaciones con tu número de socio.
                      </li>
                      <li>
                        La reserva debe ser de Noches Acumulables (reservas por
                        canales directos de Casa Andina) y solo aplica para
                        viajes personales, como se indica en los términos y
                        condiciones del programa.
                      </li>
                      <li>
                        Se cargarán las Millas equivalentes a todos los Cargos a
                        la Habitación Acumulables, de las cuales el socio debe
                        ser titular de la reserva, que correspondan al
                        alojamiento,
                        <span>
                          consumos en alimentos y bebidas en restaurantes y
                          bares propios del Hotel, incluyendo el servicio a la
                          Habitación (Room Service).
                        </span>
                      </li>
                    </ul>

                    <p>
                      <strong>En Hoteles Asociados:</strong>
                    </p>

                    <p>
                      Solo se acumulan Millas LATAM Pass por el importe pagado
                      por la reserva de la habitación (descontando tasas,
                      impuestos y servicios adicionales). Por el contrario, no
                      serán considerados los cargos a la habitación acumulables.
                    </p>

                    <p>
                      Consulta los
                      <strong>
                        <u>términos y condiciones</u>
                      </strong>
                      para obtener todos los detalles sobre el programa.
                    </p>
                  </div>

                  <input
                    type='radio'
                    name='toggle'
                    className='accordion-toggle'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div className='accordion-header'>
                    ¿Cuántas Millas LATAM Pass acumulo por hospedarme en Casa
                    Andina?
                  </div>
                  <div className='accordion-content'>
                    <p>
                      Para acumular Millas LATAM Pass, debes estar registrado en
                      el programa y hospedarte en hoteles de Casa Andina u
                      Hoteles Asociados.
                    </p>

                    <p>
                      Deberás realizar Cargos a la Habitación Acumulables. Por
                      cada $2.50 dólares pagados (descontando tasas, impuestos y
                      servicios adicionales) en Cargos a la Habitación
                      Acumulables dentro de los Hoteles, ganarás 1 Milla LATAM
                      Pass.
                    </p>

                    <p>
                      Consulta los
                      <strong>
                        <u>términos y condiciones</u>
                      </strong>
                      para obtener todos los detalles sobre el programa.
                    </p>
                  </div>

                  <input
                    type='radio'
                    name='toggle'
                    className='accordion-toggle'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div className='accordion-header'>
                    ¿Puedo acumular Millas LATAM Pass por habitaciones que yo no
                    usé personalmente?
                  </div>
                  <div className='accordion-content'>
                    <p>
                      Sí. Siempre y cuando tu número de socio esté correctamente
                      consignado en las habitaciones dentro de tu reserva.{' '}
                    </p>
                    <p>
                      Asimismo, deberás haber brindado tu número de socio al
                      momento de hacer la reserva a través de la web o contact
                      center de Casa Andina.{' '}
                    </p>
                    <p>
                      Consulta los
                      <strong>
                        <u>términos y condiciones</u>
                      </strong>
                      para obtener todos los detalles sobre el programa.
                    </p>
                  </div>

                  <input
                    type='radio'
                    name='toggle'
                    className='accordion-toggle'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div className='accordion-header'>
                    ¿Por qué algunos consumos en Casa Andina no aparecen en mi
                    cuenta de puntos?
                  </div>
                  <div className='accordion-content'>
                    <p>
                      Para acumular Millas LATAM Pass, el socio debe realizar
                      Cargos a la Habitación Acumulables dentro de su estadía en
                      Noches Acumulables. Además, si la acumulación corresponde
                      a una estadía reciente, recuerde que las millas se cargan
                      la primera semana de cada mes con la acumulación total
                      correspondiente al mes anterior de todas aquellas reservas
                      que cuenten con registro de salida (check-out).{' '}
                    </p>
                    <p>
                      Los consumos de una estadía no serán considerados para la
                      acumulación de puntos, si la reserva:
                    </p>
                    <ul>
                      <li>
                        Pertenece a una reserva en la que no brindaste tu número
                        de socio.
                      </li>
                      <li>Pertenece a una reserva con Tarifa No Válida.</li>
                      <li>
                        Pertenece a una reserva No Show (el huésped no se
                        presentó), que no ha sido cobrada o facturada
                      </li>
                      <li>
                        Pertenece a una reserva con más de 90 días de
                        antigüedad.{' '}
                      </li>
                    </ul>
                    <p>
                      Consulta los
                      <strong>
                        <u>términos y condiciones</u>
                      </strong>
                      para obtener todos los detalles sobre el programa.
                    </p>
                  </div>

                  <input
                    type='radio'
                    name='toggle'
                    className='accordion-toggle'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div className='accordion-header'>
                    ¿Cómo solicitar puntos faltantes en mi historial?
                  </div>
                  <div className='accordion-content'>
                    <p>
                      <span>
                        Recuerda que las Millas LATAM Pass se asignarán de forma
                        mensual durante la primera semana de cada mes, siendo
                        esta asignación correspondiente al mes anterior y
                        considerando únicamente aquellas reservas que ya cuenten
                        con registro de salida (Check Out). Si el Socio no se
                        presenta a su reserva (condición de No-Show), acumulará
                        Millas únicamente si dicha reserva se encuentra en
                        condición pagada y es Titular de la misma.{' '}
                      </span>
                    </p>
                    <p>
                      Si pasado este perioro aún no visualizas tu acumulación,
                      puedes escribirnos a nuestra dirección de correo
                      electrónico:
                      <a
                        href='mailto:millaslatam@casa-andina.com'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        millaslatam@casa-andina.com
                      </a>
                      .
                    </p>
                    <p>
                      Consulta los
                      <strong>
                        <u>términos y condiciones</u>
                      </strong>
                      para obtener todos los detalles sobre el programa.
                    </p>
                    <p>
                      <br />
                    </p>
                  </div>

                  <input
                    type='radio'
                    name='toggle'
                    className='accordion-toggle'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div className='accordion-header'>
                    ¿Cuánto demoran en aparecer los puntos después de una
                    estadía?
                  </div>
                  <div className='accordion-content'>
                    <p>
                      <span>
                        Las Millas LATAM Pass se asignarán de forma mensual
                        durante la primera semana de cada mes, siendo esta
                        asignación correspondiente al mes anterior y
                        considerando únicamente aquellas reservas que ya cuenten
                        con registro de salida (Check Out). Si el Socio no se
                        presenta a su reserva (condición de No-Show), acumulará
                        Millas únicamente si dicha reserva se encuentra en
                        condición pagada y es Titular de la misma.{' '}
                      </span>
                    </p>
                    <p>
                      <span>Consulta los </span>
                      <strong>
                        <u>términos y condiciones</u>
                      </strong>
                      para obtener todos los detalles sobre el programa.
                    </p>
                    <p>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <label className='nav nav-item' htmlFor='about'>
              <span>ACUMULACIÓN DE MILLAS</span>
            </label>
            <label className='nav nav-inactive'></label>
          </div>
        </div>
      </div>
    </>
  )
}

export default AcumulaLatamPass
