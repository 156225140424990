import Event1 from './event1.webp'
import Event2 from './event2.webp'
import Event3 from './event3.webp'
import Event4 from './event4.webp'
import Event5 from './event5.webp'
import Event6 from './event6.webp'
import Event7 from './event7.webp'
import Sala1 from './sala1.webp'
import Sala2 from './sala2.webp'
import Sala3 from './sala3.webp'
import Sala4 from './sala4.webp'

export const events = {
  Event1,
  Event2,
  Event3,
  Event4,
  Event5,
  Event6,
  Event7,
  Sala1,
  Sala2,
  Sala3,
  Sala4
}
