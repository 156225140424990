import { useState } from 'react'
import './styles.scss'
import Icon from 'components/atoms/icon'
import { IAccordion } from './props'
import { Text } from 'components/atoms'

const Accordion = ({ title, description, lineColor, isHtml, defaultOpen = false }: IAccordion) => {
  const [active, setActive] = useState(defaultOpen)

  const handleOnClick = () => {
    setActive(!active)
  }

  return (
    <div
      className={`Accordion ${active ? 'active' : ''}`}
      onClick={handleOnClick}
    >
      <div className='container'>
        <div className='title'>
          <h3 className='title__edit'>{title}</h3>
          <Icon name='down' list='arrows' width={24} height={24} />
        </div>
        <div className='description'>
          {isHtml ? (
            <Text
              html={{ __html: description }}
              type='paragraph'
              className='paragraph'
            />
          ) : (
            <Text text={description} type='paragraph' className='paragraph' />
          )}
        </div>

        <hr
          className='description__line'
          style={{ border: `1px solid ${lineColor}` }}
        />
      </div>
    </div>
  )
}

Accordion.defaultProps = {
  lineColor: 'white',
  isHtml: false
}

export default Accordion
