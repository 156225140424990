import React from "react";
import { Card, Text } from "components/atoms";
import "./styles.scss";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.scss";
import { ISectionCardGrid } from "./props";
import { Animations } from "components/molecules";

const SectionCardGrid = ({
  data,
  isTag,
  bank,
  type,
  title,
}: ISectionCardGrid) => {
  const cardsType = (item: any) => {
    switch (type) {
      case "better-prices": {
        return (
          <Card
            type="food"
            data={item.promocion?.card}
            redirection={item.slug}
          />
        );
      }
      case "restaurant-food": {
        return (
          <Card
            type="restaurant-food"
            data={item}
            redirection={item.redireccion}
            titlepromotion={item.nombre}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className="sectioncardgrid">
      <div className="sectioncardgrid__container">
        <Text text={title} type="subtitle" className={"subtitle"} />
        <div className="sectioncardgrid__description">
          {data && data.length > 0 ? (
            <>
              {data.map((item: any, index: number) => (
                <div key={index}>{cardsType(item)}</div>
              ))}
            </>
          ) : (
            <>
              {[...Array(6)].map((_, index) => (
                <div key={index}>
                  <a href="#" className="card food">
                    <Animations type="skeletonContent" />
                  </a>
                </div>
              ))}
            </>
          )}

        </div>
      </div>
    </div>
  );
};

SectionCardGrid.defaultProps = {
  isTag: true,
};

export default SectionCardGrid;
