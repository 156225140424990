import { format } from "date-fns";
import { TFunction } from "i18next";
import * as Sentry from '@sentry/react';
import { REACT_APP_MAINTENANCE_START, REACT_APP_MAINTENANCE_END } from "typings/env";
import { REACT_APP_ENV } from "typings/env";

export function getDateWithoutTime(dateTimeString: string) {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
  };
  const date = new Date(dateTimeString + "T00:00:00-05:00");
  const formattedDate = date.toLocaleDateString(undefined, options);
  const formattedDateUpper = formattedDate.replace(/\b\w/g, (char) =>
    char.toUpperCase()
  );
  return formattedDateUpper;
}

export function getDateWithDay(dateTimeString: string) {
  const date = new Date(dateTimeString + "T00:00:00-05:00");

  const diasSemana = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
  const nombreDia = diasSemana[date.getUTCDay()]?.replace(/^\w/, (letra) =>
    letra.toUpperCase()
  );

  const nombreMes = date
    .toLocaleString("es-ES", { month: "short" })
    .toUpperCase();

  const dia = date.getUTCDate();

  return `${nombreDia} ${dia} ${nombreMes}`;
}

export function getNextDay() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow.toISOString().split("T")[0];
}

export function getDateWithDayAndYear(dateTimeString: string) {
  const parts = dateTimeString.split("-");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[2], 10);

  const date = new Date(year, month, day);

  const nombreMes = date
    .toLocaleString("es-ES", { month: "long" })
    .toUpperCase();

  const año = date.getFullYear();

  return `${nombreMes} ${año}`;
}

export const changeFormatDate = (inputDate: any) => {
  const months = [
    "ene",
    "feb",
    "mar",
    "abr",
    "may",
    "jun",
    "jul",
    "ago",
    "sep",
    "oct",
    "nov",
    "dic",
  ];

  const date = new Date(inputDate + "T00:00:00-05:00");
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month}. ${year}`;
};

export const handlePhoneCall = (phone: string) => {
  const telefonoURL = `tel:${phone}`;
  window.location.href = telefonoURL;
};

export const handlePlace = (direccion: string) => {
  const direccionURL = `https://www.google.com/maps/place/${direccion}`;
  if (direccion) {
    window.open(direccionURL, "_blank");
  } else {
    window.open(direccion, "_blank");
  }
};

export const handlePlace2 = (
  direccion: string | undefined,
  google: string | null | undefined
) => {
  let direccionURL;

  if (google) {
    direccionURL = `${google}`;
  } else if (direccion) {
    direccionURL = `https://www.google.com/maps/place/${direccion}`;
  } else {
    console.error("No se proporcionó una dirección válida.");
    return;
  }

  window.open(direccionURL, "_blank");
};

export const handleMail = (mail: string) => {
  const mailURL = `mailto:${mail}`;
  window.location.href = mailURL;
};

export const splitCasaAndina = (textoCompleto: string) => {
  let textoFinal = "";

  if (textoCompleto && typeof textoCompleto === "string") {
    const palabras = textoCompleto.split(" ");
    const palabrasSinCasaPrimera = palabras.filter(
      (palabra) =>
        palabra.toLowerCase() !== "casa" && palabra.toLowerCase() !== "andina"
    );
    textoFinal = palabrasSinCasaPrimera.join(" ");
  } else {
    textoFinal = "No hay texto disponible";
  }

  return textoFinal;
};

export const redirectUrlExternal = (link: string) => {
  if (link) {
    window.open(link, "_blank");
  }
};

export const updateMetadata = ({
  title,
  description,
  canonicalUrl,
  hreflang,
  nofollow = false,
}: any) => {
  document.title = title;
  const metaDescription = document.querySelector('meta[name="description"]');
  const isProduction = REACT_APP_ENV === 'production';

  if (metaDescription) {
    metaDescription.setAttribute("content", description || "");
  }

  const linkCanonical = document.querySelector('link[rel="canonical"]');
  if (canonicalUrl) {
    if (linkCanonical) {
      linkCanonical.setAttribute("href", canonicalUrl);
    } else {
      const newLinkCanonical = document.createElement("link");
      newLinkCanonical.rel = "canonical";
      newLinkCanonical.href = canonicalUrl;
      document.head.appendChild(newLinkCanonical);
    }
  }

  if (nofollow || !isProduction) {
    const metaRobots = document.querySelector('meta[name="robots"]') as HTMLMetaElement;
    metaRobots.content = "noindex, nofollow";
  }

  const existingHreflang = document.querySelectorAll(
    'link[rel="alternate"][hreflang]'
  );
  existingHreflang.forEach((el) => el.remove());

  if (hreflang && Array.isArray(hreflang)) {
    hreflang.forEach((langInfo) => {
      const linkAlternate = document.createElement("link");
      linkAlternate.rel = "alternate";
      linkAlternate.hreflang = langInfo.hreflang;
      linkAlternate.href = langInfo.href;
      document.head.appendChild(linkAlternate);
    });
  }
};

export const handleTravelClick = (better: any, coin: any, codigo?: string) => {
  const id = better.id_travel_click;
  const startDate = better.fecha_inicio;
  const endDate = better.fecha_fin;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString + "T00:00:00-05:00");
    return format(date, "MM/dd/yyyy");
  };

  const dateIn = formatDate(startDate);
  const dateOut = formatDate(endDate);

  const bookingLink = () => {
    const maintLink = validateTravelClickIsInMainteinnace()
    if(maintLink) return maintLink;

    if (codigo) {
      return `https://bookings.casa-andina.com/${id}?LanguageID=2&Rooms=1&datein=${encodeURIComponent(
        dateIn
      )}&dateout=${encodeURIComponent(
        dateOut
      )}&currency=${coin}&identifier=${codigo}`;
    } else {
      return `https://bookings.casa-andina.com/${id}?LanguageID=2&Rooms=1&datein=${encodeURIComponent(
        dateIn
      )}&dateout=${encodeURIComponent(dateOut)}&currency=${coin}`;
    }
    return "";
  };

  const link = bookingLink();
  if (link) {
    window.open(link, "_blank");
  }
};

export const handleTravelClickWhithoutDate = (
  better: any,
  coin: any,
  codigo?: string
) => {
  const id = better.id_travel_click;
  const startDate = better.fecha_inicio;
  const productId = better.product_id;

  let dateIn = "";

  if (startDate) {
    const formatDate = (dateString: string) => {
      const date = new Date(dateString + "T00:00:00-05:00");
      return format(date, "MM/yyyy");
    };

    dateIn = formatDate(startDate);
  }

  const bookingLink = () => {
    const maintLink = validateTravelClickIsInMainteinnace()
    if(maintLink) return maintLink;

    let link = `https://bookings.casa-andina.com/${id}?LanguageID=2&Rooms=1&currency=${coin}`;

    if (dateIn) {
      link += `&startmonth=${dateIn}`;
    }

    if (codigo) {
      link += `&identifier=${codigo}`;
    }
    if (productId) {
      link += `&ProdID=${productId}`;
    }
    return link;
  };

  const link = bookingLink();
  if (link) {
    window.open(link, "_blank");
  }
};

export const validateTravelClickIsInMainteinnace = () => {
  // Get the maintenance timestamps from environment variables
  const maintenanceStart = new Date(REACT_APP_MAINTENANCE_START || '');
  const maintenanceEnd = new Date(REACT_APP_MAINTENANCE_END || '');
  const currentTime = new Date();

  // Check if the current time is within the maintenance period
  if (currentTime >= maintenanceStart && currentTime <= maintenanceEnd) {
    return '/es/mantenimiento';
  }
  return false;
};

export const handleTravelClickRooms = (
  idHotel: any,
  coin: any,
  IdRoom: any,
  adultsOccupancy: number = 2,
) => {
  const bookingLink = () => {
    const maintLink = validateTravelClickIsInMainteinnace()
    if(maintLink) return maintLink;

    const maxAdultsOccupancy = adultsOccupancy == 1 ? 1 : 2;
    if (IdRoom) {
      return `https://bookings.casa-andina.com/${idHotel}?LanguageID=2&Rooms=1&RoomTypeId=${IdRoom}&currency=${coin}&adults=${maxAdultsOccupancy}`;
    }
    return "";
  };

  const link = bookingLink();
  if (link) {
    window.open(link, "_blank");
  }
};

// Funcion para darle color a las palabras premium, standard, select, asociados y centro en un texto
export const formattedTextType = (text: string) => {
  const keywords: { [key: string]: string } = {
    premium: "#002855",
    standard: "#FF4200",
    select: "#008EAA",
    asociado: "#D31364",
    centro: "#8C7CFF",
  };

  return text?.replace(
    /\b(premium|standard|select|asociado|centro)\b/gi,
    (match) => {
      const color = keywords[match.toLowerCase()];
      return `<span style="color: ${color}; font-weight: 700">${match}</span>`;
    }
  );
};

// Funcion para darle color a las palabras premium, standard, select, asociados y centro en los iconos
export const getColorIconFromText = (text: string): string => {
  const keywords = ["premium", "standard", "select", "asociado", "centro"];
  const textLowerCase = text?.toLowerCase();

  for (const words of keywords) {
    if (textLowerCase?.includes(words)) {
      let color = "";
      switch (words) {
        case "premium":
          color = "#002855";
          break;
        case "standard":
          color = "#FF4200";
          break;
        case "select":
          color = "#008EAA";
          break;
        case "asociado":
          color = "#D31364";
          break;
        case "centro":
          color = "#8C7CFF";
          break;
        default:
          color = "black";
          break;
      }
      return color;
    }
  }

  return "black";
};

export const estadiasDate = (inicio: any, fin: any, t: TFunction<"translation", undefined>, line: boolean) => {
  const fechaInicio = getDateWithoutTime(inicio);
  const fechaFin = getDateWithoutTime(fin);

  const fechaInicioObj = new Date(inicio);
  const fechaFinObj = new Date(fin);

  const diffInMs = fechaFinObj.getTime() - fechaInicioObj.getTime();
  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

  if (line) {
    return diffInDays > 1
      ? `${t("betterPrices.staysFromTo")} ${fechaInicio} ${t("betterPrices.to")} ${fechaFin}`
      : `${t("betterPrices.validForThe")} ${fechaInicio}`;
  } else {
    return diffInDays > 1
      ? `${t("betterPrices.stays")} <br /> ${t("betterPrices.from")} ${fechaInicio} ${t("betterPrices.to")} ${fechaFin}`
      : `${t("betterPrices.valid")} <br /> ${t("betterPrices.forThe")} ${fechaInicio}`;
  }
};

export const logSentryErrorResponse = async (response: Response, errorName: string = 'API Error') => {
  try {
    // Lee el contenido de la respuesta en formato JSON
    const errorContent = await response.json();

    // Captura un mensaje personalizado en Sentry con detalles adicionales
    Sentry.captureMessage(errorName, {
      level: "error",
      extra: {
        status: response.status,
        statusText: response.statusText,
        errorContent,
      },
    });
  } catch (error) {
    // Si hay un error al procesar la respuesta, captura el error en Sentry
    Sentry.captureException(error);
  }
};