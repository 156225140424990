import {
  DestinationsLink,
  Explore,
  FavDestinations,
  GalleryDestinations,
  BetterExperience
} from 'components/organisms'
import React, { useMemo, useLayoutEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useAppSelector } from 'hooks/useAppSelector'
import { getDestinations } from 'actions/destinations/services'
import { useTranslation } from 'react-i18next'

const Destinations: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    language,
    data: rawData,
    numerosdestinos,
    destinos: rawDestinos,
    destinosdestacados: rawDestinosDestacados,
    tipohoteles: rawTiposHoteles
  } = useAppSelector(state => ({
    language: state.config.language,
    data: state.language.dataDestinations,
    numerosdestinos: state.language.dataDestinations?.numero_destinos,
    destinos: state.language.dataDestinations?.destinos || [],
    destinosdestacados: state.language.dataDestinations?.destinos_destacados || [],
    tipohoteles: state.language.dataDestinations?.tipos_hoteles || [],
  }));

  const { data, destinos, destinosdestacados, tipohoteles } = useMemo(() => ({
    data: rawData || [],
    destinos: rawDestinos,
    destinosdestacados: rawDestinosDestacados,
    tipohoteles: rawTiposHoteles,
  }), [rawData, rawDestinos, rawDestinosDestacados, rawTiposHoteles]);

  const fetchData = () => getDestinations({ language, navigate });

  useLayoutEffect(() => {
    if (!rawData) {
      fetchData();
    }
    window.scrollTo(0, 0);
  }, [language, rawData]);

  const count = useMemo(() => (numerosdestinos ?? 11) - 1, [numerosdestinos]);

  return (
    <>
      <FavDestinations
        title={data?.subtitulos ? data?.subtitulos[0].subtitulo : t('destinations.section1')}
        subtitle={t('destinations.section1.1', { count })}
      />

      <GalleryDestinations data={destinosdestacados} />

      <Explore
        data={destinos}
        title={data?.subtitulos ? data?.subtitulos[1].subtitulo : t('destinations.section2')}
      />

      <DestinationsLink data={destinos} />

      <BetterExperience
        data={tipohoteles}
        title={data?.subtitulos ? data?.subtitulos[2].subtitulo : t('destinations.section4')}
      />
    </>
  );
};

export default Destinations;