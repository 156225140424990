import { BannerSlider } from 'components/organisms'
import { banner_data_centrado } from './data'
import './styles.scss'
import Section from 'components/molecules/section'
import { useEffect } from 'react'

const PoliticasPrivacidad = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <BannerSlider data={banner_data_centrado} gradiente/>
      <Section>
        <div className='politicas_privacidad politicas_privacidad-element'>
          <div className='politicas_privacidad__container'>
            <b>AUTORIZACIÓN DE USO DE IMAGEN</b>
            <p>Por el presente documento, yo, quien ha cumplido con el llenado del formulario “Comparte tus #MomentosCasaAndina”, a quien en adelante se le denominará EL CONCEDENTE, autorizo expresamente a Nessus Hoteles Perú S.A. con RUC 20505670443 con domicilio en Av. La Paz 463 Miraflores, Lima; y Nessus Hoteles Perú Selva S.A. con RUC 20393937948 con domicilio en Jr. Sucre Nro. 198, Callería, Coronel Portillo, Ucayali; en adelante “NESSUS HOTELES”, para que utilice de manera gratuita, mi nombre, apellido y/o imagen, en las redes sociales, los medios audiovisuales, impresos y digitales donde se difundan los anuncios, cuyo diseño, características, condiciones y especificaciones serán libremente decididos por NESSUS HOTELES.</p>
            <p>Su utilización, reproducción, transmisión a terceros, aprovechamiento para fines publicitarios, didácticos, y, en general, para su difusión y promoción de las actividades e intereses de NESSUS HOTELES, en todo medio, a nivel nacional, de cualquier forma, y en cualquier modalidad, incluyendo la producción de trabajos derivados de las fotografías y videos.</p>
            <p>La cesión de derechos de imagen y la autorización de EL CONCEDENTE son concedidas por el periodo de 2 años, a partir de su primera exposición en medios masivos impresos y digitales.</p>

            <br />

            <b>PROTECCIÓN DE PROTECCIÓN DE DATOS PERSONALES:</b>
            <p>Conforme a la Ley N° 29733, Ley de Protección de Datos Personales y su Reglamento, otorgo mi consentimiento previo, libre, expreso, inequívoco e informado, para que Nessus Hoteles Perú S.A. con RUC 20505670443 con domicilio en Av. La Paz 463 Miraflores, Lima; y Nessus Hoteles Perú Selva S.A. con RUC 20393937948 con domicilio en Jr. Sucre Nro. 198, Callería, Coronel Portillo, Ucayali; en adelante “NESSUS HOTELES”, puedan realizar el tratamiento de los datos personales que proporciono. Dichos datos serán almacenados en el Banco de datos con Código: RNPDP-PJP N° 5753 denominado “Web y Presencial” y RNPDP-PJP N° 6908 denominado “Recopilados vía web y presencial”, con la finalidad de realizar la fidelización de clientes a través de concursos. Para llevar a cabo la finalidad descrita, es obligatorio nos proporcione los siguientes datos personales: nombre y apellidos, número del documento nacional de identidad, dirección de correo electrónico, imagen. No utilizaremos datos sensibles. De no proporcionar los datos obligatorios no podrá participar del concurso.</p>
            <p>Los datos personales se transferirán a nivel nacional e internacional a: https://wp.casa-andina.com/Flujo-transfronterizo-RECOPILADOS.pdf . Los datos serán conservados por el periodo de 15 años, a partir de su primera exposición en medios masivos impresos y digitales. Puede ejercer los derechos de acceso, rectificación, cancelación y oposición, a través del correo electrónico proteccióndedatos@casa-andina.com o ingresando al siguiente enlace web https://www.casa-andina.com/arco/. Además de los anteriores derechos, tendrá derecho a retirar el consentimiento otorgado en cualquier momento mediante el procedimiento más arriba descrito, sin que dicha retirada de consentimiento afecte a la licitud del tratamiento anterior a la retirada de este. De considerar que no ha sido atendido en el ejercicio de sus derechos puede presentar una reclamación ante la Autoridad Nacional de Protección de Datos Personales.</p>
          </div>
        </div>
      </Section>
    </>
  )
}

export default PoliticasPrivacidad
