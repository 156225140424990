import { ICheckbox } from "./props";
import "./styles.scss";

const Checkbox = ({
  id,
  titulo,
  checked,
  onChange,
  onBlur,
  className,
  name,
}: ICheckbox) => {
  return (
    <div>
      <label className={`inputcheckbox ${className}`}>
        <div className="inputcheckbox_content">
          <input
            type="checkbox"
            checked={checked}
            name={name}
            onChange={(e) => onChange(name, e.target.checked)}
            onBlur={onBlur}
          />
        </div>
        {titulo}
      </label>
    </div>
  );
};

export default Checkbox;
