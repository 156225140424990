import React from 'react'
import './styles.scss';
import Lineal from 'assets/images/lineal.webp';

const Carousel = () => {
  const linealData = {
    index: 1,
    path: Lineal,
    alt: 'Descripción de la imagen',
  };

  const carouselData = Array.from({ length: 3 }, (_, index) => ({
    ...linealData,
    index: index + 1
  }));


  return (
    <div className="infinite-carousel">
      {carouselData.map(({ path, alt, index }) => (
        <img
          key={index}
          src={path}
          alt={`${alt} ${index}`}
          className="infinite-carousel-image"
        />
      ))}
    </div>
  );
};

export default Carousel;