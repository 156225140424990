import { useEffect } from "react";
import { BannerSlider } from "components/organisms";
import Section from "components/molecules/section";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const PoliticasPrivacidad = () => {
  const { t } = useTranslation();

  const banner_data_centrado = [
    {
      id: 1,
      titulo: t("privacyPolicy.privacyPolicy"),
      subtitulo: "",
      color_fondo_logo: "rgba(62, 145, 41, 1)",
      img_desktop: {
        name: "banner-politicas-privacidad",
        path: "https://s3.us-east-1.amazonaws.com/ca-webprod/media/c9c6116d-b559-445b-b8c3-3b6dd38656a2.jpg",
        width: 1440,
        height: 400,
        size: 794666,
        title: "Banner Políticas web de privacidad",
        alt: "Banner Políticas web de privacidad",
        description: null,
        caption: "Banner Políticas web de privacidad",
      },
      img_mobile: [],
      descuento: "",
      descripcion_boton: "",
      redireccion_boton: "",
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: "landings",
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BannerSlider data={banner_data_centrado} gradiente />
      <Section>
        <div className="politicas_privacidad politicas_privacidad-element">
          <div className="politicas_privacidad__container">
            <h1>{t("privacyPolicy.privacyPolicy")}</h1>

            <p>{t("privacyPolicy.statement")}</p>

            <p>
              <strong>{t("privacyPolicy.generalObjective")}</strong>
            </p>

            <p>{t("privacyPolicy.text1")}</p>
            <p>
              {t("privacyPolicy.text2")}{" "}
              <a
                href="https://s3.us-east-1.amazonaws.com/ca-webprod/media/3ffa6936-b2a5-454a-a294-9f796d73449c.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t("privacyPolicy.seeTransboundaryFlow")}
              </a>{" "}
              {t("privacyPolicy.and")}{" "}
              <a
                href="https://s3.us-east-1.amazonaws.com/ca-webprod/media/flujo-transfronterizo-recopilados.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t("privacyPolicy.seeCollectedTransboundaryFlow")}
              </a>{" "}
              {t("privacyPolicy.text3")}
            </p>
            <p>{t("privacyPolicy.text4")}</p>
            <p>{t("privacyPolicy.text5")}</p>

            <ul>
              <li>
                II Tucano Peru SAC, Calle Elías Aguirre 633 distrito de
                Miraflores, ciudad de Lima
              </li>
              <li>
                Coltur Peruana de Turismo, Av. Reducto 1255, distrito de
                Miraflores, ciudad de Lima
              </li>
              <li>
                Lima Tours S.A.C. , Av. Nicolás de Piérola 589 piso 18, distrito
                de Lima-cercado, ciudad de Lima
              </li>
              <li>
                Inversiones Receptour S.A., Av. Del Pinar 180 Of. 1001 Urb.
                Chacarilla del Estanque, distrito de Santiago de Surco, ciudad
                de Lima
              </li>
              <li>
                Silvana Tours SAC, Av. 28 de Julio 660-Int.11, distrito de
                Miraflores, ciudad de Lima
              </li>
              <li>
                Viajes Pacifico S.A.C, Av. La Mar N° 163, distrito de
                Miraflores, ciudad de Lima
              </li>
              <li>
                Domiruth Travel Service S.A.C., Urbanización Barboncito, calle
                Rio de Janeiro 216, distrito de Miraflores, ciudad de Lima
              </li>
              <li>
                Condor Travel S.A.C, Calle Mayor Armando Blondet 249, distrito
                de San Isidor, ciudad de Lima
              </li>
            </ul>

            <p>{t("privacyPolicy.optionalPurpose")}</p>

            <p>1. {t("privacyPolicy.text6")}</p>

            <p>2. {t("privacyPolicy.text7")}</p>

            <p>{t("privacyPolicy.text8")}</p>
            <p>{t("privacyPolicy.text9")}</p>
            <p>{t("privacyPolicy.text10")}</p>

            <p>
              <strong>{t("privacyPolicy.specificObjectives")}</strong>
            </p>

            <p>
              <strong>1. {t("privacyPolicy.dataProcessing")}</strong>
            </p>

            <p>
              <strong>A. {t("privacyPolicy.typesOfCollectedData")}</strong>
            </p>
            <p>{t("privacyPolicy.text11")}</p>
            <p>– {t("privacyPolicy.nameSurname")}</p>
            <p>– {t("privacyPolicy.email")}</p>
            <p>– {t("privacyPolicy.nationality")}</p>
            <p>– {t("privacyPolicy.phone")}</p>
            <p>– {t("privacyPolicy.idDocument")}</p>
            <p>– {t("privacyPolicy.address")}</p>
            <p>– {t("privacyPolicy.gender")}</p>
            <p>– {t("privacyPolicy.paymentInfo")}</p>

            <p>{t("privacyPolicy.text12")}</p>

            <p>
              <strong>B. {t("privacyPolicy.dataCollectionMethods")}</strong>
            </p>
            <p>{t("privacyPolicy.text13")}</p>

            <p>
              <strong>
                C. {t("privacyPolicy.infoTransferToThirdParties")}
              </strong>
            </p>
            <p>{t("privacyPolicy.text14")}</p>

            <ul>
              <li>
                Il Tucano Peru SAC, Calle Elías Aguirre 633 distrito de
                Miraflores, ciudad de Lima
              </li>
              <li>
                Coltur Peruana de Turismo, Av. Reducto 1255, distrito de
                Miraflores, ciudad de Lima
              </li>
              <li>
                Lima Tours S.A.C. , Av. Nicolás de Piérola 589 piso 18, distrito
                de Lima-cercado, ciudad de Lima
              </li>
              <li>
                Inversiones Receptour S.A., Av. Del Pinar 180 Of. 1001 Urb.
                Chacarilla del Estanque, distrito de Santiago de Surco, ciudad
                de Lima
              </li>
              <li>
                Silvana Tours SAC, Av. 28 de Julio 660-Int.11, distrito de
                Miraflores, ciudad de Lima
              </li>
              <li>
                Viajes Pacifico S.A.C, Av. La Mar N° 163, distrito de
                Miraflores, ciudad de Lima
              </li>
              <li>
                Domiruth Travel Service S.A.C., Urbanización Barboncito, calle
                Rio de Janeiro 216, distrito de Miraflores, ciudad de Lima
              </li>
              <li>
                Condor Travel S.A.C, Calle Mayor Armando Blondet 249, distrito
                de San Isidor, ciudad de Lima
              </li>
            </ul>

            <p>
              {t("privacyPolicy.text15")}{" "}
              <a
                href="https://empresas-intercorp.azurewebsites.net/"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://empresas-intercorp.azurewebsites.net/
              </a>{" "}
              {t("privacyPolicy.text16")}
            </p>

            <p>
              <strong>D. {t("privacyPolicy.processingOnBehalf")}</strong>
            </p>
            <p>{t("privacyPolicy.text17")}</p>

            <p>
              <strong>E. {t("privacyPolicy.transboundaryFlow")}</strong>
            </p>
            <p>
              {t("privacyPolicy.text18")}{" "}
              <a
                href="https://s3.us-east-1.amazonaws.com/ca-webprod/media/3ffa6936-b2a5-454a-a294-9f796d73449c.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t("privacyPolicy.flow-of-customers")}
              </a>{" "}
              {t("privacyPolicy.and")}{" "}
              <a
                href="https://s3.us-east-1.amazonaws.com/ca-webprod/media/flujo-transfronterizo-recopilados.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t("privacyPolicy.flow-of-collected-data")}
              </a>
            </p>

            <p>
              <strong>
                2. {t("privacyPolicy.personalDataProcessingPurposes")}
              </strong>
            </p>

            <p>- {t("privacyPolicy.text19")}</p>
            <p>- {t("privacyPolicy.advertising")}</p>
            <p>- {t("privacyPolicy.economicManagement")}</p>
            <p>- {t("privacyPolicy.compliance")}</p>
            <p>- {t("privacyPolicy.clientListManagement")}</p>
            <p>- {t("privacyPolicy.statisticalPurposes")}</p>
            <p>- {t("privacyPolicy.customerLoyalty")}</p>

            <p>{t("privacyPolicy.text20")}</p>
            <p>{t("privacyPolicy.text21")}</p>

            <p>
              <strong>3. {t("privacyPolicy.dataProcessingMinors")}</strong>
            </p>

            <p>
              <strong>A. {t("privacyPolicy.minorsUnder14")}</strong>
            </p>
            <p>{t("privacyPolicy.text22")}</p>

            <p>
              <strong>B. {t("privacyPolicy.minorsOver14")}</strong>
            </p>
            <p>{t("privacyPolicy.text23")}</p>
            <p>{t("privacyPolicy.text24")}</p>

            <p>
              <strong>4. {t("privacyPolicy.consentDataProcessing")}</strong>
            </p>

            <p>{t("privacyPolicy.text25")}</p>

            <p>
              <strong>5. {t("privacyPolicy.userRights")}</strong>
            </p>

            <p>{t("privacyPolicy.text26")}</p>

            <p>{t("privacyPolicy.text27")}</p>
            <p>
              <strong>– {t("privacyPolicy.access")}</strong>&nbsp;
              {t("privacyPolicy.accessDescription")}
            </p>
            <p>
              <strong>– {t("privacyPolicy.rectification")}</strong>&nbsp;
              {t("privacyPolicy.rectificationDescription")}
            </p>
            <p>
              <strong>– {t("privacyPolicy.cancellation")}</strong>&nbsp;
              {t("privacyPolicy.cancellationDescription")}
            </p>
            <p>
              <strong>– {t("privacyPolicy.opposition")}</strong>&nbsp;
              {t("privacyPolicy.oppositionDescription")}
            </p>
            <p>{t("privacyPolicy.text28")}</p>

            <p>{t("privacyPolicy.text29")}</p>

            <p>
              <strong className="underlink">
                {t("privacyPolicy.nessusWebsite")}
              </strong>{" "}
              {t("privacyPolicy.nessusEmailDescription")}
            </p>

            <p>
              <strong className="underlink">
                {t("privacyPolicy.electronicCommunication")}
              </strong>{" "}
              {t("privacyPolicy.electronicCommunicationDescription")}{" "}
              <a href="mailto:protecciondedatos@casa-andina.com">
                protecciondedatos@casa-andina.com
              </a>{" "}
              {t("privacyPolicy.text30-1")}
            </p>

            <p>•&nbsp;&nbsp;{t("privacyPolicy.arcoRequest")}</p>
            <p>•&nbsp;&nbsp;{t("privacyPolicy.simpleCopyId")}</p>
            <p>•&nbsp;&nbsp;{t("privacyPolicy.simpleCopyPower")}</p>
            <p>•&nbsp;&nbsp; {t("privacyPolicy.necessaryDocs")}</p>

            <p>
              <strong>6. {t("privacyPolicy.useOfCookies")}</strong>
            </p>

            <p>{t("privacyPolicy.text31")}</p>
            <p>{t("privacyPolicy.text32")}</p>

            <p>
              <strong>7. {t("privacyPolicy.intellectualProperty")}</strong>
            </p>

            <p>{t("privacyPolicy.text33")}</p>
            <p>{t("privacyPolicy.text34")}</p>

            <p>
              <strong>8. {t("privacyPolicy.security")}</strong>
            </p>

            <p>
              <strong>A. {t("privacyPolicy.securityMeasures")}</strong>
            </p>
            <p>{t("privacyPolicy.text35")}</p>

            <p>
              <strong>B. {t("privacyPolicy.responsibilityForTransfer")}</strong>
            </p>
            <p>{t("privacyPolicy.text36")}</p>
            <p>{t("privacyPolicy.text37")}</p>

            <p>
              <strong>9. {t("privacyPolicy.responsibilityForContent")}</strong>
            </p>

            <p>{t("privacyPolicy.text38")}</p>
            <p>{t("privacyPolicy.text39")}</p>
            <p>{t("privacyPolicy.text40")}</p>
            <p>{t("privacyPolicy.text41")}</p>
            <p>{t("privacyPolicy.text42")}</p>
            <p>{t("privacyPolicy.text43")} webmaster@casa-andina.com.</p>

            <p>
              <strong>
                10. {t("privacyPolicy.responsibilityForSocialContent")}
              </strong>
            </p>

            <p>{t("privacyPolicy.text44")}</p>
            <p>{t("privacyPolicy.text45")}</p>

            <p>
              <strong>11. {t("privacyPolicy.changesToPrivacyPolicy")}</strong>
            </p>

            <p>{t("privacyPolicy.text46")}</p>

            <p>
              <strong>12. {t("privacyPolicy.userCommitment")}</strong>
            </p>

            <p>{t("privacyPolicy.text47")}</p>
            <p>{t("privacyPolicy.text48")}</p>
            <p>{t("privacyPolicy.text49")}</p>

            <p>
              <strong>13. {t("privacyPolicy.onlinePaymentProcessing")}</strong>
            </p>

            <p>{t("privacyPolicy.text50")}</p>

            <p className="ta-center">
              <img
                src="https://s3.us-east-1.amazonaws.com/ca-webprod/media/razon-social-hoteles.webp"
                alt="table-hotels"
              />
            </p>

            <p>
              <strong>{t("privacyPolicy.glossary.title")}</strong>
            </p>

            <p>
              –&nbsp;
              <strong>{t("privacyPolicy.glossary.personalDataBank")}</strong>
              {t("privacyPolicy.glossary.text51")}
            </p>
            <p>
              –&nbsp;<strong>{t("privacyPolicy.glossary.userConsent")}</strong>{" "}
              {t("privacyPolicy.glossary.text52")}
            </p>
            <p>
              –&nbsp;<strong>{t("privacyPolicy.glossary.personalData")}</strong>{" "}
              {t("privacyPolicy.glossary.text53")}
            </p>
            <p>
              –&nbsp;
              <strong>{t("privacyPolicy.glossary.sensitiveData")}</strong>{" "}
              {t("privacyPolicy.glossary.text54")}
            </p>
            <p>
              –&nbsp;<strong>{t("privacyPolicy.glossary.dataTransfer")}</strong>{" "}
              {t("privacyPolicy.glossary.text55")}
            </p>

            <p>
              –&nbsp;
              <strong>{t("privacyPolicy.glossary.dataProcessing")}</strong>{" "}
              {t("privacyPolicy.glossary.text56")}
            </p>

            <p>
              –&nbsp;<strong>{t("privacyPolicy.glossary.dataOwner")}</strong>{" "}
              {t("privacyPolicy.glossary.text57")}
            </p>

            <p>
              –&nbsp;<strong>{t("privacyPolicy.glossary.user")}</strong>{" "}
              {t("privacyPolicy.glossary.text58")}
            </p>
          </div>
        </div>
      </Section>
    </>
  );
};

export default PoliticasPrivacidad;
