import React from "react";
import "./styles.scss";
import { Icon, IconText } from "components/atoms";

const Modal = ({
  children,
  close,
  desktop,
  btnactive,
  btninactive,
  mobile,
  allowTabs,
  showModal,
  allowButtons,
  title,
  allowClose,
  isTitle,
  isSubtitle,
  subtitleCustom,
  onlyClose,
  breadcrumb,
  route,
  buttonCustom,
  isHeight,
  oncbtnactive,
  changeWidth,
  btnWaze,
  btnGoogle,
  onlyModal,
}: any) => {
  const handleClickOutside = (e: any) => {
    if (e.target.classList.contains("modalb__overlay")) {
      close();
    }
  };

  const redirectToURL = (oncbtnactive: string) => {
    window.location.href = oncbtnactive;
  };

  return (
    <>
      {showModal ? (
        <div className="modalb">
          <div
            className="modalb__overlay"
            onClick={onlyModal ? handleClickOutside : () => {}}
          >
            <div
              className={`modalb__overlay--content ${
                changeWidth ? "min-width" : "large-width"
              }`}
            >
              <div className="modalb-close">
                {breadcrumb ? (
                  <div className="d-flex jc-flex-start ai-center g-1rem">
                    <Icon
                      name="close"
                      list="items"
                      height={20}
                      width={20}
                      onClick={close}
                      className="cursor"
                    />
                    <p className="titleroute">
                      {title} / <span>{route}</span>
                    </p>
                  </div>
                ) : isTitle ? (
                  <IconText
                    text={title}
                    nameIcon={"close"}
                    listIcon="items"
                    w={20}
                    h={20}
                    className="title"
                    onClick2={close}
                    className2="cursor"
                  />
                ) : onlyClose ? null : (
                  <Icon
                    name="close"
                    list="items"
                    width={20}
                    height={20}
                    onClick={close}
                    className="cursor"
                  />
                )}
                {isSubtitle && subtitleCustom}
              </div>
              {allowTabs ? (
                <div className="modalb-tabs">
                  <div className="modalb-tabs__text">{children}</div>
                </div>
              ) : (
                <div className="modalb-content">
                  <div className="modalb-content__text">{children}</div>
                </div>
              )}
              {allowButtons &&
                (buttonCustom ? (
                  <div className="modalb-buttons">
                    {btnWaze && (
                      <a
                        className="btn-google nlink"
                        target="_blank"
                        rel="noreferrer"
                        href={btnWaze}
                      >
                        <IconText
                          listIcon={"social"}
                          nameIcon="waze"
                          text={"Waze"}
                          w={24}
                          h={24}
                          family={"txnormal"}
                        />
                      </a>
                    )}
                    {btnGoogle && (
                      <a
                        className="btn-google nlink"
                        target="_blank"
                        rel="noreferrer"
                        href={btnGoogle}
                      >
                        <IconText
                          listIcon={"social"}
                          nameIcon="google-maps"
                          text={"Google Maps"}
                          w={24}
                          h={24}
                          family={"txnormal"}
                        />
                      </a>
                    )}
                  </div>
                ) : (
                  <div className="modalb-buttons">
                    {oncbtnactive && <div onClick={btninactive}>atrás</div>}
                    {oncbtnactive && (
                    <button
                      className="gold"
                      onClick={() => redirectToURL(oncbtnactive)}
                    >
                      {btnactive}
                    </button>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

Modal.defaultProps = {
  allowClose: true,
  isTitle: true,
  onlyClose: false,
  breadcrumb: false,
  isHeight: false,
  allowButtons: false,
  allowTabs: false,
  buttonCustom: false,
  isSubtitle: false,
  changeWidth: false,
  onlyModal: false,
};

export default Modal;
