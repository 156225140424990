import React, { useEffect } from "react";
import { BannerSlider } from "components/organisms";
import { useAppSelector } from "hooks/useAppSelector";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "components/molecules/loader";
import { getRestaurantsCategory } from "actions/restaurants/services";
import PromotionsCard from "components/organisms/promotions-card";
import PromotionsCopy from "components/organisms/promotions-copy";

const RestaurantRestaurant = () => {
  const { category } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [route, setRoute] = React.useState<string | undefined>(undefined);

  const { loading, coin, language, promociones, data } = useAppSelector(
    (state) => ({
      loading: state.language.loading,
      coin: state.config.coin,
      language: state.config.language,
      data: state.language.dataRestaurantsCategory,
      promociones:
        state.language.dataRestaurantsCategory?.categoria
          ?.promociones_restaurante || [],
    })
  );

  useEffect(() => {
    if (location.pathname !== route) {
      setRoute(location.pathname);
    }
  }, [location.pathname, route]);
  
  useEffect(() => {
    if (category && language && coin) {
      getRestaurantsCategory({ category, language, coin, navigate });
      window.scrollTo(0, 0);
    }
  }, [category, language, coin, navigate]);

  if (loading || !data) return <Loader />;

  return (
    <>
      <BannerSlider data={data?.categoria?.banners} gradiente />

      <PromotionsCopy data={data.categoria}/>

      <PromotionsCard
        title={data?.categoria?.titulo}
        data={promociones}
        type="restaurant-logo"
        isFilter={false}
      />
    </>
  );
};

export default RestaurantRestaurant;
