import { Card, Dropdown, Text } from "components/atoms";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.scss";
import { ICalendarTypes } from "./props";
import { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

type CalendarType = "calendar" | "calendar2" | "calendar3";

const CalendarTypes = ({
  price_days,
  price_month,
  better_prices,
  title,
  id_hotel,
  coin,
}: ICalendarTypes) => {
  const { t } = useTranslation();
  const orderBy = useMemo(
    () => [
      { id: 1, titulo: t("hotelCity.bestPrices"), data: better_prices },
      { id: 2, titulo: t("hotelCity.calendar"), data: price_days },
      { id: 3, titulo: t("hotelCity.months"), data: price_month },
    ],
    [better_prices, price_days, price_month]
  );

  const [selectedOption, setSelectedOption] = useState<string>("");
  const [type, setType] = useState<CalendarType>("calendar");
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const firstWithData = orderBy.find((item) => item.data.length > 0);
    if (firstWithData) {
      setSelectedOption(firstWithData.titulo);
      handleSelect(firstWithData);
    }
  }, [orderBy]);

  const handleSelect = (option: any) => {
    setSelectedOption(option.titulo);
    switch (option.titulo) {
      case t("hotelCity.bestPrices"):
        setType("calendar");
        break;
      case t("hotelCity.calendar"):
        setType("calendar2");
        break;
      case t("hotelCity.months"):
        setType("calendar3");
        break;
      default:
        setType("calendar");
        break;
    }

    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(0);
    }
  };

  const filteredOrderBy = orderBy.filter((item) => item.data.length > 0);

  return (
    <div className="promotionscalendar">
      <div className="promotionscalendar__container">
        <div className="promotionscalendar__container--titlefilter">
          <Text type="subtitle" text={title} className={"subtitle"} />
          <div className="drop">
            <Dropdown
              title={t("hotelCity.view")}
              ordenarPor={filteredOrderBy}
              selectedOption={selectedOption}
              onSelect={handleSelect}
            />
          </div>
        </div>
        <div className="promotionscalendar__container--description">
          <Swiper
            ref={swiperRef}
            slidesPerView={"auto"}
            grabCursor={true}
            navigation={true}
            className={"promotionscalendar__container--description--calendar"}
          >
            {filteredOrderBy
              .find((item) => item.titulo === selectedOption)
              ?.data.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <Card
                    type={type}
                    data={item}
                    id_hotel={id_hotel}
                    coin={coin}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default CalendarTypes;
