import { useState } from "react";
import Icon from "components/atoms/icon";
import { IAccordionLink, IAccordionLinkDescription } from "./props";
import './styles.scss'
import { useNavigate } from "react-router-dom";
import { Text } from "components/atoms";

const AccordionLink = ({ title, descriptions, pdX, bgColor, txColor, txColorDescription, slug, handleToggle }: IAccordionLink) => {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();

  const handleOnClick = (event: React.MouseEvent<HTMLTableSectionElement>) => {
    event.stopPropagation();
    setActive(!active);
    
    navigate(slug);
  };

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  return (
    <section className={`accordionLink bg-${bgColor} tc-${txColor}   ${active ? 'active' : ''}`} onClick={handleOnClick} style={{ padding: pdX}}>
      <div className="accordionLink__container">
        <div className="title">
          <Text text={title} type='paragraph' onClick={handleToggle} className="title__edit" />
          <Icon name="down" list="arrows" color="white" width={24} height={24} />
        </div>
        {active && descriptions && descriptions.length > 0 && (
          <div className="description">
            {descriptions.map(({ id, descripton, link }: IAccordionLinkDescription) => (
              <a
                key={id}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className={`description__link`}
                onClick={handleLinkClick}
              >
                {descripton}
              </a>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

AccordionLink.defaultProps = {
  bgColor: 'secondary-color1',
  txColor: 'secondary-color2',
  pdX: '0px 20px',
}

export default AccordionLink;
