import { Icon, IconText, Text } from "components/atoms";
import "./styles.scss";
import { IBannerRestaurants } from "./props";
import { handlePhoneCall, handlePlace2 } from "utils/function";

const BannerRestaurants = ({
  activeIcons,
  data,
  google,
  types,
  hotelesRef,
  bannerRef,
  place,
  direccion,
  telefono,
  city,
}: IBannerRestaurants) => {
  const bgImg = data.img_banner?.path || data.img?.path;

  const bgColor = data?.color || place?.color;

  if (!data) {
    return null;
  }

  const scrollToSection = (ref: any) => {
    if (ref.current) {
      const marginTop = 70;

      const topOffset = ref.current.offsetTop - marginTop;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  const handleRedireccionar = () => {
    scrollToSection(hotelesRef);
  };

  return (
    <section ref={bannerRef}>
      <div className="bannerestaurant2">
        <div className="bannerestaurant2__content">
          <div
            className="bannerestaurant2__content--bg"
            style={{ backgroundColor: bgColor }}
          >
            {types === "tipo_hotel" && (
              <div className="type-hotel">
                <Text
                  text="Casa Andina"
                  type="paragraph"
                  className="subtitle"
                />
                <Text text={data.titulo} type="paragraph" className="title" />
                  <button className="btn-hotel" onClick={handleRedireccionar}>
                    <Text
                      text="Ver hoteles"
                      type="paragraph"
                      className="paragraph"
                    />
                    <Icon
                      name="down-white"
                      list={"arrows"}
                      width={24}
                      height={24}
                    />
                  </button>
              </div>
            )}
            {types === "tipo_restaurante" && place && (
              <div className="type-direction">
                {
                  place.img?.path && (
                    <img src={place.img?.path} alt={place.img?.alt} height={60} />
                  )
                }
                <div className="bnrestaurant__text">
                  <div className="active-icons">
                    <Text className="title" type="paragraph" text={city} />
                  </div>
                </div>
                <div className="items">
                  <IconText
                    nameIcon="place-white"
                    listIcon="items"
                    w={24}
                    h={24}
                    text={direccion || ""}
                    type="txt"
                    className="items-txt"
                    onClick={() => handlePlace2(direccion || "", google)}
                  />
                  {telefono && (
                    <IconText
                      nameIcon="phone-white"
                      listIcon={"items"}
                      w={24}
                      h={24}
                      text={telefono}
                      type="txt"
                      className="items-txt"
                      onClick={() => handlePhoneCall(telefono || "")}
                    />
                  )}
                </div>
              </div>
            )}
            {types === "restaurantes" && place && (
              <div className="type-restaurant">
                <img src={place.img?.path} alt={place.img?.alt} />

                <Text
                  html={{ __html: place.descripcion }}
                  type="paragraph"
                  className="paragraph"
                />
              </div>
            )}
          </div>
          <div
            className="bannerestaurant2__content--bgimg"
            style={{
              backgroundImage: `url(${bgImg})`,
            }}
          ></div>
        </div>
      </div>
    </section>
  );
};

BannerRestaurants.defaultProps = {
  types: "tipo_hotel",
};

export default BannerRestaurants;
