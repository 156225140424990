export const esMessages = {
  // Language
  spanish: 'Español',
  english: 'Inglés',
  portuguese: 'Portugués',

  // Slugs
  slugdestinations: 'destinos',
  slughotels: 'hoteles',
  slugrestaurants: 'restaurantes',
  slugoffers: 'ofertas',
  slugevents: 'eventos',
  slugenterprises: 'empresas',
  slugbest_price: 'mejores-precios',

  // Sections
  description: 'Descripción',
  hotels: 'Hoteles',
  restaurants: 'Restaurantes',
  questions: 'Preguntas',
  place: 'Lugar',
  schedule: 'Horario',
  shift: 'Turnos',
  prices: 'Precios',

  // NavBar
  navBarBook: 'Reservar',
  navBarDestinations: 'Destinos',
  navBarHotels: 'Hoteles',
  navBarRestaurants: 'Restaurantes',
  navBarCyberCa: 'Cyber CA',
  navBarOffers: 'Ofertas',
  navBarLife: 'Life',
  navBarCorporate: 'Coorporativo',
  navBarEvents: 'Eventos',

  // Buttons
  buttonreservar: 'Reserva',
  buttonBanner: 'Regístrate gratis',
  buttonHotels: 'Ver hoteles',
  buttonHotel: 'Ver hotel',
  buttonBackToTop: 'Volver arriba',

  // Section Description
  descriptionTitle: 'Conoce nuestro destino',
  descriptionToogleMore: 'Ver más',
  descriptionToogleLess: 'Ver menos',
  showless: 'Mostrar menos',
  showmore: 'Mostrar más',

  // Section Activities
  activitiesTitle: 'Razones para elegirnos cuando viajes por el Perú',
  activitiesKnowLess: 'saber menos',

  // Sections Better Price
  betterPriceTitle: 'Los mejores precios del momento',

  // Sections Discounts
  discountsTitle: 'Disfruta con nuestros descuentos',

  // Section Everything Peru
  everythingPeruTitle: 'Conoce todo lo que tenemos para ti, conoce del Perú',

  // Sections Banners
  bannerTitle:
    '¿Aún no eres socios? Conviértete en nuestro socio Life y canjea noches gratis.',

  // Section Hotel
  sortAndFilter: 'Ordenar y filtrar',
  delete: 'Borrar',
  sortBy: 'Ordenar por',
  sortByRecommended: 'Recomendadas',
  sortByPriceLowToHigh: 'Precio (de menos a más)',
  sortByPriceHighToLow: 'Precio (de más a menos)',
  sortByTopRated: 'Puntuación destacada',
  filterBy: 'Filtrar por',
  searchByName: 'Buscar por nombre',
  chooseHotelOrDestination: 'Elige un hotel o destino',
  pricePerNight: 'Precio por noche',
  pricePerNightWithoutTax: 'Precio por noche sin impuestos desde',
  travelType: 'Tipo de viaje',
  hotelCategory: 'Categoría del hotel',
  experiences: 'Experiencias',
  services: 'Servicios',
  ready: 'Listo',

  // Section Hotels
  hotelsTitle: 'Nuestros hoteles en',
  hotelsReview: 'Reseñas',
  hotelReview: 'Reseña',
  hotelsPriceFrom: 'Desde',
  hotelsPricesIgv: 'de IGV y servicios',
  ourServices: 'Nuestros servicios',
  ourHouseServices: 'Nuestros servicios de nuestra casa',
  moreAmenities: 'Más comodidades',
  ourRoomsAndSuites: 'Nuestras habitaciones y suites',
  enjoyPlaceInOurHouse1: `Disfruta de`,
  enjoyPlaceInOurHouse2: `en nuestra casa`,

  maximizeWithBestPromotions:
    'Disfruta al máximo con las mejores promociones de nuestra casa',
  activitiesInCity1: `Actividades en`,
  activitiesInCity2: `suma momentos de alegría y diversión a tu viaje`,
  guestReviews: 'Lo que dicen nuestros huéspedes',
  rating: 'Valoración',
  excelent: 'Excelente',
  discoverOurPrices: 'Conoce todos nuestros mejores precios del momento',
  allOurDestinations: 'Todos nuestros destinos',
  discoverOurDestination: 'Conoce todos nuestros mejores destinos del momento',
  discoverOurHotels: `Conoce nuestros hoteles`,

  // Section Restaurants
  learnMore: 'saber más',
  restaurantsTitle: 'Visita nuestros restaurantes',
  exploreOurRestaurants: 'Conoce nuestros restaurantes',
  exploreOurRestaurantsIn: 'Conoce nuestros restaurantes en',
  discoverPeruvianCulinaryRichness: 'Descubre la riqueza culinaria del Perú',
  enjoyOurPromotions: 'Disfruta de nuestras promociones',

  // Section Promotions
  day: 'Día',
  days: 'Días',
  hour: 'Hora',
  hours: 'Horas',
  minute: 'Minuto',
  minutes: 'Minutos',
  second: 'Segundo',
  seconds: 'Segundos',
  comingSoon: 'Falta poco días para nuestro',
  specialPackagesForYou:
    'Paquetes especiales para ti, viaja y vive experiencias con Casa Andina',
  enjouyAndTreatYourself: 'Disfruta y date un gusto probando lo mejor',
  findTheBestDiscounts: 'Encuentra el mejor descuento para ti',
  discountsyourBanks: 'Descuentos con tus bancos',
  getToKnowPerufirst:'Conoce el Perú primero, aprovecha nuestros descuentos',

  // Section Explore
  exploreTitle: 'Explora más allá de',
  exploreHotel: 'Hotel',
  exploreHotels: 'Hoteles',
  exploreTitle2: 'Descubre el Perú en cada destino',

  // Section Better Experience
  betterExperienceTitle: 'Vive la mejor experiencia en nuestros hoteles',

  //Section Questions
  questionsTitle: 'Preguntas frecuentes sobre',
  questionsSubtitle: 'Resolvemos tus dudas',

  // Page
  morethan: 'Más de',

  // Page Destinations City
  pageDestinationsCityTitle: 'destinos para ti',
  pageDestinationsCityDescription: 'Tu hotel ideal, tu elección',

  // Page Hotels
  pageHotelsTitle: 'hoteles para conocer',

  //Section Footer
  footerFollowUS: 'Síguenos en redes ',
  footerPayment: 'Medios de pago',
  footerCustomerService: 'Atención al cliente',
  footerCalUsForFree: 'Llámanos gratis',
  footerContactUs: 'Contáctanos',
  footerTermsAndConditions: 'Términos y condiciones',
  footerSmv:
    '(SMV) Accede a la información pública del emisor que se difunden en el Portal del Mercado de Valores Política Web de Privacidad',
  footerPrivacyPolicy: 'Política Web de privacidad',
  footerAllRightsReserved: 'Todos los derechos reservados - Casa Andina',

  //Quienes somos
  quienessomosuno:
    'Somos una cadena hotelera peruana fundada en febrero de 2003, enfocada en proporcionar a nuestros huéspedes una experiencia de viaje. Integrando las particularidades de cada destino, desde la arquitectura hasta la gastronomía, actividades y música, creamos momentos memorables para nuestros visitantes.',
  quienessomosdos: '20 Años de Historia: "El Camino de Casa Andina"',
  quienessomostres: 'Guiados por un propósito',
  quienessomoscuatro: 'Misión',
  quienessomoscinco:
    'Creamos experiencias auténticas que van más allá de lo que el viajero espera.',
  quienessomosseis: 'Visión',
  quienessomossiete:
    'Ser la cadena hotelera peruana referente en el mundo gracias a nuestros colaboradores, excelencia en el servicio y resultados extraordinarios.',
  quienessomosocho: 'Los valores que nos definen',
  quienessomosnueve: 'Cultura de integridad',
  quienessomosdiez: 'Vocación',
  quienessomosdiez2: 'Vocación',
  quienessomosonce: 'Trabajo',
  quienessomosonce2: 'Trabajo',
  quienessomosdoce: 'Atención',
  quienessomosdoce2: 'Atención',
  quienessomostrece: 'Espíritu',
  quienessomoscatorce: 'Por qué nos eligen',
  quienessomosquince:
    'Casa Andina va más allá de ser una cadena hotelera; somos tu conexión auténtica con el Perú',
  quienessomosdieciseis: 'Variedad para Todos los Gustos:',
  quienessomosdiecisiete:
    'Con 5 tipos de hoteles, ajustamos nuestras ofertas a las necesidades, garantizando que cada visita sea justo lo que desean.',
  quienessomosdieciocho: 'Equipo Apasionado:',
  quienessomosdiecinueve:
    'Somos alrededor de 1000 colaboradores unidos por la pasión por el servicio, buena onda y amor por el Perú.',
  quienessomosveinte: 'Líderes en el Perú:',
  quienessomosveintiuno:
    'Con más de 19 años, somos la cadena hotelera líder del país, brindando no solo habitaciones, sino una experiencia incomparable."',
  quienessomosveintidos:
    '¨Cuando piensas en viajar por el Perú, piensa en Casa Andina¨',
  quienessomosveintitres: 'Nuestros equipo',
  quienessomosveinticuatro: 'Nuestros reconocimientos',
  quienessomosveinticinco: 'Nuestros logros',

  // Responsabilida Social
  responsabilidadSocialuno: 'PERÚ CHAMPS',
  responsabilidadSocialdos: '¿Qué solución ofrece Perú Champs?',
  responsabilidadSocialtres:
    'Identifica a escolares de bajos recursos con alto talento académico, artístico o deportivo y les otorga becas para que reciban una educación de excelencia* y se conviertan en futuros líderes de un Perú sostenible.',
  responsabilidadSocialcuatro:
    'Con el apoyo de Casa Andina, se sigue potenciando la educación de miles de niños y niñas de bajos recursos con talentos excepcionales.',
  responsabilidadSocialcinco: '¿Por qué donar a Perú Champs?',
  responsabilidadSocialseis:
    'Porque cuentan con un modelo educativo único de alto impacto que ha otorgado aproximadamente 3,000 becas, y la meta es poder otorgar 1,000 cada año.',
  responsabilidadSocialsiete:
    'Además, para garantizar su formación integral, ofrecen 9 actividades de liderazgo como mentorías, coaching, emprendimiento, entre otras. No solo brindan becas, sino que también refuerzan las habilidades blandas de los Champs.',
  responsabilidadSocialocho: 'Conoce más en',

  //eventos
  eventosUno: 'Eventos',
  eventosDos: 'ORGANIZA TU EVENTO CON NOSOTROS',
  eventosTres: 'SALA',
  eventosCuatro: 'DESTINOS',
  eventosCinco: 'Desayunos',
  eventosSeis: 'Cooffee break',
  eventosSiete: 'Almuerzos/cena',
  eventosOcho: 'Cocktail',
  eventosNueve: 'Brunch/Tea Time',
  eventosDiez: 'Box lunch',
  eventosOnce: 'Set menú/ buffet',
  eventosDoce: 'Open Bar',
  eventosTrece: 'Completa el formulario para ponernos en contacto',
  eventosCatorce: 'Selecciona el tipo de evento',
  eventosQuince: 'Evento corporativo',
  eventosDieciséis: 'Evento social',
  eventosDiecisiete: 'Nombre',
  eventosDieciocho: 'Celular',
  eventosDiecinueve: 'Correo electrónico',
  eventosVeinte: 'RUC',
  eventosVeintiuno: 'Nombre del la empresa',
  eventosVeintidós: 'Fecha del evento',
  eventosVeintitrés: 'dd/mm/aaaa',
  eventosVeinticuatro: 'Duración del evento',
  eventosVeinticinco: 'Alimentación requerida',
  eventosVeintiséis: 'Alimentación requerida',
  eventosVeintisiete: 'Capacidad de personas',
  eventosVeintiocho: 'Número de personas (desde 15 a 300 personas)',
  eventosVeintinueve: 'Seleccione el destino de tu interés',
  eventosTreinta:
    'He leído y acepto el envío de la publicidad y promociones y la política de protección de datos personales',
  eventosTreintaYuno: 'Solicitar información',
  eventosTreintaYdos: '¿Por que elegir celebrar tu evento con nosotros?',
  eventosTreintaYtres: 'Cobertura nivel nacional y ubicación estratégica',
  eventosTreintaYcuatro: 'Más de 65 salas en 10 destinos',
  eventosTreintaYcinco: '1 contacto para eventos a nivel nacional',
  eventosTreintaYseis: 'Tarifas especiales',
  eventosTreintaYsiete: 'Opciones audivisuales',
  eventosTreintaYocho: 'Facilidades de pago',
  eventosTreintaYnueve: 'Alimentación',
  eventosCuarenta: 'Eventos a Tu Estilo con Casa Andina',
  eventosCuarentaYuno: 'Elige tu sala',
  eventosCuarentaYdos:
    'En Casa Andina queremos ofrecerte la mejor experiencia a ti y a todos tus invitados. Contamos con la mejor infraestructura y un servicio que supera expectativas con modernas salas que se adaptan a tus necesidades.',
  eventosCuarentaYtres: 'Armado de salas',
  eventosCuarentaYcuatro: 'Elige tus equipos',
  eventosCuarentaYcinco: 'Proyector',
  eventosCuarentaYseis: 'Ecran',
  eventosCuarentaYsiete: 'Micrófonos',
  eventosCuarentaYocho: 'Equipo de sonido',
  eventosCuarentaYnueve: 'Laptop',
  eventosCincuenta: 'TV 75"',
  eventosCincuentaYuno: 'Alimentación',
  eventosCincuentaYdos: 'Desayunos',
  eventosCincuentaYtres: 'Cooffee break',
  eventosCincuentaYcuatro: 'Almuerzos/cena',
  eventosCincuentaYcinco: 'Cocktail',
  eventosCincuentaYseis: 'Brunch/Tea Time',
  eventosCincuentaYsiete: 'Box lunch',
  eventosCincuentaYocho: 'Set menú/ buffet',
  eventosCincuentaYnueve: 'Open Bar',
  eventosSesenta: '¿Quieres conocer todas las opciones para tu eventos?',
  eventosSesentaYuno:
    'Conoce las opciones para tu evento , reuniones e invitados.',
  eventosSesentaYdos: 'Solicitar información',

  // reservas-agentes
  reservasAgentesUno: 'Ingresa tu código de agencia para seguir navegando',
  reservasAgentesDos: 'Ingresa tu código',
  reservasAgentesTres: 'Ingresar',
  reservasAgentesCuatro: 'YA ABRIMOS ',
  reservasAgentesCinco: 'HOTEL PARACAS',
  reservasAgentesSeis:
    '¡Reserva Ahora y Ofrece a tus Clientes una Estadía Inolvidable!',
  reservasAgentesSiete: 'Elige un destino, hotel',
  reservasAgentesDoce: '1 Habitación, 1 Adulto, 0 Niños',
  reservasAgentesTrece:
    'Ten en cuenta que solo pueden alojarse hasta 3 adultos y 2 niños de o años por habitación, hasta un máximo de personas.',
  reservasAgentesCatorce: 'HABITACIÓN 1',
  reservasAgentesQuince: 'Adultos',
  reservasAgentesDieciséis: 'Niños',
  reservasAgentesDiecisiete: '+ Añadir habitaciones',
  reservasAgentesDieciocho: 'Continuar',
  reservasAgentesDiecinueve: 'Ingresa tu código',
  reservasAgentesVeinte: 'Reservar',
  reservasAgentesVeintiuno: 'Anular reserva',
  reservasAgentesVeintidós:
    'Únete a nuestro canal de difusión por donde compartimos novedad y herramientas de utilidad. Da clic aquí para unirte',
  reservasAgentesVeintitrés: 'Unirme al grupo',
  reservasAgentesVeinticuatro: 'Explora con Nosotros: Fichas, Menús y Fechas',
  reservasAgentesVeinticinco: 'Fichas técnicas',
  reservasAgentesVeintiséis: 'Ver',
  reservasAgentesVeintisiete: 'Menú turísticos',
  reservasAgentesVeintiocho: 'Ver',
  reservasAgentesVeintinueve: 'Fechas especiales',
  reservasAgentesTreinta: 'Ver',
  reservasAgentesTreintaYuno: 'Fotos de nuestros hoteles Casa Andina',
  reservasAgentesTreintaYdos: 'Ver',

  // dog friendly

  dogFriendlyUno: 'Dog Friendly',
  dogFriendlyDos: 'POLÍTICAS DE ADMISIÓN Y CUIDADO DE MASCOTAS',
  dogFriendlyTres:
    'Sabemos que las mascotas forman parte importante de la familia. Con el fin de que su estancia y la de su mascota sean lo más agradable posible y no supongan ninguna incomodidad para usted y para el resto de los huéspedes, es necesario que conozca y acepte las Políticas de Admisión y Cuidado de Mascotas en CASA ANDINA que se detallan a continuación:',
  dogFriendlyCuatro:
    'En CASA ANDINA admitimos el ingreso de huéspedes acompañados de su perro (Mascota) siempre que se cumpla con las presente Políticas de Admisión.',
  dogFriendlyCinco:
    'Se permite el ingreso de gatos, sin embargo, los artículos ofrecidos para la estadía son para perros (no incluye arenero para gatos o algún articulo adicional para gatos, solo equipamiento dogfriendly)',
  dogFriendlySeis:
    'Las reservas Dog Friendly se podrán realizar a través del departamento de Reservas, Contact Center, canales Online y de manera presencial.',
  dogFriendlySiete:
    'De preferencia la reserva se debe solicitar con 2 días hábiles de anticipación.',
  dogFriendlyOcho:
    'Solo se permitirá el ingreso de dos mascotas por habitación.',
  dogFriendlyNueve:
    'Los Hoteles Asociados Fundo San José Parque Ecológico & Lodge, Empedrada Ranch & Lodge, Maresta, Hotel Andino Club, El Sauce Resort, Hotel Presidente Huancayo, Hotel Presidente Huancavelica, Irapay Amazon Lodge, AltaVista CasaHotel, La Xalca Hotel, La Ensenada, Santacruz Ecolodge e Ikam Riverview Amazon Lodge no son dog friendly.',
  dogFriendlyDiez:
    'Al momento de la reserva, el huésped deberá hacer entrega de los certificados emitidos por veterinario colegiado (u órgano gubernamental competente) que acrediten las vacunaciones y desparasitaciones actualizadas de su mascota, con una antigüedad no mayor de 1 año. Es necesario portar los referidos certificados durante su estadía en el hotel y presentarlos en caso de requerimiento del personal de CASA ANDINA.',
  dogFriendlyOnce:
    'En caso se trate de perros guía se debe presentar la constancia del CONADIS, acreditando que es necesaria la compañía del perro (discapacidad visual, emocional, medición de glucosa u otro tipo), de no contar con la certificación de CONADIS, el huésped asumirá el cobro por noche de estadía de su mascota. ',
  dogFriendlyDoce:
    'Solo se permite el ingreso de mascotas cuyo peso máximo no exceda de 18 kilos.',
  dogFriendlyTrece:
    'No se admitirá el ingreso de razas potencialmente peligrosas, en aplicación de la legislación peruana, tales como American Pitbull, fila brasilera, tosa japonesa, bullmastiff, doberman y rottweiler, asi como aquellos que han sido adiestrados para peleas o que hayan participado en ellas, los que tengan antecedentes de agresividad contra las personas y que no puedan asegurar su sociabilidad, temperamento o carácter.',
  dogFriendlyCatorce:
    'El ingreso de su Mascota tiene un precio de US$ 49.00 (inc. Impuestos y servicios) por cada noche. El precio es por mascota.',
  dogFriendlyQuince:
    'Se deberá dejar como garantía el importe correspondiente a una noche de alojamiento facturada por habitación. CASA ANDINA no se responsabilizará por la alimentación de su Mascota. Durante su estancia, su Mascota podrá hacer uso, en condición de préstamo, de lo siguiente: (i) cama de perro; (ii) platos de comida; y, (iii) tapete. CASA ANDINA obsequiará por la estancia de su Mascota (i) un llavero con bolsas descartables; y, (ii) almohadillas absorbentes.',
  dogFriendlyDieciséis:
    'Su Mascota deberá hacer sus necesidades en las almohadillas absorbentes que estarán colocadas en el baño de la habitación. En cualquier otro caso usted será responsable de recoger los residuos que deje su Mascota y dar aviso inmediato al personal de limpieza de CASA ANDINA. No está permitido el uso de la lencería del Hotel para recoger los residuos y para el aseo de su Mascota.',
  dogFriendlyDiecisiete:
    'Su Mascota deberá permanecer siempre en compañía de Usted durante su estadía en CASA ANDINA. No está permitido que su Mascota suba a las camas, sofás, sillones y otro tipo de mobiliarios dentro de la habitación. Asimismo, no está permitido dejar a su Mascota sola dentro de la habitación, ni en cualquier área común de CASA ANDINA, el personal del restaurante lo ubicará en las mesas asignadas a este programa',
  dogFriendlyDieciocho:
    'Nuestros restaurantes son dogfriendlys, por lo cual pueden ingresar con su mascota a los ambientes bajo supervision.',
  dogFriendlyDiecinueve:
    'Cuando deba transitar por las áreas comunes del hotel, su Mascota deberá permanecer siempre con su correa.',
  dogFriendlyVeinte:
    'Usted será el único responsable del cuidado y comportamiento de su Mascota, debiendo evitar, ladridos, ruidos y comportamientos que generen incomodidades al resto de huéspedes.',
  dogFriendlyVeintiuno:
    'Cualquier daño o incidente causado por su Mascota a cualquier persona será asumido de forma íntegra por Usted, debiendo realizar todos los gastos y costos de reparación que correspondan, incluyendo, pero sin limitarse a los gastos médicos de las personas afectadas. Los gastos y costos que pueda efectuar CASA ANDINA deberán ser reembolsados de inmediato por Usted.',
  dogFriendlyVeintidós:
    'Los daños causados por su Mascota al mobiliario dentro de su habitación y en las demás instalaciones de CASA ANDINA serán asumido de forma íntegra por Usted al momento de realizar su Check Out, tomando en consideración lo siguiente:',
  dogFriendlyVeintitrés: '-US$ 75 por daños en cada mueble o enseres.',
  dogFriendlyVeinticuatro:
    '-US$ 90 por daños en la lencería (sabanas, almohadas, toallas, etc.) y otros bienes.',
  dogFriendlyVeinticinco: '-US$ 350 por daños en cada colchón.',
  dogFriendlyVeintiséis:
    'Lo anterior no limita que CASA ANDINA exija el pago de importes mayores si los bienes dañados tienen una valorización mayor.',
  dogFriendlyVeintisiete:
    'Se encuentra terminantemente prohibido cualquier acto de maltrato o crueldad contra su Mascota, o actos que pongan en riesgo la salud pública. De acuerdo con la legislación peruana, estas acciones constituyen delitos que podrán ser sancionadas con pena privativa de la libertad. CASA ANDINA dará aviso inmediato de estas acciones a las autoridades competentes.',
  dogFriendlyVeintiocho:
    'Los Huéspedes que ingresen con perros guía deberán presentar los certificados que acrediten dicha condición, emitidos por las entidades competentes.',
  dogFriendlyVeintinueve:
    'Apreciamos y esperamos que tenga una estadía placentera en CASA ANDINA, en compañía de su Mascota.',

  // terminos
  terminosYcondicionesUno:
    'Términos y condiciones de reservas en Casa Andina Hoteles',
  terminosYcondicionesDos: 'Términos del contrato',
  terminosYcondicionesTres:
    'Proveedora del servicio de hospedaje.',
  terminosYcondicionesCuatro:
    'CLIENTE: Pasajero que toma los servicios de CASA ANDINA (mayor de edad).',
  terminosYcondicionesCinco:
    'CHECK–IN: Proceso de registro de entrada del cliente en hotel antes de ingresar a la Habitación.',
  terminosYcondicionesSeis:
    'CHECK-OUT: Proceso de registro de salida del cliente al momento de terminar de usar los servicios del hotel.',
  terminosYcondicionesSiete:
    'NO-SHOW: Es la no presentación del pasajero en el hotel antes de las 23:59 horas del día previsto de llegada, sin comunicación previa por parte del cliente.',
  terminosYcondicionesOcho:
    'RESERVA: Acuerdo entre Casa Andina y el Cliente para reservar la(s) habitación(es) para las fechas solicitadas por el cliente en cualquier hotel de la cadena CASA ANDINA.',
  terminosYcondicionesNueve:
    'CARGO POR SERVICIOS: Recargo del 10% al consumo por servicios prestados de Casa Andina.',
  terminosYcondicionesDiez:
    'IMPUESTO GENERAL A LAS VENTAS (IGV): Impuesto correspondiente al 18% de la tarifa.',
  terminosYcondicionesOnce: 'Condiciones generales'
}
