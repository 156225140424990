import { ICardCustom } from "./props";
import { IconText, Paragraph, Text } from "components/atoms";
import "./styles.scss";
import IBK from "assets/images/home/interbank.webp";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardCustom = ({
  type,
  data,
  redirection,
  height,
  category,
  onClick,
  typehotel,
  entidad,
}: ICardCustom) => {
  const { t } = useTranslation();

  const isPackageCategory =
    category === "paquete" ||
    category === "paquete exclusivo" ||
    category === "alimentos y bebidas" ||
    category === "campaña" ||
    category === "banco";

  const backgroundImageStyle = {
    backgroundImage: isPackageCategory ? `url(${data.img?.path})` : "",
    backgroundPosition: isPackageCategory ? "center" : "initial",
    backgroundColor: data.color,
    backgroundRepeat: isPackageCategory ? "no-repeat" : "",
    backgroundSize: isPackageCategory ? "cover" : "",
  };

  const isOfferWithBackgroundImage =
    data?.tipo_disenio?.titulo === "Oferta con imagen de fondo" ||
    data?.tipo_disenio === null;

  const backgroundImageAndColor = isOfferWithBackgroundImage
    ? { backgroundColor: "rgba(0, 0, 0, 0.30)" }
    : { backgroundColor: data?.color || "none" };

  const isHotelSlug = !!typehotel && typehotel.includes("/hoteles");

  const cardTypes = () => {
    switch (type) {
      case "Oferta con color de fondo":
        return (
          <Link
            className="bgcolor nlink"
            style={{ backgroundColor: data.color, height: height }}
            to={redirection || ""}
          >
            <div
              className="bgcolor__inner"
              style={{ height: height ? "218px" : "190px" }}
            >
              <div className="bgcolor__inner--container">
                <img
                  src={data.logo?.path}
                  alt={data.logo?.alt}
                  width={"auto"}
                  height={24}
                  loading="lazy"
                />
              </div>
              <div
                className="bgcolor__inner--description"
                style={{ gap: height ? "10px" : "0px" }}
              >
                <Text
                  className="title"
                  text={data.titulo}
                  style={{ fontSize: data.titulo.length > 20 ? 24 : 30 }}
                />
                <div className="content">
                  <Text className="content__valor" text={data.valor} />
                  <div className="content--right">
                    <Text className="content__percentage" text={"%"} />
                    <Text className="content__dscto" text={"dscto."} />
                  </div>
                </div>
                <Text
                  className="interest"
                  text={data.subtitulo}
                  style={{ fontSize: data.titulo.length > 20 ? 11 : 13 }}
                />
              </div>
            </div>
          </Link>
        );
      case "home":
        return (
          <Link
            className="cardhome nlink"
            style={backgroundImageStyle}
            to={redirection || ""}
          >
            <div className="cardhome__inner" style={backgroundImageAndColor}>
              <div className="cardhome__inner--description">
                <Text text={data.titulo} className="description__title" />
                {category === "paquete" && (
                  <div style={{ width: "100%" }}>
                    {data.precio !== null && (
                      <>
                        <Text
                          text={isHotelSlug ? "" : t("general.from")}
                          className="description__left"
                        />
                        <div className="content">
                          <Text
                            text={`${data.precio?.simbolo} ${data.precio?.precio}`}
                            className={
                              data.precio?.precio?.length >= 4
                                ? "content__valor2"
                                : "content__valor"
                            }
                          />
                        </div>
                      </>
                    )}
                    <Text
                      text={data.subtitulo}
                      className="description__subtitle"
                    />
                    {data.logo && (
                      <div className="content__banco">
                        <img src={data.logo?.path} alt={"casa andina"} />
                      </div>
                    )}
                  </div>
                )}
                {category === "alimentos y bebidas" && (
                  <div style={{ width: "100%" }}>
                    <Text
                      text={isHotelSlug ? data.texto_encima_precio : t("general.from")}
                      className="description__left"
                    />
                    <div className="content">
                      <Text
                        text={`${data.precio?.simbolo} ${data.precio?.precio}`}
                        className="content__price"
                      />
                    </div>
                    <Text
                      text={data.condicion_abajo_precio}
                      className="description__subtitle"
                    />

                    {data.logo && <div className="content__banco">
                      <img src={data?.logo?.path} alt={data?.logo?.alt} />
                    </div>}
                  </div>
                )}
                {category === "paquete exclusivo" && (
                  <div style={{ width: "100%" }}>
                  {data.precio !== null && (
                    <>
                      <Text
                        text={isHotelSlug ? "" : t("general.from")}
                        className="description__left"
                      />
                      <div className="content">
                        <Text
                          text={`${data.precio?.simbolo} ${data.precio?.precio}`}
                          className={
                            data.precio?.precio?.length >= 4
                              ? "content__valor2"
                              : "content__valor"
                          }
                        />
                      </div>
                    </>
                  )}
                  <Text
                    text={data.subtitulo}
                    className="description__subtitle"
                  />
                  {data.logo && (
                    <div className="content__banco">
                      <img src={data.logo?.path} alt={"casa andina"} />
                    </div>
                  )}
                </div>
                )}
                {category !== "paquete" && category !== "alimentos y bebidas" && category !== "paquete exclusivo" && (
                  <div style={{ width: "90%" }}>
                    {!isHotelSlug && (
                      <Text text={t("general.upTo")} className="description__left" />
                    )}
                    <div className="content">
                      <Text text={data.valor} className="content__valor" />
                      <div className="content--right">
                        <Text text={"%"} className="content__percentage" />
                        <Text text={"dscto."} className="content__dscto" />
                      </div>
                    </div>
                    <Text
                      text={data.subtitulo}
                      className="description__subtitle"
                    />
                    {data.logo && (
                      <div className="content__banco">
                        <img
                          src={data?.logo?.path}
                          alt={data?.logo?.alt}
                          style={{
                            height: category === "campaña" ? "45px" : "34px",
                          }}
                        />
                      </div>
                    )}
                  </div>)}
              </div>
            </div>
          </Link>
        );
      case "Oferta con imagen de fondo":
        return (
          <div
            className="bgquota nlink"
            style={{ backgroundImage: `url(${data.img?.path})` }}
          >
            <div
              className="bgquota__inner"
              style={{ backgroundColor: data.color }}
            >
              <div className="bgquota__inner--container">
                <img
                  src={data.logo?.path}
                  alt={data.logo.alt}
                  width={"auto"}
                  height={24}
                />
              </div>
              <div className="bgquota__inner--description">
                <Text
                  className="bgquota__inner--title"
                  text={data.titulo}
                  type="paragraph"
                />
                <div className="contentq">
                  <Text
                    className={"valor"}
                    text={data.valor}
                    type="paragraph"
                  />
                </div>
                <Text
                  className="bgquota__inner--subtitle"
                  text={
                    data.subtitulo ||
                    "Cuotas sin intereses, comprando con tus tarjetas"
                  }
                  type="paragraph"
                />
              </div>
            </div>
          </div>
        );
      case "Oferta con imagen de fondo soles":
        return (
          <Link
            className="bgimgbuffet nlink"
            style={{ backgroundImage: `url(${data.img?.path})` }}
            to={data.slug}
          >
            <div
              className="bgimgbuffet__inner"
              style={{ backgroundColor: data.color }}
            >
              <div className="bgimgbuffet__inner--description">
                <Paragraph
                  type="28"
                  family="tbfcarddiscount"
                  text={data.titulo}
                />
                <div className="content">
                  <Paragraph type="25" family="sbcarddiscount" text={"S/"} />
                  <Paragraph
                    type="40"
                    family="sbcarddiscount"
                    text={data.valor}
                  />
                </div>
                <Paragraph
                  type="10"
                  family="txcardexclusive"
                  text={data.subtitulo}
                />
              </div>
            </div>
          </Link>
        );
      case "Oferta con imagen de fondo cuotas":
        return (
          <div
            className="bgquota nlink"
            style={{ backgroundImage: `url(${data.img?.path})` }}
          >
            <div
              className="bgquota__inner"
              style={{ backgroundColor: data.color }}
            >
              <div className="bgquota__inner--container">
                <img src={data.logo?.path} alt="a" width={"auto"} height={24} />
              </div>
              <div className="bgquota__inner--description">
                <Text
                  className="bgquota__inner--title"
                  text={data.titulo}
                  type="paragraph"
                />
                <div className="contentq">
                  <Text
                    className={"valor"}
                    text={data.valor}
                    type="paragraph"
                  />
                </div>
                <Text
                  className="bgquota__inner--subtitle"
                  text={data.subtitulo}
                  type="paragraph"
                />
              </div>
            </div>
          </div>
        );
      case "Oferta banco restaurantes":
        return (
          <div
            className="bgbankrestaurant"
            style={{ backgroundImage: `url(${data.img?.path})` }}
          >
            <div
              className="bgbankrestaurant__inner"
              style={{ backgroundColor: data.color }}
            >
              <div className="bgbankrestaurant__inner--container">
                <img src={data.logo?.path} alt={data.logo?.alt} height={24} />
              </div>
              <div className="bgbankrestaurant__inner--description">
                <Text
                  className="bgbankrestaurant__inner--title"
                  text={data.titulo}
                  type="paragraph"
                />
                <div className="contentq">
                  <Text
                    className={"valor"}
                    text={data.valor}
                    type="paragraph"
                  />
                </div>
                <Text
                  className="bgbankrestaurant__inner--subtitle"
                  text={data.subtitulo}
                  type="paragraph"
                />
              </div>
            </div>
          </div>
        );
      case "destinationImg":
        return (
          <Link
            style={{ padding: "10px 16px" }}
            to={data.redireccion}
            className="nlink"
          >
            <div
              className="destimg"
              style={{ backgroundImage: `url(${data.img?.path})` }}
            >
              <div
                className="destimg__inner"
                style={{ backgroundColor: data.color }}
              >
                <div className="destimg__inner--description">
                  <Text
                    text={t("destinations.hotelsIn")}
                    type="paragraph"
                    className="subparagraph"
                  />
                  <Text
                    text={data.nombre}
                    type="paragraph"
                    className="paragraph"
                  />
                </div>
              </div>
            </div>
          </Link>
        );
      case "gallery":
        return (
          <div
            className="galleryg"
            style={{ backgroundImage: `url(${data.img[0]?.path})` }}
            onClick={onClick}
          >
            <div className="galleryg__inner">
              <div className="galleryg__inner--description">
                <Text className="title" text={data.titulo} />
                <div>
                  <IconText
                    nameIcon="img"
                    listIcon={"items"}
                    text={`1/${data.cantidad}`}
                    className="count"
                    w={16}
                    h={16}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case "typeHotels":
        return (
          <Link to={data.slug} className="nlink">
            <div
              className="better__description--card"
              style={{ backgroundColor: data.color }}
            >
              <div className="better__description--card--slider">
                <img
                  src={data.img?.path}
                  alt={data.img?.alt}
                  width={160}
                  height={90}
                />
              </div>
              <div className="better__description--card--container">
                <Text
                  type="paragraph"
                  className="paragraph"
                  text={data.titulo}
                />
              </div>
            </div>
          </Link>
        );
      case "card":
        return (
          <Link
            className="bgimgbuffet nlink"
            style={{ backgroundImage: `url(${data.logo?.path})` }}
            to={data.slug}
          >
            <div
              className="bgimgbuffet__inner"
              style={{ backgroundColor: data.color }}
            >
              <div className="bgimgbuffet__inner--description">
                <Paragraph
                  type="28"
                  family="tbfcarddiscount"
                  text={data.titulo}
                />
                <div className="content">
                  <Paragraph type="25" family="sbcarddiscount" text={"S/"} />
                  <Paragraph
                    type="40"
                    family="sbcarddiscount"
                    text={data.valor}
                  />
                </div>
                <Paragraph
                  type="10"
                  family="txcardexclusive"
                  text={data.subtitulo}
                />
              </div>
            </div>
          </Link>
        );
      case "select":
        return (
          <Link
            className="bgimg nlink"
            style={{ backgroundImage: `url(${data.img?.path})` }}
            to={data.slug}
          >
            <div
              className="bgimg__inner"
              style={{ backgroundColor: data.color }}
            >
              <div className="bgimg__inner--container">
                <img
                  src={data.logo_img?.path || data.img?.path}
                  alt="a"
                  width={"auto"}
                  height={24}
                />
              </div>
              <div className="bgimg__inner--description">
                <Paragraph
                  type="28"
                  family="tcarddiscount"
                  text={data.titulo}
                />
                <div className="content">
                  <Paragraph
                    type="40"
                    family="sbcarddiscount"
                    text={data.valor}
                  />
                  <div className="content--right">
                    <Paragraph type="25" family="txcarddiscountp" text={"%"} />
                    <Paragraph
                      type="8"
                      family="txcarddiscount"
                      text={"dscto."}
                    />
                  </div>
                </div>
                <Paragraph
                  type="10"
                  family="txcardexclusive"
                  text={data.subtitulo}
                />
              </div>
            </div>
          </Link>
        );
      default:
        return (
          <div className="bgcolor">
            <div className="bgcolor__inner">
              <div className="bgcolor__inner--container">
                <img src={IBK} alt="a" width={116} height={24} />
              </div>
            </div>
          </div>
        );
    }
  };

  return <>{cardTypes()}</>;
};

export default CardCustom;
