import { useState, useEffect } from "react";
import "./styles.scss";
import { Text } from "components/atoms";
import Animations from "../animations";

const TabsIndex = ({ data }: any) => {
  const [activeTab, setActiveTab] = useState<number | null>(null);

  const handleTabClick = (activity: any) => {
    setActiveTab(activity.id);
  };

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      setActiveTab(data[0].id)
    }
  }, [data]);

  return (
    <div className="tabsection">
      <div className="tabsection__container">
        <div className="tabsection__container--tabs">
          {data && Array.isArray(data) && data.length > 0 ? (
            <>
              {data.map((activity: any) => (
                <div
                  key={activity.id}
                  className={activeTab === activity.id ? "active" : ""}
                  onClick={() => handleTabClick(activity)}
                >
                  <Text
                    text={activity.titulo}
                    type={"subtitle2"}
                    className={activeTab === activity.id ? "active-bold" : ""}
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              <div className={"active"}>
                <Animations type="skeletonContent">
                  <Text
                    text={"Lorem ipsum dolor sit amet"}
                    style={{ color: "transparent" }}
                    type={"subtitle2"}
                    className={"active-bold"}
                  />
                </Animations>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="tabsection__content">
        {data && Array.isArray(data) && data.length > 0 ? (
          <>
            {data.map((activity: any) => (
              <section
                key={activity.id}
                className={activeTab === activity.id ? "active" : ""}
              >
                <div className="tabsection__content--slides">
                  <div className="tabsection__content--slides--img">
                    <img src={activity.img?.path} alt="" />
                  </div>

                  <div className="tabsection__content--slides--description">
                    <div className="tabsection__content--slides--card">
                      <div className="tabsection__content--slides--left">
                        <Text
                          text={
                            activity.subtitulo
                              ? activity.subtitulo
                              : activity.titulo
                          }
                          className="title"
                          type="subtitle2"
                        />
                        <div className={"d-flex jc-start ai-center"}>
                          <Text
                            html={{ __html: activity.descripcion }}
                            type="paragraph"
                            className={"paragraph"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ))}
          </>
        ) : (
          <>
            <section
              className={"active"}
            >
              <div className="tabsection__content--slides">
                <div className="tabsection__content--slides--img">
                  <Animations type="skeletonContent">
                    <div style={{ height : "100%", width : "100%" }}></div>
                  </Animations>
                </div>

                <div className="tabsection__content--slides--description">
                  <div className="tabsection__content--slides--card">
                    <div className="tabsection__content--slides--left">
                      <Animations type="skeletonContent">
                        <Text
                          text={"Lorem ipsum dolor sit amet"}
                          style={{ color : "transparent" }}
                          className="title"
                          type="subtitle2"
                        />
                      </Animations>
                      <div className={"d-flex jc-start ai-center"}>
                        <Animations type="skeletonContent">
                          <Text
                            text={"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid corrupti nobis, fugiat illo sequi quibusdam doloribus? Atque unde voluptate neque ipsum, harum dolores cum ratione dolor cumque molestias, excepturi quae."}
                            type="paragraph"
                            style={{ color : "transparent" }}
                            className={"paragraph"}
                          />
                        </Animations>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  );
};

export default TabsIndex;
