import React from 'react'
import { IText } from './props'
import './styles.scss'

const getClassByFamily = (text: string) => {
  const styleMap: Record<string, string> = {
    paragraph: 'paragraph',
    tpage: 'titlepage',
    sbpage: 'subtitlepage',
    t2page: 'title2page',
    sb2page: 'subtitle2page',
    tsection: 'titlesection',
    t2section: 'title2section',
    sbsection: 'subtitlesection',
    ttcbannerslider: 'textcursivebannerslider',
    ttab: 'titletab',
    textcardpromotion: 'textcardpromotion',
    sbtab: 'subtitletab',
    dtab: 'descriptiontab',
    ttabm: 'titletabm',
    sbtabm: 'subtitletabm',
    dtabm: 'descriptiontabm',
    ttabmodal: 'titletabmodal',
    txnormal: 'textnormal',
    txnormal2: 'txnormal2',
    txsnormal: 'txsnormal',
    txsmall: 'textsmall',
    tbanner: 'titlebanner',
    tbannerc: 'titlebannerc',
    sbbanner: 'subtitlebanner',
    dscbanner: 'descriptionbanner',
    tcarddiscount: 'titlecarddiscount',
    sbcarddiscount: 'subtitlecarddiscount',
    tcardrelax: 'titlecardrelax',
    sbcardrelax: 'subtitlecardrelax',
    sbbcardrelax: 'sbbcardrelax',
    pricecardrelax: 'pricecardrelax',
    txcarddiscountp: 'textcarddiscountp',
    txcarddiscount: 'textcarddiscount',
    tbfcarddiscount: 'textcarddiscountbf',
    txcardexclusive: 'textcardexclusive',
    txbannerslider: 'textbannerslider',
    ttbannerslider: 'titlebannerslider',
    txtbannerssliderprc: 'textbannersliderprc',
    txbannersliderdsct: 'textbannersliderdsct',
    txbanner: 'textbanner',
    slbanner: 'sliderBanner',
    ttvisit: 'titlevisit',
    sbvisit: 'subtitlevisit',
    subvisit: 'subsubtitlevisit',
    tthotel: 'titlehotel',
    sbhotel: 'subtitlehotel',
    txrestaurant: 'textrestaurant',
    btnrestaurant: 'buttonrestaurant',
    txexperience: 'textexperience',
    txtfooter: 'textfooter',
    sliderItem: 'sliderItem',
    textGallery: 'textGallery',
    textmobGallery: 'textmobGallery',
    textGallery2: 'textGallery2',
    txdestiny: 'textdestiny',
    txfilter: 'textfilter',
    sbfilter: 'subtitlefilter',
    scfilter: 'sectionfilter',
    txfilter2: 'textfilter2',
    tcardicon: 'titlecardicon',
    tagcardicon: 'tagcardicon',
    sbcardicon: 'subtitlecardicon',
    sbbcardicon: 'sbbcardicon',
    cleanfilter: 'cleanfilter',
    txicon: 'texticon',
    screstaurant: 'sectionrestaurant',
    tmodal: 'titlemodal',
    bcyberw: 'bcyberw',
    txpromotion: 'txpromotion',
    textcardquota: 'textcardquota',
    txtbannerBanks: 'txtbannerBanks',
    txtbannerBanksSuperior: 'txtbannerBanksSuperior',
    txtbannerBanksInferior: 'txtbannerBanksInferior',
    titleTypesRestaurant: 'titleTypesRestaurant',
    subtitleTypesRestaurant: 'subtitleTypesRestaurant',
    title18Nexa: 'title18Nexa',
    subtitle18Nexa: 'subtitle18Nexa'
  }

  return styleMap[text] || ''
}

const Text = ({
  className,
  family,
  html,
  onClick,
  style,
  text,
  text2,
  className2,
  type,
}: IText) => {
  const Element = () => {
    switch (type) {
      case 'title':
        return (
          <h1
            className={className}
            dangerouslySetInnerHTML={html}
            onClick={onClick}
            style={style}
          >
            {text}
          </h1>
        )
      case 'subtitle':
        return (
          <h2
            className={className}
            dangerouslySetInnerHTML={html}
            onClick={onClick}
            style={style}
          >
            {text}
          </h2>
        )
      case 'subtitle2':
        return (
          <h3
            className={className}
            dangerouslySetInnerHTML={html}
            onClick={onClick}
            style={style}
          >
            {text}
          </h3>
        )
      case 'paragraph':
        return (
          <p
            className={className}
            dangerouslySetInnerHTML={html}
            onClick={onClick}
            style={style}
          >
            {text}
          </p>
        )
        case 'paragraph2':
          return (
            <p
              className={className}
              onClick={onClick}
              style={style}
            >
              {text} {text2 && <span className={className2}>{text2}</span>}
            </p>
          )
      default:
        return (
          <p
            className={`${getClassByFamily(family)}`}
            dangerouslySetInnerHTML={html}
            onClick={onClick}
            style={style}
          >
            {text}
          </p>
        )
    }
  }

  return <Element />
}

Text.defaultProps = {
  type: 'paragraph'
}

export default Text
