import React, { useEffect, useRef, useState } from 'react'
import {
  PromotionsBanks,
  PromotionsCampaign,
  PromotionsFood,
  PromotionsPackages,
  PromotionsRelax
} from 'components/templates'
import { getPromotionsSubPages } from 'actions/promotions/services'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'hooks/useAppSelector'
import Loader from 'components/molecules/loader'

const PromotionsSubPages = () => {
  const { subpages } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [route, setRoute] = React.useState<string | undefined>(undefined)
  const isFirstRender = useRef(true);

  const language = useAppSelector(state => state.config.language)
  const coin = useAppSelector(state => state.config.coin)
  const data = useAppSelector(state => state.language.dataPromotionSubPages)

  const [localParams, setLocalParams] = useState({
    sort_by: '',
    destinos: '',
  })

  const handleIdFilter = (id: any, destination: any) => {
    id = id ?? ''
    destination = destination ?? ''

    setLocalParams({
      sort_by: id,
      destinos: destination
    })

    // const sort_byGeneral = id ? `sort_by=${id}` : ''
    // const destinationQuery = destination ? `destinos=${destination}` : ''

    // const urlParams = `?${sort_byGeneral}&${destinationQuery}`
    // navigate(urlParams, { replace: true })
  }

  const subPagesType = () => {
    switch (data.categoria?.titulo?.toLowerCase()) {
      case 'banco':
        return <PromotionsBanks data={data} idFilter={handleIdFilter} />
      case 'paquete':
        return <PromotionsRelax data={data} idFilter={handleIdFilter} />
      case 'campaña':
        return <PromotionsCampaign data={data} idFilter={handleIdFilter}/>
      case 'alimentos y bebidas':
        return <PromotionsFood data={data} />
      case 'paquete exclusivo':
        return <PromotionsPackages data={data} />
    }
  }

  useEffect(() => {
    if (location.pathname !== route) {
      setRoute(location.pathname);
    }
  }, [location.pathname, route]);

  useEffect(() => {
    if (subpages && language && coin) {
      getPromotionsSubPages({ language, subpages, coin, params: localParams, navigate });
    }
  }, [subpages, language, coin, location.pathname, localParams, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  // if (loading || !data) return <Loader />;

  if (isFirstRender.current || !data) {
    isFirstRender.current = false;
    return <Loader />;
  }

  return <>{subPagesType()}</>
}

export default PromotionsSubPages
