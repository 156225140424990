import { Text } from 'components/atoms'
import GalleryHotels from 'components/organisms/gallery-hotels'
import './styles.scss'

const SliderSixImg = ({ data, title, nameHotel, galleryRef }: any) => {
  return (
    <section className='slidersiximg' ref={galleryRef}>
      <div className='slidersiximg__description'>
        <Text
          text={title}
          type='paragraph'
          className='subtitle'
        />

        <div className='slidersiximg__description--slider'>
          <GalleryHotels data={data} nameHotel={nameHotel}/>
        </div>
      </div>
    </section>
  )
}

export default SliderSixImg
