import React from 'react'
import { CardCustom } from 'components/molecules'
import { Text } from 'components/atoms'
import { IDiscounts } from './props'
import { Swiper, SwiperSlide } from 'swiper/react'
import './styles.scss'
import 'swiper/css'
import 'swiper/css/navigation'

const Discounts = ({ data, discountsRef, title, card }: IDiscounts) => {
  return (
    <div className='discounts' ref={discountsRef}>
      <div className='discounts__container'>
        <Text text={title} type='subtitle' className={'subtitle'} />

        <div className='discounts__description'>
          <Swiper
            slidesPerView={'auto'}
            grabCursor={true}
            navigation={true}
            className='discounts__swiper'
          >
            {data.map((item: any, index: number) => (
              <SwiperSlide key={index}>
                {
                  card ? (
                    <CardCustom
                      type={item.tipo_oferta?.titulo || item.tipo_promocion?.titulo || item.card.tipo_disenio?.titulo}
                      redirection={item.redireccion}
                      data={item.card}
                    />
                  ) : (
                    <CardCustom
                      type={'card'}
                      data={item.card}
                      redirection={item.redireccion}
                    />
                  )
                }
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

Discounts.defaultProps = {
  card: true
}

export default Discounts
