import { useEffect, useState } from 'react'
import { IconText, Text } from 'components/atoms'
import { useIntl } from 'react-intl'
import { messages } from 'utils/lang'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import './styles.scss'
import 'swiper/css'
import { ICardDescription, ICardItem } from './props'
import ModalScreen from 'components/molecules/modal-screen'
import LightGallery from 'lightgallery/react'
import lgZoom from 'lightgallery/plugins/zoom'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import { SliderHotelMobile } from '../sliders'
import { redirectUrlExternal } from 'utils/function'

// Componente de tarjeta individual
const CardItem = ({ text, iconName, className }: ICardItem) => (
  <div className={`carditems__card ${className}`}>
    <IconText text={text} nameIcon={iconName} listIcon='items' w={16} h={16} />
  </div>
)

// Componente de descripción de la tarjeta
const CardDescription = ({
  better,
  index,
  toggleActivitiesModal
}: ICardDescription) => {
  const { locale } = useIntl()

  return (
    <div
      className='sectionactivities__description--card'
      onClick={() => toggleActivitiesModal(index)}
    >
      <div className='sectionactivities__description--card--slider'>
        <SliderHotelMobile
          tipo={better.img}
          images={better.img}
          isTag={false}
        />
      </div>
      <div className='sectionactivities__description--card--container'>
        <div
          className='tagposition'
          style={{ backgroundColor: better.tipo_reserva?.color }}
        >
          <Text
            text={better.tipo_reserva?.titulo}
            type='paragraph'
            className='tagposition__reservation'
          />
        </div>
        <div className='cardtitle'>
          <Text
            text={better.titulo}
            type='paragraph'
            className='tagposition__title'
          />
        </div>
        <div className='carditems'>
          {/* {better.ubicacion && (
            <CardItem
              text={`${messages[locale].place}: ${better.ubicacion}`}
              iconName='place-gray'
              className='carditems__card--place'
            />
          )} */}
          {better.horario && (
            <CardItem
              text={`${messages[locale].schedule}: ${better.horario}`}
              iconName='calendar2'
              className='carditems__card--date'
            />
          )}
          {better.turnos && (
            <CardItem
              text={`${messages[locale].shift}: ${better.turnos}`}
              iconName='clock'
              className='carditems__card--hours'
            />
          )}
          {better.precio && (
            <CardItem
              text={`${messages[locale].prices}: ${better.precio}`}
              iconName='coin'
              className='carditems__card--price'
            />
          )}
        </div>
      </div>
    </div>
  )
}

const Activities = ({ data, sectionref, title, namehotel }: any) => {
  const { locale } = useIntl()

  const [activitiesModal, setActivitiesModal] = useState(false)
  const [selectedActivityIndex, setSelectedActivityIndex] = useState<
    number | null
  >(0)

  const toggleActivitiesModal = (itemIndex: number | null) => {
    setSelectedActivityIndex(itemIndex)
    setActivitiesModal(!activitiesModal)
  }

  const toggleActivitiesModal2 = (itemIndex: number | null) => {
    setSelectedActivityIndex(itemIndex)
  }

  const onBeforeSlide = (detail: any) => {
    const { index, prevIndex } = detail
    console.info(index, prevIndex)
  }

  const selectedActivity =
    selectedActivityIndex !== null ? data[selectedActivityIndex] : null

  const itemsForLoop = data.map((item: any) => item.id)

  const HighlightedText = ({ text, color }: any) => {
    const keywords = ['Premium', 'Standard', 'Select']

    const highlightKeywords = (text: string, keywords: string[]) => {
      const regex = new RegExp(`\\b(${keywords.join('|')})\\b`, 'gi')
      return text.replace(
        regex,
        match => `<span style="color: ${color}">${match}</span>`
      )
    }

    const formattedText = highlightKeywords(text, keywords)

    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />
  }

  useEffect(() => {
    if (activitiesModal) {
      document.body.classList.add('disable-scroll')
    } else {
      document.body.classList.remove('disable-scroll')
    }
  }, [activitiesModal])

  return (
    <div className='sectionactivities' ref={sectionref}>
      <div className='sectionactivities__container'>
        <Text type='subtitle' className={'subtitle'} text={title} />

        <div className='sectionactivities__description'>
          <Swiper
            slidesPerView={'auto'}
            grabCursor={true}
            navigation={true}
            loop={itemsForLoop.length > 3}
            autoplay={{
              delay: 7000,
              disableOnInteraction: false
            }}
            modules={[Autoplay]}
            className='sectionactivities__description--swiper'
          >
            {data.map((better: any, index: number) => (
              <SwiperSlide key={better.id}>
                <CardDescription
                  better={better}
                  index={index}
                  toggleActivitiesModal={toggleActivitiesModal}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <ModalScreen
        showModal={activitiesModal}
        close={toggleActivitiesModal}
        title={
          <p>
            {HighlightedText({ text: namehotel, color: '#D69C4F' })} /
            Actividades
          </p>
        }
      >
        <div className='tabactivitys'>
          <Swiper
            slidesPerView={'auto'}
            grabCursor={true}
            navigation={false}
            className='tabactivitys__swiper'
          >
            {data.map((activity: any, index: number) => (
              <SwiperSlide key={index}>
                <div
                  key={activity.id}
                  className={`tabactivityborder ${
                    index === selectedActivityIndex ? 'active' : ''
                  }`}
                  onClick={() => toggleActivitiesModal2(index)}
                >
                  <Text
                    text={activity.titulo}
                    type='paragraph'
                    className={
                      `activity-tab` === activity.id ? 'active-bold' : ''
                    }
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {selectedActivity && (
            <div className='columnroom'>
              <div>
                <Text
                  type='subtitle2'
                  text={selectedActivity.titulo}
                  className='columnroom__title'
                />
                <Text
                  type='paragraph'
                  html={{ __html: selectedActivity.descripcion }}
                  className='columnroom__description'
                />

                <div className='columnroom__carditems'>
                  {selectedActivity.ubicacion && (
                    <CardItem
                      text={`${messages[locale].place}: ${selectedActivity.ubicacion}`}
                      iconName='place-gray'
                      className='carditems__place'
                    />
                  )}
                  {selectedActivity.horario && (
                    <CardItem
                      text={`${messages[locale].schedule}: ${selectedActivity.horario}`}
                      iconName='calendar2'
                      className='carditems__date'
                    />
                  )}
                  {selectedActivity.turnos && (
                    <CardItem
                      text={`${messages[locale].shift}: ${selectedActivity.turnos}`}
                      iconName='clock'
                      className='carditems__hours'
                    />
                  )}
                  {selectedActivity.precio && (
                    <CardItem
                      text={`${messages[locale].prices}: ${selectedActivity.precio}`}
                      iconName='coin'
                      className='carditems__price'
                    />
                  )}

                  {selectedActivity.redireccion && (
                    <Text
                      type='paragraph2'
                      text='Si quieres conocer más,'
                      text2='click aquí'
                      className='carditems__info'
                      className2='carditems__url'
                      onClick={() =>
                        redirectUrlExternal(selectedActivity.redireccion)
                      }
                    />
                  )}
                </div>
              </div>
              <div className='parentcontainer'>
                <div className='parentcontainer__container'>
                  <LightGallery
                    plugins={[lgZoom]}
                    mode='lg-fade'
                    elementClassNames='parentcontainer__container--item'
                    onBeforeSlide={onBeforeSlide}
                    showZoomInOutIcons={true}
                  >
                    {selectedActivity.img.map((item: any, index: number) => (
                      <a
                      key={index}
                      href={item?.path}
                      className={`length-${index}`}
                    >
                      <img src={item?.path} alt="" className='parentcontainer__container--img'/>
                    </a>
                    ))}
                  </LightGallery>
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalScreen>
    </div>
  )
}

export default Activities
