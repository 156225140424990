
import Axios from "actions/api";
import { endpoints } from "actions/endpoints/services";
import { setDataBoxSearch, setDataBoxSearchPrice } from "redux/actions/language";
import { IGetPriceBox } from "./props";
import * as Sentry from '@sentry/react';

export const getBoxSearch = async ({
  coin,
  navigate
}: any) => {
  try {
    const response = await Axios.request({
      method: "get",
      url: endpoints.cajabusqueda,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Currency-Code": coin,
      },
    });

    const data = response.data;

    setDataBoxSearch(data)

    return data;

  } catch (error) {
    // alert('No se pudo cargar los precios de la caja de búsqueda')
    console.log("Server error: ", error);
    Sentry.captureException(error);
    navigate('/es/error');
  }
}

export const getPriceBox = async ({
  setLoadingPrices,
  htl_id,
  coin,
  navigate,
}: IGetPriceBox) => {
  setLoadingPrices(true)
  try {
    const response = await Axios.request({
      method: "get",
      url: `${endpoints.cajabusqueda}?hotel_travelclick_id=${htl_id}`,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Currency-Code": coin,
      },
    });

    const data = response.data;
    setDataBoxSearchPrice(data)
    return data;

  } catch (error) {
    // alert('No se pudo cargar los precios de la caja de búsqueda')
    console.log("Server error: ", error);
    Sentry.captureException(error);
    navigate('/es/error');
  } finally {
    setLoadingPrices(false)
  }
}
