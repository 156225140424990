export const banner_data_centrado = [
  {
    id: 1,
    titulo: 'CONOCE MÁS SOBRE',
    subtitulo: 'NOSOTROS',
    img_desktop: {
      name: 'banner-responsabilidad-social',
      path: 'https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/media/b793570d-e349-4043-9309-67217e419eaf.jpg',
      width: 1440,
      height: 400,
      size: 794666,
      title: 'Banner Responsabilidad Social',
      alt: 'Banner Responsabilidad Social',
      description: null,
      caption: 'Banner Responsabilidad Social'
    },
    img_mobile: [],
    descripcion_boton: '',
    redireccion_boton: '',
    tipo_banner: {
      id: 1,
      titulo: 'subtitulo/título collage'
    }
  }
]

export const valuations = [
  {
    titulo: 'Estuve con toda mi familia, para un desayuno especial.Todo fue delicioso,como siempre destacar la predisposición de Waldir,quien puso de su tiempo para interactuar.',
    descripcion: '',
    calificacion: 5,
    nombre_cliente: 'Antonio',
    fecha: '2023-11-28',
    pais: {
      nombre: 'PE',
      imagen:
        'https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/pais/PE.svg'
    },
    ota: {
      nombre: 'Booking.com',
      imagen: null
    }
  },
  {
    titulo: 'Estuve con toda mi familia,para un desayuno especial.Todo fue delicioso,como siempre destacar la predisposición de Waldir,quien puso de su tiempo para interactuar.',
    descripcion: '',
    calificacion: 5,
    nombre_cliente: 'Antonio',
    fecha: '2023-11-28',
    pais: {
      nombre: 'PE',
      imagen:
        'https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/pais/PE.svg'
    },
    ota: {
      nombre: 'Booking.com',
      imagen: null
    }
  },
  {
    titulo: 'Estuve con toda mi familia,para un desayuno especial.Todo fue delicioso,como siempre destacar la predisposición de Waldir,quien puso de su tiempo para interactuar.',
    descripcion: '',
    calificacion: 5,
    nombre_cliente: 'Antonio',
    fecha: '2023-11-28',
    pais: {
      nombre: 'PE',
      imagen:
        'https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/pais/PE.svg'
    },
    ota: {
      nombre: 'Booking.com',
      imagen: null
    }
  },
  {
    titulo: 'Estuve con toda mi familia,para un desayuno especial.Todo fue delicioso,como siempre destacar la predisposición de Waldir,quien puso de su tiempo para interactuar.',
    descripcion: '',
    calificacion: 5,
    nombre_cliente: 'Antonio',
    fecha: '2023-11-28',
    pais: {
      nombre: 'PE',
      imagen:
        'https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/pais/PE.svg'
    },
    ota: {
      nombre: 'Booking.com',
      imagen: null
    }
  },
  {
    titulo: 'Estuve con toda mi familia,para un desayuno especial.Todo fue delicioso,como siempre destacar la predisposición de Waldir,quien puso de su tiempo para interactuar.',
    descripcion: '',
    calificacion: 5,
    nombre_cliente: 'Antonio',
    fecha: '2023-11-28',
    pais: {
      nombre: 'PE',
      imagen:
        'https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/pais/PE.svg'
    },
    ota: {
      nombre: 'Booking.com',
      imagen: null
    }
  },
  {
    titulo: 'Estuve con toda mi familia,para un desayuno especial.Todo fue delicioso,como siempre destacar la predisposición de Waldir,quien puso de su tiempo para interactuar.',
    descripcion: '',
    calificacion: 5,
    nombre_cliente: 'Antonio',
    fecha: '2023-11-28',
    pais: {
      nombre: 'PE',
      imagen:
        'https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/pais/PE.svg'
    },
    ota: {
      nombre: 'Booking.com',
      imagen: null
    }
  }
]
