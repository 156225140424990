import { useState, useEffect } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import FlipUnit from "./FlipUnit";

const CountdownTimer = ({ contador, colorBg, colorFuente, isPopup = false }: any) => {
  const { t } = useTranslation();
  const [timeRemaining, setTimeRemaining] = useState({
    days: '  ',
    hours: '  ',
    minutes: '  ',
    seconds: '  ',
  });

  const targetTime = new Date(contador).getTime();

  useEffect(() => {
    const updateCountdown = () => {
      const currentTime = new Date().getTime();
      const timeDifference = targetTime - currentTime;

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeRemaining({
          days: String(days).padStart(2, '0'),
          hours: String(hours).padStart(2, '0'),
          minutes: String(minutes).padStart(2, '0'),
          seconds: String(seconds).padStart(2, '0'),
        });
      } else {
        setTimeRemaining({
          days: '00',
          hours: '00',
          minutes: '00',
          seconds: '00',
        });
      }
    };

    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId);
  }, [targetTime]);

  return (
    <div className="countdown-timer" style={{ color: colorFuente }}>
      <div className={isPopup ? "countdown-display countdown-is-popup" : "countdown-display"}>
        {(timeRemaining.days !== '  ' && timeRemaining.days !== '00') && (
          <>
            <div className="countdown-display-box">
              <div className="timer">
                <FlipUnit backgroundColor={colorBg} color={colorFuente} value={timeRemaining.days[0]} />
                <FlipUnit backgroundColor={colorBg} color={colorFuente} value={timeRemaining.days[1]} />
              </div>
              <p>
                {timeRemaining.days === "01"
                  ? t("countdown.day")
                  : t("countdown.days")}
              </p>
            </div>
            <span className="dots">:</span>
          </>
        )}
        <div className="countdown-display-box">
          <div className="timer">
            <FlipUnit backgroundColor={colorBg} color={colorFuente} value={timeRemaining.hours[0]} />
            <FlipUnit backgroundColor={colorBg} color={colorFuente} value={timeRemaining.hours[1]} />
          </div>
          <p>
            {timeRemaining.hours === "01"
              ? t("countdown.hour")
              : t("countdown.hours")}
          </p>
        </div>
        <span className="dots">:</span>
        <div className="countdown-display-box">
          <div className="timer">
            <FlipUnit backgroundColor={colorBg} color={colorFuente} value={timeRemaining.minutes[0]} />
            <FlipUnit backgroundColor={colorBg} color={colorFuente} value={timeRemaining.minutes[1]} />
          </div>
          <p>
            {timeRemaining.minutes === "01"
              ? t("countdown.minute")
              : t("countdown.minutes")}
          </p>
        </div>
        <span className="dots">:</span>
        <div className="countdown-display-box">
          <div className="timer">
            <FlipUnit backgroundColor={colorBg} color={colorFuente} value={timeRemaining.seconds[0]} />
            <FlipUnit backgroundColor={colorBg} color={colorFuente} value={timeRemaining.seconds[1]} />
          </div>
          <p>
            {timeRemaining.seconds === "01"
              ? t("countdown.second")
              : t("countdown.seconds")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
