import React, { useEffect, useState } from "react";
import { getColorIconFromText } from "../../../utils/function";
import { GoogleMap, OverlayViewF, useLoadScript } from "@react-google-maps/api";
import { Icon, IconText, Text } from "components/atoms";
import { Modal } from "components/molecules";
import { REACT_APP_GOOGLE_MAPS_API_KEY, REACT_APP_MAP_ID } from "typings/env";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import CAMAP from "assets/logo/casa-andina-mapa.webp";
import "./styles.scss";

const CustomSliderTagSection = ({
  item,
  scrollToSection,
  handleSetActiveSection,
  activeSection,
  containerClass,
  activeClass,
  inactiveClass,
}: any) => {
  const handleItemClick = () => {
    handleSetActiveSection(item.link);
    scrollToSection(item.link);
  };

  return (
    <div
      className={`${containerClass} ${
        item.link === activeSection ? activeClass : inactiveClass
      }`}
      onClick={handleItemClick}
    >
      <Text text={item.text} type="paragraph" />
    </div>
  );
};

const TabsHotel = ({
  data,
  tabsHotelRef,
  servicesRef,
  galleryRef,
  discountsRef,
  roomsRef,
  activitiesRef,
  restaurantCityRef,
  valuationsRef,
  colorIcon,
}: any) => {
  const { t } = useTranslation();

  const [ubicationModal, setUbicationModal] = useState(false);
  const [transportModal, setTransportModal] = useState(false);
  const [tourModal, setTourModal] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolledMob, setIsScrolledMob] = useState(false);
  const [activeSection, setActiveSection] = useState(tabsHotelRef);
  const toggleUbicationModal = () => setUbicationModal(!ubicationModal);
  const toggleTransportModal = () => setTransportModal(!transportModal);
  const toggleTourModal = () => setTourModal(!tourModal);

  const item = [
    {
      id: 1,
      text: t("hotelCity.description"),
      link: tabsHotelRef,
      referencia: data.description,
    },
    {
      id: 2,
      text: t("hotelCity.services"),
      link: servicesRef,
      referencia: data.servicios,
    },
    {
      id: 3,
      text: t("hotelCity.gallery"),
      link: galleryRef,
      referencia: data.ambientes,
    },
    {
      id: 4,
      text: t("hotelCity.offers"),
      link: discountsRef,
      referencia: data.promociones,
    },
    {
      id: 5,
      text: t("hotelCity.rooms"),
      link: roomsRef,
      referencia: data.habitaciones,
    },
    {
      id: 6,
      text: t("hotelCity.activities"),
      link: activitiesRef,
      referencia: data.actividades,
    },
    {
      id: 7,
      text: t("hotelCity.restaurants"),
      link: restaurantCityRef,
      referencia: data.restaurantes,
    },
    {
      id: 8,
      text: t("hotelCity.reviewsText"),
      link: valuationsRef,
      referencia: data.reseñas,
    },
  ];

  const hasTarifaSoles = data.tabla_traslados?.tabla.some(
    (item: any) => item.tarifa_soles
  );
  const hasPaxAdicionalesSoles = data.tabla_traslados?.tabla.some(
    (item: any) => item.pax_adicionales_soles
  );
  const hasTarifaDolares = data.tabla_traslados?.tabla.some(
    (item: any) => item.tarifa_dolares
  );
  const hasPaxAdicionalesDolares = data.tabla_traslados?.tabla.some(
    (item: any) => item.pax_adicionales_dolares
  );

  const isValidCoordinate = (coord: any) =>
    !isNaN(parseFloat(coord)) && isFinite(coord);

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        { ref: tabsHotelRef, id: 1 },
        { ref: servicesRef, id: 2 },
        { ref: galleryRef, id: 3 },
        { ref: discountsRef, id: 4 },
        { ref: roomsRef, id: 5 },
        { ref: activitiesRef, id: 6 },
        { ref: restaurantCityRef, id: 7 },
        { ref: valuationsRef, id: 8 },
      ];

      const currentScrollY = window.scrollY;
      const active = sections.find((section) => {
        if (section.ref.current) {
          const { offsetTop, offsetHeight } = section.ref.current;
          return (
            currentScrollY >= offsetTop &&
            currentScrollY < offsetTop + offsetHeight
          );
        }
        return false;
      });

      if (active && activeSection !== active.ref) {
        setActiveSection(active.ref);
      }

      setIsScrolled(currentScrollY > 470);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [
    tabsHotelRef,
    servicesRef,
    galleryRef,
    discountsRef,
    roomsRef,
    activitiesRef,
    restaurantCityRef,
    valuationsRef,
    activeSection,
  ]);

  const scrollToRef = (ref: any) => {
    const yOffset = -220;
    const y =
      ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > 470) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      if (scrollY > 420) {
        setIsScrolledMob(true);
      } else {
        setIsScrolledMob(false);
      }
    };

    // Agrega un event listener para el evento de scroll
    window.addEventListener("scroll", handleScroll);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (ubicationModal || tourModal || transportModal) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
  }, [ubicationModal, tourModal, transportModal]);

  const HighlightedText = ({ text, color }: any) => {
    const keywords = ["Premium", "Standard", "Select"];

    const highlightKeywords = (text: string, keywords: string[]) => {
      const regex = new RegExp(`\\b(${keywords.join("|")})\\b`, "gi");
      return text.replace(
        regex,
        (match) => `<span style="color: ${color}">${match}</span>`
      );
    };

    const formattedText = highlightKeywords(text, keywords);

    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  const getMapCenter = (lat: string, lng: string) => {
    const coordinates = {
      lat: isValidCoordinate(lat) ? parseFloat(lat) : 0,
      lng: isValidCoordinate(lng) ? parseFloat(lng) : 0,
    };
    return coordinates;
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map: any) => {
    mapRef.current = map;
  }, []);

  if (!data) return null;

  return (
    <section style={{ position: "relative" }}>
      <div className="isdesktop">
        <div
          className="tabhotel"
          ref={tabsHotelRef}
          style={{ paddingTop: isScrolled ? "50px" : "80px" }}
        >
          <div className="tabhotel__section">
            <div className="tabhotel__section--descriptions">
              <div className={`onlytab ${isScrolled ? "hidden" : ""}`}>
                {item.map(
                  (activity: any, index: number) =>
                    activity.referencia?.length > 0 && (
                      <CustomSliderTagSection
                        item={activity}
                        scrollToSection={scrollToRef.bind(this, activity.link)}
                        handleSetActiveSection={setActiveSection}
                        activeSection={activeSection}
                        containerClass="tabsections"
                        activeClass="active"
                        inactiveClass=""
                      />
                    )
                )}
              </div>
              <div className="text">
                <div className="list">
                  <div className="listIcons">
                    <Icon
                      name={"map"}
                      list={"buttons"}
                      width={20}
                      height={20}
                    />
                    <Text text={data.direccion} type="paragraph" />
                  </div>
                  <div className="listIcons">
                    <Icon
                      name={"phone"}
                      list={"items"}
                      width={20}
                      height={20}
                    />
                    <Text text={data.telefono} type="paragraph" />
                  </div>
                  <div className="listIcons">
                    <Icon
                      name={"move-right"}
                      list={"items"}
                      width={20}
                      height={20}
                    />
                    <Text
                      text={`Check-in : ${data.check_in} / Check-out : ${data.check_out}`}
                      type="paragraph"
                    />
                  </div>
                </div>

                <div className="items">
                  <Text
                    html={{ __html: data.description }}
                    className={"description"}
                  />
                </div>

                <div className="buttons">
                  {data.enlace_360 !== null && (
                    <button className="button-icon" onClick={toggleTourModal}>
                      <Icon name={"360"} list={"buttons"} width={18} />
                      <Text
                        text={"Tour virtual"}
                        type={"paragraph"}
                        className="paragraph"
                      />
                    </button>
                  )}
                  {data.tabla_traslados?.descripcion && (
                    <button
                      className="button-icon"
                      onClick={toggleTransportModal}
                    >
                      <Icon name={"car"} list={"buttons"} width={18} />
                      <Text
                        text={t("hotelCity.transfers")}
                        type={"paragraph"}
                        className="paragraph"
                      />
                    </button>
                  )}
                  {data.latitud !== null && data.longitud !== null && (
                    <button
                      className="button-icon"
                      onClick={toggleUbicationModal}
                    >
                      <Icon name={"map"} list={"buttons"} width={18} />
                      <Text
                        text={t("hotelCity.location")}
                        type={"paragraph"}
                        className="paragraph"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="tabhotel__section--container">
              <div className="tabhotel__section--container--review">
                <p className="night">
                  {t("betterPrices.nightFrom")}{" "}
                  <span>
                    {data.precio?.simbolo}
                    {data.precio?.precio}
                  </span>
                </p>
                <p className="igv">
                  + {data.igv?.simbolo}
                  {data.igv?.precio} {data?.texto_tooltip_igv_card}
                </p>
                {data.logro?.img?.path && (
                  <div className="tripadvisor">
                    <Text text={data.logro?.titulo} type="paragraph" />
                    <img
                      src={data.logro?.img?.path}
                      alt={data.logro?.img?.alt}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ismobile">
        {data.ota.calificacion !== null && (
          <div className="cardvalue">
            <div
              className="cardvalue__container"
              style={{
                backgroundColor: getColorIconFromText(data.nombre_corto_marca),
              }}
            >
              <div>
                <Text
                  text={t("hotelCity.rating")}
                  type="paragraph"
                  className="title"
                />
                <Text
                  text={data.ota?.calificacion_descripción}
                  type="paragraph"
                  className="subtitle"
                />
              </div>

              <IconText
                text={data.ota?.calificacion}
                nameIcon="star2"
                listIcon="items"
                className="calificacion"
                w={24}
                h={24}
                type="left2"
              />
            </div>
          </div>
        )}
        <div
          className={`cardvalue__sections ${
            isScrolledMob ? "hidden" : "visible"
          }`}
          style={{ paddingTop: "30px" }}
        >
          <Swiper
            slidesPerView={"auto"}
            grabCursor={true}
            navigation={false}
            className="tabactivitys__swiper"
          >
            {item.map(
              (activity: any, index: number) =>
                activity.referencia?.length > 0 && (
                  <SwiperSlide key={index}>
                    <CustomSliderTagSection
                      item={activity}
                      scrollToSection={scrollToRef.bind(this, activity.link)}
                      handleSetActiveSection={setActiveSection}
                      activeSection={activeSection}
                      containerClass="cardvalueselect"
                      activeClass="active"
                      inactiveClass="inactive"
                    />
                  </SwiperSlide>
                )
            )}
          </Swiper>
        </div>
        <div
          className="slider-container"
          style={{
            top: isScrolledMob ? "64px" : "-120px",
          }}
        >
          <Swiper
            spaceBetween={0}
            slidesPerView="auto"
            className="tabactivitys__swiper"
          >
            {item.map(
              (activity: any, index: number) =>
                activity.referencia?.length > 0 && (
                  <SwiperSlide className="slider-slide" key={index}>
                    <CustomSliderTagSection
                      item={activity}
                      scrollToSection={scrollToRef.bind(this, activity.link)}
                      handleSetActiveSection={setActiveSection}
                      activeSection={activeSection}
                      containerClass="cardvalueselect"
                      activeClass="active"
                      inactiveClass="inactive"
                    />
                  </SwiperSlide>
                )
            )}
          </Swiper>
        </div>
        <div className="tabhotelm px-1rem">
          <div className="tabhotelm__section">
            <div className="container">
              <div className="info">
                <IconText
                  text={data.direccion}
                  nameIcon="map"
                  listIcon={"buttons"}
                  family={"paragraph"}
                  type="left"
                  w={20}
                  h={20}
                />
                <IconText
                  text={data.telefono}
                  nameIcon="phone"
                  listIcon={"items"}
                  family={"paragraph"}
                  type="left"
                  w={20}
                  h={20}
                />
                <IconText
                  text={`Check-in : ${data.check_in} / Check-out : ${data.check_out}`}
                  nameIcon="move-right"
                  listIcon={"items"}
                  family={"paragraph"}
                  type="left"
                  w={20}
                  h={20}
                />

              </div>
              <div className="itesm">
                <Text
                  type="paragraph"
                  html={{ __html: data.description }}
                  className={"paragraph"}
                />
              </div>

              <div className="buttons">
                <Swiper
                  slidesPerView={"auto"}
                  grabCursor={true}
                  className="btnstabs__swiper"
                >
                  {data.enlace_360 !== null && (
                    <SwiperSlide>
                      <button className="button-icon" onClick={toggleTourModal}>
                        <Icon name={"360"} list={"buttons"} width={18} />
                        <Text
                          text={"Tour virtual"}
                          type={"paragraph"}
                          className="paragraph"
                        />
                      </button>
                    </SwiperSlide>
                  )}
                  {data.tabla_traslados.tabla.length > 0 && (
                    <SwiperSlide>
                      <button
                        className="button-icon"
                        onClick={toggleTransportModal}
                      >
                        <Icon name={"car"} list={"buttons"} width={18} />
                        <Text
                          text={t("hotelCity.transfers")}
                          type={"paragraph"}
                          className="paragraph"
                        />
                      </button>
                    </SwiperSlide>
                  )}
                  {data.latitud !== null && data.longitud !== null && (
                    <SwiperSlide>
                      <button
                        className="button-icon"
                        onClick={toggleUbicationModal}
                      >
                        <Icon name={"map"} list={"buttons"} width={18} />
                        <Text
                          text={t("hotelCity.location")}
                          type={"paragraph"}
                          className="paragraph"
                        />
                      </button>
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
              <div className="reviews">
                <div className="list">
                  {data.igv !== null && data.igv.precio > 0 && (
                    <div className="line">
                      <IconText
                        type="left"
                        text={t("betterPrices.nightFrom")}
                        nameIcon="check"
                        listIcon={"items"}
                        w={22}
                        h={22}
                        family={"txnormal"}
                      />
                      <div>
                        <Text
                          type="paragraph"
                          text={`${data.precio.simbolo} ${data.precio.precio}`}
                          className="price"
                        />
                        <Text
                          type="paragraph"
                          text={`+ ${data.igv.simbolo} ${data.igv?.precio} ${data?.texto_tooltip_igv_card}`}
                          className={"paragraph"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="line">
                    {data.logro?.img && (
                      <>
                        <IconText
                          type="left"
                          text={data.logro?.titulo}
                          nameIcon="check"
                          listIcon={"items"}
                          w={22}
                          h={22}
                        />
                        <img
                          src={data.logro?.img?.path}
                          alt={data.logro?.img?.alt}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        breadcrumb={true}
        isTitle={true}
        showModal={tourModal}
        close={toggleTourModal}
        title={HighlightedText({ text: data.titulo, color: "#D69C4F" })}
        route={"Tour Virtual"}
        allowButtons={false}
        btninactive={toggleTourModal}
        btnactive={"Link"}
        oncbtnactive={data.enlace_360}
      >
        <div className="transporta">
          <iframe
            className="enlace360"
            src={data.enlace_360}
            frameBorder={0}
            allow="autoplay; fullscreen; vr"
            allowFullScreen
            title="360"
            width={"100%"}
          />
        </div>
      </Modal>

      <Modal
        breadcrumb={true}
        showModal={transportModal}
        close={toggleTransportModal}
        title={HighlightedText({ text: data.titulo, color: "#D69C4F" })}
        route={"Traslado"}
        allowButtons={true}
        btninactive={toggleTransportModal}
        btnactive={"Contactar"}
        oncbtnactive={
          data.correo
            ? `mailto:${data.correo}`
            : data.telefono_primario
            ? `tel:${data.telefono_primario}`
            : ""
        }
      >
        <div className="transport">
          <Text
            text="Traslado hacia el hotel"
            type={"paragraph"}
            className="title"
          />

          <Text
            html={{ __html: data.tabla_traslados?.descripcion }}
            type="paragraph"
            className={"paragraph"}
          />

          {data.tabla_traslados?.tabla.length > 0 && (
            <div className="table-container">
              <table bgcolor="#EEE" cellSpacing={24} width={"100%"}>
                <thead>
                  <tr>
                    <th>Ruta</th>
                    <th>Medio</th>
                    <th>N° personas</th>
                    {hasTarifaSoles && <th>Tarifa (S/)</th>}
                    {hasPaxAdicionalesSoles && <th>Pax adicionales (S/)</th>}
                    {hasTarifaDolares && <th>Tarifa ($)</th>}
                    {hasPaxAdicionalesDolares && <th>Pax adicionales ($)</th>}
                  </tr>
                </thead>
                <tbody>
                  {data.tabla_traslados?.tabla.map(
                    (item: any, index: number) => (
                      <tr key={index}>
                        <td>{item.ruta}</td>
                        <td>{item.medio}</td>
                        <td>{item.numero_personas}</td>
                        {hasTarifaSoles && (
                          <td>
                            {item.tarifa_soles
                              ? `S/ ${item.tarifa_soles}`
                              : "-"}
                          </td>
                        )}
                        {hasPaxAdicionalesSoles && (
                          <td>
                            {item.pax_adicionales_soles
                              ? `S/ ${item.pax_adicionales_soles}`
                              : "-"}
                          </td>
                        )}
                        {hasTarifaDolares && (
                          <td>
                            {item.tarifa_dolares
                              ? `$ ${item.tarifa_dolares}`
                              : "-"}
                          </td>
                        )}
                        {hasPaxAdicionalesDolares && (
                          <td>
                            {item.pax_adicionales_dolares
                              ? `$ ${item.pax_adicionales_dolares}`
                              : "-"}
                          </td>
                        )}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}

          {data.tabla_traslados?.informacion_importante && (
            <div className="icontext">
              <IconText
                text={"Información importante"}
                nameIcon="info"
                listIcon={"items"}
                type="left"
                w={20}
                h={20}
              />

              <Text
                html={{ __html: data.tabla_traslados?.informacion_importante }}
                type="paragraph"
                className="info"
              />
            </div>
          )}
        </div>
      </Modal>

      <Modal
        breadcrumb={true}
        showModal={ubicationModal}
        close={toggleUbicationModal}
        title={HighlightedText({ text: data.titulo, color: "#D69C4F" })}
        route={t("hotelCity.location")}
        allowButtons={true}
        buttonCustom={true}
        btnWaze={data.htl_waze_link}
        btnGoogle={data.htl_google_maps_link}
        isSubtitle={true}
        subtitleCustom={
          <div className="subtitle-maps">
            <Text
              text="Ubicación del hotel"
              type="paragraph"
              className="title"
            />

            <div className="direction">
              <IconText
                text={data.direccion}
                nameIcon="map"
                listIcon={"buttons"}
                type="left"
                w={20}
                h={20}
                className="place-city"
              />
            </div>
          </div>
        }
      >
        <div className="mapsact">
          <div className="mapcontainer">
            {isLoaded ? (
              <GoogleMap
                center={getMapCenter(data.latitud, data.longitud)}
                onLoad={onMapLoad}
                zoom={18}
                mapContainerStyle={{ width: "100%", height: "100%" }}
                options={{
                  mapId: REACT_APP_MAP_ID,
                  zoomControl: true,
                  fullscreenControl: true,
                  streetViewControl: false,
                  mapTypeControl: false,
                  disableDefaultUI: true,
                }}
              >
                <OverlayViewF
                  position={getMapCenter(data.latitud, data.longitud)}
                  mapPaneName="overlayLayer"
                  children={
                    <div
                      className="tooltipmap"
                      style={{
                        top: 0,
                        left: 0,
                        transform: "translateX(-50%) translateY(-100%)",
                      }}
                    >
                      <div>
                        <figure>
                          <img src={CAMAP} alt="Casa Andina" height={26} />
                        </figure>
                        <p
                          style={{
                            color: getColorIconFromText(
                              data.nombre_corto_marca
                            ),
                          }}
                        >
                          {data.nombre_corto_marca}
                        </p>
                        <p>{data.nombre_corto_sin_marca}</p>
                      </div>
                    </div>
                  }
                />
              </GoogleMap>
            ) : (
              <div>Loading Maps</div>
            )}
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default TabsHotel;
