import { Card, Text } from "components/atoms";
import { IVisit } from "./props";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.scss";
import "swiper/css";
import "swiper/css/navigation";
import { Animations } from "components/molecules";
import Searchboxgeneral from "../navbar/searchboxgeneral";

const Visit = ({ data, title }: IVisit) => {
  return (
    <div className="visit visit-element">
      <div className="visit__container">
        <div
        // TODO searchbox
          style={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            width: "100%",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9,
            padding: "16px",
          }}
        >
          <Searchboxgeneral searchMobile={false} />
        </div>

        <div>
          <Text text={title} type="title" className={"subtitle"} />
        </div>

        <div className="visit__container--description">
          <Swiper
            slidesPerView={"auto"}
            grabCursor={true}
            className="visit__description--swiper"
          >
            {data && Array.isArray(data) && data.length > 0 ? (
              <>
                {data.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <Animations type="downSide">
                    <Card type="visit" visit={item} />
                  </Animations>
                </SwiperSlide>
                ))}
              </>
            ) : (
              <>
                {[...Array(4)].map((_, index) => (
                  <SwiperSlide key={index}>
                    <Animations type="downSide">
                      <div className={`cardvisit visits`}>
                        <Animations type="skeletonContent">
                          <div className="visit-img">
                          </div>
                        </Animations>
                        <div className="content">
                          <div className="content__description">
                            <div>
                              <Animations type="skeletonContent">
                                <Text
                                  style={{ width : 40, height : 40, borderRadius : 10 }}
                                  type="paragraph"
                                  className="content__description--number"
                                />
                              </Animations>
                            </div>
                            <div>
                              <Animations type="skeletonContent">
                                <Text
                                  style={{ width : 70, height : 15, borderRadius : 10 }}
                                  type="paragraph"
                                  className="content__description--title"
                                />
                              </Animations>
                              <div style={{ marginTop : "2px" }}>
                                <Animations type="skeletonContent">
                                  <Text
                                    style={{ width : 60, height : 10, borderRadius : 5 }}
                                    type="paragraph"
                                    className="content__description--subtitle"
                                  />
                                </Animations>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Animations>
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Visit;
