import React from 'react'
import './styles.scss'
import { Icon, Text } from 'components/atoms'

const PromotionsYTQP = ({ data, isList }: any) => {

  return (
    <div
      className='promotionsyqp'
      // style={{ backgroundColor: data?.color_promocion, color: 'white' }}
    >
      <div className='promotionsyqp__container'>
        <div className='promotionsyqp__container--titulo'>
          <Text html={{ __html: data.descripcion }} family={'txpromotion'} />
        </div>
        <div className='promotionsyqp__container--icon'>
          <Icon name='rightcopy' list='arrows' width={26} height={45} />
        </div>
        <div className='codigo'>
          <Text
            html={{ __html: data.prm_descripcion_2 }}
            family={'txpromotion'}
          />

          {data.servicios &&
              data.servicios.map((item: any, index: number) => (
                <div className='list' key={index}>
                  <img src={item.icono?.path} alt={item.icono?.alt} width={24} height={24}/>
                  <Text text={item.titulo} type='paragraph'/>
                </div>
              ))
            }
        </div>
      </div>
    </div>
  )
}

PromotionsYTQP.defaultProps = {
  isList: false
}

export default PromotionsYTQP
