import React, { useEffect, useState } from "react";
import Icon from "../icon";
import { IRating } from "./props";
import "./styles.scss";
import IconText from "../iconText";

const Rating = ({
  number,
  onlyText,
  text,
  reset,
  data,
  setSelectedRating,
  selectedRating,
  setRatingsValue,
  setLabel,
}: IRating) => {
  const [selectedId, setSelectedId] = useState("");

  const handleRatingClick = (id: string) => {
    if (id === selectedId) {
      setSelectedId("");
      setSelectedRating("");
      setRatingsValue("");
    } else {
      setSelectedId(id);
      setSelectedRating(id);
      setRatingsValue(id);
      setLabel(data.titulo);
    }
  };
  useEffect(() => {
    setSelectedId(selectedRating);
  }, [data, selectedRating]);

  return (
    <div
      key={data.id}
      onClick={() => handleRatingClick(data.id)}
      className={`rating ${selectedId === data.id ? "selected" : ""}`}
    >
      <IconText
        text={data.titulo}
        nameIcon="starfull"
        listIcon="items"
        color="#FFC400"
        w={16}
        h={16}
        type="right"
        className="starfull-rating"
      />
    </div>
  );
};

export default Rating;
