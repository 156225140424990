import { useEffect, useRef } from 'react'
import { BannerRestaurants, Hotels } from 'components/organisms'
import ListIcons from 'components/molecules/listIcons'
import ListHeader from 'components/molecules/list-header'
import Loader from 'components/molecules/loader'
import { useAppSelector } from 'hooks/useAppSelector'
import { useNavigate, useParams } from 'react-router-dom'
import { getHotelTypes } from 'actions/hotel/services'
import { messages } from 'utils/lang'
import { useIntl } from 'react-intl'
import { ScrollTopButton } from 'components/molecules'

const HotelCategories = () => {
  const { locale } = useIntl()
  const { types = '' } = useParams()
  const navigate = useNavigate();
  
  const { language, coin, loading, data, tipo_hotel, hoteles } = useAppSelector(
    state => ({
      language: state.config.language,
      loading: state.language.loading,
      coin: state.config.coin,
      data: state.language.dataTypesHotels,
      tipo_hotel: state.language.dataTypesHotels?.tipo_hotel || [],
      hoteles: state.language.dataTypesHotels?.hoteles || []
    })
  )

  const hotelesRef = useRef(null)
  const bannerRef = useRef(null)

  const scrollToSection = (ref: any) => {
    if (ref.current) {
      const marginTop = 70

      const topOffset = ref.current.offsetTop - marginTop
      window.scrollTo({ top: topOffset, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    getHotelTypes({ types, language, coin, navigate })
    window.scrollTo(0, 0)
  }, [types, language, coin, navigate])

  if (loading || !data) return <Loader />

  return (
    <section>
      <BannerRestaurants
        types='tipo_hotel'
        data={tipo_hotel}
        hotelesRef={hotelesRef}
        bannerRef={bannerRef}
      />

      {tipo_hotel.servicios_tipo_hotel.length > 0 && (
        <ListHeader
          data={tipo_hotel}
          commoditiess={tipo_hotel.servicios_tipo_hotel}
        />
      )}

      {tipo_hotel.servicios.length > 0 && (
        <ListIcons
          title={messages[locale].ourServices}
          data={tipo_hotel.servicios}
          colorIcon={tipo_hotel.color}
        />
      )}

      {hoteles.length > 0 && (
        <Hotels
          isTitle
          title={`${messages[locale].discoverOurHotels} ${tipo_hotel.titulo}`}
          data={hoteles}
          hotelesRef={hotelesRef}
          type='hotel'
        />
      )}

      <ScrollTopButton
        scrollToSection={scrollToSection}
        descriptionRef={bannerRef}
      />
    </section>
  )
}

export default HotelCategories
