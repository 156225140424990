import React, { useEffect } from "react";
import { BannerSlider, ListCards } from "components/organisms";
import Section from "components/molecules/section";
import "./styles.scss";
import { bankEntities } from "assets/images/home/bank-entities";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PromotionsBanks = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const bannerRestaurantBank = [
    {
      id: 1,
      titulo: t("restaurants-banks.ourDiscounts"),
      subtitulo: t("restaurants-banks.enjoyRestaurants"),
      color_fondo_logo: "rgb(47, 28, 216)",
      img_desktop: {
        name: "ambiente-bar",
        path: bankEntities.background,
        width: 354,
        height: 405,
        size: 302962,
        title: "ambiente-bar",
        alt: "ambiente-bar",
        description: "ambiente-bar",
        caption: "ambiente-bar",
      },
      img_mobile: null,
      descuento: "",
      descripcion_boton: "",
      redireccion_boton: "",
      img_logos: [
        {
          id: 1,
          path: bankEntities.bcpWhite,
          alt: "bcp",
          title: "bcp",
          color: "rgb(47, 28, 216)",
        },
        {
          id: 2,
          path: bankEntities.ibkWhite,
          alt: "ibk",
          title: "ibk",
          color: "",
        },
        {
          id: 3,
          path: bankEntities.bbvaWhite,
          alt: "bbva",
          title: "bbva",
          color: "",
        },
      ],
      tipo_banner: {
        id: 1,
        titulo: "bancos",
      },
    },
  ];

  const listRestaurantBank = [
    {
      id: 3,
      nombre: "PROMOCION INTERBANK ",
      title: t("restaurants-banks.interbankAccount"),
      restriction: t("restaurants-banks.interbankValidPeriod"),
      redireccion: "/es/ofertas/promo",
      color_promocion: "rgba(20, 51, 255, 1)",
      entidad: {
        id: 2,
        titulo: "INTERBANK",
        color: "rgba(114, 170, 58, 0.85)",
        logo: {
          name: "logo-ibk-white",
          path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Promociones/logo-ibk-white.png",
          width: 244,
          height: 69,
          size: 10406,
          title: null,
          alt: "logo-ibk-white",
          description: null,
          caption: null,
        },
      },
      categoria: {
        id: 2,
        titulo: "BANCO",
      },
      is_formulario: false,
      card: {
        tipo_disenio: {
          id: 1,
          titulo: "Oferta banco restaurantes",
        },
        titulo_card: "",
        condicion_abajo_precio: "",
        texto_encima_precio: "",
        valor: "50%",
        titulo: null,
        subtitulo: t("restaurants-banks.entireMenuBuffet"),
        logo: {
          name: "logo-ibk",
          path: bankEntities.ibkColor,
          width: 105,
          height: 20,
          size: 2320,
          title: null,
          alt: "ibk",
          description: null,
          caption: null,
        },
        img: {
          name: "fondo-oferta-2",
          path: bankEntities.Card1,
          width: 191,
          height: 194,
          size: 90219,
          title: "Fondo Oferta 2",
          alt: "Fondo Oferta 2",
          description: null,
          caption: "Fondo Oferta 2",
        },
        color: "rgba(6, 190, 79, 0.85)",
        color_secundario: null,
        precio_con_descuento: 0,
        precio: 0,
      },
    },
    {
      id: 2,
      nombre: "PROMOCION BBVA",
      redireccion: "/es/ofertas/promo",
      color_promocion: "rgba(20, 51, 255, 1)",
      title: t("restaurants-banks.bbvaAccount"),
      restriction: t("restaurants-banks.bbvaValidPeriod"),
      entidad: {
        id: 2,
        titulo: "bbva",
        color: "rgba(41, 212, 13, 1)",
        logo: {
          name: "logo-bbva",
          path: bankEntities.bbvaColor,
          width: 244,
          height: 69,
          size: 10406,
          title: null,
          alt: "logo-ibk-white",
          description: null,
          caption: null,
        },
      },
      categoria: {
        id: 2,
        titulo: "BANCO",
      },
      is_formulario: false,
      card: {
        tipo_disenio: {
          id: 1,
          titulo: "Oferta banco restaurantes",
        },
        titulo_card: "",
        condicion_abajo_precio: "",
        texto_encima_precio: "",
        valor: "40%",
        titulo: null,
        subtitulo: t("restaurants-banks.plazaMenuBuffet"),
        logo: {
          name: "logo-bbva",
          path: bankEntities.bbvaColor,
          width: 105,
          height: 20,
          size: 2320,
          title: null,
          alt: "ibk",
          description: null,
          caption: null,
        },
        img: {
          name: "fondo-oferta-2",
          path: bankEntities.Card2,
          width: 191,
          height: 194,
          size: 90219,
          title: "Fondo Oferta 2",
          alt: "Fondo Oferta 2",
          description: null,
          caption: "Fondo Oferta 2",
        },
        color: "rgba(0, 42, 142, 0.85)",
        color_secundario: null,
        precio_con_descuento: 0,
        precio: 0,
      },
    },
    {
      id: 3,
      nombre: "PROMOCION BCP",
      redireccion: "/es/ofertas/promo",
      color_promocion: "rgba(20, 51, 255, 1)",
      title: t("restaurants-banks.bcpAccount"),
      restriction: t("restaurants-banks.bcpValidPeriod"),
      entidad: {
        id: 2,
        titulo: "INTERBANK",
        color: "rgba(41, 212, 13, 1)",
        logo: {
          name: "logo-ibk-white",
          path: "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/Promociones/logo-ibk-white.png",
          width: 244,
          height: 69,
          size: 10406,
          title: null,
          alt: "logo-ibk-white",
          description: null,
          caption: null,
        },
      },
      categoria: {
        id: 2,
        titulo: "BANCO",
      },
      is_formulario: false,
      card: {
        tipo_disenio: {
          id: 1,
          titulo: "Oferta banco restaurantes",
        },
        titulo_card: "",
        condicion_abajo_precio: "",
        texto_encima_precio: "",
        valor: "50%",
        titulo: null,
        subtitulo: t("restaurants-banks.entireMenuBuffet"),
        logo: {
          name: "logo-bcp",
          path: bankEntities.bcpColor,
          width: 105,
          height: 20,
          size: 2320,
          title: null,
          alt: "ibk",
          description: null,
          caption: null,
        },
        img: {
          name: "fondo-oferta-2",
          path: bankEntities.Card3,
          width: 191,
          height: 194,
          size: 90219,
          title: "Fondo Oferta 2",
          alt: "Fondo Oferta 2",
          description: null,
          caption: "Fondo Oferta 2",
        },
        color: "rgba(14, 69, 126, 0.85)",
        color_secundario: null,
        precio_con_descuento: 0,
        precio: 0,
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BannerSlider data={bannerRestaurantBank} />

      <Section title={t("restaurants-banks.takeAdvantage")}>
        <div className="quota">
          <div className="quota__container">
            <p>{t("restaurants-banks.weAwaitYou")}</p>

            <div className="quota__container--restaurantes">
              <div
                className="circle"
                style={{ backgroundColor: "rgb(234, 28, 45)" }}
                onClick={() => navigate(`/${i18n.language}/${t("routes.restaurants")}/la_plaza`)}
              >
                <img
                  src="https://s3.us-east-1.amazonaws.com/ca-webdev/TiposRestaurantes/a910daa1-1fef-445e-a322-323adc32dcbb.png"
                  alt=""
                />
              </div>
              <div
                className="circle"
                style={{ backgroundColor: "rgb(0, 44, 85)" }}
                onClick={() => navigate(`/${i18n.language}/${t("routes.restaurants")}/alma`)}
              >
                <img
                  src="https://s3.us-east-1.amazonaws.com/ca-webdev/TiposRestaurantes/991891cc-9876-4bdd-993f-80aca6f01598.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section bgColor="#F5F5F5" isTitle={false}>
        <ListCards list={listRestaurantBank} isTitle />
      </Section>
    </>
  );
};

export default PromotionsBanks;
