import Axios from 'actions/api'
import { endpoints } from 'actions/endpoints/services'
import {
  setDataPromotion,
  setDataPromotionSubPages,
  setLoading,
  setDataPromotionDestinations
} from 'redux/actions/language'
import {
  IGetPromotionDestinations,
  IGetPromotions,
  IGetPromotionsSubPages
} from './props'
import { updateMetadata } from 'utils/function'
import * as Sentry from '@sentry/react';

export const getPromotions = async ({ language, coin, navigate }: IGetPromotions) => {
  try {
    const response = await Axios.request({
      method: 'get',
      url: endpoints.promociones,
      headers: {
        'Accept-Language': language,
        'Currency-Code': coin,
        'Content-Type': 'application/json'
      }
    })

    const data = response.data

    setDataPromotion(data)
    updateMetadata({
      title: data?.meta_data?.titulo || 'Casa Andina Promociones',
      description: data?.meta_data?.descripcion,
      canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
      hreflang: [
        { hreflang: 'es', href: data?.meta_data?.url_canonica_es },
        { hreflang: 'en', href: data?.meta_data?.url_canonica_en },
        { hreflang: 'pt', href: data?.meta_data?.url_canonica_pt },
      ],
    })

    return data
  } catch (error) {
    // alert('No se pudo cargar la vista promociones')
    console.log("Server error: ", error);
    Sentry.captureException(error);
    navigate('/es/error');
  }
}

export const getPromotionsSubPages = async ({
  subpages,
  coin,
  language,
  params,
  navigate
}: IGetPromotionsSubPages) => {
  try {
    setLoading(true);

    let paramsparse: any = {};
    for (let key in params) {
      if (params[key] !== '') {
        paramsparse[key] = params[key];
      }
    }

    const promotionSubpages = `/promociones/${subpages}`;

    const response = await Axios.request({
      method: 'get',
      url: promotionSubpages,
      params: paramsparse,
      headers: {
        'Accept-Language': language,
        'Currency-Code': coin,
        "Accept": "application/json",
        'Content-Type': 'application/json'
      }
    });

    const data = response.data;

    setDataPromotionSubPages(data);
    updateMetadata({
      title: data?.meta_data?.titulo || 'Casa Andina Promociones',
      description: data?.meta_data?.descripcion,
      canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
      hreflang: [
        { hreflang: 'es', href: data?.meta_data?.url_canonica_es },
        { hreflang: 'en', href: data?.meta_data?.url_canonica_en },
        { hreflang: 'pt', href: data?.meta_data?.url_canonica_pt },
      ],
      nofollow: data?.meta_data?.no_follow
    });

    return data;
  } catch (error) {
    navigate('*', { replace: false });
  } finally {
    setLoading(false);
  }
};

export const getPromotionsDestinations = async ({
  subpages,
  language,
  navigate,
}: IGetPromotionDestinations) => {
  try {
    setLoading(true)
    const promotionDestinations = `/promociones-destinos/${subpages}`

    const response = await Axios.request({
      method: 'get',
      url: promotionDestinations,
      headers: {
        'Accept-Language': language,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    const data = response.data
    setDataPromotionDestinations(data)

    return data
  } catch (error) {
    // alert('No se pudo cargar los destinos')
    console.log("Server error: ", error);
    Sentry.captureException(error);
    navigate('/es/error');
  } finally {
    setLoading(false)
  }
}