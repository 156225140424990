import { BannerSlider } from 'components/organisms'
import PromotionsCopy from 'components/organisms/promotions-copy'
import PromotionsCard from 'components/organisms/promotions-card'
import { useTranslation } from 'react-i18next';

const PromotionsBanks = ({ data, idFilter }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <BannerSlider data={data.banners} />

      <PromotionsCopy
        data={data}
        bank={data.entidad}
      />

      <PromotionsCard
        title={t("promotions.adventurousOpportunities")}
        data={data.hoteles}
        bank={data.entidad}
        codigo={data.codigo}
        type='campaign'
        idFilter={idFilter}
      />
    </>
  )
}

export default PromotionsBanks
