import { useEffect } from "react";
import "./styles.scss";

const CheckinPiura = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='checkin_hotel checkin_hotel-element'>
        <div className='checkin_hotel__container'>
          <h1>Acelera tu check in</h1>

          <div className="item-checkin">            
            <img
                src='https://s3.us-east-1.amazonaws.com/ca-webprod/media/bcdb7df5-c1c4-4be2-9617-43b9738e54a2.jpg' className="item-img"
              ></img>
              <div className="item-content">
                  <h2>Casa Andina Premium Piura</h2>
                  <p>Ubicación privilegiada en una de las mejores zonas de Piura, acceso fácil, seguridad y a unos pasos del centro financiero. 
                    83 amplias habitaciones en un hotel moderno y funcional. La opción ideal si valoras la eficiencia y el servicio. Prueba la 
                    mejor comida y bebida local en nuestro restaurante La Plaza Bar & Grill, además de un delicioso desayuno buffet con extras gratuitos. 
                    Cuenta con servicio de traslado gratuito (aplican restricciones). También contamos con un jardín y 4 salas con capacidad de hasta 700 personas. 
                    Ofrecemos servicio de catering con variadas opciones de alimentación, así como equipos y personal altamente entrenado.
                    |Check-in: 14:00 p.m. - Check-out: 12:00 p.m.|</p>
                  <a href="https://kiosk.newhotelcloud.com/#/welcome?ht=82450DE013064A51E0532B1A330A7B30" target="_blank">ACELERA TU CHECK IN</a>
              </div>

          </div>

          <div className="item-checkin">            
            <img
                src='https://s3.us-east-1.amazonaws.com/ca-webprod/media/1d9b8462-e7e9-4f0f-a46a-e1795f2724ea.jpg' className="item-img"
              ></img>
              <div className="item-content">
                  <h2>Casa Andina Standard Piura</h2>
                  <p>Casa Andina Standard Piura está ubicado a un paso del nuevo centro financiero de Piura. Gracias a que colinda con Casa Andina Premium Piura, puede hacer uso de todas sus instalaciones. Contamos con estacionamiento, servicios en la habitación, transporte para el aeropuerto, servicio de traslado gratuito (aplican restricciones) entre otros servicios que complementarán tu experiencia. Además, puedes disfrutar de un delicioso desayuno buffet y una variada carta en nuestro restaurante La Plaza Bar & Grill. Ideal para ejecutivos o viajeros que buscan un ambiente cálido, cómodo y funcional. |Check-in: 14:00 p.m. - Check-out: 12:00 p.m.|</p>
                  <a href="https://kiosk.newhotelcloud.com/#/welcome?ht=82452F54F8F3A119E0532A1A330A23C1" target="_blank">ACELERA TU CHECK IN</a>
              </div>

          </div>

        </div>
      </div>            
    </>
  );
};

export default CheckinPiura;
