import { useEffect } from "react";
import { BannerSlider } from "components/organisms";
import "./styles.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PersonalClientDataProtectionPolicy = () => {
  const { t, i18n } = useTranslation();

  const banner_data_centrado = [
    {
      id: 1,
      titulo: t("personalDataProtectionPolicy.title"),
      subtitulo: "",
      color_fondo_logo: "rgba(62, 145, 41, 1)",
      img_desktop: {
        name: "banner-terminos-y-condiciones",
        path: "https://s3.us-east-1.amazonaws.com/multimediaqa.casa-andina.com/images/1440X600_promocionestadiadosnochescasaandinahoteleschimenea.JPG",
        title: "Banner Términos y Condiciones",
        alt: "Banner Términos y Condiciones",
        caption: "Banner Términos y Condiciones",
      },
      tipo_banner: {
        id: 1,
        titulo: "landings",
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BannerSlider data={banner_data_centrado} gradiente textLarge />
      <div className="associatedhotels">
        <div className="associatedhotels__container">
          <div className="associatedhotels__container--section">
            <h1>{t("personalDataProtectionPolicy.clientsClause")}</h1>

            <p>
              {t("personalDataProtectionPolicy.consent")}{" "}
              <span>Nessus Hoteles Perú S.A.</span>{" "}
              {t("personalDataProtectionPolicy.and")}{" "}
              <span>Nessus Hoteles Perú Selva S.A.</span>
              {t("personalDataProtectionPolicy.nessusHotels")}{" "}
              <span>RNPDP-PJP N° 5757</span> y<span>RNPDP-PJP N° 6907,</span>{" "}
              {t("personalDataProtectionPolicy.location")}{" "}
              <a
                href="https://s3.us-east-1.amazonaws.com/multimediaqa.casa-andina.com/document/FlujotransfronterizoCLIENTES.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("personalDataProtectionPolicy.here")}.
              </a>
            </p>

            <p>
              {t("personalDataProtectionPolicy.retentionPeriod")}{" "}
              <a href="mailto:proteccióndedatos@casa-andina.com">
                proteccióndedatos@casa-andina.com
              </a>{" "}
              {t("personalDataProtectionPolicy.contactInfo")}{" "}
              <Link to={`/${i18n.language}/arco`} className="insite">
                https://www.casa-andina.com/{i18n.language}/arco/
              </Link>
              . {t("personalDataProtectionPolicy.disagreementPolicy")}{" "}
              <a href="mailto:marketing@casa-andina.com">
                marketing@casa-andina.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalClientDataProtectionPolicy;
