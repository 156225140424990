import {
  GoogleMap,
  InfoWindowF,
  OverlayView,
  OverlayViewF,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { fitBounds } from "google-map-react";

import { REACT_APP_GOOGLE_MAPS_API_KEY, REACT_APP_MAP_ID } from "typings/env";
import { CardMapNew } from "../template/templates";

interface GoogleMapHotelsProps {
  hotelesdata: any;
  dataHoteles: any;
  selectedTitle: any;
  setNumberHotels: (number: number) => void;
  latitud: any;
  longitud: any;
  zoom: number;
  openInfoWindowIndex: number | null;
  setOpenInfoWindowIndex: (index: number | null) => void;
}

const loaderOptions = {
  id: REACT_APP_MAP_ID || "",
  googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY || "",
};

export const GoogleMapHotels = ({
  hotelesdata,
  dataHoteles,
  selectedTitle,
  openInfoWindowIndex,
  setOpenInfoWindowIndex,
  setNumberHotels,
  latitud,
  longitud,
  zoom,
}: GoogleMapHotelsProps) => {
  const { isLoaded } = useJsApiLoader(loaderOptions);

  const [customLat, setCustomLat] = useState(null);
  const [customLng, setCustomLng] = useState(null);
  const [customCenter, setCustomCenter] = useState<any>(null);
  const [customZoom, setCustomZoom] = useState(null);

  const toggleInfoWindow = (index: number | null) => {
    setOpenInfoWindowIndex(index);
  };

  function MarkerClicked(index: number | null) {
    toggleInfoWindow(index);
  }

  const isValidCoordinate = (coord: any) =>
    !isNaN(parseFloat(coord)) && isFinite(coord);

  useEffect(() => {
    let hotelesdata = dataHoteles.hoteles;
    if (
      isValidCoordinate(latitud) &&
      isValidCoordinate(longitud) &&
      hotelesdata &&
      hotelesdata.length > 0
    ) {
      hotelesdata = hotelesdata.filter((_: any) => _.latitud && _.longitud);
      setCustomLat(latitud);
      setCustomLng(longitud);
      setNumberHotels(hotelesdata.length);
      if (hotelesdata.length === 1) {
        const soloHotel = hotelesdata[0];
        const lat: any = isValidCoordinate(soloHotel.latitud)
          ? parseFloat(soloHotel.latitud)
          : 0;
        const lng: any = isValidCoordinate(soloHotel.longitud)
          ? parseFloat(soloHotel.longitud)
          : 0;
        setCustomLat(lat);
        setCustomLng(lng);
        const zum: any = 17;
        setCustomZoom(zum);
        setCustomCenter({
          lat: isValidCoordinate(soloHotel.latitud)
            ? parseFloat(soloHotel.latitud)
            : 0,
          lng: isValidCoordinate(soloHotel.longitud)
            ? parseFloat(soloHotel.longitud)
            : 0,
        });
      } else {
        const bounds = {
          nw: { lat: Number.NEGATIVE_INFINITY, lng: Number.POSITIVE_INFINITY },
          se: { lat: Number.POSITIVE_INFINITY, lng: Number.NEGATIVE_INFINITY },
        } as any;

        hotelesdata.forEach((hotel: any) => {
          if (
            hotel.latitud &&
            hotel.longitud &&
            isValidCoordinate(hotel.latitud) &&
            isValidCoordinate(hotel.longitud)
          ) {
            bounds.nw.lat = Math.max(bounds.nw.lat, parseFloat(hotel.latitud));
            bounds.nw.lng = Math.min(bounds.nw.lng, parseFloat(hotel.longitud));
            bounds.se.lat = Math.min(bounds.se.lat, parseFloat(hotel.latitud));
            bounds.se.lng = Math.max(bounds.se.lng, parseFloat(hotel.longitud));
          }
        });

        if (selectedTitle) {
          const centerLat = (bounds.nw.lat + bounds.se.lat) / 2;
          const centerLng = (bounds.nw.lng + bounds.se.lng) / 2;
          const roundedCenter = {
            lat: parseFloat(centerLat.toFixed(6)), // Redondear a 6 decimales
            lng: parseFloat(centerLng.toFixed(6)), // Redondear a 6 decimales
          };
          setCustomCenter(roundedCenter);
        } else {
          const peruBounds = {
            minLat: -18.34917, // Latitud mínima de Perú
            maxLat: -0.03889, // Latitud máxima de Perú
            minLng: -81.32778, // Longitud mínima de Perú
            maxLng: -68.67778, // Longitud máxima de Perú
          };
          const peruCenter = {
            lat: (peruBounds.minLat + peruBounds.maxLat) / 2,
            lng: (peruBounds.minLng + peruBounds.maxLng) / 2,
          };
          setCustomCenter(peruCenter);
        }

        const deviceWidth = window.innerWidth;
        const Size =
          deviceWidth < 500
            ? { width: 300, height: 300 }
            : { width: 800, height: 600 };
        const newZoom = (fitBounds(bounds, Size).zoom +
          (hotelesdata.length > 20 ? 1 : 0)) as any;
        setCustomZoom(newZoom);
      }
    }
  }, [selectedTitle, dataHoteles.hoteles, latitud, longitud]);

  if (!isLoaded) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="googlemaps">
      {hotelesdata?.length > 0 ? (
        <>
          {isLoaded && customLat && customLng && customZoom ? (
            <GoogleMap
              center={
                customCenter
                  ? customCenter
                  : {
                      lat: isValidCoordinate(customLat)
                        ? parseFloat(customLat)
                        : 0,
                      lng: isValidCoordinate(customLng)
                        ? parseFloat(customLng)
                        : 0,
                    }
              }
              zoom={customZoom}
              onClick={() => MarkerClicked(null)}
              mapContainerStyle={{ width: "100%", height: "100%" }}
              options={{
                mapId: REACT_APP_MAP_ID,
                mapTypeControl: false,
                zoomControl: true,
                fullscreenControl: true,
                streetViewControl: false,
              }}
            >
              {hotelesdata
                .filter(
                  (_: any) =>
                    _.latitud && _.longitud && _.nombre_corto.trim("") !== ""
                )
                .map((location: any, index: number) => (
                  <>
                    <OverlayViewF
                      key={index}
                      position={{
                        lat: isValidCoordinate(location.latitud)
                          ? parseFloat(location.latitud)
                          : 0,
                        lng: isValidCoordinate(location.longitud)
                          ? parseFloat(location.longitud)
                          : 0,
                      }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      children={
                        <div
                          className="iconmap"
                          onMouseEnter={() => MarkerClicked(index)}
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="iconmap__content"
                            style={{
                              backgroundColor: location.tipo_hotel?.color,
                            }}
                          >
                            <p>{`${location.precio.simbolo} ${location.precio.precio}`}</p>
                          </div>
                        </div>
                      }
                    />
                    {openInfoWindowIndex === index && (
                      <InfoWindowF
                        position={{
                          lat: parseFloat(location.latitud),
                          lng: parseFloat(location.longitud),
                        }}
                        onCloseClick={() => setOpenInfoWindowIndex(null)}
                        options={{
                          pixelOffset: new window.google.maps.Size(0, 1),
                          disableAutoPan: true,
                          maxWidth: 241,
                          minWidth: 241,
                        }}
                      >
                        <CardMapNew data={location} />
                      </InfoWindowF>
                    )}
                  </>
                ))}
            </GoogleMap>
          ) : null}
        </>
      ) : (
        <GoogleMap
          center={{
            lat: isValidCoordinate(latitud) ? parseFloat(latitud) : 0,
            lng: isValidCoordinate(longitud) ? parseFloat(longitud) : 0,
          }}
          zoom={zoom}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            zoomControl: true,
            fullscreenControl: true,
            streetViewControl: false,
            mapTypeControl: false,
          }}
        />
      )}
    </div>
  );
};

export const GoogleMapMob = ({
  hotelesdata,
  dataHoteles,
  selectedTitle,
  openInfoWindowIndex,
  setOpenInfoWindowIndex,
  setNumberHotels,
  latitud,
  longitud,
  zoom,
}: GoogleMapHotelsProps) => {
  const { isLoaded } = useJsApiLoader(loaderOptions);

  const [customLat, setCustomLat] = useState(null);
  const [customLng, setCustomLng] = useState(null);
  const [customCenter, setCustomCenter] = useState<any>(null);
  const [customZoom, setCustomZoom] = useState(null);

  const toggleInfoWindow = (index: number | null) => {
    setOpenInfoWindowIndex(index);
  };

  function MarkerClicked(index: number | null) {
    toggleInfoWindow(index);
  }

  const isValidCoordinate = (coord: any) =>
    !isNaN(parseFloat(coord)) && isFinite(coord);

  useEffect(() => {
    let hotelesdata = dataHoteles.hoteles;
    if (
      isValidCoordinate(latitud) &&
      isValidCoordinate(longitud) &&
      hotelesdata &&
      hotelesdata.length > 0
    ) {
      hotelesdata = hotelesdata.filter((_: any) => _.latitud && _.longitud);
      setCustomLat(latitud);
      setCustomLng(longitud);
      setNumberHotels(hotelesdata.length);
      if (hotelesdata.length === 1) {
        const soloHotel = hotelesdata[0];
        const lat: any = isValidCoordinate(soloHotel.latitud)
          ? parseFloat(soloHotel.latitud)
          : 0;
        const lng: any = isValidCoordinate(soloHotel.longitud)
          ? parseFloat(soloHotel.longitud)
          : 0;
        setCustomLat(lat);
        setCustomLng(lng);
        const zum: any = 17;
        setCustomZoom(zum);
        setCustomCenter({
          lat: isValidCoordinate(soloHotel.latitud)
            ? parseFloat(soloHotel.latitud)
            : 0,
          lng: isValidCoordinate(soloHotel.longitud)
            ? parseFloat(soloHotel.longitud)
            : 0,
        });
      } else {
        const bounds = {
          nw: { lat: Number.NEGATIVE_INFINITY, lng: Number.POSITIVE_INFINITY },
          se: { lat: Number.POSITIVE_INFINITY, lng: Number.NEGATIVE_INFINITY },
        } as any;

        hotelesdata.forEach((hotel: any) => {
          if (
            hotel.latitud &&
            hotel.longitud &&
            isValidCoordinate(hotel.latitud) &&
            isValidCoordinate(hotel.longitud)
          ) {
            bounds.nw.lat = Math.max(bounds.nw.lat, parseFloat(hotel.latitud));
            bounds.nw.lng = Math.min(bounds.nw.lng, parseFloat(hotel.longitud));
            bounds.se.lat = Math.min(bounds.se.lat, parseFloat(hotel.latitud));
            bounds.se.lng = Math.max(bounds.se.lng, parseFloat(hotel.longitud));
          }
        });

        if (selectedTitle) {
          const centerLat = (bounds.nw.lat + bounds.se.lat) / 2;
          const centerLng = (bounds.nw.lng + bounds.se.lng) / 2;
          const roundedCenter = {
            lat: parseFloat(centerLat.toFixed(6)), // Redondear a 6 decimales
            lng: parseFloat(centerLng.toFixed(6)), // Redondear a 6 decimales
          };
          setCustomCenter(roundedCenter);
        } else {
          const peruBounds = {
            minLat: -18.34917, // Latitud mínima de Perú
            maxLat: -0.03889, // Latitud máxima de Perú
            minLng: -81.32778, // Longitud mínima de Perú
            maxLng: -68.67778, // Longitud máxima de Perú
          };
          const peruCenter = {
            lat: (peruBounds.minLat + peruBounds.maxLat) / 2,
            lng: (peruBounds.minLng + peruBounds.maxLng) / 2,
          };
          setCustomCenter(peruCenter);
        }

        const deviceWidth = window.innerWidth;
        const Size =
          deviceWidth < 500
            ? { width: 300, height: 300 }
            : { width: 800, height: 600 };
        const newZoom = (fitBounds(bounds, Size).zoom +
          (hotelesdata.length > 20 ? 1 : 0)) as any;
        setCustomZoom(newZoom);
      }
    }
  }, [selectedTitle, dataHoteles, latitud, longitud]);

  if (!isLoaded) {
    return <div>Cargando...</div>;
  }
  return (
    <div className="googlemapsmob">
      {hotelesdata?.length > 0 ? (
        <>
          {isLoaded && customLat && customLng && customZoom ? (
            <GoogleMap
              center={{
                lat: isValidCoordinate(customLat) ? parseFloat(customLat) : 0,
                lng: isValidCoordinate(customLng) ? parseFloat(customLng) : 0,
              }}
              zoom={customZoom}
              mapContainerStyle={{ width: "100%", height: "100%" }}
              onClick={() => setOpenInfoWindowIndex(null)}
              options={{
                mapId: REACT_APP_MAP_ID,
                mapTypeControl: false,
                zoomControl: true,
                fullscreenControl: true,
                streetViewControl: false,
              }}
            >
              {hotelesdata
                .filter(
                  (_: any) =>
                    _.latitud && _.longitud && _.nombre_corto.trim("") !== ""
                )
                .map((location: any, index: number) => (
                  <>
                    <OverlayViewF
                      key={index}
                      position={{
                        lat: parseFloat(location.latitud),
                        lng: parseFloat(location.longitud),
                      }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      children={
                        <div
                          className="iconmap"
                          onMouseMove={() => MarkerClicked(index)}
                        >
                          <div
                            className="iconmap__content"
                            style={{
                              backgroundColor: location.tipo_hotel?.color,
                            }}
                          >
                            <p>{`${location.precio.simbolo} ${location.precio.precio}`}</p>
                          </div>
                        </div>
                      }
                      // onLoad={() => MarkerClicked(index)}
                    />
                    {openInfoWindowIndex === index && (
                      <InfoWindowF
                        position={{
                          lat: parseFloat(location.latitud),
                          lng: parseFloat(location.longitud),
                        }}
                        onCloseClick={() => setOpenInfoWindowIndex(null)}
                        options={{
                          pixelOffset: new window.google.maps.Size(0, 1),
                          disableAutoPan: true,
                          maxWidth: 241,
                          minWidth: 241,
                        }}
                      >
                        <CardMapNew data={location} />
                      </InfoWindowF>
                    )}
                  </>
                ))}
            </GoogleMap>
          ) : null}
        </>
      ) : (
        <GoogleMap
          center={{
            lat: isValidCoordinate(latitud) ? parseFloat(latitud) : 0,
            lng: isValidCoordinate(longitud) ? parseFloat(longitud) : 0,
          }}
          zoom={zoom}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            zoomControl: true,
            fullscreenControl: true,
            streetViewControl: false,
            mapTypeControl: false,
          }}
        />
      )}
    </div>
  );
};
