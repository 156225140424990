import React from "react";
import { Animations, CardCustom } from "components/molecules";
import { Text } from "components/atoms";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.scss";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useTranslation } from "react-i18next";

interface GalleryDestinationsProps {
  data: any[];
}

const GalleryDestinations: React.FC<GalleryDestinationsProps> = ({ data }) => {
  const { t } = useTranslation();

  const classArray = ["area1", "area2", "area3", "area4", "area5", "area6"];

  const getDefaultAltText = (altText: string | null) => {
    return altText ?? "Destinos";
  };

  const slicedData = data.slice(0, 6);

  return (
    <div className="gallery">
      <div className="gallery__container">
        <div className="ismobile">
          <Swiper
            slidesPerView={"auto"}
            grabCursor={true}
            className="customswiper"
          >
            {slicedData && slicedData.length > 0 ? (
              <>
                {slicedData.map((item: any, index: number) => (
                  <SwiperSlide key={index}>
                    <CardCustom type={"destinationImg"} data={item} />
                  </SwiperSlide>
                ))}
              </>
            ) : (
              <>
                {[...Array(6)].map((_, index) => (
                  <SwiperSlide key={index}>
                    <div style={{ width : "95%", height : "100%", borderRadius : "10px", overflow : "hidden" }}>
                      <Animations type="skeletonContent" />
                    </div>
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        </div>

        <div className="isdesktop">
          <div className="gallery__container--items">
            {slicedData && slicedData.length > 0 ? (
              <>
                {slicedData.map((item: any, index: number) => (
                  <Link
                    key={item.id}
                    className={classArray[index]}
                    to={item.redireccion}
                  >
                    <LazyLoadImage
                      src={item.img?.path}
                      alt={getDefaultAltText(item.img?.alt)}
                      effect="blur"
                    />
                    {/* <Text type="paragraph" className="paragraph" text={item.nombre} /> */}
                    <div>
                      <p className="subparagraph">
                        {t("destinations.hotelsIn")} <br />{" "}
                        <span className="paragraph">{item.nombre}</span>{" "}
                      </p>
                    </div>
                  </Link>
                ))}
              </>
            ) : (
              <>
                {[...Array(6)].map((_, index) => (
                  <div key={index} className={classArray[index]}>
                    <Animations type="skeletonContent">
                      <Link to={"#"}></Link>
                    </Animations>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryDestinations;
