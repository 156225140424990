import React from "react";
import { Text } from "components/atoms";
import { Animations, DescriptionLocal } from "components/molecules";
import { IDestinations } from "./props";
import "./styles.scss";

const Destinations = ({
  altitude,
  description,
  title,
  temperature,
  descriptionRef,
  data
}: IDestinations) => {
  return (
    <section className="destinations" ref={descriptionRef}>
      <div className="destinations__description">
        {title && title.trim() !== "" ? (
          <Text text={title} type="subtitle" className={"subtitle"} />
        ) : (
          <Animations type="skeletonContent" style={{ width : "fit-content", marginLeft : 20 }}>
            <Text text={"Qué conocer en -------"} type="subtitle" className={"subtitle"} style={{ color : "transparent" }} />
          </Animations>
        )}

        <DescriptionLocal
          altitude={altitude}
          data={data}
          temperature={temperature}
          description={description}
        />
      </div>
    </section>
  );
};

export default Destinations;
