import "./styles.scss";
import visa from "assets/images/medios-pago/visa.png";
import mastercard from "assets/images/medios-pago/mastercard.png";
import americanexpress from "assets/images/medios-pago/american.png";
import dinnersclub from "assets/images/medios-pago/dinners.png";
import logo from "assets/logo/casa_andina_white.png";
import IconLink from "components/molecules/iconLink";
import { Link } from "react-router-dom";
import { Icon, Text } from "components/atoms";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { REACT_APP_ENDPOINT_CA } from "typings/env";
import { toast } from "sonner";
import { useEffect, useState } from "react";
import useRecaptcha from "hooks/useRecaptcha";
import { logSentryErrorResponse } from "utils/function";
import * as Sentry from '@sentry/react';

interface IDataProps {
  correo: string;
  tipo: string;
}

const Footer = () => {
  const { t, i18n } = useTranslation();
  const { getRecaptchaToken } = useRecaptcha();

  const [openTitles, setOpenTitles] = useState<number[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTitleClick = (index: number) => {
    if (isMobile) {
      setOpenTitles((prev) =>
        prev.includes(index)
          ? prev.filter((i) => i !== index)
          : [...prev, index]
      );
    }
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data: object) => {
    const token = await getRecaptchaToken();

    if (!token) {
      toast.error("Error al obtener el token de reCAPTCHA", {
        position: "top-right",
      });
      return;
    }

    try {
      const { correo, tipo } = data as IDataProps;

      // console.log(correo, tipo);

      const response = await fetch(
        `${REACT_APP_ENDPOINT_CA}/form/suscripcion`,
        {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "g-recaptcha-response": token,
          },
          body: JSON.stringify({
            correo: correo,
            tipo: tipo,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        toast.success(data.message, { position: "top-right" });
        reset();
      } else {
        logSentryErrorResponse(response, 'Form - Suscripcion Form');
        console.error(response);
        toast.error(t("forms.errorSendingData"), { position: "top-right" });
      }
    } catch (error) {
      Sentry.captureException(error); // Captura el error en Sentry
      console.error(error);
      toast.error(t("forms.errorSendingData"), { position: "top-right" });
    }
  };

  const info = [
    {
      title: "Casa Andina",
      descriptions: [
        // {
        //   id: 1,
        //   description: "Sobre nosotros",
        //   link: `/${i18n.language}/quienes-somos`,
        // },
        {
          id: 4,
          description: t("footer.socialResponsibility"),
          link: `/${i18n.language}/responsabilidad-social`,
        },
        { id: 5, description: t("footer.contact"), link: `/${i18n.language}/contacto` },
        {
          id: 6,
          description: "Dog friendly",
          link: `/${i18n.language}/dog-friendly`,
        },
        {
          id: 8,
          description: "Life",
          url: "https://life.casa-andina.com",
        },
        {
          id: 9,
          description: "Friends & Family",
          link: `/${i18n.language}/friends-and-family`,
        },
        {
          id: 11,
          description: t("footer.ethicsLine"),
          url: "https://coneticacasaandina.lineaseticas.com/",
        },
      ],
    },
    {
      title:  t("footer.reservations"),
      descriptions: [
        {
          id: 1,
          description: t("footer.reservationCenter"),
          tel: "+51 1 3916500",
          number: "+5113916500",
        },
        {
          id: 2,
          description: t("footer.reservationEmail"),
          email: "centraldereservas@casa-andina.com",
        },
      ],
    },
    {
      title: t("footer.customerService"),
      descriptions: [
        {
          id: 1,
          description: t("footer.arcoRightsRequest"),
          link: `/${i18n.language}/arco`,
        },
        {
          id: 2,
          description: t("footer.electronicReceipts"),
          link: `/${i18n.language}/como-acceder-a-los-comprobantes`,
        },
        {
          id: 3,
          description: t("footer.faq"),
          link: `/${i18n.language}/preguntas-frecuentes`,
        },
      ],
    },
    {
      title: t("footer.callUsFree"),
      descriptions: [
        {
          id: 1,
          description: t("footer.provinces"),
          tel: "0 800 71899",
          number: "080071899",
        },
        {
          id: 2,
          description: "Canadá",
          tel: "888 350 6263",
          number: "8883506263",
        },
        {
          id: 3,
          description: "Chile",
          tel: "080 091 4123",
          number: "0800914123",
        },
        {
          id: 4,
          description: "EEUU",
          tel: "866 220 4434",
          number: "8662204434",
        },
        {
          id: 5,
          description: "Reino Unido",
          tel: "08 082 343 805",
          number: "08082343805",
        },
      ],
    },
    {
      title: t("footer.contactUs"),
      descriptions: [
        {
          id: 1,
          description: t("footer.travelAgencies"),
          email: "agencias@casa-andina.com",
        },
        { id: 2, description: t("footer.events"), email: "eventos@casa-andina.com" },
        {
          id: 3,
          description: t("footer.companies"),
          email: "empresas@casa-andina.com",
          url: "https://libro.aplicacionescasaandina.com",
          image:
            "https://s3.us-east-1.amazonaws.com/ca-webprod/media/229c608a-5d2f-46e7-be38-47cac985f1a8.webp",
        },
      ],
    },
  ];

  return (
    <footer>
      <div className="footersection">
        <div className="footersection__container">
          <div className="footersection__container--left">
            <div>
              <p>{t("footer.follow-us")}</p>
            </div>
            <div>
              <IconLink
                iconName="facebook"
                itemList="social"
                linkIcon="https://www.facebook.com/casaandina/"
                className="mr-1-5rem"
                aria-label="Link a Facebook"
              />
              <IconLink
                iconName="twitter"
                itemList="social"
                linkIcon="https://twitter.com/casaandinahotel"
                className="mr-1-5rem"
                aria-label="Link a Twitter"
              />
              <IconLink
                iconName="instagram"
                itemList="social"
                linkIcon="https://www.instagram.com/casaandina/"
                className="mr-1-5rem"
                aria-label="Link a Instagram"
              />
              <IconLink
                iconName="youtube"
                itemList="social"
                linkIcon="https://www.youtube.com/casaandina/"
                className="mr-1-5rem"
                aria-label="Link a Youtube"
              />
              <IconLink
                iconName="linkedin"
                itemList="social"
                linkIcon="https://www.linkedin.com/company/casa-andina/mycompany/"
                className="mr-1-5rem"
                aria-label="Link a LinkedIn"
              />
            </div>
          </div>
          <div className="footersection__container--right">
            <div>
              <p>{t("footer.subscribe")}</p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} id="suscripcion_form">
              <div className="label-input">
                <label htmlFor="tipoPersona">
                  <input
                    type="radio"
                    id="tipoPersona"
                    value="Persona"
                    defaultChecked
                    {...register("tipo", { required: true })}
                  />
                  {t("footer.person")}
                </label>

                <label htmlFor="tipoEmpresa">
                  <input
                    type="radio"
                    id="tipoEmpresa"
                    value="Empresa"
                    {...register("tipo", { required: true })}
                  />
                  {t("footer.company")}
                </label>
                {errors.tipo && <span>Seleccione un tipo de suscripción</span>}
              </div>

              <div>
                <Controller
                  name="correo"
                  control={control}
                  rules={{
                    required: "El email es obligatorio",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Debe ser un email válido",
                    },
                  }}
                  render={({ field }) => (
                    <div className="inputform">
                      <input
                        type="email"
                        id="correo"
                        placeholder={t("footer.enter-your-email")}
                        {...field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let value = e.target.value;
                          if (value.endsWith(".con")) {
                            value = value.slice(0, -4) + ".com";
                          }
                          field.onChange(value);
                        }}
                      />
                      <button id="submitForm" aria-label="Suscribirse" type="submit" disabled={!isValid}>
                        <Icon
                          name="next"
                          list="arrows"
                          color="white"
                          width={24}
                          height={24}
                        />
                      </button>
                    </div>
                  )}
                />

                <p className="errors">
                  {errors.correo && <>{errors.correo.message}</>}
                </p>
              </div>
            </form>

            <div>
              <Link
                to={`/${i18n.language}/${t(
                  "routes.personal-data-protection-policy"
                )}`}
                className="politicalink"
              >
                {t("footer.personal-data-protection-policy")}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="footersection1">
        <div className="footersection1__container">
          <div className="footersection1__container--left">
            <img src={logo} alt="Casa Andina Hoteles" />
          </div>
          <div className="footersection1__container--right">
            <div>
              <p>{t("footer.payment-methods")}</p>
            </div>
            <div className="containerimg">
              <img src={visa} height={30} width={45} alt="visa" />
              <img src={mastercard} height={30} width={45} alt="mastercard" />
              <img src={americanexpress} height={30} width={45} alt="americanexpress" />
              <img src={dinnersclub} height={30} width={45} alt="dinersclub" />
            </div>
          </div>
        </div>
      </div>

      <div className="footersection2">
        <div className="footersection2__container">
          {info.map((item, index) => (
            <div className="col" key={index}>
              <div className="title" onClick={() => handleTitleClick(index)}>
                <Text
                  text={item.title}
                  type="paragraph"
                  className="title__edit"
                />
                <div className="iconmob">
                  <Icon
                    name={openTitles.includes(index) ? "up" : "down"}
                    list="arrows"
                    color="#D69C4F"
                    width={24}
                    height={24}
                  />
                </div>
              </div>
              {(openTitles.includes(index) || !isMobile) && (
                <div className="description">
                  {item.descriptions.map(
                    ({
                      id,
                      description,
                      link,
                      tel,
                      email,
                      image,
                      url,
                      number,
                    }: any) => (
                      <div className="description__links" key={id}>
                        {(link || url) && (
                          <div>
                            <a
                              key={id}
                              href={link || url}
                              target={url ? "_blank" : "_self"}
                              rel="noopener noreferrer"
                              className={`description__link`}
                            >
                              {description}
                            </a>
                          </div>
                        )}
                        {tel && (
                          <div>
                            <p>{description}</p>
                            <a
                              key={id}
                              href={`tel:${number}`}
                              className={`description__link`}
                            >
                              {tel}
                            </a>
                          </div>
                        )}
                        {email && (
                          <div>
                            <p>{description}</p>
                            <a
                              key={id}
                              href={`mailto:${email}`}
                              className={`description__link`}
                            >
                              {email}
                            </a>
                          </div>
                        )}
                        {image && (
                          <div className="reclamaciones">
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={image} alt="Libro de Reclamaciones" />
                            </a>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="footersection3">
        <div className="footersection3__container">
          <div className="footersection3__container--line">
            <Link
              to={`/${i18n.language}/${t("routes.terms-and-conditions")}`}
              className="footersection3__container--link"
            >
              {t("footer.terms-and-conditions")}
            </Link>
            <Icon
              name="dot"
              list="items"
              width={8}
              height={8}
              className="icondot"
            />
            <Link
              to={`/${i18n.language}/${t("routes.web-privacy-policy")}`}
              className="footersection3__container--link"
            >
              {t("footer.web-privacy-policy")}
            </Link>
            <Icon
              name="dot"
              list="items"
              width={8}
              height={8}
              className="icondot"
            />
            <Link
              to={`/${i18n.language}/${t(
                "routes.terms-and-conditions-for-bookings-at-associated-hotels"
              )}`}
              className="footersection3__container--link"
            >
              {t(
                "footer.terms-and-conditions-for-bookings-at-associated-hotels"
              )}
            </Link>
          </div>
          <div className="footersection3__container--text">
            <p>{t("footer.all-rights-reserved")} - Casa Andina Hoteles</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
