import React, { useEffect } from "react";
import { Banner, BannerSlider } from "components/organisms";
import SectionCardsTypes from "components/organisms/section-cards-types";
import SectionCardGrid from "components/organisms/section-card-grid";
import Loader from "components/molecules/loader";
import { getRestaurants } from "actions/restaurants/services";
import { useAppSelector } from "hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Restaurants: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    language,
    data,
    coin,
    tipos_restaurantes,
    restaurantes,
    categoria,
    tira_banner,
  } = useAppSelector((state: any) => ({
    language: state.config.language,
    coin: state.config.coin,
    data: state.language.dataRestaurants,
    tipos_restaurantes: state.language.dataRestaurants?.tipos_restaurante || [],
    restaurantes: state.language.dataRestaurants?.restaurantes || [],
    categoria:
      state.language.dataRestaurants?.categoria_promocion_restaurante || [],
    tira_banner: state.language.dataRestaurants?.banners_tira || [],
  }));

  const fetchData = () => getRestaurants({ language, coin, navigate });

  useEffect(() => {
    if (language && coin) {
      fetchData();
      window.scrollTo(0, 0);
    }
  }, [language, coin]);

  return (
    <>
      <BannerSlider data={data?.banners ?? []} gradiente />

      <SectionCardsTypes
        data={tipos_restaurantes ?? []}
        type="tipos-restaurante"
        title={
          data?.subtitulos
            ? data?.subtitulos[0].subtitulo
            : t("restaurants.discoverOurRestaurants")
        }
      />

      <SectionCardsTypes
        data={restaurantes ?? []}
        type="hotel-direccion"
        title={
          data?.subtitulos
            ? data?.subtitulos[1].subtitulo
            : t("restaurants.discoverTheCulinaryRichnessOfPeru")
        }
      />

      <Banner data={tira_banner ?? []} />

      <SectionCardGrid
        data={categoria}
        type="restaurant-food"
        title={
          data?.subtitulos
            ? data?.subtitulos[2].subtitulo
            : t("restaurants.enjoyOurPromotions")
        }
      />
    </>
  );
};

export default Restaurants;
