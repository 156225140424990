import React, { useEffect } from "react";
import { Text } from "components/atoms";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { updateMetadata } from "utils/function";

const ServerError = () => {
  const navigate = useNavigate();

  useEffect(() => {
    updateMetadata({
      title: "500 - Error en el servidor",
      description:
        "Ocurrió un error en el servidor. Por favor, intenta de nuevo más tarde.",
      canonicalUrl: "https://www.casa-andina.com/es/500",
      hreflang: [
        {
          hreflang: "es",
          href: "https://www.casa-andina.com/es/500",
        },
      ],
    });
  }, []);

  return (
    <section className="server-error">
      <div className="server-error__container">
        <div className="server-error__container--text">
          <Text type="title" text="Algo salió mal." />
          <br />
          <Text
            type="subtitle2"
            text="Parece que tuvimos un problema. Estamos trabajando para solucionarlo."
          />
          <Text
            type="subtitle2"
            text="Por favor, intenta nuevamente más tarde."
          />
          <br />
          <Text
            type="paragraph"
            text="Regresa y reservar en nuestros 43 hoteles."
            onClick={() => navigate("/")}
            className="redirect"
          />
        </div>
      </div>
    </section>
  );
};

export default ServerError;