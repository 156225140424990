import Section from '../section'
import { Icon, Text } from 'components/atoms'
import './styles.scss'
import Texture from 'assets/images/bkg-texture.webp';

const ListHeader = ({ data, commoditiess }: any) => {
  return (
    <Section bgImage={Texture}>
      <div className='listheader'>
        <div>
          <div className='listheader__container'>
            <div className='listheader__container--text'>
              <Text text={data.texto_1} type='paragraph' className='title' />
              <Text
                text={data.texto_2}
                type='paragraph'
                className='description'
              />
            </div>
            <div>
              <Icon name='rightp' list='arrows' />
            </div>
            <div className='listheader__container--content'>
              {commoditiess.map((item: any, index: number) => (
                <div className='column' key={index}>
                  <Icon name={'check'} list='items' width={20} height={20} />
                  <div>
                    <Text
                      className={'list'}
                      text={item.titulo}
                      type='paragraph'
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ListHeader
