
import Axios from "actions/api";
import { endpoints } from "actions/endpoints/services";
import { setDataBetterPrice, setLoading } from "redux/actions/language";
import { IGetBetterPrices } from "./props";
import { updateMetadata } from "utils/function";
import * as Sentry from '@sentry/react';

export const getBetterPrices = async ({
  language,
  params,
  coin,
  navigate,
}: IGetBetterPrices) => {
  try {
    let paramsparse: any = {};

    for (let key in params) {
      if (params[key] !== "") {
        paramsparse[key] = params[key];
      }
    }

    if (Object.keys(params).length === 0) {
      setLoading(true);
    }

    const response = await Axios.request({
      method: "get",
      url: endpoints.mejoresprecios,
      params: paramsparse,
      headers: {
        "Accept-Language": language,
        "Accept": "application/json",
        'Currency-Code': coin,
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setDataBetterPrice(data)
    updateMetadata({
      title: data?.meta_data?.titulo || 'Casa Andina Mejores Precios',
      description: data?.meta_data?.descripcion,
      canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
      hreflang: [
        { hreflang: 'es', href: data?.meta_data?.url_canonica_es },
        { hreflang: 'en', href: data?.meta_data?.url_canonica_en },
        { hreflang: 'pt', href: data?.meta_data?.url_canonica_pt },
      ],
    })

    return data;

  } catch (error) {
    // alert('No se pudo cargar la vista mejores precios')
    console.log("Server error: ", error);
    Sentry.captureException(error);
    navigate('/es/error');
  } finally {
    if (Object.keys(params).length !== 0) {
      setLoading(false)
    }
  }
}
