
export const banner_data_centrado = [
    {
      id: 1,
      titulo: 'LATAM PASS',
      subtitulo: 'TÉRMINOS Y CONDICIONES',
      color_fondo_logo: 'rgba(62, 145, 41, 1)',
      img_desktop: {
        name: 'banner-terminos-y-condiciones-latam-pass',
        path: 'https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/media/73465b28-466b-48c2-a076-4349d2cb2a1d.jpg',
        width: 1440,
        height: 400,
        size: 794666,
        title: 'Banner Términos y Condiciones Latam Pass',
        alt: 'Banner Términos y Condiciones Latam Pass',
        description: null,
        caption: 'Banner Términos y Condiciones Latam Pass'
      },
      img_mobile: [],
      descuento: '',
      descripcion_boton: '',
      redireccion_boton: '',
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: 'Título/subtitulo collage logo'
      }
    }
  ]