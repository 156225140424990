import { Accordion } from "components/molecules";
import { IQuestions } from "./props";
import "./index.scss";

const Questions = ({ data, preguntasRef, title, subtitle, defaultOpen, lineColor }: IQuestions) => {
  return (
    <section className="section" ref={preguntasRef}>
      <div className="sectionfaq">
        <div className="sectionfaq__container">
          <h2 className="section__title">
            {title}
            <span>{subtitle}</span>
          </h2>
          <div className="section__faqs">
            {data.map((data: any) => (
              <Accordion
                title={data.pregunta}
                description={data.respuesta}
                key={data.id}
                isHtml={true}
                defaultOpen={defaultOpen}
                lineColor={lineColor}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Questions;
