import { IconText } from 'components/atoms'
import './styles.scss'

const ModalScreen = ({ children, close, showModal, title }: any) => {
  return (
    <>
      {showModal ? (
        <div className='screen'>
          <div className='screen__overlay'>
            <div className='screen__overlay--content'>
              <div className='screen__overlay--header'>
                <div className='screen__overlay--btn' onClick={close}>
                  <IconText
                    text={'Atrás'}
                    nameIcon='leftp'
                    listIcon={'arrows'}
                    w={20}
                    h={20}
                  />
                </div>
                {title && (
                  <div className='screen__overlay--txt'>
                    <p>{title}</p>
                  </div>
                )}
              </div>
              <div className='screendren'>
                <div className='screendren__container'>{children}</div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

ModalScreen.defaultProps = {
  allowClose: true,
  isTitle: true,
  onlyClose: false,
  breadcrumb: false,
  isHeight: false,
  allowButtons: false,
  allowTabs: false,
  buttonCustom: false,
  isSubtitle: false,
  changeWidth: false,
  onlyModal: false,
}

export default ModalScreen
