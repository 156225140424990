import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from "react-router-dom";
import { Icon, Text } from "components/atoms";
import { IBanner } from "./props";
import { Autoplay } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";
import "./styles.scss";
import { Animations } from "components/molecules";

const BannerTira = ({ textColor, bgImg, data }: IBanner) => {
  const { lang } = useParams();
  const { t } = useTranslation();

  const bgColor = data?.color_fondo || "#000000";

  const handleRedirect = () => {
    window.open(data[0].redireccion_boton, "__blank");
  };

  const BannerType = ({ data }: any) => {
    const types = data.tipo?.tbt_titulo.toLowerCase();

    switch (types) {
      case "banner casa andina life":
        return (
          <div
            className="bannerlife"
            style={{ backgroundColor: data?.color_fondo }}
          >
            <div className="bannerlife__container" style={{ color: textColor }}>
              <div className="bannerlife__container--content">
                <div className="bannerlife__container--content--img">
                  <img src={data.img?.path} alt={data.img?.alt} />
                </div>
                <div className="bannerlife__container--content--container">
                  <Text
                    text={data.texto_1}
                    type="paragraph"
                    className="title"
                  />
                  <button className="btn-black" onClick={handleRedirect}>
                    {data.texto_boton}
                  </button>
                </div>
                {/* <div className='right'>
                </div> */}
              </div>
            </div>
          </div>
        );

      // banner tira agencias
      case "icon-button":
        return (
          <div className="bannerimgbutton" style={{ backgroundColor: bgColor }}>
            <div
              className="bannerimgbutton__container"
              style={{ color: textColor }}
            >
              <div className="bannerimgbutton__container--content">
                <div className="bannerimgbutton__container--content--img">
                  <img src={data[0].img?.path} alt={data[0].img?.alt} />
                </div>
                <div className="bannerimgbutton__container--content--container">
                  <p className="bannerimgbutton__container--content--title">
                    <span>Únete a nuestro canal</span> de difusión por donde
                    compartimos novedad y herramientas de utilidad. Da{" "}
                    <span>clic aquí para unirte</span>
                  </p>
                  <div>
                    <button
                      className="bannerimgbutton__container--content--gold"
                      onClick={handleRedirect}
                    >
                      {data[0].texto_boton}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      //Promociones
      case "banner tira con logos": {
        return (
          <Link
            className="bannerbanks nlink"
            style={{ backgroundColor: bgColor }}
            to={`/${lang}/ofertas/cuotas-sin-intereses`}
          >
            <div className="bannerbanks__container">
              <div className="bannerbanks__container--banner">
                <div className="bannerbanks__container--content">
                  <div className="textos">
                    <Text
                      type="paragraph"
                      text={data.texto_1}
                      className="texto_1"
                    />
                    <Text
                      type="paragraph"
                      text={data.texto_2}
                      className="texto_2"
                    />
                  </div>

                  <div className="btnbanks">
                    {data.promociones_restaurante.map((item: any) => (
                      <div
                        className="btn-banner"
                        key={item.id}
                        style={{ backgroundColor: item.color }}
                      >
                        <div className="btn-banner--content">
                          <img src={item.logo?.path} alt={item.logo?.alt} />
                          <Text
                            type="paragraph"
                            text={item.texto_encima_valor}
                            family={"txnormal"}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        );
      }

      // Banner tira de vista de restaurantes
      case "banner tira con restaurantes y promociones": {
        const backgroundStyle = data.img?.path
          ? { backgroundImage: `url(${data.img.path})` }
          : { backgroundColor: data.color_fondo };

        return (
          <Link
            className="bannerrestaurant2 nlink"
            key={data.id}
            style={backgroundStyle}
            to={`/${lang}/${t("routes.offers")}/${t("routes.restaurants")}/${t(
              "routes.banks"
            )}`}
          >
            <div className="bannerrestaurant2__container">
              <div className="bannerrestaurant2__container--banner">
                <div>
                  <div className="text-banner">
                    <Text
                      type="paragraph"
                      text={data.texto_1}
                      className={"title"}
                    />
                    <Text
                      type="paragraph"
                      text={data.texto_2}
                      className={"subtitle"}
                    />
                  </div>
                  <div className="button-banner">
                    {data.logos.map((logo: any, index: number) => (
                      <div className="button-banner__restaurant" key={index}>
                        <img src={logo?.path} alt={logo?.alt} height={42} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="column-banner-restaurant">
                  {data.promociones_restaurante.map(
                    (promo: any, index: number) => (
                      <div className="bancobanner">
                        <div className="banco-banner">
                          <div className="banco-img-banner">
                            <img src={promo.logo?.path} alt={promo.logo?.alt} />
                          </div>
                        </div>
                        <div
                          className="contenido-banner"
                          style={{ backgroundColor: promo.color }}
                        >
                          <div className="descuento-banner">
                            <p className="text-up">
                              {promo.texto_encima_valor}
                            </p>
                            <div className="content">
                              <p className="discount">{promo.valor}</p>
                              <div className="content--right">
                                <p className="percentage">%</p>
                                <Text
                                  text="Dscto."
                                  type="paragraph"
                                  className="dscto"
                                />
                              </div>
                            </div>
                            <p className="text-down">
                              {promo.texto_abajo_valor.length > 45
                                ? `${promo.texto_abajo_valor.slice(0, 45)}`
                                : promo.texto_abajo_valor}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </Link>
        );
      }
      case 'banner tira casa andina con logon y sin icono':
        return (
          <div className='bannernext' style={{ backgroundColor: bgColor }}>
            <div className='bannernext__container'>
              <div className='bannernext__container--content'>
                {/* <div>
                  <Icon name='receive' list='items' width={46} height={46} />
                </div> */}
                <Text
                  type='paragraph'
                  text={data?.texto_1}
                  className={'paragraph'}
                />
                <div>
                  <Icon
                    name='arrowsright'
                    list='arrows'
                    width={18}
                    height={18}
                  />
                </div>

                <div className={'bannernext__container--content--banner'}>
                  {data?.logos.map((logo: any, index: number) => (
                    <div
                      className={`imgbannernext ${data?.logos.length === 1 ? 'centered' : 'spaced'
                        }`}
                      key={index}
                    >
                      <img src={logo?.path} alt={logo?.alt || ''} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )

      case 'banner tira casa andina con logos y boton':
        return (
          <div className='bannerhotel' style={{ backgroundColor: data?.color_fondo }}>
            <div className='bannerhotel__container'>
              <div className='bannerhotel__container--left'>
                {/* {data[0].icon.path !== null && (
                  <div>
                    <img src={data[0].icon.path} alt={data[0].icon.alt || ''} />
                  </div>
                )} */}
                <div>
                  <Text
                    type='paragraph'
                    text={data?.texto_1}
                    className={'paragraph'}
                  />
                </div>
              </div>
              <div className='bannerhotel__container--center'>
                <Icon name='arrowsright' list='arrows' width={18} height={18} />
              </div>
              <div className='bannerhotel__container--right'>
                <div className='bannerhotel__container--banks'>
                  {data?.logos.map((logo: any, index: number) => (
                    <img src={logo?.path} alt={logo?.alt || ''} height={'60px'}
                      className={data.logos.length === 1 ? 'centered' : 'spaced'}
                      key={index} />
                  ))}
                </div>
                <div className='bannerhotel__container--button'>
                  <button className='buttonredirect' onClick={handleRedirect}>
                    {data?.texto_boton}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )

      // banner tira de Promociones
      case "banner tira con promociones": {
        return (
          <Link
            className="bannerbanks nlink"
            style={{ backgroundColor: bgColor }}
            to={`/${lang}/ofertas/cuotas-sin-intereses`}
          >
            <div className="bannerbanks__container">
              <div className="bannerbanks__container--banner">
                <div className="bannerbanks__container--content">
                  <div className="textos">
                    <Text
                      type="paragraph"
                      text={data.texto_1}
                      className="texto_1"
                    />
                    <Text
                      type="paragraph"
                      text={data.texto_2}
                      className="texto_2"
                    />
                    {/* <div style={{ color: '#D69C4F' }}>
                    <Text
                      type='paragraph'
                      text={'texto_encima_precio'}
                      family={'txtbannerBanksSuperior'}
                    />
                    <Text
                      type='paragraph'
                      text={'condicion_abajo_precio'}
                      family={'txtbannerBanksSuperior'}
                    />
                  </div> */}
                  </div>

                  <div className="btnbanks">
                    {data.promociones_restaurante.map((item: any) => (
                      <div
                        className="btn-banner"
                        key={item.id}
                        style={{ backgroundColor: item.color }}
                      >
                        <div className="btn-banner--content">
                          <img src={item.logo?.path} alt={item.logo?.alt} />
                          <Text
                            type="paragraph"
                            text={item.texto_encima_valor}
                            family={"txnormal"}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        );
      }
    }
  };

  const itemsForLoop = data.map((item: any) => item.id);

  return (
    <>
      <Swiper
        className="bannerslider"
        loop={itemsForLoop.length > 3}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {data && Array.isArray(data) && data.length > 0 ? (
          <>
            {data.map((banners: any) => (
              <SwiperSlide key={banners.id}>
                <div style={{ width: "100%" }}>
                  <BannerType data={banners} />
                </div>
              </SwiperSlide>
            ))}
          </>
        ) : (
          <SwiperSlide>
            <div style={{ width: "100%" }}>
              <div className="bannerlife" style={{ backgroundColor: "#0001" }}>
                <div className="bannerlife__container">
                  <div className="bannerlife__container--content">
                    <div className="bannerlife__container--content--img">
                      <Animations type="skeletonContent">
                        <div style={{ height: 60, width: 100 }} />
                      </Animations>
                    </div>
                    <div className="bannerlife__container--content--container">
                      <Animations type="skeletonContent">
                        <Text
                          style={{ height: 40, width: "80%" }}
                          type="paragraph"
                          className="title"
                        />
                      </Animations>
                      <button className="btn-black">
                        <Animations type="skeletonContent">
                          <span style={{ height: 40, width: "100%" }}></span>
                        </Animations>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </>
  );
};

BannerTira.defaultProps = {
  type: "bannerLife",
  bgBanner: "black",
  textColor: "white",
};

export default BannerTira;
