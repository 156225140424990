
import Axios from "actions/api";
import { endpoints } from "actions/endpoints/services";
import { setDataBetterPrice, setDataContactInformation, setLoading } from "redux/actions/language";
import { IGetContactInformation } from "./props";
import * as Sentry from '@sentry/react';

export const getContactInformation = async ({
  language,
  params,
  navigate,
}: IGetContactInformation) => {
  try {
    setLoading(true);
    let paramsparse: any = {};

    for (let key in params) {
      if (params[key] !== "") {
        paramsparse[key] = params[key];
      }
    }

    const response = await Axios.request({
      method: "get",
      url: endpoints.contactinformation,
      params: paramsparse,
      headers: {
        "Accept-Language": language,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setDataContactInformation(data)

    return data;

  } catch (error) {
    // alert('No se pudo cargar la vista mejores precios')
    console.log("Server error: ", error);
    Sentry.captureException(error);
    navigate('/es/error');
  } finally {
    setLoading(false);
  }
}
