
export const banner_data_centrado = [
    {
      id: 1,
      titulo: 'Autorización uso de imagen concurso UCG',
      subtitulo: '',
      color_fondo_logo: 'rgba(62, 145, 41, 1)',
      img_desktop: {
        name: 'banner-politicas-privacidad',
        path: 'https://s3.us-east-1.amazonaws.com/ca-webprod/media/4d9c1069-9eda-43e8-b9dc-4105b3517de5.jpg',
        width: 1440,
        height: 400,
        size: 794666,
        title: 'Banner Políticas de uso de imagen',
        alt: 'Banner Políticas de uso de imagen',
        description: null,
        caption: 'Banner Políticas de uso de imagen'
      },
      img_mobile: [],
      descuento: '',
      descripcion_boton: '',
      redireccion_boton: '',
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: 'landings'
      }
    }
  ]