import { Text } from 'components/atoms'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ISectionCardsHotelPackage } from './props'
import 'swiper/css'
import 'swiper/css/navigation'
import './styles.scss'
import { Animations, CardPromotionHotelPackage } from 'components/molecules'

const SectionCardsHotelPackage = ({
  data,
  title,
  sectionref,
  textoTooltipIgv,
  textoTooltipIgvCard,
  coin,
  idTravelClick
}: ISectionCardsHotelPackage) => {
  return (
    <section>
      <div
        className='section-cards-hotel-package'
        ref={sectionref}
      >
        <div
          className='section-cards-hotel-package__container'
        >
          <Text type='subtitle' text={title} className={'subtitle'} />

          <div className='section-cards-hotel-package__container--description'>
            <Swiper
              slidesPerView={'auto'}
              grabCursor={true}
              navigation={true}
              className='section-cards-hotel-package__container--description--swiper'
            >
              {data && Array.isArray(data) && data.length > 0 ? (
                <>
                  {data.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <CardPromotionHotelPackage
                        key={index}
                        data={item}
                        textoTooltipIgv={textoTooltipIgv}
                        textoTooltipIgvCard={textoTooltipIgvCard}
                        coin={coin}
                        idTravelClick={idTravelClick}
                      />
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                <>
                  <SwiperSlide>
                    <Animations type='skeletonContent'>
                      <div style={{ height : 370, width : 218 }}>
                      </div>
                    </Animations>
                  </SwiperSlide>
                </>
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

SectionCardsHotelPackage.defaultProps = {
  bgColor: 'white',
  titleColor: 'black'
}

export default SectionCardsHotelPackage
