import React from "react";
import "./styles.scss";
import { CardCustom } from "components/molecules";

const ListCards = ({ list, isList, isTitle }: any) => {

  return (
    <div className="listcards">
      <div className="listcards__container">
        {list.map((item: any, index: number) => (
          <div className="listcards__container--card" key={index}>
            <CardCustom
              type={item.card.tipo_disenio.titulo}
              data={item.card}
              redirection={item.redireccion}
            />

            {isList && item.description && (
              <div className="listcards__container--description">
                <ul>
                  {item.description.map((descItem: any, descIndex: number) => (
                    <li key={descIndex}>{descItem.content}</li>
                  ))}
                </ul>
              </div>
            )}
            {isTitle && (
              <div className="listcards__container--description">
                <p className="title">{item.title}</p>
                <p className="restriction">{item.restriction}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListCards;
