import React from 'react';
import { IParagraph } from './props';
import './styles.scss';

const getClassByFamily = (text: string) => {
  const styleMap: Record<string, string> = {
    paragraph: 'paragraph',
    tpage: 'titlepage',
    sbpage: 'subtitlepage',
    t2page: 'title2page',
    sb2page: 'subtitle2page',
    tsection: 'titlesection',
    t2section: 'title2section',
    sbsection: 'subtitlesection',
    ttcbannerslider: 'textcursivebannerslider',
    textcardpromotion: 'textcardpromotion',
    ttab: 'titletab',
    sbtab: 'subtitletab',
    dtab: 'descriptiontab',
    ttabm: 'titletabm',
    sbtabm: 'subtitletabm',
    dtabm: 'descriptiontabm',
    ttabmodal: 'titletabmodal',
    txnormal: 'textnormal',
    txnormal2: 'txnormal2',
    txsmall: 'textsmall',
    tbanner: 'titlebanner',
    tbannerc: 'titlebannerc',
    sbbanner: 'subtitlebanner',
    dscbanner: 'descriptionbanner',
    tcarddiscount: 'titlecarddiscount',
    sbcarddiscount: 'subtitlecarddiscount',
    tcardrelax: 'titlecardrelax',
    sbcardrelax: 'subtitlecardrelax',
    sbbcardrelax: 'sbbcardrelax',
    pricecardrelax: 'pricecardrelax',
    txcarddiscountp: 'textcarddiscountp',
    txcarddiscount: 'textcarddiscount',
    tbfcarddiscount: 'textcarddiscountbf',
    txcardexclusive: 'textcardexclusive',
    txbannerslider: 'textbannerslider',
    ttbannerslider: 'titlebannerslider',
    txtbannerssliderprc: 'textbannersliderprc',
    txbannersliderdsct: 'textbannersliderdsct',
    txbanner: 'textbanner',
    slbanner: 'sliderBanner',
    ttvisit: 'titlevisit',
    sbvisit: 'subtitlevisit',
    subvisit: 'subsubtitlevisit',
    tthotel: 'titlehotel',
    sbhotel: 'subtitlehotel',
    txrestaurant: 'textrestaurant',
    btnrestaurant: 'buttonrestaurant',
    txexperience: 'textexperience',
    txtfooter: 'textfooter',
    sliderItem: 'sliderItem',
    textGallery: 'textGallery',
    textmobGallery: 'textmobGallery',
    textGallery2: 'textGallery2',
    txdestiny: 'textdestiny',
    txfilter: 'textfilter',
    sbfilter: 'subtitlefilter',
    scfilter: 'sectionfilter',
    txfilter2: 'textfilter2',
    tcardicon: 'titlecardicon',
    tagcardicon: 'tagcardicon',
    sbcardicon: 'subtitlecardicon',
    sbbcardicon: 'sbbcardicon',
    cleanfilter: 'cleanfilter',
    txicon: 'texticon',
    screstaurant: 'sectionrestaurant',
    tmodal: 'titlemodal',
    bcyberw: 'bcyberw',
    txpromotion: 'txpromotion',
    textcardquota: 'textcardquota',
    txtbannerBanks: 'txtbannerBanks',
    txtbannerBanksSuperior: 'txtbannerBanksSuperior',
    txtbannerBanksInferior: 'txtbannerBanksInferior',
  };

  return styleMap[text] || '';
};

const Paragraph = ({ text, className, onClick, family, html, style}: IParagraph) => {

  return (
    <p className={`${getClassByFamily(family)} ${className}`} dangerouslySetInnerHTML={html} onClick={onClick} style={style}>
      {text}
    </p>
  );
};


Paragraph.defaultProps = {
  type: 'default',
}

export default Paragraph;
