import React, { useState } from 'react';
import './styles.scss';
import { IconText, Icon } from 'components/atoms';

const Modal = ({children, text, zIndex}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="modal-container">
      <button className="open-button" onClick={handleOpenModal}>
        <IconText nameIcon='filter' listIcon='items' text={text} family={'sbsection'}/>
      </button>
      {isModalOpen && (
        <div className="overlay" style={{ zIndex: zIndex}}>
          <div className="modal">
            <div className="close-button" onClick={handleCloseModal}>
              <Icon name='close' list='items' width={35} height={35}/>
            </div>
            <div className="modal-content">
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Modal.defaultProps = {
  zIndex: 1500
};

export default Modal;
