
export const banner_data_centrado = [
    {
      id: 1,
      titulo: 'Términos y condiciones',
      subtitulo: '',
      img_desktop: {
        name: 'banner-terminos-y-condiciones',
        path: 'https://s3.us-east-1.amazonaws.com/ca-webprod/media/9f8b746c-bfb1-42f1-b07c-e35ae15dea99.jpg',
        title: 'Banner Términos y Condiciones',
        alt: 'Banner Términos y Condiciones',
        caption: 'Banner Términos y Condiciones'
      },
      tipo_banner: {
        id: 1,
        titulo: 'landings'
      }
    }
  ]