import React from 'react'
import { IButton } from './props'
import './styles.scss'

const Button = ({ children, className, type, onClick, style }: IButton) => {
  let buttonClass = className || ''

  switch (type) {
    case 'gold':
      buttonClass += 'gold'
      break
    case 'borders':
      buttonClass += 'borders'
      break
    case 'icongold':
      buttonClass += 'icongold'
      break
    case 'black':
      buttonClass += 'black'
      break
    case 'disabled':
      buttonClass += 'disabled'
      break
    default:
      break
  }

  return (
    <button className={buttonClass} onClick={onClick} style={style}>
      {children}
    </button>
  )
}

export default Button
